import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import debounce from 'lodash/debounce';
import * as Action from "../../../../redux/actionTypes";
import { isEqual } from "lodash";
class StaticTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      networkViewData: [],
      view: false,
      viewTemplate: false,
      keyUpdate: "",
      selectedTemplateId: this.props?.id ? this.props?.id : "",
      template_schema_json: {
        details: [],
      },
      templateData: [
        {
          type: "IPv4",
          target: "",
          netmask: "",
          gateway: "",
          metric: "",
          network: "",
          isdeleted: "0"
        }
      ],
      columns: [
        {
          name: "Destination Network ",
          selector: "target",
          cell: (row) => (
            <span
              title={row?.target}
              style={{ width: "auto" }}
              className={this.props.link ? "cursor-pointer" : ''}
            >{this.props.link ?
              <Link
                to={{}}
                className="txt-blue"
                onClick={() => this.ViewStaticTemplate(row)}
              >
                {" "}
                {row?.target}
              </Link> :
              row?.target}
            </span>
          ),
        },
        {
          name: "Destination Type ",
          selector: "type",
          cell: (row) => <span title={row?.type}>{row?.type}</span>,
        },
        {
          name: "Netmask ",
          selector: "type",
          cell: (row) => <span title={row?.netmask}>{row?.netmask}</span>,
        },
        {
          name: "Gateway ",
          selector: "gateway",
          cell: (row) => <span title={row?.gateway}>{row?.gateway}</span>,
        },
        {
          name: "Metric ",
          selector: "metric",
          cell: (row) => <span title={row?.metric}>{row?.metric}</span>,
        },
      ],
      applied: false
    };
  }
  componentDidMount() {
    if (this.props.template_schema_json !== undefined) {
      this.props?.template_schema_json?.details.map((item) => {
        if (this.props.networkViewData && this.props?.networkViewData?.length > 0) {
          let availableNetworks = this.props?.networkViewData?.filter(element => element.ip_address !== "" && element.name !== "Control Tunnel"
            && element.name !== "Data Tunnel");
          let newArr = []
          newArr = availableNetworks.filter(e => e.networkName === item.network)
          if (newArr.length !== 0)
            return item.network = newArr[0].networkName;
          else
            return item.network = "";
        }
      })
      this.setState({
        template_schema_json: this.props.template_schema_json,
        view: this.props.view || false,
      });
    }
    if (this.props.apply && this.props.networkViewData) {
      this.setState({
        networkViewData: this.props?.networkViewData?.filter(element => element.ip_address !== "" && element.name !== "Control Tunnel"
          && element.name !== "Data Tunnel")
      }, () => this.setNetwork())
    }
    if (!this.props.info && this.props?.networkViewData?.length > 0) {
      this.setState({
        networkViewData: this.props.networkViewData.filter(element => element.ip_address !== "" && element.name !== "Control Tunnel"
          && element.name !== "Data Tunnel")
      }, () => {
        this.setNetwork();
        let { columns } = this.state;
        const index = columns.findIndex(item => item.name === 'Network Name');
        if (index < 0) {
          columns.splice(1, 0, {
            name: "Network Name",
            selector: "network",
            minWidth: '150px',
            cell: (row) => {
              return (this.state.view || this.props.reapply
                ? (<span>{this.getNetworkName(row.network)}</span>)
                : (<select
                  className="form-control"
                  onChange={(e) => this.handleChange(e, row)}
                  defaultValue={row.network}>
                  <option value="">Select</option>
                  {
                    (this.state.networkViewData?.filter(element => element.ip_address !== ""
                      && element.name !== "Control Tunnel"
                      && element.name !== "Data Tunnel")?.map((item, index) => {
                        return (<option value={item.networkName}>{item.name}</option>)
                      })
                    )}
                </select>))
            }
          });
          if (this.props.selectedModel === 'Moretti_Model' && this.props.selectedModel === 'Firestorm' &&
            this.props.selectedModel === 'Duvel_Model' && this.props.selectedModel === "Stormbreaker") {
            const index = columns?.findIndex(item => item.name === 'VTY shell');
            if (index > 0) {
              this.setState({
                columns
              })
              columns = columns.filter(item => item.name !== "VTY shell")
            }
          }
          this.setState({
            columns,
            keyUpdate: Math.random()
          })
        }
      })
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.id) {
      this.setState({
        selectedTemplateId: nextProps.id ? nextProps.id : "",
      });
    }
    if (!isEqual(this.props.template_schema_json, nextProps.template_schema_json)) {
      this.setState({
        template_schema_json: nextProps.template_schema_json
      })
    }
    if (!isEqual(this.props.networkViewData, nextProps.networkViewData))
      if (!nextProps.info && nextProps.networkViewData.length > 0) {
        this.setState({
          networkViewData: nextProps.networkViewData.filter(element => element.ip_address !== "" && element.name !== "Control Tunnel"
            && element.name !== "Data Tunnel")
        }, () => {
          let { columns } = this.state;
          const index = columns.findIndex(item => item.name === 'Network Name');
          if (index < 0) {
            columns.splice(1, 0, {
              name: "Network Name",
              selector: "network",
              minWidth: '150px',
              cell: (row) => {
                return (this.state.view || nextProps.reapply ? (<span>{this.getNetworkName(row.network)}</span>) :
                  (<select className="form-control" onChange={(e) => this.handleChange(e, row)} defaultValue={row.network}>
                    <option value="">Select</option>
                    {
                      (this.state.networkViewData.filter(element => element.ip_address !== "" && element.name !== "Control Tunnel"
                        && element.name !== "Data Tunnel").map((item, index) => {
                          return (<option value={item.networkName}>{item.name}</option>)
                        })
                      )}
                  </select>))
              }
            });
            if (nextProps.selectedModel === 'Moretti_Model' && nextProps.selectedModel === 'Firestorm' &&
              nextProps.selectedModel === 'Duvel_Model' && nextProps.selectedModel === "Stormbreaker") {
              const index = columns.findIndex(item => item.name === 'VTY shell');
              if (index > 0) {
                this.setState({
                  columns
                })
                columns = columns.filter(item => item.name !== "VTY shell")
              }
            }
            this.setState({
              columns,
              keyUpdate: Math.random()
            })
          }
        })
      }
  }
  setNetwork = () => {
    let { template_schema_json } = this.state;
    if (this.state.networkViewData.length > 0) {
      template_schema_json.details.map((item) => (
        item.network = this.state.networkViewData?.[0]?.networkName
      ));
    }
  }
  getNetworkName = (name) => {
    const network = this.state.networkViewData?.filter(item => item.networkName === name)?.[0];
    let networkName = ''
    if (network)
      networkName = network.name;
    return networkName;
  }
  ViewStaticTemplate = (data) => {
    this.setState({
      templateData: data,
      viewTemplate: true,
    });
  };
  closeStaticRoute = () => {
    this.setState({
      viewTemplate: false,
    });
  };

  handleChange = (e, row) => {
    let { template_schema_json } = this.state;
    template_schema_json.details?.map((item) => {
      if (item.routename === row.routename)
        item.network = e.target.value;
      return item;
    });
    this.setState({
      template_schema_json
    })
  }
  applyTemplate = () => {
    this.props.dispatch({
      type: Action.SET_APPLY_TEMPLATE,
    });
    this.props.applyTemplate(this.state.template_schema_json);
  }
  debounceApply = debounce((fn, event) => {
    fn(event)
  }, 500);
  checkError = () => {
    let result = false;
    if (this.props.apply) {
      this.state.template_schema_json.details.map((route) => {
        if (route.network === "")
          result = true;
      })
    } else if (
      this.state.networkViewData?.length === 0 || this.props.applying
    )
      result = true;
    return result;
  };
  render() {
    return (
      <div>
        <div className="static-route-template">
          {this.state.template_schema_json?.details?.length > 0 && (
            <div className="gray-bg p-3 rounded mt-3">
              <p>
                <strong>StaticRoute</strong>
              </p>
              {this.state.template_schema_json?.details.filter(item => item?.isdeleted !== '1')?.map((item) => {
                return (
                  <div className="row mb-3">
                    <div className="table-autoheight col-md-12">
                      <div className="lan-table" key={this.state.keyUpdate}>
                        <DataTable
                          columns={this.state.columns}
                          data={this.state.template_schema_json?.details?.filter(
                            (element) => element === item
                          )}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {(!this.props.info && !this.props.view) && (
          <div className="s-footer">
            {/* 1665 reapply page issue */}
            <button
              className="btn btn-light"
              onClick={(this.props.apply && !this.props.edit && !this.props.reapply)
                ? () => this.props.closeAddTemplate()
                : () => this.props.closeApplyTemplate()}
            >
              {/* 1665 reapply page issue */}
              {this.props.apply && !this.props.reapply ? "Back" : "Cancel"}
            </button>
            <button
              className="btn btn-primary"
              disabled={this.checkError()}
              onClick={
                this.props.apply
                  ? (event) => this.debounceApply(this.applyTemplate, event)
                  : ""
              }
            >
              {this.props.apply
                ? "Apply"
                : this.state.selectedTemplateId !== ""
                  ? "Update"
                  : "Save"}
            </button>
          </div>
        )}
        {this.state.viewTemplate && (
          <div className="sidebar-slide second-open zindex10 w-350 r-757">
            <div className="s-header">
              <h1 className="ss-title">View StaticRoute</h1>
              <button>
                <img
                  src="/images/svg-icons/s-cancel.svg"
                  alt=""
                  onClick={() => this.closeStaticRoute()}
                />
              </button>
            </div>
            <div className="s-body">
              <div className="form-group">
                <label>
                  Destination Type
                  <div
                    className="tooltip-c"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Internet protocol version"
                  >
                    <img src="/images/svg-icons/info.svg" alt='' />
                  </div>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="type"
                  value={this.state.templateData?.type}
                  readOnly={this.props.view || this.props.apply}
                />
              </div>
              <div className="form-group">
                <label>
                  Destination Network
                  <div
                    className="tooltip-c"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Target network address of the route"
                  >
                    <img src="/images/svg-icons/info.svg" alt='' />
                  </div>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="target"
                  value={this.state.templateData?.target}
                  readOnly={this.props.view || this.props.apply}
                />
                <div className="error-txt" id="target">
                  Invalid value
                </div>
              </div>
              <div className="form-group">
                <label className="d-block">
                  Netmask
                  <div
                    className="tooltip-c"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Specifies the route netmask"
                  >
                    <img src="/images/svg-icons/info.svg" alt='' />
                  </div>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="netmask"
                  defaultValue={this.state.templateData?.netmask}
                  readOnly={this.props.view || this.props.apply}
                  title="netmask"
                />
              </div>
              <div className="form-group">
                <label>
                  Gateway
                  <div
                    className="tooltip-c"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Specifies the route netmask"
                  >
                    <img src="/images/svg-icons/info.svg" alt='' />
                  </div>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="gateway"
                  value={this.state.templateData?.gateway}
                  readOnly={this.props.view || this.props.apply}
                />
              </div>
              <div className="form-group">
                <label>
                  Metric (Optional)
                  <div
                    className="tooltip-c"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Cost of the route"
                  >
                    <img src="/images/svg-icons/info.svg" alt='' />
                  </div>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="metric"
                  min="1"
                  max="9999"
                  value={this.state.templateData?.metric}
                  readOnly={this.props.view || this.props.apply}
                />
              </div>
              <div>
                <div className="form-group">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <span className="mr-5"><strong>Add route via VTY shell</strong>
                      <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="VTY shell add the route in quagga routing table.The quagga daemon chose the best path among route with same destination and different gateway and  will added it to kernel routing table">
                        <img src="/images/svg-icons/info.svg" alt="" /></div></span>
                    <label className="switch gray">
                      <input
                        type="checkbox"
                        name='isvtysh'
                        disabled={this.props.view || this.props.apply || this.props.selectedModel === 'Moretti_Model' ||
                          this.props.selectedModel === 'Firestorm' || this.props.selectedModel === 'Duvel_Model' || this.props.selectedModel === "Stormbreaker"}
                        checked={this.state.templateData?.isvtysh === '1'} />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    applying: state.displayAddTemplate.applying,
    openStaticTemplate: state.displayAddTemplate.openStaticTemplate,
  };
};
export default connect(mapStateToProps)(StaticTemplate);
