import React, { Component } from "react";
import { connect } from "react-redux";
import $ from 'jquery';
import debounce from 'lodash/debounce';
import * as Action from "../../../../redux/actionTypes";
import * as Common from "../../../../utils/common";
import ApiCall from "../../../../redux/API/apiCall";
import urlMapping from "../../../../redux/API/api";
import { ErrorPopup } from "../../../../layOuts/errorPopup";
import ErrorHandlePopup from "../../../ErrorHandlePopup";
import { Loader } from "../../../../layOuts/Loader";
import isEqual from 'lodash/isEqual';
class ModemTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTemplateId: this.props?.id ? this.props?.id : "",
            template_type: this.props?.templateData?.template_type || "",
            template_name: this.props?.templateData?.template_name || "",
            template_desc: this.props?.templateData?.template_desc || "",
            device_model: this.props?.templateData?.device_model || "",
            collection_version: this.props?.templateData?.collection_version || "",
            fw_version: this.props?.templateData?.fw_version || "",
            chimay: this.props?.chimay,
            cbr: this.props?.cbr,
            mifi: this.props?.mifi,
            editSimInfo: false,
            openAPN: false,
            device_id: '',
            modemData: {},
            enableApply: false,
            enableSwitch: true,
            isAxiosError: false,
            template_schema_json: {
                "data": {
                    "common_config": {
                        "cellular_data": "1",
                        "auto_sim_switch": "0",
                        "roaming": "1",
                        "active_sim": "",

                        "network_tech": "auto",
                        "network_mode_5g": "auto",
                        "is_network_section_modified": "1",

                        "signal_strength_failover": "1",
                        "is_signal_section_modified": "0",

                        "sim_failover": "1",
                        "sim_failover_timer": "120",
                        "is_sim_section_modified": "1"
                    },
                    "sim_config": [
                        {
                            "imsi": "",
                            "sim": "1",

                            "cycle_start_date": "1",
                            "data_limit": "0",
                            "disable_data_on_limit": "0",
                            "is_data_section_modified": "1",

                            "signal_strength": "-110",
                            "signal_failover_timer": "45",

                            "apn_name": "",
                            "apn_auth": "none",
                            "apn_user": "",
                            "apn_pass": "",
                            "apn_conn_type": "IPv4",
                            "is_apn_section_modified": "1"
                        },
                        {
                            "imsi": "",
                            "sim": "2",
                            "cycle_start_date": "1",
                            "data_limit": "0",
                            "disable_data_on_limit": "0",
                            "is_data_section_modified": "1",

                            "signal_strength": "-110",
                            "signal_failover_timer": "45",

                            "apn_name": "",
                            "apn_auth": "none",
                            "apn_user": "",
                            "apn_pass": "",
                            "apn_conn_type": "IPv4",
                            "is_apn_section_modified": "1"
                        }
                    ],
                    "modem_recovery": {
                        "enable": "1",
                        "max_dns_trials": "5",
                        "dns_check_interval": "180",
                        "dns_fail_check_interval": "10",
                        "max_modem_reset": "3",
                        "max_device_reset": "1",
                        "max_sim_switch": "2"
                    }
                }
            },
            simInfoData: {},
            APNInfoData: {
                "apn_name": "",
                "apn_auth": "none",
                "apn_user": "",
                "apn_pass": "",
                "apn_conn_type": "IPv4",
            },
            showError: false,
            selectedModel: this.props?.selectedModel || undefined
        }
    }
    componentDidMount() {
        if (this.props?.template_schema_json !== undefined) {
            let template_schema_json = this.undefinedCheck(JSON.parse(
                JSON.stringify(this.props?.template_schema_json)));
            this.setState({
                device_id: this.props?.device_id || "",
                template_schema_json
            }, () => {
                if (this.props.apply || this.props.reapply || this.props.view)
                    this.getModemInfo()
            });
        }
        if (this.props.edit || this.props.apply) {
            let template_schema_json = this.undefinedCheck(JSON.parse(
                JSON.stringify(this.props.template_schema_json)));
            template_schema_json?.data?.sim_config?.map((sim) => {
                sim.is_data_section_modified = "0";
                sim.is_apn_section_modified = "0";
                return sim;
            });
            template_schema_json.data.common_config.is_network_section_modified = "0";
            template_schema_json.data.common_config.is_signal_section_modified = "0";
            template_schema_json.data.common_config.is_sim_section_modified = "0";
            this.setState({
                template_schema_json
            })
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        {/*Modified template_schema_json for selected model*/ }
        if (
            nextProps?.selectedModelList !== undefined) {
            let selectedModelList = Common.getModels(nextProps?.selectedModelList);
            if (['Moretti_Model', 'Duvel_Model', 'Stormbreaker', 'Orval', 'Firestorm']?.includes(selectedModelList)) {
                $("#Recovery-tab").attr('class', 'nav-link');
                $("#Settings-tab").attr('class', 'nav-link active');
                $("#Recovery").attr('class', 'tab-pane');
                $("#Settings").attr('class', 'tab-content tab-pane active');
            }
            this.setState({
                selectedModel: this.props.selectedModel
                    ? this.props.selectedModel
                    : (selectedModelList
                        ? selectedModelList
                        : undefined)
            });
        }
        if (Object.keys(nextProps.templateData).length !== 0) {
            this.setState({
                selectedTemplateId: nextProps.id ? nextProps.id : '',
                template_type: nextProps?.templateData?.template_type || "",
                template_name: nextProps?.templateData?.template_name || "",
                template_desc: nextProps?.templateData?.template_desc || "",
                device_model: nextProps?.templateData?.device_model || "",
                collection_version: nextProps?.templateData?.collection_version || "",
                fw_version: nextProps?.templateData?.fw_version || "",
                device_id: nextProps?.device_id || ""
            });
            if (!isEqual(this.props?.template_schema_json, nextProps.template_schema_json)) {
                let template_schema_json = this.undefinedCheck(JSON.parse(
                    JSON.stringify(nextProps.template_schema_json)));
                if (nextProps.edit || nextProps.apply) {
                    template_schema_json?.data?.sim_config.map((sim) => {
                        sim.is_data_section_modified = "0";
                        sim.is_apn_section_modified = "0";
                        return sim;
                    })
                    template_schema_json.data.common_config.is_network_section_modified = "0"
                    template_schema_json.data.common_config.is_signal_section_modified = "0";
                    template_schema_json.data.common_config.is_sim_section_modified = "0";
                }
                this.setState({
                    template_schema_json,
                })
            }
            if (nextProps?.device_id !== this.props?.device_id) {
                this.setState({
                    device_id: nextProps?.device_id,
                }, () => {
                    if (nextProps.apply || nextProps.reapply || nextProps.view)
                        this.getModemInfo();
                })
            }
            if (!isEqual(this.props?.chimay, nextProps?.chimay) ||
                !isEqual(this.props?.cbr, nextProps?.cbr) ||
                !isEqual(this.props?.mifi, nextProps?.mifi)) {
                this.setState({
                    chimay: nextProps.chimay,
                    cbr: nextProps.cbr,
                    mifi: nextProps.mifi
                })
            }
        }
    }
    undefinedCheck = (value) => {
        if (value) {
            let { template_schema_json } = this.state;
            template_schema_json = {
                "data": {
                    "common_config": {
                        "cellular_data": value?.data?.common_config?.cellular_data || "1",
                        "auto_sim_switch": value?.data?.common_config?.auto_sim_switch || "0",
                        "roaming": value?.data?.common_config?.roaming || "1",
                        "active_sim": value?.data?.common_config?.active_sim || "",
                        "network_tech": value?.data?.common_config?.network_tech || "auto",
                        "network_mode_5g": value?.data?.common_config?.network_mode_5g || "auto",
                        "is_network_section_modified": value?.data?.common_config?.is_network_section_modified || "1",
                        "signal_strength_failover": value?.data?.common_config?.signal_strength_failover || "1",
                        "is_signal_section_modified": value?.data?.common_config?.is_signal_section_modified || "0",
                        "sim_failover": value?.data?.common_config?.sim_failover || "1",
                        "sim_failover_timer": value?.data?.common_config?.sim_failover_timer || "120",
                        "is_sim_section_modified": value?.data?.common_config?.is_sim_section_modified || "1"
                    },
                    "sim_config": [
                        {
                            "imsi": value?.data?.sim_config?.[0]?.imsi || "",
                            "sim": value?.data?.sim_config?.[0]?.sim || "1",
                            "cycle_start_date": value?.data?.sim_config?.[0]?.cycle_start_date || "1",
                            "data_limit": value?.data?.sim_config?.[0]?.data_limit || "0",
                            "disable_data_on_limit": value?.data?.sim_config?.[0]?.disable_data_on_limit || "0",
                            "is_data_section_modified": value?.data?.sim_config?.[0]?.is_data_section_modified || "1",
                            "signal_strength": value?.data?.sim_config?.[0]?.signal_strength || "-110",
                            "signal_failover_timer": value?.data?.sim_config?.[0]?.signal_failover_timer || "45",
                            "apn_name": value?.data?.sim_config?.[0]?.apn_name || "",
                            "apn_auth": value?.data?.sim_config?.[0]?.apn_auth || "none",
                            "apn_user": value?.data?.sim_config?.[0]?.apn_user || "",
                            "apn_pass": value?.data?.sim_config?.[0]?.apn_pass || "",
                            "apn_conn_type": value?.data?.sim_config?.[0]?.apn_conn_type || "IPv4",
                            "is_apn_section_modified": value?.data?.sim_config?.[0]?.is_apn_section_modified || "1"
                        },
                        {
                            "imsi": value?.data?.sim_config?.[1]?.imsi || "",
                            "sim": value?.data?.sim_config?.[1]?.sim || "2",
                            "cycle_start_date": value?.data?.sim_config?.[1]?.cycle_start_date || "1",
                            "data_limit": value?.data?.sim_config?.[1]?.data_limit || "0",
                            "disable_data_on_limit": value?.data?.sim_config?.[1]?.disable_data_on_limit || "0",
                            "is_data_section_modified": value?.data?.sim_config?.[1]?.is_data_section_modified || "1",
                            "signal_strength": value?.data?.sim_config?.[1]?.signal_strength || "-110",
                            "signal_failover_timer": value?.data?.sim_config?.[1]?.signal_failover_timer || "45",
                            "apn_name": value?.data?.sim_config?.[1]?.apn_name || "",
                            "apn_auth": value?.data?.sim_config?.[1]?.apn_auth || "none",
                            "apn_user": value?.data?.sim_config?.[1]?.apn_user || "",
                            "apn_pass": value?.data?.sim_config?.[1]?.apn_pass || "",
                            "apn_conn_type": value?.data?.sim_config?.[1]?.apn_conn_type || "IPv4",
                            "is_apn_section_modified": value?.data?.sim_config?.[1]?.is_apn_section_modified || "1"
                        }
                    ],
                    "modem_recovery": {
                        "enable": value?.data?.modem_recovery?.enable || "1",
                        "max_dns_trials": value?.data?.modem_recovery?.max_dns_trials || "5",
                        "dns_check_interval": value?.data?.modem_recovery?.dns_check_interval || "180",
                        "dns_fail_check_interval": value?.data?.modem_recovery?.dns_fail_check_interval || "10",
                        "max_modem_reset": value?.data?.modem_recovery?.max_modem_reset || "3",
                        "max_device_reset": value?.data?.modem_recovery?.max_device_reset || "1",
                        "max_sim_switch": value?.data?.modem_recovery?.max_sim_switch || "2"
                    }
                }
            }
            return template_schema_json
        }
    }
    getModemInfo = () => {
        let data = {
            device_id: this.state.device_id
        }
        if ((this.state.device_id !== undefined) && (this.state.device_id !== '')) {
            this.setState({ showLoader: true });
            //EMGT-351-EM - Modem Template Field Validation-Issue 4,Issue -5
            ApiCall(urlMapping.modemInfo(data), (response) => {
                if (response?.Success) {
                    this.setState({
                        modemData: response?.data,
                        showLoader: false
                    }, () => {
                        if (!this.props.edit && !this.props.view) {
                            let { template_schema_json } = this.state;
                            let { sim_config } = template_schema_json.data;
                            template_schema_json.data.common_config.active_sim = this.state.modemData.Slots_Info?.data?.filter(item => item.card_state === 'PRESENT')?.[0]?.imsi;
                            sim_config.map((item) => {
                                if (item.sim === "1") {
                                    item.imsi = this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')?.[0]?.imsi;
                                } else if (item.sim === "2") {
                                    item.imsi = this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')?.[0]?.imsi;
                                }
                            })
                            this.setState({
                                template_schema_json
                            }, () => this.checkSimStatus())
                        } else {
                            this.checkSimStatus()
                        }
                    })
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showRefreshModal: true, showLoader: false })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({ showLoader: false });
                    }
                }
            })
        }
    }
    openSimInfo = (data) => {
        this.setState({
            editSimInfo: true,
            simInfoData: JSON.parse(JSON.stringify(data))
        })
    }
    closeSimInfo = () => {
        const elements = document.getElementsByClassName("error-txt sim");
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.cssText = "display: none;"
        }
        this.setState({
            editSimInfo: false,
            showError: false
        })
    }
    getCycleRateOptions = () => {
        const options = [];
        for (let i = 1; i <= 28; i++) {
            options.push(<option>{i}</option>)
        }
        return options;
    }
    getSignalStrengthOptions = () => {
        const options = [];
        for (let i = -130; i <= -60; i++) {
            options.push(<option>{i}</option>)
        }
        return options;
    }
    getSignalStrengthTimer = () => {
        const options = [];
        for (let i = 10; i <= 300; i++) {
            options.push(<option>{i}</option>)
        }
        return options;
    }
    getSimTimer = () => {
        const options = [];
        for (let i = 120; i <= 1440; i++) {
            options.push(<option>{i}</option>)
        }
        return options;
    }
    openAPNInfo = () => {
        let { APNInfoData } = this.state;
        let { simInfoData } = this.state;
        APNInfoData.apn_name = simInfoData.apn_name
        APNInfoData.apn_auth = simInfoData.apn_auth
        APNInfoData.apn_user = simInfoData.apn_user
        APNInfoData.apn_pass = simInfoData.apn_pass
        APNInfoData.apn_conn_type = simInfoData.apn_conn_type
        this.setState({
            APNInfoData,
            openAPN: true
        }, () =>
            $("#exampleModal").modal("show"))
    }
    closeAPNInfo = () => {
        $("#exampleModal").modal("hide")
        this.setState({
            openAPN: false
        })
    }
    setValues = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                showError: false
            })
        }
        let { template_schema_json } = this.state;
        const name = e.target.name
        const value = e.target.type === "checkbox" ? e.target.checked === true ? "1" : "0" : e.target.value;
        template_schema_json.data.common_config[name] = value;
        if (name === "cellular_data" && value === "0") {
            template_schema_json.data.common_config["auto_sim_switch"] = "0";
            template_schema_json.data.common_config["roaming"] = "0";
        }
        //EMGT-351-EM - Modem Template Field Validation-Issue 3
        if (e.target.name === "activeSimSelection") {
            template_schema_json.data.common_config["active_sim"] = e.target.value
            template_schema_json?.data?.sim_config?.map((sim) => {
                sim.is_data_section_modified = "1";
                sim.is_apn_section_modified = "1";
                return sim;
            });
            template_schema_json.data.common_config.is_signal_section_modified = "1";
        }
        this.setState({
            template_schema_json
        })
    }
    setSimValues = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                showError: false
            })
        }
        let { simInfoData } = this.state;
        const name = e.target.name
        const value = e.target.type === "checkbox" ? e.target.checked === true ? "1" : "0" : e.target.value;
        if (name === 'data_limit') {
            if (Common.validateNumeric(e))
                simInfoData[name] = value;
            else
                simInfoData[name] = this.state.simInfoData.data_limit;
        }
        else {
            simInfoData[name] = value;
        }
        if (name === 'signal_strength_failover') {
            if (e.target.checked === false)
                simInfoData.signal_failover_timer = "45"
        }
        if (name === 'sim_failover') {
            if (e.target.checked === false)
                simInfoData.sim_failover_timer = "120"
        }
        if (simInfoData[name].trim() === '') {
            const element = document.getElementById(name);
            if (element) {
                element.style.display = "block";
                this.setState({ showError: true })
            }
        }
        this.setState({
            simInfoData
        })
    }
    applyTemplate = () => {
        let { template_schema_json } = this.state;
        template_schema_json = JSON.parse(JSON.stringify(this.state.template_schema_json));
        if (this.props.apply || this.props.edit) {
            if (this.state.template_schema_json !== this.props.template_schema_json) {
                template_schema_json.data.sim_config.map((sim) => {
                    let simInfo = this.props.edit ?
                        this.props.template_schema_json.data.sim_config.filter(item => item.imsi === sim.imsi)[0] :
                        this.props.template_schema_json.data.sim_config.filter(item => item.sim === sim.sim)[0];
                    if (sim.cycle_start_date !== simInfo?.cycle_start_date ||
                        sim.data_limit !== simInfo?.data_limit ||
                        sim.disable_data_on_limit !== simInfo?.disable_data_on_limit) {
                        sim.is_data_section_modified = "1";
                    }
                    if (sim.signal_strength !== simInfo?.signal_strength ||
                        sim.signal_failover_timer !== simInfo?.signal_failover_timer ||
                        template_schema_json?.data?.common_config?.signal_strength_failover !== this.props.template_schema_json.data?.common_config?.signal_strength_failover) {
                        template_schema_json.data.common_config.is_signal_section_modified = "1";
                    }
                    if (sim.apn_name !== simInfo?.apn_name || sim.apn_pass !== simInfo?.apn_pass ||
                        sim.apn_auth !== simInfo?.apn_auth || sim.apn_conn_type !== simInfo?.apn_conn_type ||
                        sim.apn_user !== simInfo?.apn_user) {
                        sim.is_apn_section_modified = "1";
                    }
                    return sim;
                })
                if (template_schema_json.data.common_config.network_tech !== this.props.template_schema_json.data?.common_config?.network_tech ||
                    template_schema_json.data.common_config.network_mode_5g !== this.props.template_schema_json.data?.common_config?.network_mode_5g) {
                    template_schema_json.data.common_config.is_network_section_modified = "1"
                }
                if (template_schema_json.data.common_config.sim_failover !== this.props.template_schema_json.data?.common_config?.sim_failover ||
                    template_schema_json.data.common_config.sim_failover_timer !== this.props.template_schema_json.data?.common_config?.sim_failover_timer) {
                    template_schema_json.data.common_config.is_sim_section_modified = "1";
                }
            }
        }
        if (this.props.apply && !this.props.edit && !this.props.reapply) {
            template_schema_json?.data?.sim_config?.map((sim) => {
                sim.is_data_section_modified = "1";
                sim.is_apn_section_modified = "1";
                return sim;
            });
            template_schema_json.data.common_config.is_network_section_modified = "1";
            template_schema_json.data.common_config.is_signal_section_modified = "1";
            template_schema_json.data.common_config.is_sim_section_modified = "1";
        }
        this.props.dispatch({
            type: Action.SET_APPLY_TEMPLATE,
        });
        $("#exampleModal").modal("hide");
        this.props.applyTemplate(template_schema_json);
    }
    debounceApply = debounce((fn, event) => {
        fn(event)
    }, 500);
    saveData = () => {
        let data = {};
        data.template_name = this.state.template_name;
        data.template_desc = this.state.template_desc;
        data.device_model = this.props?.selectedModelList ? this.props?.selectedModelList?.toString() : this.state.device_model.toString();
        data.template_type = this.state.template_type;
        data.collection_version = this.state.collection_version;
        data.fw_version = this.state.fw_version;
        data.template_order = 14;
        data.template_schema_json = JSON.parse(JSON.stringify(this.state.template_schema_json));
        if (this.state.selectedTemplateId === "") {
            data.template_version = {
                chimay: this.state.chimay,
                cbr: this.state.cbr,
                mifi: this.state.mifi
            }
        }
        this.setState({
            showLoader: true,
        });
        if (this.state.selectedTemplateId !== "") {
            ApiCall(
                urlMapping.updateTemplate(this.state.selectedTemplateId, data),
                (response) => {
                    this.setState({
                        showLoader: false,
                    });
                    if (response?.Success) {
                        this.props.closeAddTemplate();
                        this.props.refreshList();
                        this.props.openApplyTemplate(this.state.selectedTemplateId);
                    } else {
                        if (parseInt(response?.response?.status) === 401) {
                            this.setState({ showRefreshModal: true });
                        }
                        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                            this.setState({
                                title: 'Network Error',
                                message: 'Something went wrong. Please try again later.',
                                showLoader: false,
                                isAxiosError: true,
                                delete: false,
                                error: true
                            }, () => $("#errorMessage").modal("show"))
                        }
                        else {
                            this.setState(
                                {
                                    title: "Update Template",
                                    message: response?.message,
                                    show: true,
                                    error: true,
                                },
                                () => $("#errorMessage").modal("show")
                            );
                        }
                    }
                }
            );
        } else {
            ApiCall(urlMapping.createTemplate(data), (response) => {
                this.setState({
                    showLoader: false,
                });
                if (response?.success) {
                    this.props.refreshList();
                    this.props.openApplyTemplate(response?.data?.id);
                    this.props.closeAddTemplate();
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showRefreshModal: true })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState(
                            {
                                title: "Create Template",
                                message: response?.message,
                                show: true,
                                error: true,
                            },
                            () => $("#errorMessage").modal("show")
                        );
                    }
                }
            });
        }
    }
    resetPopup = () => {
        this.setState(
            {
                show: false,
                error: false,
            },
            () => {
                $("#errorMessage").modal("hide");
                this.props.refreshList();
                this.props.closeAddTemplate();
            }
        );
    };
    saveSimInfo = () => {
        let result = false;
        const { template_schema_json } = this.state;
        const elements = document.getElementsByClassName('error-txt sim');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (result === false) {
            template_schema_json.data.sim_config =
                template_schema_json.data.sim_config.filter(item => item.sim !== this.state.simInfoData.sim);
            template_schema_json.data.sim_config.push(this.state.simInfoData);
            this.setState({ template_schema_json }, () => this.closeSimInfo())
        }
    }
    checkSimError = () => {
        let result = false;
        const elements = document.getElementsByClassName("error-txt sim");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        return result;
    }
    checkError = () => {
        let result = false;
        if (this.props.applying)
            result = true;
        const elements = document.getElementsByClassName('error-txt');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (this.state.template_name === '' ||
            this.state.template_type === '' ||
            this.state.template_schema_json.data.modem_recovery?.dns_check_interval === '' ||
            this.state.template_schema_json.data.modem_recovery?.dns_fail_check_interval === '')
            result = true;
        if ((this.props.apply || this.props.reapply) && !this.state.enableApply) {
            result = true;
        }
        return result;
    }
    setAPNValues = (e, list) => {
        if (Common.removeError(e)) {
            this.setState({
                showError: false
            })
        }
        let { APNInfoData } = this.state;
        const name = 'apn_name' && list ? e.target.dataset.name : e.target.name
        const value = 'apn_name' && list ? e.target.dataset.value : e.target.value;
        APNInfoData[name] = value;
        if (list) {
            const element = document.getElementById('apn_name');
            element.style.display = "none";
        }
        if (APNInfoData.apn_auth !== 'none' && (name === 'apn_user' || name === 'apn_pass')) {
            if (value === '') {
                const element = document.getElementById(name);
                element.style.display = "block";
                this.setState({ showError: true })
            }
        } else {
            if (value === '' && name === 'apn_name') {
                const element = document.getElementById(name);
                element.style.display = "block";
                this.setState({ showError: true })
            }
        }
        if (name === 'apn_auth') {
            if (value === 'none') {
                APNInfoData['apn_user'] = '';
                APNInfoData['apn_pass'] = "";
                let element = document.getElementById('apn_user');
                element.style.display = "none";
                element = document.getElementById('apn_pass');
                element.style.display = "none";
                this.setState({ showError: false })
            }
        }
        if (name === 'apn_name') {
            if (value === this.state.modemData.Apn_Info.filter((val) => val.imsi === this.state?.simInfoData?.imsi)?.[0]?.apn) {
                APNInfoData['apn_user'] = this.state.modemData.Apn_Info.filter((val) => val.imsi === this.state?.simInfoData?.imsi)?.[0]?.username;
                APNInfoData['apn_pass'] = this.state.modemData.Apn_Info.filter((val) => val.imsi === this.state?.simInfoData?.imsi)?.[0]?.password;
                APNInfoData['apn_auth'] = this.state.modemData.Apn_Info.filter((val) => val.imsi === this.state?.simInfoData?.imsi)?.[0]?.authentication;
                APNInfoData['apn_conn_type'] = this.state.modemData.Apn_Info.filter((val) => val.imsi === this.state?.simInfoData?.imsi)?.[0]?.pdp;
            } else {
                APNInfoData['apn_user'] = "";
                APNInfoData['apn_pass'] = "";
                APNInfoData['apn_auth'] = "none";
                APNInfoData['apn_conn_type'] = "IPv4";
            }
        }
        this.setState({
            APNInfoData
        })
    }
    checkDomainName = (e) => {
        let str = (e.target.value).trim();
        if (str.length < 8 || str.length > 128) {
            const element = document.getElementById(e.target.name);
            element.style.display = "block";
            this.setState({ showError: true })
        } else {
            let { APNInfoData } = this.state;
            APNInfoData.apn_name = e.target.value;
            const element = document.getElementById(e.target.name);
            element.style.display = "none";
            this.setState({ APNInfoData, showError: false })
        }
    }
    checkAPNError = () => {
        let result = false;
        const elements = document.getElementsByClassName('error-txt apn');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (this.state.APNInfoData.apn_auth !== 'none' && (this.state.APNInfoData.apn_user === '' ||
            this.state.APNInfoData.apn_pass === '')) {
            result = true;
        }
        if (this.state.APNInfoData.apn_name === '') {
            result = true;
        }
        return result;
    }
    saveAPNInfo = () => {
        let result = false;
        const { simInfoData } = this.state;
        const elements = document.getElementsByClassName('error-txt apn');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (result === false) {
            simInfoData.apn_auth = this.state.APNInfoData.apn_auth;
            simInfoData.apn_name = this.state.APNInfoData.apn_name;
            simInfoData.apn_user = this.state.APNInfoData.apn_user;
            simInfoData.apn_conn_type = this.state.APNInfoData.apn_conn_type;
            simInfoData.apn_pass = this.state.APNInfoData.apn_pass;
        }
        this.setState({ simInfoData },
            () => this.closeAPNInfo())
    }
    checkSimStatus = () => {
        let { enableApply, enableSwitch } = this.state;
        if ((this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')[0].card_state === 'PRESENT' &&
            this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')[0].card_state === 'PRESENT') ||
            ((this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')[0].sim_state === 'SIM READY' ||
                this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')[0].sim_state === 'SIM CONNECTED') &&
                (this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')[0].sim_state === 'SIM READY' ||
                    this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')[0].sim_state === 'SIM CONNECTED'))) {
            enableApply = true;
            enableSwitch = true;
        }
        else if ((this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')[0].card_state === 'PRESENT' &&
            this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')[0].card_state !== 'PRESENT') ||
            ((this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')[0].sim_state === 'SIM READY' ||
                this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')[0].sim_state === 'SIM CONNECTED') &&
                (this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')[0].sim_state !== 'SIM READY' ||
                    this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')[0].sim_state !== 'SIM CONNECTED'))) {
            enableApply = true;
            enableSwitch = false;
        }
        else if ((this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')[0].card_state !== 'PRESENT' &&
            this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')[0].card_state === 'PRESENT') ||
            ((this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')[0].sim_state !== 'SIM READY' ||
                this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')[0].sim_state !== 'SIM CONNECTED') &&
                (this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')[0].sim_state === 'SIM READY' ||
                    this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')[0].sim_state === 'SIM CONNECTED'))) {
            enableApply = true;
            enableSwitch = false;
        }
        else if ((this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')[0].card_state !== 'PRESENT' &&
            this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')[0].card_state !== 'PRESENT')) {
            enableApply = false;
            enableSwitch = false;
        }
        this.setState({
            enableApply,
            enableSwitch
        })
    }
    closeAll = () => {
        this.props.dispatch({
            type: Action.CLOSE_APPLY_TEMPLATE,
        });
        this.props.closeAddTemplate();
    };
    setModemRecovery = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                showError: true
            })
        }
        let { template_schema_json } = this.state;
        const value = (e.target.type === "checkbox") ?
            (e.target.checked === true ? "1" : "0") : e.target.value;
        template_schema_json.data.modem_recovery[e.target.name] = value;
        if (e.target.name === 'enable' && value === "0") {
            template_schema_json.data.modem_recovery.max_dns_trials = "5";
            template_schema_json.data.modem_recovery.dns_check_interval = "180";
            template_schema_json.data.modem_recovery.dns_fail_check_interval = "10";
            template_schema_json.data.modem_recovery.max_modem_reset = "3";
            template_schema_json.data.modem_recovery.max_device_reset = "1";
            template_schema_json.data.modem_recovery.max_sim_switch = "2";
        }
        if (e.target.name === "dns_check_interval") {
            if (value === '' || (parseInt(value) < 10 || parseInt(value) > 7200) || (!Common.validateNumeric(e))) {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                this.setState({ showError: true })
            }
        } else if (e.target.name === "dns_fail_check_interval") {
            if (value === '' || (parseInt(value) < 5 || parseInt(value) > 60) || (!Common.validateNumeric(e))) {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                this.setState({ showError: true })
            }
        }
        this.setState({
            template_schema_json
        })
    }
    render() {
        return (
            <div className="modem-template tab-box">
                {this.state.showLoader && <Loader />}
                <ul className="tab-nav nav nav-pills bg-gray" id="wanconfig-tab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="Settings-tab" data-toggle="tab" href="#Settings" role="tab" aria-controls="Settings" aria-selected="true">Modem Settings</a>
                    </li>
                    {(this.state.selectedModel !== 'Moretti_Model' &&
                        this.state.selectedModel !== 'Duvel_Model' &&
                        this.state.selectedModel !== "Stormbreaker" &&
                        this.state.selectedModel !== "Orval" &&
                        this.state.selectedModel !== "Firestorm") &&
                        <li className="nav-item">
                            <a className="nav-link" id="Recovery-tab" data-toggle="tab" href="#Recovery" role="tab" aria-controls="Recovery" aria-selected="false">Modem Recovery</a>
                        </li>}
                </ul>
                <div className="tab-content mt-2">
                    <div className="tab-pane active" id="Settings" role="tabpanel" aria-labelledby="Settings-tab">
                        {(this.state.selectedModel !== 'Duvel_Model' &&
                            this.state.selectedModel !== "Orval" &&
                            this.state.selectedModel !== "Firestorm") &&
                            <div className="d-flex align-items-center mt-3 mb-3">
                                <div className="col-md-4">Cellular Data
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Enable/disable cellular network connection in the device">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </div>
                                <label className={this.props.info ? "switch gray ml-5" : "switch ml-5"}>
                                    <input
                                        type="checkbox"
                                        name="cellular_data"
                                        onClick={this.setValues}
                                        disabled={this.props.info || this.props.view || this.props.reapply}
                                        checked={this.state.template_schema_json?.data?.common_config?.cellular_data === "1"} />
                                    <span className="slider round"></span>
                                </label>
                            </div>}
                        {(this.state.selectedModel !== 'Bradshaw' &&
                            this.state.selectedModel !== 'Sander' &&
                            this.state.selectedModel !== 'Duvel_Model' &&
                            this.state.selectedModel !== "Orval" &&
                            this.state.selectedModel !== "Firestorm") &&
                            <div className="d-flex align-items-center mb-3">
                                <div className="col-md-4">Enable Auto SIM Switching
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Enable/disable the automatic switching of the SIM(s) in the device, when both slot has SIM">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </div>
                                <label className={this.props.info ? "switch gray ml-5" : "switch ml-5"}>
                                    <input
                                        type="checkbox"
                                        name="auto_sim_switch"
                                        onClick={this.setValues}
                                        disabled={(this.props.info || this.props.view || this.props.reapply) ||
                                            this.state?.template_schema_json?.data?.common_config?.cellular_data === "0" ||
                                            !this.state.enableSwitch
                                        }
                                        checked={this.state?.template_schema_json?.data?.common_config?.auto_sim_switch === "1"} />
                                    <span className="slider round"></span>
                                </label>
                            </div>}
                        <div className="d-flex align-items-center mb-3">
                            <div className="col-md-4">Enable Roaming
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Enable/disable the roaming functionality of the SIM(s) present in the device">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </div>
                            <label className={this.props.info ? "switch gray ml-5" : "switch ml-5"}>
                                <input
                                    type="checkbox"
                                    name="roaming"
                                    onClick={this.setValues}
                                    disabled={(this.props.info || this.props.view || this.props.reapply) ||
                                        this.state?.template_schema_json?.data?.common_config?.cellular_data === "0"}
                                    checked={this.state?.template_schema_json?.data?.common_config?.roaming === "1"} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        {(this.state.selectedModel !== 'Duvel_Model' &&
                            this.state.selectedModel !== "Orval" &&
                            this.state.selectedModel !== "Firestorm") &&
                            <div className="d-flex align-items-center mb-3">
                                <div className="col-md-4">Signal Strength Fallback
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Enable/Disable the signal strength based Fallback mechanism">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </div>
                                <label className="switch ml-5">
                                    <input
                                        type="checkbox"
                                        name="signal_strength_failover"
                                        onChange={this.setValues}
                                        checked={this.state?.template_schema_json?.data?.common_config?.signal_strength_failover === '1'}
                                        disabled={(this.props.info || this.props.view || this.props.reapply)}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </div>}
                        {(this.state.selectedModel !== 'Duvel_Model' &&
                            this.state.selectedModel !== "Orval" &&
                            this.state.selectedModel !== "Firestorm") &&
                            <div className="d-flex align-items-center mb-3">
                                <div className="col-md-4">SIM Fallback
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Enable/Disable the SIM failover">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </div>
                                <label className="switch ml-5">
                                    <input
                                        type="checkbox"
                                        name="sim_failover"
                                        onChange={this.setValues}
                                        disabled={(this.props.info || this.props.view || this.props.reapply)}
                                        checked={this.state?.template_schema_json?.data?.common_config?.sim_failover === "1"} />
                                    <span className="slider round"></span>
                                </label>
                            </div>}
                        {(this.state?.template_schema_json?.data?.common_config?.sim_failover === "1" &&
                            (this.state.selectedModel !== 'Duvel_Model' &&
                                this.state.selectedModel !== "Orval" &&
                                this.state.selectedModel !== "Firestorm")) &&
                            <div className="d-flex align-items-center mb-3">
                                <div className="col-md-4">SIM Fallback Timer
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Threshold for the SIM Fallback timer limit">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <select
                                        className="form-control"
                                        value={this.state?.template_schema_json?.data?.common_config?.sim_failover_timer}
                                        name="sim_failover_timer"
                                        disabled={(this.props.info || this.props.view || this.props.reapply)}
                                        onChange={this.setValues}>
                                        {this.getSimTimer()}
                                    </select>
                                </div>
                                <span className={(this.props.info || this.props.view || this.props.reapply) ? "SIM_Fallback_Units Outside" : "SIM_Fallback_Units"}>Minutes</span>
                            </div>}
                        {(this.props.apply || this.props.edit || this.props.reapply || this.props.view) &&
                            <>
                                <div className="d-flex align-items-center mb-3">
                                    <div className="col-md-4">Network Technology
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                            title="Network technology to be used by the device to connect to the cellular network">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <select
                                            className="form-control"
                                            value={this.state?.template_schema_json?.data?.common_config?.network_tech}
                                            name="network_tech" onChange={this.setValues}
                                            disabled={(this.props.info || this.props.view || this.props.reapply) ||
                                                this.state?.template_schema_json?.data?.common_config?.cellular_data === "0"}
                                        >
                                            <option value="auto">AUTO(LTE/5G)</option>
                                            <option value="4g">4G LTE</option>
                                            <option value="5g">5G</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-3">
                                    <div className="col-md-4">5G Network Mode
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                            title="In 5G Network, the mode to be used by the device to connect to the 5G cellular network">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <select
                                            className="form-control"
                                            value={this.state?.simInfoData?.["network_mode_5g"]}
                                            disabled={(this.state?.template_schema_json?.data?.common_config?.network_tech !== 'auto' && this.state?.template_schema_json?.data?.common_config?.network_tech !== '5g')
                                                || ((this.props.info || this.props.view || this.props.reapply) ||
                                                    this.state?.template_schema_json?.data?.common_config?.cellular_data === "0")
                                            }
                                            name="network_mode_5g"
                                            onChange={this.setValues}>
                                            <option value="auto">{(
                                                this.state.selectedModel !== 'Duvel_Model' &&
                                                this.state.selectedModel !== "Orval" &&
                                                this.state.selectedModel !== "Firestorm") ? "AUTO(SA/NSA)" : "AUTO(SA)"}</option>
                                            {(this.state.selectedModel !== 'Duvel_Model' &&
                                                this.state.selectedModel !== "Orval" &&
                                                this.state.selectedModel !== "Firestorm") &&
                                                <option value="nsa">NSA</option>}
                                            <option value="sa">SA</option>
                                        </select>
                                    </div>
                                </div>
                            </>}
                        {
                            (this.props.apply || this.props.edit || this.props.reapply || this.props.view) && !this.props.info && <>
                                <div className="d-flex align-items-center mb-3">
                                    <div className="col-md-4">
                                        <label className="text-nowrap mb-0 mr-3">
                                            <strong>Active SIM Selection</strong>
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                title="Select the SIM which is to be in active state">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                        </label>
                                    </div>
                                    {/*EMGT-351-EM - Modem Template Field Validation-Issue 3 */}
                                    <div className="col-md-4">
                                        <select
                                            className="form-control"
                                            name="activeSimSelection"
                                            onChange={this.setValues}
                                            value={this.state?.template_schema_json?.data?.common_config?.active_sim}
                                            disabled={this.props.info || this.props.view || this.props.reapply}>
                                            {this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')?.[0]?.card_state === 'PRESENT' &&
                                                <option value={this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')?.[0]?.imsi}>SIM 1</option>}
                                            {this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')?.[0]?.card_state === 'PRESENT' &&
                                                <option value={this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')?.[0]?.imsi}>SIM 2</option>}
                                        </select>
                                    </div>
                                </div>
                                <p className="mt-3 mb-1">
                                    <strong>SIM Status</strong>
                                </p>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>SIM Slot</th>
                                            <th>IMSI</th>
                                            <th>Carrier</th>
                                            <th>Connection Status</th>
                                            <th>Selection</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>{this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')?.[0]?.card_state === 'PRESENT' ?
                                                this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')?.[0]?.imsi : '-'}</td>
                                            <td>{this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')?.[0]?.card_state === 'PRESENT' ?
                                                this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')?.[0]?.sim_carrier : '-'}</td>
                                            <td>{this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')?.[0]?.card_state === 'PRESENT' ?
                                                this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')?.[0]?.sim_state : '-'}</td>
                                            <td>{(this.state.template_schema_json.data.common_config.active_sim ===
                                                this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')?.[0]?.imsi) &&
                                                this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')?.[0]?.imsi !== undefined &&
                                                this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')?.[0]?.imsi !== '' ? 'Active' : 'Inactive'}</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>{this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')?.[0]?.card_state === 'PRESENT' ?
                                                this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')?.[0]?.imsi : '-'}</td>
                                            <td>{this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')?.[0]?.card_state === 'PRESENT' ?
                                                this.state.modemData.Slots_Info.data?.filter(item => item.slot === '2')?.[0]?.sim_carrier : '-'}</td>
                                            <td>{this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')?.[0]?.card_state === 'PRESENT' ?
                                                this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')?.[0]?.sim_state : '-'}</td>
                                            <td>{(this.state.template_schema_json.data.common_config.active_sim ===
                                                this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')?.[0]?.imsi) &&
                                                this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')?.[0]?.imsi !== undefined &&
                                                this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')?.[0]?.imsi !== '' ? 'Active' : 'Inactive'}</td>
                                        </tr>
                                    </tbody>
                                </table></>
                        }
                        <div className="gray-bg p-3">
                            <div className="row">
                                <div className="col-md-4">
                                    <strong>Parameters</strong>
                                </div>
                                <div className="col-md-4">
                                    <strong>SIM 1 Configuration</strong>
                                    <span className={this.props.info || this.props.view || this.props.reapply || (this.props.apply &&
                                        this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')?.[0]?.card_state !== 'PRESENT') ? "round-btn disabled" :
                                        "round-btn"}>
                                        <img
                                            src="/images/svg-icons/edit-white.svg"
                                            alt=""
                                            onClick={this.props.info || this.props.view || this.props.reapply || (this.props.apply &&
                                                this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '1')?.[0]?.card_state !== 'PRESENT') ? undefined :
                                                () => this.openSimInfo(this.state.template_schema_json?.data?.sim_config.filter(item => item.sim === '1')?.[0])} /></span>
                                </div>
                                <div className="col-md-4">
                                    <strong>SIM 2 Configuration</strong>
                                    <span className={this.props.info || this.props.view || this.props.reapply || (this.props.apply &&
                                        this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')?.[0]?.card_state !== 'PRESENT') ? "round-btn disabled" :
                                        "round-btn"}>
                                        <img
                                            src="/images/svg-icons/edit-white.svg"
                                            alt=""
                                            onClick={this.props.info || this.props.view || this.props.reapply || (this.props.apply &&
                                                this.state.modemData.Slots_Info?.data?.filter(item => item.slot === '2')?.[0]?.card_state !== 'PRESENT') ? undefined :
                                                () => this.openSimInfo(this.state.template_schema_json?.data?.sim_config?.filter(item => item.sim === '2')?.[0])} />
                                    </span>
                                </div>
                            </div>
                            {(this.state.selectedModel !== 'Bradshaw' &&
                                this.state.selectedModel !== 'Orval' &&
                                this.state.selectedModel !== 'Sander' &&
                                this.state.selectedModel !== 'Duvel_Model' &&
                                this.state.selectedModel !== "Firestorm") &&
                                <>
                                    <div className="row mt-2">
                                        <div className="col-md-4">Cycle Start Date:</div>
                                        <div className="col-md-4">{this.state.template_schema_json?.data?.sim_config?.filter(item => item.sim === '1')?.[0]?.["cycle_start_date"]}</div>
                                        <div className="col-md-4">{this.state.template_schema_json?.data?.sim_config?.filter(item => item.sim === '2')?.[0]?.["cycle_start_date"]}</div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-4">Data Limit:</div>
                                        <div className="col-md-4">{this.state.template_schema_json?.data?.sim_config?.filter(item => item.sim === '1')?.[0]?.["data_limit"]} MB</div>
                                        <div className="col-md-4">{this.state.template_schema_json?.data?.sim_config?.filter(item => item.sim === '2')?.[0]?.["data_limit"]} MB</div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-4">Disable Data On Max Limit:</div>
                                        {/* EMGT-351-EM - Modem Template Field Validation-Issue 2 */}
                                        <div className="col-md-4">{this.state.template_schema_json?.data?.sim_config?.filter(item => item.sim === '1')?.[0]?.["disable_data_on_limit"] === "1" ?
                                            "Enabled" : "Disabled"}</div>
                                        <div className="col-md-4">{this.state.template_schema_json?.data?.sim_config?.filter(item => item.sim === '2')?.[0]?.["disable_data_on_limit"] === "1" ?
                                            "Enabled" : "Disabled"}</div>
                                    </div>
                                    {this.state.template_schema_json?.data?.common_config?.signal_strength_failover === '1' && <><div className="row mt-2">
                                        <div className="col-md-4">Signal Strength:</div>
                                        <div className="col-md-4">{this.state.template_schema_json?.data?.sim_config?.filter(item => item.sim === '1')?.[0]?.["signal_strength"]} dBm</div>
                                        <div className="col-md-4">{this.state.template_schema_json?.data?.sim_config?.filter(item => item.sim === '2')?.[0]?.["signal_strength"]} dBm</div>
                                    </div>
                                        <div className="row mt-2">
                                            <div className="col-md-4">Signal Strength Fallback Timer:</div>
                                            <div className="col-md-4">{this.state.template_schema_json?.data?.sim_config?.filter(item => item.sim === '1')?.[0]?.["signal_failover_timer"]} seconds</div>
                                            <div className="col-md-4">{this.state.template_schema_json?.data?.sim_config?.filter(item => item.sim === '2')?.[0]?.["signal_failover_timer"]} seconds</div>
                                        </div>
                                    </>
                                    }
                                </>
                            }
                            {(this.props.edit || this.props.view || this.props.reapply || this.props.apply) &&
                                <>
                                    <div className="row mt-2">
                                        <div className="col-md-4">APN:</div>
                                        <div className="col-md-4">{this.state.template_schema_json?.data?.sim_config?.filter(item => item.sim === '1')?.[0]?.["apn_name"] || '-'}</div>
                                        <div className="col-md-4">{this.state.template_schema_json?.data?.sim_config?.filter(item => item.sim === '2')?.[0]?.["apn_name"] || '-'}</div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className={this.state.editSimInfo ? "disabled-slide" : ""}></div>
                        {/* second slide */}
                        {
                            this.state.editSimInfo && <div className="sidebar-slide  zindex10 w-350 second-open r-757">
                                <div className="s-header">
                                    <h1 className="ss-title">{`SIM ${this.state?.simInfoData?.sim} Configuration`}</h1>
                                    <button>
                                        <img src="/images/svg-icons/s-cancel.svg" alt="" onClick={this.closeSimInfo} />
                                    </button>
                                </div>
                                <div className="s-body">
                                    {(this.state.selectedModel !== 'Bradshaw' &&
                                        this.state.selectedModel !== 'Sander' &&
                                        this.state.selectedModel !== 'Duvel_Model' &&
                                        this.state.selectedModel !== "Orval" &&
                                        this.state.selectedModel !== "Firestorm") &&
                                        <>
                                            <div className="form-group">
                                                <label>Cycle Start Date
                                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                        title="Starting cycle date of the cellular data usage monitoring">
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                                <select className="form-control" value={this.state?.simInfoData?.cycle_start_date}
                                                    name="cycle_start_date" onChange={this.setSimValues}>
                                                    {this.getCycleRateOptions()}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>Data Limit
                                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                        title="Set the limit for the cellular data usage">
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                                <input className="form-control" value={this.state?.simInfoData?.data_limit}
                                                    name="data_limit" onChange={this.setSimValues} />
                                                <div className="error-txt sim" id="data_limit">
                                                    Invalid value
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                <div>Disable Data On Max Limit
                                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                        title="Disable the data of the celluar interface after reaching the specified data limit">
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </div>
                                                <label className="switch">
                                                    <input type="checkbox" name="disable_data_on_limit" disabled={this.state?.simInfoData?.data_limit === '' ||
                                                        this.state?.simInfoData?.data_limit === '0'}
                                                        checked={this.state?.simInfoData?.disable_data_on_limit === "1"}
                                                        onChange={this.setSimValues} />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            {this.state?.template_schema_json?.data?.common_config?.signal_strength_failover === '1' && <div className="form-group">
                                                <label>Signal Strength
                                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                        title="Threshold of the signal stength level ">
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                                <select className="form-control" value={this.state?.simInfoData?.signal_strength}
                                                    name="signal_strength" onChange={this.setSimValues}>
                                                    {this.getSignalStrengthOptions()}
                                                </select>
                                                <span className="units r-20">dBm</span>
                                            </div>}
                                            {this.state.template_schema_json?.data?.common_config?.signal_strength_failover === '1' && <div className="form-group">
                                                <label>Signal Strength Fallback Timer
                                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                        title="Threshold of the signal stength timer limit">
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                                <select className="form-control" name="signal_failover_timer"
                                                    value={this.state.simInfoData?.signal_failover_timer} onChange={this.setSimValues}>
                                                    {this.getSignalStrengthTimer()}
                                                </select>
                                                <span className="units r-20">Seconds</span>
                                            </div>}</>}
                                    {((this.state.selectedModel === 'Bradshaw' ||
                                        this.state.selectedModel === 'Orval' ||
                                        this.state.selectedModel === 'Sander' ||
                                        this.state.selectedModel === 'Duvel_Model' ||
                                        this.state.selectedModel === "Firestorm") &&
                                        !this?.props?.apply) &&
                                        <div className="">
                                            APN settings can only be modified during the apply phase of the template.
                                        </div>
                                    }
                                    {(this.props.apply || this.props.edit || this.props.reapply || this.props.view) &&
                                        <>
                                            <div className="form-group">
                                                <label>APN
                                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                        title="APN used to connect to the network for data call">
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                                <div className="">
                                                    <input value={this.state.simInfoData?.apn_name} type="text" readOnly className="form-control" />
                                                    <img
                                                        className={this.state.template_schema_json?.data?.common_config?.cellular_data !== "1" ||
                                                            this.state.simInfoData.imsi !== this.state.template_schema_json?.data?.common_config?.active_sim ?
                                                            "pos-edit disabled" : "pos-edit"}
                                                        src="/images/svg-icons/edit-blue.svg" alt=""
                                                        onClick={this.state.template_schema_json?.data?.common_config?.cellular_data !== "1" ||
                                                            this.state.simInfoData.imsi !== this.state?.template_schema_json?.data?.common_config?.active_sim ?
                                                            undefined : () => this.openAPNInfo()}
                                                        disabled={this.state.template_schema_json?.data?.common_config?.cellular_data !== "1" ||
                                                            this.state.simInfoData.imsi !== this.state.template_schema_json?.data?.common_config?.active_sim} />
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="s-footer">
                                    <button className="btn btn-light" onClick={this.closeSimInfo}>Cancel</button>
                                    <button className="btn btn-primary" disabled={this.checkSimError()} onClick={this.saveSimInfo}>Save</button>
                                </div>
                            </div>
                        }
                        {/* close second slide */}
                        {
                            this.state.openAPN && <div className="modal fade exampleModal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">

                                        <div className="modal-body">
                                            <div className="form-group with-arrow">
                                                <label>Add APN
                                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                        title="APN used to connect to the network for data call">
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    onChange={(e) => this.setAPNValues(e, false)}
                                                    onBlur={this.checkDomainName}
                                                    name="apn_name"
                                                    value={this.state.APNInfoData?.apn_name} />
                                                <i className="drop-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                <div className="dropdown-menu max-height-200" aria-labelledby="dropdownMenuButton">
                                                    <ul>
                                                        {
                                                            this.state.modemData.Apn_Info.filter((val) => val.imsi === this.state?.simInfoData?.imsi)?.[0]?.apn !== "" &&
                                                            <li
                                                                data-name="apn_name"
                                                                data-value={this.state.modemData.Apn_Info.filter((val) => val.imsi === this.state?.simInfoData?.imsi)?.[0]?.apn}
                                                                onClick={(e) => this.setAPNValues(e, true)}>
                                                                {this.state.modemData.Apn_Info.filter((val) => val.imsi === this.state?.simInfoData?.imsi)?.[0]?.apn}
                                                            </li>
                                                        }
                                                        {
                                                            String(this.state.modemData?.Slots_Info?.data?.filter(val => String(val?.slot) === String(this.state?.simInfoData?.sim))?.[0]?.oper_name) === "T-Mobile" &&

                                                            <li
                                                                data-name="apn_name"
                                                                data-value={"B2B.tmobile.com"}
                                                                onClick={(e) => this.setAPNValues(e, true)}>
                                                                {"B2B.tmobile.com"}
                                                            </li>
                                                        }
                                                        {
                                                            String(this.state.modemData?.Slots_Info?.data?.filter(val => String(val?.slot) === String(this.state?.simInfoData?.sim))?.[0]?.oper_name) === "T-Mobile" &&

                                                            <li
                                                                data-name="apn_name"
                                                                data-value={"b2b.static"}
                                                                onClick={(e) => this.setAPNValues(e, true)}>
                                                                {"b2b.static"}
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                                <div className="error-txt apn" id="apn_name">
                                                    Invalid value
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Authentication
                                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                        title="Authentication used to connect to the APN">
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                                <select
                                                    className="form-control"
                                                    value={this.state.APNInfoData?.apn_auth}
                                                    name="apn_auth"
                                                    onChange={this.setAPNValues}>
                                                    <option value="none">NONE</option>
                                                    <option value="PAP">PAP</option>
                                                    <option value="CHAP">CHAP</option>
                                                    <option value="PAP or CHAP">PAP or CHAP</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>Username
                                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                        title="Username for the selected authentiation method">
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    onChange={this.setAPNValues}
                                                    name="apn_user"
                                                    value={this.state.APNInfoData?.apn_user}
                                                    disabled={this.state.APNInfoData?.apn_auth === 'none'} />
                                                <div className="error-txt apn" id="apn_user">
                                                    Invalid value
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Password
                                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                        title="Password  for the selected authentiation method">
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    onChange={this.setAPNValues}
                                                    name="apn_pass"
                                                    value={this.state.APNInfoData?.apn_pass} disabled={this.state.APNInfoData?.apn_auth === 'none'} />
                                                <div className="error-txt apn" id="apn_pass">
                                                    Invalid value
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>IP Connection Type
                                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                        title="IP connection used by the device to connect to the APN">
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                                <select
                                                    className="form-control"
                                                    onChange={this.setAPNValues}
                                                    name="apn_conn_type"
                                                    value={this.state.APNInfoData?.apn_conn_type}>
                                                    <option value="IPv4">IPV4</option>
                                                    <option value="IPv4 or IPv6">IPV4 or IPV6</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="text-center pb-4">
                                            <button className="btn btn-light mr-2" data-dismiss="modal" aria-label="Close" onClick={this.closeAPNInfo}>Cancel</button>
                                            <button className="btn btn-primary" disabled={this.checkAPNError()}
                                                onClick={this.saveAPNInfo}>Ok</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {/* Recovery tab started */}
                    {this.state.selectedModel !== 'Moretti_Model' && this.state.selectedModel !== 'Duvel_Model'
                        && this.state.selectedModel !== "Stormbreaker" &&
                        <div className="tab-pane" id="Recovery" role="tabpanel" aria-labelledby="Recovery-tab">
                            <div className="row">
                                <div className="col-10"><strong>Enable
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="To enable/disable the recovery feature*">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </strong>
                                </div>
                                <div className="col-2">
                                    <label className="switch mt-2">
                                        <input
                                            type="checkbox"
                                            name='enable'
                                            checked={this.state?.template_schema_json?.data?.modem_recovery?.enable === '1'}
                                            onChange={this.props.info || this.props.view || this.props.reapply ?
                                                undefined : this.setModemRecovery}
                                            readOnly={this.props.info || this.props.view || this.props.reapply}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <div>
                                {/* className={this.state.template_schema_json.data.modem_recovery.enable === '1' ? "border rounded p-2" : "border rounded p-2 disabled"}> */}
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-6">Maximum DNS Trials
                                            {this.state?.template_schema_json?.data?.modem_recovery?.enable === '1' &&
                                                <span className="red-txt">*</span>}
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                title="Maximum dns tries before reporting failure">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <select
                                                type="text"
                                                class="form-control"
                                                value={this.state?.template_schema_json?.data?.modem_recovery?.max_dns_trials}
                                                onChange={this.setModemRecovery} name="max_dns_trials"
                                                disabled={this.props.info || this.props.view || this.props.reapply
                                                    || this.state?.template_schema_json?.data?.modem_recovery?.enable !== '1'}>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-6">DNS Check Interval
                                            {this.state?.template_schema_json?.data?.modem_recovery?.enable === '1' &&
                                                <span className="red-txt">*</span>}
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                title="Interval between which dns resolve should be tried">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <input
                                                type="text"
                                                class="form-control"
                                                value={this.state?.template_schema_json?.data?.modem_recovery?.dns_check_interval}
                                                onChange={this.setModemRecovery} name="dns_check_interval"
                                                readOnly={this.props.info || this.props.view || this.props.reapply ||
                                                    this.state?.template_schema_json?.data?.modem_recovery?.enable !== '1'} />
                                            <div className="error-txt" id="dns_check_interval">
                                                Value must be between 10 and 7200
                                            </div>
                                            <span className="units t-15">Seconds</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-6">DNS Fail Check Interval
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                title="Interval between which dns resolve should be tried in failure case">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <input
                                                type="text"
                                                class="form-control"
                                                value={this.state?.template_schema_json?.data?.modem_recovery?.dns_fail_check_interval}
                                                onChange={this.setModemRecovery} name="dns_fail_check_interval"
                                                readOnly={this.props.info || this.props.view || this.props.reapply ||
                                                    this.state?.template_schema_json?.data?.modem_recovery?.enable !== '1'} />
                                            <div className="error-txt" id="dns_fail_check_interval">
                                                Value must be between 5 and 60
                                            </div>
                                            <span className="units t-15">Seconds</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-6">Maximum Modem Reset
                                            {this.state?.template_schema_json?.data?.modem_recovery?.enable === '1' &&
                                                <span className="red-txt">*</span>}
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                title="Maximum modem reboot count during recovery">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <select
                                                type="text"
                                                class="form-control"
                                                value={this.state?.template_schema_json?.data?.modem_recovery?.max_modem_reset}
                                                onChange={this.setModemRecovery} name="max_modem_reset"
                                                disabled={this.props.info || this.props.view || this.props.reapply ||
                                                    this.state?.template_schema_json?.data?.modem_recovery?.enable !== '1'}>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {(this.state.selectedModel !== 'Bradshaw' && this.state.selectedModel !== 'Orval' && this.state.selectedModel !== 'Sander') && <div className="form-group">
                                    <div className="row">
                                        <div className="col-6">Maximum SIM Switch
                                            {this.state?.template_schema_json?.data?.modem_recovery?.enable === '1' &&
                                                <span className="red-txt">*</span>}
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                title="Maximum SIM switch count during dns failure">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <select
                                                type="text"
                                                class="form-control"
                                                value={this.state?.template_schema_json?.data?.modem_recovery?.max_sim_switch}
                                                onChange={this.setModemRecovery} name="max_sim_switch"
                                                disabled={this.props.info || this.props.view || this.props.reapply ||
                                                    this.state?.template_schema_json?.data?.modem_recovery?.enable !== '1'}>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>}
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-6">Maximum Device Reset
                                            {this.state?.template_schema_json?.data?.modem_recovery?.enable === '1' &&
                                                <span className="red-txt">*</span>}
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                title="Maximum device reboot count during recovery">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <select
                                                type="text"
                                                class="form-control"
                                                value={this.state?.template_schema_json?.data?.modem_recovery?.max_device_reset}
                                                onChange={this.setModemRecovery} name="max_device_reset"
                                                disabled={this.props.info || this.props.view || this.props.reapply ||
                                                    this.state?.template_schema_json?.data?.modem_recovery?.enable !== '1'}>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    {/* Recovery tab end */}
                </div >
                {
                    !this.props.info && !this.props.view &&
                    <div className="s-footer">
                        <button className="btn btn-light" onClick={
                            (this.props.apply && !this.props.edit && !this.props.reapply) ? () => this.props.closeAddTemplate() :
                                () => this.closeAll()}>
                            {(this.props.apply && !this.props.edit && !this.props.reapply) ? 'Back' : 'Cancel'}</button>
                        <button className="btn btn-primary"
                            disabled={this.checkError()}
                            onClick={this.props.apply ? (event) => this.debounceApply(this.applyTemplate, event) : () => this.saveData()}
                        >{this.props.apply ? 'Apply' : (this.state.selectedTemplateId !== '' ? 'Update' : 'Save')}</button>
                    </div>
                }
                {
                    (this.state.show) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        reset={() => this.resetPopup()}
                    />
                }
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        applying: state.displayAddTemplate.applying
    }
};
export default connect(mapStateToProps)(ModemTemplate);
