import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import apiCall from '../../redux/API/apiCall';
import urlMapping from '../../redux/API/api';
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import ErrorHandlePopup from '../ErrorHandlePopup';
import { ErrorPopup } from '../../layOuts/errorPopup';
import { Loader } from '../../layOuts/Loader';
import $ from 'jquery';
class Notifications extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showRefreshModal: false,
            showLoader: false,
            userLookUp: [],
            deviceLookUp: [],
            deviceGroupLookUp: [],
            userOptions: [],
            deviceOptions: [],
            deviceGroupOptions: [],
            selectedUser: [],
            selectedDevice: [],
            selectedDeviceGroup: [],
            setSeverity: false,
            setSystemSeverity: false,
            setCPUAlerts: false,
            setMemoryAlerts: false,
            setRenewalAlerts: false,
            setDiscAlerts: false,
            setUserLoginAlerts: false,
            setWarning: false,
            setDisaster: false,
            setHigh: false,
            setAverage: false,
            toggledClearDeviceRows: false,
            setInformation: false,
            setAlerts: false,
            setConnected_Client: false,
            setData_Tunnel_Link: false,
            setDisk_Space: false,
            setInterface_Status: false,
            setMemory: false,
            setCPU: false,
            setSystem_Status: false,
            setData_Usage: false,
            showAddDevice: false,
            specificDeviceGroupOptions: [],
            newGroupName: '',
            showError: false,
            showAlerts: false,
            message: '',
            title: "",
            error: "",
            show: false,
            SendDeviceGroup: [],
            selectedDeviceGroupId: "",
            selectionStatus: [],
            isAxiosError: false
        }
    }
    componentDidMount = () => {
        if (this.props?.userRole === "Viewer_Group" || this.props?.userRole === "Operator_Group") {
            const buttons = document.querySelectorAll('button[type="button"]');
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].disabled = true;
                buttons[i].style = "cursor: not-allowed"
            }
            const elements = document.getElementsByClassName('slider round');
            for (let i = 0; i < elements.length; i++) {
                elements[i].className = "slider round disabled";
                elements[i].style = "cursor: not-allowed";
            }
        }
        this.refreshPage()
    }
    refreshPage = async () => {
        await this.getUserList()
        await this.getDeviceLookUpDeviceList()
        await this.getDeviceGroupLookUp()
        await this.getNotification()
    }
    getNotification = () => {
        $("#alerts").modal("hide");
        this.setState({ showLoader: true, showAlerts: false })
        apiCall(urlMapping.getNotification(), (response) => {
            if (response?.success) {
                let severity = response?.data?.severity
                let alerts = response?.data?.alerts
                let systemAlerts = response?.data?.system_alerts
                this.setState({
                    showLoader: false,
                    setWarning: severity.warning,
                    setDisaster: severity.disaster,
                    setHigh: severity.high,
                    setAverage: severity.average,
                    setInformation: severity.information,
                }, () => this.severityChange())
                this.setState({
                    setConnected_Client: alerts?.client,
                    setData_Tunnel_Link: alerts?.["data tunnel"],
                    setDisk_Space: alerts?.["disk space"],
                    setInterface_Status: alerts?.["interface"],
                    setMemory: alerts?.["memory utilization"],
                    setCPU: alerts?.["cpu utilization"],
                    setSystem_Status: alerts?.["device o"],
                    setData_Usage: alerts?.["data usage"],
                }, () => this.alertsChange())
                this.setState({
                    setCPUAlerts: systemAlerts?.cpu,
                    setMemoryAlerts: systemAlerts?.memory,
                    setDiscAlerts: systemAlerts?.em_disc_space,
                    setUserLoginAlerts: systemAlerts?.user_login,
                    setRenewalAlerts: systemAlerts?.cert_renewal
                }, () => this.systemSeverityChange())
                let user = []
                if (this.state.userOptions.length - 1 == response?.data?.recipients?.length) {
                    user.push({ label: "All", value: "*" })
                }
                response?.data?.recipients?.map((valu) => {
                    const selectedId = this.state.userLookUp.filter(item => item.email === valu)?.[0]?.id
                    let value1 = this.state.userOptions.filter(item => item.value == selectedId)
                    if (value1.length > 0) {
                        user.push(value1?.[0])
                    }
                })
                this.setState({ selectedUser: user })
                let devices = []
                if (this.state.deviceOptions?.length - 1 == response?.data?.device_ids?.length) {
                    devices.push({ label: "All", value: "*" })
                }
                response?.data?.device_ids?.map((item) => {
                    let value2 = this.state.deviceOptions.filter((val) => val.value == item)
                    if (value2.length > 0) {
                        devices.push(value2?.[0])
                    }
                })
                this.setState({
                    selectedDevice: devices,
                    SendDeviceGroup: response?.data?.groups
                })
                let newDeviceGroup = []
                response?.data?.groups?.map((item) => {
                    let value3 = this.state.deviceGroupLookUp.filter((val) => val.group_name == item.group_name)
                    if (value3.length > 0) {
                        newDeviceGroup.push(value3?.[0])
                    }
                })
                let newTemp = []
                let result = false
                this.state.deviceGroupLookUp?.map((item) => {
                    let group = response.data?.groups?.filter(val => val.group_name === item.group_name)
                    if (group?.length !== 0)
                        item.attached_devices?.split(",")?.map(val => {
                            if (!group?.[0]?.device_ids?.includes(parseInt(val)))
                                result = true
                        })
                    else {
                        result = true
                    }
                })
                if (!result) {
                    newTemp.push({ label: "All", value: "*" })
                }
                newDeviceGroup.map((item) => {
                    let value4 = this.state.deviceGroupOptions.filter((val) => val.value == item.group_id)
                    if (value4.length > 0) {
                        newTemp.push(value4[0])
                    }
                })
                let { selectionStatus } = this.state;
                selectionStatus?.map(val => {
                    let group = response.data?.groups?.filter(item => item.group_name === val.group_name)
                    if (group?.length !== 0)
                        val.selected = group?.[0]?.device_ids?.length
                })
                this.setState({
                    selectionStatus,
                    selectedDeviceGroup: newTemp,
                }, () => this.getDeviceGroupOptions())
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({ showLoader: false })
                }
            }
        })
    }
    getUserList = () => {
        this.setState({ showLoader: true })
        let data = {
            pageNo: "",
            pageSize: "",
            all: 1
        }
        apiCall(urlMapping.getUserList(data), (response) => {
            if (response?.data) {
                this.setState({
                    showLoader: false,
                });
                const data = response?.data;
                if (data.length !== 0)
                    this.setState({
                        userLookUp: data,
                    }, () => this.setState({ userOptions: [{ label: "All", value: "*" }, ...this.getUserOptions()] }))
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({ showLoader: false })
                }
            }
        })
    }
    getDeviceLookUpDeviceList = async () => {
        this.setState({ showLoader: true });
        await apiCall(urlMapping.deviceLookUp(), (response) => {
            if (response?.data) {
                this.setState({
                    showLoader: false,
                });
                const data = response?.data;
                if (data.length !== 0)
                    this.setState({
                        deviceLookUp: data,
                    }, () => this.setState({ deviceOptions: [{ label: "All", value: "*" }, ...this.getDeviceOptions()] }))
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true,
                        showLoader: false
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({ showLoader: false })
                }
            }
        });
    };
    getDeviceGroupLookUp = async () => {
        this.setState({ showLoader: true });
        await apiCall(urlMapping.deviceGroupLookUpDrop(), (response) => {
            if (response?.data) {
                let selectionStatus = []
                const data = response?.data?.filter(item => item.attached_devices !== "");
                data?.map(val => {
                    selectionStatus.push({ "group_name": val.group_name, "group_id": val.group_id, "selected": 0, "total": val.attached_devices?.split(",")?.length })
                })
                if (data?.length !== 0)
                    this.setState({
                        selectionStatus,
                        deviceGroupLookUp: data,
                        showLoader: false
                    }, () => {
                        this.getDeviceGroupOptions()
                    })
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true,
                        showLoader: false
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({ showLoader: false })
                }
            }
        });
    };
    getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
        if (value && value.some((o) => o.value === "*")) {
            return `${placeholderButtonLabel}: All`;
        } else {
            return `${placeholderButtonLabel}: ${value.length} selected`;
        }
    }
    getUserOptions = () => {
        let item = []
        let list = this.state.userLookUp;
        item = list.map((item) => {
            let name = `${item.first_name} ${item.last_name} ${item.email ? `(${item.email})` : ""}`
            return { label: name, value: item.id }
        })
        return item
    }
    getDeviceOptions = () => {
        let item = []
        let value = this.state.deviceLookUp.filter((item) => item.group_name === "")
        item = value.map((item) => {
            return { label: item.device_name, value: item.device_id }
        })
        return item
    }
    getDeviceGroupOptions = () => {
        let item = []
        item = this.state.selectionStatus?.map((item) => {
            return {
                label: <span>{item.group_name}{" "}
                    <img
                        src="/images/svg-icons/add.svg"
                        className="add-btn"
                        alt=""
                    />
                    <small>  ( {item.selected} of {item.total} selected )</small>
                </span>,
                value: item.group_id
            }
        })
        this.setState({ deviceGroupOptions: [{ label: "All", value: "*" }, ...item] })
    }
    onUserChange = (value, event) => {
        if (event.option.value !== '*' && this.state.userLookUp.filter(item => item.id === event.option.value)[0].email === '') {
            this.setState(
                {
                    title: "User Email",
                    message: "To apply notifications Email is required. Please edit user and provide Email id.",
                    show: true,
                    error: true,
                },
                () => {
                    $("#errorMessage").modal("show")
                }
            );
        } else {
            if (event.action === "select-option" && event.option.value === "*") {
                if (this.state.userLookUp.filter(item => item.email === "").length > 0) {
                    this.setState(
                        {
                            title: "User Email",
                            message: "To apply notifications Email is required. Please edit user and provide Email id.",
                            show: true,
                            error: true,
                        },
                        () => {
                            $("#errorMessage").modal("show")
                        }
                    );
                } else
                    this.setState({ selectedUser: this.state.userOptions });
            } else if (
                event.action === "deselect-option" &&
                event.option.value === "*"
            ) {
                this.setState({ selectedUser: [] });
            } else if (event.action === "deselect-option") {
                this.setState({ selectedUser: value.filter((o) => o.value !== "*") })
            } else if (value.length === this.state.userOptions.length - 1) {
                this.setState({ selectedUser: this.state.userOptions });
            } else {
                this.setState({ selectedUser: value });
            }
        }
    }
    onDeviceChange = (value, event) => {
        if (event.action === "select-option" && event.option.value === "*") {
            this.setState({ selectedDevice: this.state.deviceOptions });
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            this.setState({ selectedDevice: [] });
        } else if (event.action === "deselect-option") {
            this.setState({ selectedDevice: value.filter((o) => o.value !== "*") })
        } else if (value.length === this.state.deviceOptions.length - 1) {
            this.setState({ selectedDevice: this.state.deviceOptions });
        } else {
            this.setState({ selectedDevice: value });
        }
    }
    onDeviceGroupChange = (value, event) => {
        let data = []
        let { selectionStatus } = this.state;
        let { SendDeviceGroup } = this.state;
        if (event.action === "select-option" && event.option.value !== "*") {
            data = value
            this.getGroupDetails(event.option.value);
        }
        else if (event.action === "select-option" && event.option.value === "*") {
            selectionStatus.map(val => val.selected = val.total)
            data = this.state.deviceGroupOptions
            SendDeviceGroup = []
        }
        else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            data = [];
            selectionStatus.map(val => val.selected = 0)
            SendDeviceGroup = []
        }
        else if (event.action === "deselect-option") {
            (selectionStatus.filter(val => val.group_id === event.option.value))?.map(item => item.selected = 0)
            data = value.filter((o) => o.value !== "*")
            let filteredValue = this.state.deviceGroupLookUp.filter(val => val.group_id === event.option.value)?.[0]
            SendDeviceGroup.map(item => {
                item.device_ids.map(val => {
                    let temp = filteredValue.attached_devices.split(",").includes(String(val))
                    if (temp)
                        SendDeviceGroup = SendDeviceGroup.filter(val => val.group_name !== item.group_name)
                })
            })
        }
        this.setState({
            selectionStatus,
            SendDeviceGroup,
            selectedDeviceGroup: data
        }, () => this.getDeviceGroupOptions());
    }
    systemSeverityChange = (value) => {
        if (value === "Severity" && this.state.setSystemSeverity) {
            this.setState({
                setCPUAlerts: true,
                setMemoryAlerts: true,
                setDiscAlerts: true,
                setUserLoginAlerts: true,
                setRenewalAlerts: true
            })
        }
        else if (value === "Severity" && !this.state.setSystemSeverity) {
            this.setState({
                setCPUAlerts: false,
                setMemoryAlerts: false,
                setDiscAlerts: false,
                setUserLoginAlerts: false,
                setRenewalAlerts: false
            })
        }
        else if (!this.state.setCPUAlerts || !this.state.setMemoryAlerts || !this.state.setDiscAlerts || 
            !this.state.setUserLoginAlerts || !this.state.setRenewalAlerts) {
            this.setState({ setSystemSeverity: false })
        }
        else {
            this.setState({ setSystemSeverity: true })
        }
    }
    severityChange = (value) => {
        if (value === "Severity" && this.state.setSeverity) {
            this.setState({
                setWarning: true,
                setDisaster: true,
                setHigh: true,
                setAverage: true,
                setInformation: true
            })
        }
        else if (value === "Severity" && !this.state.setSeverity) {
            this.setState({
                setWarning: false,
                setDisaster: false,
                setHigh: false,
                setAverage: false,
                setInformation: false
            })
        }
        else if (!this.state.setWarning || !this.state.setDisaster || !this.state.setHigh || !this.state.setAverage || !this.state.setInformation) {
            this.setState({ setSeverity: false })
        }
        else {
            this.setState({ setSeverity: true })
        }
    }
    alertsChange = (value) => {
        if (value === "Alerts" && this.state.setAlerts) {
            this.setState({
                setConnected_Client: true,
                setData_Tunnel_Link: true,
                setDisk_Space: true,
                setInterface_Status: true,
                setMemory: true,
                setCPU: true,
                setSystem_Status: true,
                setData_Usage: true
            })
        }
        else if (value === "Alerts" && !this.state.setAlerts) {
            this.setState({
                setConnected_Client: false,
                setData_Tunnel_Link: false,
                setDisk_Space: false,
                setInterface_Status: false,
                setMemory: false,
                setCPU: false,
                setSystem_Status: false,
                setData_Usage: false
            })
        }
        else if (!this.state.setConnected_Client || !this.state.setData_Tunnel_Link
            || !this.state.setDisk_Space || !this.state.setInterface_Status || !this.state.setMemory || !this.state.setCPU
            || !this.state.setSystem_Status || !this.state.setData_Usage) {
            this.setState({ setAlerts: false })
        }
        else {
            this.setState({ setAlerts: true })
        }
    }
    getGroupDetails = (id) => {
        let data = { group_id: id }
        this.setState({
            showLoader: true,
            id: id,
            specificDeviceGroup: [],
            selectedDeviceGroupId: id,
        })
        apiCall(urlMapping.deviceGroupList(data), (response) => {
            this.setState({ showLoader: false })
            if (response?.Success) {
                let temp = this.state.SendDeviceGroup.filter((val) => val.group_name.includes(response?.data?.group_name))
                let newGroup = []
                newGroup = response?.data?.attached_devices?.map((item) => {
                    return { label: item?.device_name, value: item?.device_id }
                })
                let deviceGroup = []
                if (temp.length !== 0) {
                    temp?.[0].device_ids?.map((item) => {
                        let value3 = newGroup.filter((val) => val.value == item)
                        if (value3.length > 0) {
                            deviceGroup.push(value3?.[0])
                        }
                    })
                }
                this.setState({
                    deviceGroupDetails: response?.data,
                    showAddDevice: true,
                    specificDeviceGroupOptions: newGroup,
                    specificDeviceGroup: deviceGroup,
                }, () => $("#optionalModal").modal("show"))
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }
    disableCheck = () => {
        let result = false
        if (this.state.selectedUser.length !== 0) {
            if (this.state.selectedDevice.length < 1 && this.state.selectedDeviceGroup.length < 1) {
                result = true
            }
            else if (!this.state.setCPUAlerts && !this.state.setMemoryAlerts && !this.state.setDiscAlerts && !this.state.setUserLoginAlerts  && !this.state.setRenewalAlerts) {
                if (!this.state.setWarning && !this.state.setDisaster && !this.state.setHigh && !this.state.setAverage && !this.state.setInformation) {
                    result = true
                }
                else if (!this.state.setConnected_Client && !this.state.setData_Tunnel_Link
                    && !this.state.setDisk_Space && !this.state.setInterface_Status && !this.state.setMemory && !this.state.setCPU
                    && !this.state.setSystem_Status && !this.state.setData_Usage) {
                    result = true
                }
                else {
                    result = false
                }
            }
        }
        else {
            result = true
        }
        return result
    }
    deviceGroupModalCancel = () => {
        let { selectedDeviceGroup } = this.state;
        selectedDeviceGroup = selectedDeviceGroup.filter(val => (val.value !== this.state.selectedDeviceGroupId && val.value !== "*"))
        this.setState({ selectedDeviceGroup })
    }
    updateGroup = () => {
        let { selectedDeviceGroup } = this.state;
        let temp = this.state.SendDeviceGroup
        let value3 = []
        let group_name = this.state.deviceGroupDetails.group_name
        value3 = this.state.specificDeviceGroup.map((item) => {
            return item.value
        })
        let group = {
            group_name: group_name,
            device_ids: value3
        }
        let newTemp = []
        newTemp = temp.filter((val) => !val.group_name.includes(group_name))
        if (group) {
            newTemp.push(group)
        }
        let result = false
        this.state.deviceGroupLookUp.map((item) => {
            let temp = newTemp?.filter(val => val.group_name === item.group_name)
            if (temp?.length !== 0)
                item.attached_devices.split(",").map(val => {
                    if (!temp?.[0]?.device_ids?.includes(parseInt(val)))
                        result = true
                })
            else {
                result = true
            }
        })
        if (!result) {
            selectedDeviceGroup = this.state.deviceGroupOptions
            newTemp = []
        }
        let { selectionStatus } = this.state;
        selectionStatus.filter(val => val.group_name === group.group_name).map(item => {
            item.selected = group.device_ids?.length
        })
        this.setState({
            selectionStatus,
            selectedDeviceGroup,
            SendDeviceGroup: newTemp,
            specificDeviceGroup: []
        }, () => this.getDeviceGroupOptions())
    }
    save = () => {
        this.setState({ showLoader: true })
        let data = {}
        const users = this.state.selectedUser.map((item) => item.value);
        let userList = this.state.userLookUp.filter((item) => users.includes(item.id));
        userList = userList.map((item) => item.email);
        data.recipients = userList
        let device = []
        this.state.selectedDevice.map((item) => {
            var value1 = this.state.deviceLookUp.find(function (element) {
                return item.value == element.device_id;
            });
            if (value1) {
                device.push(value1)
            }
        })
        let deviceId = device.map((item) => item.device_id)
        data.device_ids = deviceId
        let deviceGroup = []
        this.state.selectedDeviceGroup.map((item) => {
            var value2 = this.state.deviceGroupLookUp.find(function (element) {
                return item.value == element.group_id;
            });
            if (value2) {
                deviceGroup.push(value2)
            }
        })
        if (this.state.SendDeviceGroup.length !== 0) {
            data.groups = this.state.SendDeviceGroup
        }
        else {
            let group = []
            deviceGroup.map((item) => {
                let value3 = {
                    group_name: item.group_name,
                    device_ids: JSON.parse("[" + item.attached_devices + "]")
                }
                if (value3) {
                    group.push(value3)
                }
            })
            data.groups = group
        }
        let severity = {
            "information": this.state.setInformation,
            "warning": this.state.setWarning,
            "average": this.state.setAverage,
            "high": this.state.setHigh,
            "disaster": this.state.setDisaster
        }
        data.severity = severity
        let alerts = {
            "data tunnel": this.state.setData_Tunnel_Link,
            "client": this.state.setConnected_Client,
            "disk space": this.state.setDisk_Space,
            "interface": this.state.setInterface_Status,
            "memory utilization": this.state.setMemory,
            "cpu utilization": this.state.setCPU,
            "device o": this.state.setSystem_Status,
            "data usage": this.state.setData_Usage
        }
        data.alerts = alerts
        let system_alerts = {
            "cpu": this.state.setCPUAlerts,
            "memory": this.state.setMemoryAlerts,
            "em_disc_space": this.state.setDiscAlerts,
            "user_login": this.state.setUserLoginAlerts,
            "cert_renewal": this.state.setRenewalAlerts
        }
        data.system_alerts = system_alerts
        let request = {
            trigger_info: data
        }
        apiCall(urlMapping.notificationSetting(request), (response) => {
            if (response) {
                this.setState({
                    showLoader: false,
                    message: response?.message,
                    showAlerts: true
                }, () => $("#alerts").modal("show"))
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({ showLoader: false })
                }
            }
        })
    }
    resetPopup = () => {
        this.setState(
            {
                show: false,
                error: false,
            },
            () => {
                $("#errorMessage").modal("hide");
            }
        );
    };
    render() {
        return (
            <div>
                {this.state.showLoader && <Loader />}
                <div className="white-bg" >
                    <div className="ta-box set-pos mb-0">
                        <div className="action-btn d-flex align-items-center">
                            <button
                                onClick={() =>
                                    this.refreshPage()
                                }
                            >
                                <img src="/images/svg-icons/refreshing.svg" alt="" />{" "}
                                Refresh
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div
                                className={(this.props?.userRole === "Viewer_Group" ||
                                    this.props?.userRole === "Operator_Group")
                                    ? "disabled"
                                    : ""
                                }
                            >
                                <strong>Users:</strong>
                                <ReactMultiSelectCheckboxes
                                    options={this.state.userOptions}
                                    placeholderButtonLabel="User"
                                    getDropdownButtonLabel={(value, event) => this.getDropdownButtonLabel(value, event)}
                                    value={this.state.selectedUser}
                                    onChange={(value, event) => this.onUserChange(value, event)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div
                                className={(this.props?.userRole === "Viewer_Group" ||
                                    this.props?.userRole === "Operator_Group")
                                    ? "disabled"
                                    : ""
                                }
                            >
                                <strong>Devices:</strong>
                                <ReactMultiSelectCheckboxes
                                    options={this.state.deviceOptions}
                                    placeholderButtonLabel="Devices"
                                    getDropdownButtonLabel={(value, event) => this.getDropdownButtonLabel(value, event)}
                                    value={this.state.selectedDevice}
                                    onChange={(value, event) => this.onDeviceChange(value, event)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div
                                className={(this.props?.userRole === "Viewer_Group" ||
                                    this.props?.userRole === "Operator_Group")
                                    ? "disabled"
                                    : ""
                                }
                            >
                                <strong>Device Groups:</strong>
                                <ReactMultiSelectCheckboxes
                                    options={this.state.deviceGroupOptions}
                                    hideSearch={true}
                                    placeholderButtonLabel="Device Groups"
                                    getDropdownButtonLabel={(value, event) => this.getDropdownButtonLabel(value, event)}
                                    value={this.state.selectedDeviceGroup}
                                    onChange={(value, event) => this.onDeviceGroupChange(value, event)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row notification_row'>
                        <div className="col-md-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <strong>Host System Alerts</strong>
                                <div className='row'>
                                    <div className="col-md-4">All</div>
                                    <div className="col-md-8">
                                        <label className="switch notification_switch">
                                            <input
                                                type="checkbox"
                                                name="advancedOptions"
                                                checked={this.state.setSystemSeverity}
                                                onChange={() =>
                                                    (this.props?.userRole === "Viewer_Group" ||
                                                        this.props?.userRole === "Operator_Group")
                                                        ? undefined
                                                        : this.setState({ setSystemSeverity: !this.state.setSystemSeverity }, () => this.systemSeverityChange("Severity"))}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='notification_severity notification_card'>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <span>Memory</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="advancedOptions"
                                            checked={this.state.setMemoryAlerts}
                                            onChange={() =>
                                                (this.props?.userRole === "Viewer_Group" ||
                                                    this.props?.userRole === "Operator_Group")
                                                    ? undefined
                                                    : this.setState({ setMemoryAlerts: !this.state.setMemoryAlerts }, () => this.systemSeverityChange())}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <span>CPU</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="advancedOptions"
                                            checked={this.state.setCPUAlerts}
                                            onChange={() => (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group")
                                                ? undefined
                                                : this.setState({ setCPUAlerts: !this.state.setCPUAlerts }, () => this.systemSeverityChange())}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <span>Hard Disc</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="advancedOptions"
                                            checked={this.state.setDiscAlerts}
                                            onChange={() => (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group")
                                                ? undefined
                                                : this.setState({ setDiscAlerts: !this.state.setDiscAlerts }, () => this.systemSeverityChange())}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <span>User Login</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="advancedOptions"
                                            checked={this.state.setUserLoginAlerts}
                                            onChange={() => (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group")
                                                ? undefined
                                                : this.setState({ setUserLoginAlerts: !this.state.setUserLoginAlerts }, () => this.systemSeverityChange())}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <span>Certificate Renewal</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="advancedOptions"
                                            checked={this.state.setRenewalAlerts}
                                            onChange={() =>
                                                (this.props?.userRole === "Viewer_Group" ||
                                                    this.props?.userRole === "Operator_Group")
                                                    ? undefined
                                                    : this.setState({ setRenewalAlerts: !this.state.setRenewalAlerts }, () => this.systemSeverityChange())}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <strong>Severity</strong>
                                <div className='row'>
                                    <div className="col-md-4">All</div>
                                    <div className="col-md-8">
                                        <label className="switch notification_switch">
                                            <input
                                                type="checkbox"
                                                name="advancedOptions"
                                                checked={this.state.setSeverity}
                                                onChange={() =>
                                                    (this.props?.userRole === "Viewer_Group" ||
                                                        this.props?.userRole === "Operator_Group")
                                                        ? undefined
                                                        : this.setState({ setSeverity: !this.state.setSeverity }, () => this.severityChange("Severity"))}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='notification_severity notification_card'>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <span>Low</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="advancedOptions"
                                            checked={this.state.setWarning}
                                            onChange={() =>
                                                (this.props?.userRole === "Viewer_Group" ||
                                                    this.props?.userRole === "Operator_Group")
                                                    ? undefined
                                                    : this.setState({ setWarning: !this.state.setWarning }, () => this.severityChange())}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <span>Critical</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="advancedOptions"
                                            checked={this.state.setDisaster}
                                            onChange={() => (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group")
                                                ? undefined
                                                : this.setState({ setDisaster: !this.state.setDisaster }, () => this.severityChange())}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div><div className="d-flex align-items-center justify-content-between mb-3">
                                    <span>High</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="advancedOptions"
                                            checked={this.state.setHigh}
                                            onChange={() => (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group")
                                                ? undefined
                                                : this.setState({ setHigh: !this.state.setHigh }, () => this.severityChange())}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div><div className="d-flex align-items-center justify-content-between mb-3">
                                    <span>Medium</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="advancedOptions"
                                            checked={this.state.setAverage}
                                            onChange={() => (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group")
                                                ? undefined
                                                : this.setState({ setAverage: !this.state.setAverage }, () => this.severityChange())}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div><div className="d-flex align-items-center justify-content-between mb-3">
                                    <span>Information</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="advancedOptions"
                                            checked={this.state.setInformation}
                                            onChange={() => (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group")
                                                ? undefined
                                                : this.setState({ setInformation: !this.state.setInformation }, () => this.severityChange())}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="d-flex align-items-center justify-content-between">
                                <strong>Alerts</strong>
                                <div className='row'>
                                    <div className="col-md-4">All</div>
                                    <div className="col-md-8">
                                        <label className="switch notification_switch">
                                            <input
                                                type="checkbox"
                                                name="advancedOptions"
                                                checked={this.state.setAlerts}
                                                onChange={() => (this.props?.userRole === "Viewer_Group" ||
                                                    this.props?.userRole === "Operator_Group")
                                                    ? undefined
                                                    : this.setState({ setAlerts: !this.state.setAlerts }, () => this.alertsChange("Alerts"))}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='notification_severity notification_card'>
                                {/* <div className='row'> */}
                                {/* <div className=' col-md-4'> */}
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <span>Connected Client List</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="advancedOptions"
                                            checked={this.state.setConnected_Client}
                                            onChange={() => (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group")
                                                ? undefined
                                                : this.setState({ setConnected_Client: !this.state.setConnected_Client }, () => this.alertsChange())}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <span>Data Tunnel Link</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="advancedOptions"
                                            checked={this.state.setData_Tunnel_Link}
                                            onChange={() => (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group")
                                                ? undefined
                                                : this.setState({ setData_Tunnel_Link: !this.state.setData_Tunnel_Link }, () => this.alertsChange())}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <span>Disk Space</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="advancedOptions"
                                            checked={this.state.setDisk_Space}
                                            onChange={() => (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group")
                                                ? undefined
                                                : this.setState({ setDisk_Space: !this.state.setDisk_Space }, () => this.alertsChange())}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                {/* </div> */}
                                {/* <div className='col-md-4'> */}
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <span>Interface Status</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="advancedOptions"
                                            checked={this.state.setInterface_Status}
                                            onChange={() => (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group")
                                                ? undefined
                                                : this.setState({ setInterface_Status: !this.state.setInterface_Status }, () => this.alertsChange())}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <span>Memory</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="advancedOptions"
                                            checked={this.state.setMemory}
                                            onChange={() => (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group")
                                                ? undefined
                                                : this.setState({ setMemory: !this.state.setMemory }, () => this.alertsChange())}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <span>CPU</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="advancedOptions"
                                            checked={this.state.setCPU}
                                            onChange={() => (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group")
                                                ? undefined
                                                : this.setState({ setCPU: !this.state.setCPU }, () => this.alertsChange())}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                {/* </div>
                                    <div className=' col-md-4'> */}
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <span>System Status</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="advancedOptions"
                                            checked={this.state.setSystem_Status}
                                            onChange={() => (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group")
                                                ? undefined
                                                : this.setState({ setSystem_Status: !this.state.setSystem_Status }, () => this.alertsChange())}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <span>Data Usage</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="advancedOptions"
                                            checked={this.state.setData_Usage}
                                            onChange={() => (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group")
                                                ? undefined
                                                : this.setState({ setData_Usage: !this.state.setData_Usage }, () => this.alertsChange())}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                {/* </div> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='row notification_save justify-content-center'>
                        <button
                            className='btn btn-primary'
                            onClick={() => this.save()}
                            disabled={
                                (this.props?.userRole === "Viewer_Group"
                                    || this.props?.userRole === "Operator_Group") ||
                                this.disableCheck()}
                        >Save</button>
                    </div>
                </div>
                {
                    this.state.showAddDevice && <div className="modal fade" id="optionalModal"
                        tabIndex="-1" aria-labelledby="exampleModalLabel" data-backdrop="static" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Device Group</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                        onClick={() => this.deviceGroupModalCancel()}
                                    >
                                        <span aria-hidden="true"><img src="/images/svg-icons/cancel.svg" alt="" /></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <strong>Group Name
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Name of device group">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                        </strong>
                                        <br />
                                        <label>{this.state.deviceGroupDetails.group_name}</label>
                                    </div>
                                    <div className="s-body">
                                        <div className="notification-table-height notification_col">
                                            <div>
                                                <strong>Devices:</strong>
                                                <ReactMultiSelectCheckboxes
                                                    options={this.state.specificDeviceGroupOptions}
                                                    placeholderButtonLabel="Device"
                                                    value={this.state.specificDeviceGroup}
                                                    onChange={(value) => this.setState({ specificDeviceGroup: value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button
                                        type="button"
                                        className="btn btn-light"
                                        data-dismiss="modal"
                                        onClick={() => this.deviceGroupModalCancel()}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-dismiss="modal"
                                        disabled={this.state.specificDeviceGroup.length === 0}
                                        onClick={() => this.updateGroup()}
                                    >
                                        OK
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.showAlerts &&
                    <div className="modal fade" id="alerts" tabIndex="-1" role="dialog" aria-labelledby="alertsLabel" aria-hidden="true" data-backdrop="static">
                        <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-body pt-5">
                                    <div className="error-msg">
                                        <span className="success-icon"></span>
                                        <div className="txt1">Email Alerts</div>
                                        <div className="txt2">{this.state.message}</div>
                                    </div>
                                </div>
                                <div className="modal-footer border-top-0 justify-content-center pb-4">
                                    <button type="button" className="btn btn-primary" data-dismiss="modal"
                                        onClick={() => this.getNotification()}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
                {
                    this.state.show && (
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            reset={() => this.resetPopup()}
                        />
                    )
                }
                {
                    (this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
            </div >
        )
    }
}
const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        userRole: String(state.auth.role)
    });
}
export default withRouter(connect(mapStateToProps)(Notifications));
