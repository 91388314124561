let loginURL = process.env.REACT_APP_LOGIN_URL;
// let loginURL = 'https://poc9-5gsdedge.inseego.com:7001/';
// let loginURL = "http://52.42.100.144/"
// let envURL =  'http://35.165.191.48:7001/';
// const envURL = 'http://44.241.218.80:7000/';
// const envURL = 'http://52.89.122.67:7001/';
// const envURL ='http://54.148.248.241:7001/';
// const envURL = 'http://52.42.100.144/';
const envURL = process.env.REACT_APP_API_URL;

// let loginURL = ""
// let envURL = '';
// const envURL = 'https://qa.inseego5g.net:7001/'
const deviceURL = envURL + 'api-device/device/';
const userURL = loginURL + 'api-user/';
const guacamoleUrl = process.env.REACT_APP_GUACAMOLE_API_URL;
const urlMapping = {
  login: function (data) {
    return {
      url: userURL + `login/`,
      headers: {
        'Authorization': `Basic ${data}`
      },
      method: "POST",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 180000,
    };
  },
  getUserList: function (data) {
    return {
      url: userURL + `users/?page=${data.pageNo}&page_size=${data.pageSize}&all=${data.all}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 180000,
    };
  },
  deleteUser: function (userId) {
    return {
      url: userURL + `users/${userId}`,
      method: "DELETE",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  addUserList: function (data) {
    return {
      url: userURL + `register/`,
      method: "POST",
      data: data,
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  updateUser: function (data, userId) {
    return {
      url: userURL + `users/${userId}`,
      method: "PUT",
      data: data,
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getUnconfiguredDevicelist: function (sorting_parameter, sorting_type, pinned, pageNo, pageSize) {
    return {
      url:
        deviceURL +
        `unconfiguredDeviceList?sorting_parameter=${sorting_parameter}&sorting_type=${sorting_type}&pinned=${pinned}&page=${pageNo}&page_size=${pageSize}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getConfiguredDevicelist: function (sorting_parameter, sorting_type, pinned, pageNo, pageSize) {
    return {
      url:
        deviceURL +
        `configuredDeviceList?sorting_parameter=${sorting_parameter}&sorting_type=${sorting_type}&pinned=${pinned}&page=${pageNo}&page_size=${pageSize}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  saveConfig: function (data) {
    return {
      url: deviceURL + `vlanVpnConfig`,
      data: data,
      method: "POST",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deleteDevice: function (data) {
    return {
      url: deviceURL + `deleteDevice`,
      data: data,
      method: "POST",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getDeviceDetails: function (data) {
    return {
      url: deviceURL + `getDeviceDetails`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 120000,
    };
  },
  configDevice: function (data) {
    return {
      url: deviceURL + `configureDevice`,
      data: data,
      method: "POST",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getOperationDeviceDetails: function (sorting_parameter, sorting_type, pageNo, pageSize) {
    return {
      url:
        deviceURL +
        `getDeviceOperations?sorting_parameter=${sorting_parameter}&sorting_type=${sorting_type}&page=${pageNo}&page_size=${pageSize}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },

  getDeviceModels: function () {
    return {
      url:
        deviceURL +
        `deviceGroupLookUp`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getTunnelDevicelist: function () {
    return {
      url: deviceURL + `initiateTunnel`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getTunnelDeviceDetails: function (data) {
    return {
      url: deviceURL + `initiateTunnel`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getTunnelUsedInterfaces: function (data) {
    return {
      url: deviceURL + `initiateTunnel`,
      method: "PUT",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  approveDevice: function (data) {
    return {
      url: deviceURL + `approveDevice`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  createTunnel: function (data) {
    return {
      url: deviceURL + `createTunnel`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  createTemplate: function (data) {
    return {
      url: deviceURL + `createTemplate`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getTunnelList: function (sorting_parameter, sorting_type, pageNo, pageSize) {
    return {
      url: deviceURL + `tunnelList?sorting_parameter=${sorting_parameter}&sorting_type=${sorting_type}&page=${pageNo}&page_size=${pageSize}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  addNewDevice: function (data) {
    return {
      url: deviceURL + `addDevice`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getModelList: function () {
    return {
      url: deviceURL + `getModelList`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getModelDeviceList: function (data) {
    return {
      url: deviceURL + `getModelList`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getDeviceListByModel: function (data, page, pageSize) {
    return {
      url: deviceURL + `deviceListInfo?page=${page ? page : 1}&page_size=${pageSize ? pageSize : 10}`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getDeviceGroupByModel: function (data) {
    return {
      url: deviceURL + `deviceGroupLookUp`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getGroupsByModel: function (data) {
    return {
      url: deviceURL + `groupsByModel`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deleteTunnel: function (id) {
    return {
      url: deviceURL + `deleteTunnel/${id}`,
      method: "DELETE",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getNewDeviceList: function (data) {
    return {
      url: deviceURL + `getNewModelList`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getNewDeviceListByModel: function (data, pageNo, pageSize) {
    return {
      url: deviceURL + `newDeviceListInfo?page=${pageNo}&page_size=${pageSize}`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getNewModelList: function () {
    return {
      url: deviceURL + `getNewModelList`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deviceListUpload: function (data) {
    return {
      url: deviceURL + `fileUpload`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 180000,
    };
  },
  updateFixedIp: function (data) {
    return {
      url: deviceURL + `updateFixedIp`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getDeviceTunnelListFilter: function () {
    return {
      url: deviceURL + `tunnelListFilter`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  tunnelFilterStatus: function (data, pageNo, pageSize) {
    return {
      url: deviceURL + `tunnelStatusFilter?page=${pageNo}&page_size=${pageSize}`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getDeviceDashboard: function () {
    return {
      url: deviceURL + `totaldevices`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getDeviceModelDashboard: function () {
    return {
      url: deviceURL + `modelcount`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getTunnelDashboard: function () {
    return {
      url: deviceURL + `tunnelcount`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  TemplateList: function (sorting_parameter, sorting_type, pageNo, pageSize) {
    return {
      url: deviceURL + `listTemplate?sorting_parameter=${sorting_parameter}&sorting_type=${sorting_type}&page=${pageNo}&page_size=${pageSize}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deleteTemplate: function (data) {
    return {
      url: deviceURL + `deleteTemplate`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getTemplateDetails: function (id) {
    return {
      url: deviceURL + `editTemplate/${id}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getTemplateById: function (id) {
    return {
      url: deviceURL + `editTemplate/${id}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  updateTemplate: function (id, data) {
    return {
      url: deviceURL + `editTemplate/${id}`,
      method: "PUT",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  applyTemplate: function (data) {
    return {
      url: deviceURL + `applyTemplate`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deviceDataUsage: function (data) {
    return {
      url: deviceURL + `deviceusage`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  validateLicence: function (data) {
    return {
      url: deviceURL + `validateLicence`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  uploadTemplate: function (data) {
    return {
      url: deviceURL + `uploadTemplate`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  downloadTemplate: function (data) {
    return {
      url: deviceURL + `downloadTemplate?template_ids=[${data.template_ids}]`,
      method: "GET",
      cache: false,
      timeout: 40000,      
      responseType: 'blob'
    };
  },
  downloadDeviceTemplate: function (data) {
    return {
      url: deviceURL + `deviceTemplateDownload?template_operation_ids=${data.template_operation_ids}`,
      method: "GET",
      cache: false,
      timeout: 40000,      
      responseType: 'blob'
    };
  },
  uploadDeviceTemplate: function (data) {
    return {
      url: deviceURL + `deviceTemplateUpload`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  checkLicenseValidity: function () {
    return {
      url: deviceURL + `checkLicenceValidity`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  connectedClient: function (data) {
    return {
      url: deviceURL + `clientList`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deviceAppliedTemplateList: function (data, pageNo, pageSize) {
    return {
      url:
        deviceURL +
        `appliedTemplatelist`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deviceGroupTemplateList: function (data, pageNo, pageSize) {
    return {
      url:
        deviceURL +
        `deviceGroupAppliedTemplates`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deleteDeviceOperation: function (data) {
    return {
      url: deviceURL + `deleteDeviceoperation`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  dashboardDataUsage: function (data) {
    return {
      url: deviceURL + `totalDataUsage`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  // Api for Group Device Usage 
  dashboardDeviceGroupUsage: function (data) {
    return {
      url: deviceURL + `deviceGroupDataUsage`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  // api for per device group data usage
  dashboardDeviceGroupPerUsage: function (data) {
    return {
      url: deviceURL + `groupDevicesDataUsageByModel`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  // device/groupDevicesDataUsageByModel

  deviceConnectivityUsage: function (data) {
    return {
      url: deviceURL + `getdeviceuptime?host_id=${data.host_id}&start_date=${data.start_date}&end_date=${data.end_date}`,
      method: "GET",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 120000,
    };
  },
  factoryResetDevices: function (data) {
    return {
      url: deviceURL + `factoryResetDevices`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  rebootDevices: function (data) {
    return {
      url: deviceURL + `rebootDevices`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  alertWarningDevice: function (data, pageNo, pageSize) {
    return {
      url: deviceURL + `deviceEvents?page=${pageNo}&page_size=${pageSize}`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getAddresbyId: function (data) {
    return {
      url: deviceURL + `GetAddressByDeviceId`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deviceSeverity: function (data) {
    return {
      url: deviceURL + `deviceSeverity`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deviceStatistics: function (data) {
    return {
      url: deviceURL + `deviceStatistics`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  homeDashboardDeviceEvents: function (pageNo, pageSize) {
    return {
      url: deviceURL + `deviceEvents?page=${pageNo}&page_size=${pageSize}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  homeDashboardDeviceSeverity: function () {
    return {
      url: deviceURL + `deviceSeverity`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  tunnelDataUsage: function (data) {
    return {
      url: deviceURL + `tunneldatausageview`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  retryOperation: function (data) {
    return {
      url: deviceURL + `retryOperation`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  cancelOperations: function (data) {
    return {
      url: deviceURL + `taskCancellation`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  networkTopology: function () {
    return {
      url: deviceURL + `networkTopology`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  operationLog: function (data) {
    return {
      url: deviceURL + `operationLog`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  updateDeviceName: function (data) {
    return {
      url: deviceURL + `updateDeviceName`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getModuleList: function () {
    return {
      url: deviceURL + `moduleList`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  modemInfo: function (data) {
    return {
      url: deviceURL + `modemInfo`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  templateInfoById: function (data) {
    return {
      url: deviceURL + `getTemplateByTemplateId`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  attachedDeviceInfo: function (data) {
    return {
      url: deviceURL + `attachedDeviceInfo`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getDeviceOperations: function (data, pageNo, pageSize) {
    return {
      url: deviceURL + `getDeviceOperations?page=${pageNo}&page_size=${pageSize}`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getListFirmware: function (pageNo, pageSize) {
    return {
      url: deviceURL + `listFirmware?page=${pageNo}&page_size=${pageSize}`,
      method: "get",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getSyncFirmware: function () {
    return {
      url: deviceURL + `syncFirmware`,
      method: "get",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 180000,
    };
  },
  getFirmwareListFilter: function (data, pageNo, pageSize) {
    return {
      url: deviceURL + `firmwarelistfilter?page=${pageNo}&page_size=${pageSize}`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getFirmwareVersions: function (data) {
    return {
      url: deviceURL + `firmwareVersions`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deviceFirmwareUpgrade: function (data) {
    return {
      url: deviceURL + `firmwareUpgrade`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getDeviceEventFilter: function (data, pageNo, pageSize) {
    return {
      url: deviceURL + `getDeviceEventFilter?page=${pageNo}&page_size=${pageSize}`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getNetworkView: function (data) {
    return {
      url: deviceURL + `networkView`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getTunnelsView: function (data) {
    return {
      url: deviceURL + `tunnelView`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getGroupTunnelsView: function (data) {
    return {
      url: deviceURL + `groupTunnelView`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  searchDeviceList: function (pageNo, pageSize, data) {
    return {
      url: deviceURL + `searchDeviceList?pinned=${data.pinned}&page=${pageNo}&page_size=${pageSize}`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  searchTemplateList: function (pageNo, pageSize, data) {
    return {
      url: deviceURL + `searchTemplateList?page=${pageNo}&page_size=${pageSize}`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  groupNetworks: function (data) {
    return {
      url: deviceURL + `groupNetworks`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getDeviceGroupList: function (sorting_parameter, sorting_type, pageNo, pageSize) {
    return {
      url: deviceURL + `deviceGroupList?sorting_parameter=${sorting_parameter}&sorting_type=${sorting_type}&page=${pageNo}&page_size=${pageSize}`,
      method: "get",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  addDeviceGroup: function (data) {
    return {
      url: deviceURL + `addDeviceGroup`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  groupInfoByModel: function (data) {
    return {
      url: deviceURL + `groupInfoByModel`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deleteDeviceGroup: function (data) {
    return {
      url: deviceURL + `deleteDeviceGroup`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deviceGroupList: function (data) {
    return {
      url: deviceURL + `deviceGroupList`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  updateDeviceGroup: function (data, id) {
    return {
      url: deviceURL + `updateDeviceGroup/${id}`,
      method: "PUT",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  changePassword: function (data) {
    return {
      url: userURL + `change-password/`,
      method: "PUT",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deviceGroupFactoryReset: function (data) {
    return {
      url: deviceURL + `deviceGroupFactoryReset`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deviceGroupReboot: function (data) {
    return {
      url: deviceURL + `deviceGroupReboot`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deviceLookUp: function () {
    return {
      url: deviceURL + `DeviceLookUp`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deviceLogList: function (pageNo, pageSize) {
    return {
      url: deviceURL + `deviceLogList?page=${pageNo}&page_size=${pageSize}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deviceLogDownload: function () {
    return {
      url: deviceURL + `deviceLogDownload`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deviceTroubleshoot: function (data) {
    return {
      url: deviceURL + `deviceTroubleshooting`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 180000,
    };
  },
  dataUsageReset: function (data) {
    return {
      url: deviceURL + `dataUsageReset`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deviceGroupLookUp: function (data) {
    return {
      url: deviceURL + `deviceLookUpGroup`,
      method: "GET",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  // Api for Device Group information
  deviceGroupInfo: function (data) {
    return {
      url: `${deviceURL}deviceGroupList`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,

    }
  },
  getDeviceLookUp: function (data) {
    return {
      url: deviceURL + `DeviceLookUp`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },

  getGeneratedLogs: function (device_id, pageNo, pageSize) {
    return {
      // EMGT-5 : Device snapshot is not generated after downloading the generated snapshot and navigating to another device
      url: deviceURL + `snapshots?device_id=${device_id}&page=${pageNo}&page_size=${pageSize}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  defaultTemplateList: function (sorting_parameter, sorting_type, pageNo, pageSize) {
    return {
      url: deviceURL + `defaultTemplateList?sorting_parameter=${sorting_parameter}&sorting_type=${sorting_type}&page=${pageNo}&page_size=${pageSize}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  searchDefaultTemplateList: function (pageNo, pageSize, data) {
    return {
      url: deviceURL + `searchDefaultTemplateList?page=${pageNo}&page_size=${pageSize}`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  setAsDefaultTemplates: function (data) {
    return {
      url: deviceURL + `setAsDefaultZTPTemplates`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  logout: function (data) {
    return {
      url: userURL + `logout/`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  updateOperationStatus: function (data) {
    return {
      url: deviceURL + `updateOperationStatus`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  checkDeviceTask: function (data) {
    return {
      url: deviceURL + `checkDeviceTask`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  getCollections: function () {
    return {
      url: deviceURL + `collectionMapping`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  templatesDropdown: function () {
    return {
      url: deviceURL + `templatesDropdown`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  bootstrapTemplatesDropdown: function () {
    return {
      url: deviceURL + `bootstrapTempDropdown`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  restoreFactoryTemplate: function (data) {
    return {
      url: deviceURL + `setAsFactoryTemplate`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  downloadOperationLogfile: function (data) {
    return {
      url: deviceURL + `downloadOperationLogfile?operation_id=${data}`,
      method: "GET",
      cache: false,
      timeout: 40000,
    };
  },
  downloadDeviceLogfile: function (data) {
    return {
      url: deviceURL + `downloadDeviceLogfile?device_snapshot_id=${data}`,
      method: "GET",
      cache: false,
      timeout: 200000,
      responseType: 'blob'
    };
  },
  downloadSyslogFile: function (data) {
    return {
      url: deviceURL + `downloadSysLog`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  updateTunnel: function (data, id) {
    return {
      url: deviceURL + `editTunnel/${id}`,
      method: "PUT",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  tunnelInfo: function (data) {
    return {
      url: deviceURL + `tunnelInfo`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  deviceLogDownloadNew: function (data) {
    return {
      url: deviceURL + `deviceLogDownload?filename=${data}`,
      method: "GET",
      cache: false,
      timeout: 90000,
      responseType: 'blob'
    }
  },
  deviceCheckpoints: function (data) {
    return {
      url: deviceURL + `deviceCheckpoints?device_mac=${data}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  createCheckpoint: function (data) {
    return {
      url: deviceURL + `deviceCheckpoints`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  deleteCheckpoint: function (uuid) {
    return {
      url: deviceURL + `deviceCheckpoints/${uuid}`,
      method: "DELETE",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  restoreCheckpoint: function (data) {
    return {
      url: deviceURL + `restoreDeviceCheckpoints`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  searchTunnel: function (data, pageNo, pageSize) {
    return {
      url: deviceURL + `tunnelSearch?page=${pageNo}&page_size=${pageSize}`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  getWanStats: function (data) {
    return {
      url: deviceURL + `qosStatistics?device_id=${data.device_id}&interface_name=${data.interface_name}&start_time=${data.start_time}&end_time=${data.end_time}`,
      method: "GET",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 120000,
    }
  },
  getQoSData: function (data) {
    return {
      url: deviceURL + `qosJson`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  rotateTunnelKey: function (data) {
    return {
      url: deviceURL + `dataTunnelKeyRotation`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  rotateDeviceKey: function (data) {
    return {
      url: deviceURL + `controlTunnelKeyRotation`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  updateZabbixTriggerConfiguration: function (data) {
    return {
      url: deviceURL + `updatePollingParameters`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 200000,
    }
  },
  getZabbixTriggerConfiguration: function () {
    return {
      url: deviceURL + `updatePollingParameters`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  deviceGroupLookUpDrop: function () {
    return {
      url: deviceURL + `deviceGroupLookUp`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getCollectionsData: function (pageNo, pageSize) {
    return {
      url: deviceURL + `collections?page=${pageNo}&page_size=${pageSize}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  syncCollections: function () {
    return {
      url: deviceURL + `collectionMapping`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  savePFAdminUser: function (data) {
    return {
      url: deviceURL + `authServerConfiguration`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  getPFServerDetails: function () {
    return {
      url: deviceURL + `authDeviceInfo`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  getConfigInfo: function () {
    return {
      url: deviceURL + `authServerConfiguration?field=is_auth_server_configured`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  authServerConfiguration: function () {
    return {
      url: deviceURL + `authServerConfiguration`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  putAuthServerConfiguration: function (data) {
    return {
      url: deviceURL + `authServerConfiguration`,
      method: "PUT",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  setAsFavorite: function (data) {
    return {
      url: deviceURL + `pinDevice`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  keyRegeneration: function (data) {
    return {
      url: deviceURL + `regenerateKey`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  taskCancel: function (data) {
    return {
      url: deviceURL + `taskCancel`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  downloadOperationReportfile: function (data) {
    return {
      url: deviceURL + `downloadOperationLogfiles`,
      method: "POST",
      data: data,
      cache: false,
      contentType: "application/json;",
      responseType: 'blob',
      timeout: 40000,
    };
  },
  downloadDataUsageGraphs: function (data) {
    return {
      url: deviceURL + `downloadOperationLogfile1`,
      method: "POST",
      data: data,
      cache: false,
      contentType: "application/json;",
      responseType: 'blob',
      timeout: 40000,
    };
  },
  notificationSetting: function (data) {
    return {
      url: deviceURL + `notifications`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getNotification: function () {
    return {
      url: deviceURL + `notifications`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getRepoUrl: function () {
    return {
      url: deviceURL + `debianRepoURL`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  SMTP_Server: function (data) {
    return {
      url: deviceURL + `smtp`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  get_SMTP_Server: function () {
    return {
      url: deviceURL + `smtp`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  get_topConsumer: function (data) {
    return {
      url: deviceURL + `topConsumer?type_of_data_usage=${data.type_of_data_usage}&start_date=${data.start_date}&end_date=${data.end_date}&count=${data.count}&upper_threshold=${data.upper_threshold}&lower_threshold=${data.lower_threshold}`,
      method: "GET",
      dataType: "json",
      cache: false,
      // data: data,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  get_oldConfigs: function (data) {
    return {
      url: deviceURL + `oldConfigs`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  getTopConsumerDeviceGroup: function (data) {
    return {
      url: deviceURL + `topConsumerDeviceGroup?type_of_data_usage=${data.type_of_data_usage}&count=${data.count}&group_id=${data.group_id}&upper_threshold=${data.upper_threshold}&lower_threshold=${data.lower_threshold}&start_date=${data.start_date}&end_date=${data.end_date}`,
      method: "GET",
      dataType: "json",
      cache: false,
      // data: data,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  getDeviceGroupDelta: function (data) {
    return {
      url: deviceURL + `deviceGroupDelta?day_no=${data.day_no}&group_id=${data.group_id}&start_date=${data.start_date}&end_date=${data.end_date}`,
      method: "GET",
      dataType: "json",
      cache: false,
      // data: data,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  getDeviceDelta: function (data) {
    return {
      url: deviceURL + `deviceUsageDelta?day_no=${data.day_no}&device_id=${data.device_id}&start_date=${data.start_date}&end_date=${data.end_date}`,
      method: "GET",
      dataType: "json",
      cache: false,
      // data: data,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  getUsage: function (data) {
    return {
      url: deviceURL + `totalUsageDelta?day_no=${data.day_no}&start_date=${data.start_date}&end_date=${data.end_date}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  clearDevice: function (data) {
    return {
      url: deviceURL + `clearDevice`,
      method: "DELETE",
      data: data,
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getChronology: function (data) {
    const {
      device_id,
      pageNo,
      pageSize,
      type,
      severityType,
      operationStatus,
      startDate,
      endDate,
    } = data;
    const queryParams = new URLSearchParams({
      device_id,
      page: pageNo,
      page_size: pageSize,
      ...(type && { type }),
      ...(severityType && { severity_type: severityType }),
      ...(operationStatus && { operation_status: operationStatus }),
      ...(startDate && { start_date: startDate }),
      ...(endDate && { end_date: endDate }),
    });
    const url = `${deviceURL}chronology?${queryParams}`;
    return {
      url,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getOperationsDelta: function (data) {
    return {
      url: deviceURL + `opDiff?device_mac=${data.device_mac}&operation_type=${data.operation_type}&operation_start_date=${data.operation_start_date} `,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getPollingData: function (data) {
    return {
      url: deviceURL + `polling?device_id=${data.device_id}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  savePollingData: function (data) {
    return {
      url: deviceURL + `polling`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getTelemetry: function (data) {
    return {
      url: deviceURL + `onDemand?device_ip=${data.device_ip}&${data.key ? `key=${data.key}` : data.tunnel ? `tunnel=${data.tunnel}` : `network_key=${data.network_key}`}&device_id=${data.device_id}&device_model=${data.device_model}`,
      method: "GET",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getInterfaceNames: function (data) {
    return {
      url: deviceURL + `interfaceNames?device_id=${data.device_id}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getSpeedHistory: function (data) {
    return {
      url: deviceURL + `speedHistory?device_id=${data.device_id}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getSpeedTest: function (data) {
    return {
      url: deviceURL + `speedTest`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 180000,
    };
  },
  saveServer: function (data) {
    return {
      url: deviceURL + `externalServer`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getServer: function () {
    return {
      url: deviceURL + `externalServer`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  changeServer: function (data) {
    return {
      url: deviceURL + `externalServer`,
      method: "PUT",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deleteServer: function (data) {
    return {
      url: deviceURL + `externalServer`,
      method: "DELETE",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  get_Global_refresh: function (data) {
    return {
      url: deviceURL + `onDemand`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 120000,
    }
  },
  savedData: function (data) {
    return {
      url: deviceURL + `savedData?device_id=${data.device_id}&data_key=${data.data_key}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  gpNameCheck: function (data) {
    return {
      url: deviceURL + `gpNameCheck?group_id=${data.group_id}&group_name=${data.group_name}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getNewTemplate: function (data) {
    return {
      url: deviceURL + `getTemplate?model=${data.model}&template_type=${data.template_type}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  templateByType: function (data) {
    return {
      url: deviceURL + `templateByType?template_type=${data.template_type}&default_type=${data.default_type}&page=${data.pageNo}&page_size=${data.pageSize}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  gcmlcon: function (data) {
    return {
      url: deviceURL + `gcmlcon`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 120000,
    }
  },
  addDevicePTK: function (data) {
    return {
      url: deviceURL + `addDevicePTK`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  activationKey: function (data) {
    return {
      url: deviceURL + `activationKey?device_id=${data.device_id}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    }
  },
  downloadIpsecLogfile: function (data) {
    return {
      url: deviceURL + `ipsecLogDownload?device_id=${data}`,
      method: "GET",
      cache: false,
      timeout: 200000,
      responseType: 'blob'
    };
  },
  ipsecStatus: function (data) {
    return {
      url: deviceURL + `ipsecStatus`,
      method: "POST",
      dataType: "json",
      data: data,
      cache: false,
      contentType: "application/json;",
      timeout: 120000,
    };
  },
  getAllTunnelList: function () {
    return {
      url: deviceURL + `tunnelLookUp`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  deleteIPSecTunnel: function (data) {
    return {
      url: deviceURL + `deleteTunnel/0`,
      method: "DELETE",
      data: data,
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getSystemAlerts: function (pageNo, pageSize) {
    return {
      url: deviceURL + `systemAlerts?page=${pageNo}&page_size=${pageSize}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getSystemAlertsCount: function () {
    return {
      url: deviceURL + `systemAlertsCount`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  readSystemAlertsCount: function (data) {
    return {
      url: deviceURL + `systemAlertsCount`,
      method: "POST",
      data: data,
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  getSystemAlertsFilter: function (data) {
    return {
      url: data.severity ? deviceURL + `systemAlerts?acknowledged=${data.acknowledged}&severity=${data.severity}&page=${data.pageNo}&page_size=${data.pageSize}` : 
      deviceURL + `systemAlerts?acknowledged=${data.acknowledged}&page=${data.pageNo}&page_size=${data.pageSize}`,
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  startInstallationWizard: function (data) {
    return {
      url: deviceURL + 'startUiService',
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000,
    };
  },
  troubleshooting: function (data) {
    return {
      url: deviceURL + 'startTroubleshoot',
      method: "POST",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000
    };
  },
  checkStatus: function () {
    return {
      url: deviceURL + 'troubleshootStatus',
      method: "GET",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000
    };
  },
  EndTroubleshooting: function (data) {
    return {
      url: deviceURL + 'endTroubleshoot',
      method: "POST",
      dataType: "json",
      cache: false,
      contentType: "application/json;",
      timeout: 40000
    };
  },
}
export default urlMapping;
export { envURL, guacamoleUrl };