import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import ApiCall from "../../../../redux/API/apiCall";
import urlMapping from "../../../../redux/API/api";
import * as Common from "../../../../utils/common";
import debounce from 'lodash/debounce';
import * as Action from "../../../../redux/actionTypes";
import Slider from 'react-input-slider';
import { ErrorPopup } from "../../../../layOuts/errorPopup";
import isEqual from 'lodash/isEqual';
{/* Initial template_schema_json data for trigger template*/ }
const template_schema_json_data = {
  "triggers": [
    {
      "trigger_type": "Ethernet interface discovery",
      "Client Connected": {
        "status": "1",
        "severity": "INFO"
      },
      "Client Disconnected": {
        "status": "1",
        "severity": "INFO"
      }
    },
    {
      "trigger_type": "Wifi interface discovery",
      "Wifi Client Connected": {
        "status": "1",
        "severity": "INFO"
      },
      "Wifi Client Disconnected": {
        "status": "1",
        "severity": "INFO"
      }
    },
    {
      "trigger_type": "Mounted filesystem discovery",
      "Disk space is critically low": {
        "status": "1",
        "macro": "{$VFS.FS.PUSED.MAX.CRIT}",
        "threshold": "90",
        "severity": "HIGH"
      },
      "70% of disk space is utilized": {
        "status": "1",
        "macro": "{$VFS.FS.PUSED.MAX.WARN}",
        "threshold": "70",
        "severity": "WARNING"
      },
      "50% of disk space is utilized": {
        "status": "1",
        "macro": "{$VFS.FS.PUSED.MAX.INFO}",
        "threshold": "50",
        "severity": "INFO"
      },
    },
    {
      "trigger_type": "Network Interface Discovery",
      "High error rate": {
        "status": "1",
        "severity": "WARNING"
      },
      "Operation status": {
        "Link down": {
          "status": "1",
          "severity": "WARNING"
        },
        "Link UP": {
          "status": "1",
          "severity": "INFO"
        }
      }
    },
    {
      "trigger_type": "Memory utilization",
      "High memory utilization": {
        "macro": "{$MEMORY.UTIL.MAX}",
        "status": "1",
        "threshold": "90",
        "severity": "HIGH"
      },
      "memory_above50": {
        "status": "1",
        "macro": "{$MEMORY.UTIL.AVG}",
        "threshold": "70",
        "severity": "WARNING"
      },
      "memory_below50": {
        "status": "1",
        "macro": "{$MEMORY.UTIL.INFO}",
        "threshold": "50",
        "severity": "INFO"
      },
    },
    {
      "trigger_type": "VPN status",
      "device_offline": {
        "status": "1",
        "severity": "WARNING"
      },
      "Device Online": {
        "status": "1",
        "severity": "INFO"
      },
    },
    {
      "trigger_type": "System local time",
      "System time is out of sync": {
        "status": "1",
        "severity": "WARNING"
      }
    },
    // {
    //   "trigger_type": "CPU utilization",
    //   "high_cpu_utilization": {
    //     "macro": "{$CPU.UTIL.CRIT}",
    //     "status": "1",
    //     "threshold": "90",
    //     "severity": "HIGH"
    //   },
    //   "cpu_above50": {
    //     "macro": "{$CPU.UTIL.WARN}",
    //     "status": "1",
    //     "threshold": "70",
    //     "severity": "WARNING"
    //   },
    //   "cpu_below50": {
    //     "macro": "{$CPU.UTIL.INFO}",
    //     "status": "1",
    //     "threshold": "50",
    //     "severity": "INFO"
    //   },
    // },
    {
      "trigger_type": "Data usage",
      "Cellular: Data Usage": {
        "data_usage": {
          "status": "1",
          "threshold": "100M",
          "severity": "INFO"
        },
        "average_data_usage": {
          "status": "1",
          "threshold": "500M",
          "severity": "WARNING"
        },
        "high_data_usage": {
          "status": "1",
          "threshold": "1000M",
          "severity": "HIGH"
        }
      },
      "Control tunnel: Data Usage": {
        "data_usage": {
          "status": "1",
          "threshold": "100M",
          "severity": "INFO"
        },
        "average_data_usage": {
          "status": "1",
          "threshold": "500M",
          "severity": "WARNING"
        },
        "high_data_usage": {
          "status": "1",
          "threshold": "1000M",
          "severity": "HIGH"
        }
      },
      "Data tunnel: Data Usage": {
        "data_usage": {
          "status": "1",
          "threshold": "100M",
          "severity": "INFO"
        },
        "average_data_usage": {
          "status": "1",
          "threshold": "500M",
          "severity": "WARNING"
        },
        "high_data_usage": {
          "status": "1",
          "threshold": "1000M",
          "severity": "HIGH"
        }
      },
      "WAN:  Data Usage": {
        "data_usage": {
          "status": "1",
          "threshold": "100M",
          "severity": "INFO"
        },
        "average_data_usage": {
          "status": "1",
          "threshold": "500M",
          "severity": "WARNING"
        },
        "high_data_usage": {
          "status": "1",
          "threshold": "1000M",
          "severity": "HIGH"
        }
      },
      "WiFi : Data Usage": {
        "data_usage": {
          "status": "1",
          "threshold": "100M",
          "severity": "INFO"
        },
        "average_data_usage": {
          "status": "1",
          "threshold": "500M",
          "severity": "WARNING"
        },
        "high_data_usage": {
          "status": "1",
          "threshold": "1000M",
          "severity": "HIGH"
        }
      },
      "WiFi_2.4GHz: Data Usage": {
        "data_usage": {
          "status": "1",
          "threshold": "100M",
          "severity": "INFO"
        },
        "average_data_usage": {
          "status": "1",
          "threshold": "500M",
          "severity": "WARNING"
        },
        "high_data_usage": {
          "status": "1",
          "threshold": "1000M",
          "severity": "HIGH"
        }
      },
      "WiFi_5GHz : Data Usage": {
        "data_usage": {
          "status": "1",
          "threshold": "100M",
          "severity": "INFO"
        },
        "average_data_usage": {
          "status": "1",
          "threshold": "500M",
          "severity": "WARNING"
        },
        "high_data_usage": {
          "status": "1",
          "threshold": "1000M",
          "severity": "HIGH"
        }
      },
    },
    {
      "trigger_type": "device_tunnel_discovery",
      "Link down": {
        "status": "1",
        "severity": "WARNING"
      },
      "Link UP": {
        "status": "1",
        "severity": "INFO"
      }
    },
  ]
};

class WATemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      addTrigger: false,
      triggerType: '',
      selectedTemplateId: this.props.id ? this.props.id : '',
      template_type: this.props.templateData.template_type || "",
      template_name: this.props.templateData.template_name || "",
      template_desc: this.props.templateData.template_desc || "",
      device_model: this.props.templateData.device_model || "",
      collection_version: this.props.templateData.collection_version || "",
      fw_version: this.props.templateData.fw_version || "",
      chimay: this.props.chimay,
      cbr: this.props.cbr,
      mifi: this.props.mifi,
      isAxiosError: false,
      template_schema_json: template_schema_json_data,
      temp_template_schema_json: template_schema_json_data,
      selectedTriggerData: [
        {
          "trigger_type": "Ethernet interface discovery",
          "Client Connected": {
            "status": "1",
            "severity": "INFO"
          },
          "Client Disconnected": {
            "status": "1",
            "severity": "INFO"
          }
        },
      ],
      selectedModelList: ""
    };
  }
  componentDidMount() {
    if (this.props.template_schema_json !== undefined) {
      let template_schema_json = JSON.parse(JSON.stringify(this.props.template_schema_json));
      let vCPE_template_schema_json;
      let mifi_template_schema_json;
      if (this.props.selectedModel === 'vCPE') {
        vCPE_template_schema_json = template_schema_json?.triggers?.filter(item => item.trigger_type !== "Wifi interface discovery")
        vCPE_template_schema_json = vCPE_template_schema_json.map(item => {
          if (item.trigger_type === "Data usage") {
            delete item["Cellular: Data Usage"];
            delete item["WiFi : Data Usage"];
            delete item["WiFi_2.4GHz: Data Usage"];
            delete item["WiFi_5GHz : Data Usage"];
            return item;
          }
          else {
            return item;
          }
        })
        template_schema_json.triggers = JSON.parse(JSON.stringify(vCPE_template_schema_json))
      }
      // Trigger Configuration View & Edit issue fix
      else if (this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model'
        || this.props.selectedModel === 'Stormbreaker' || this.props.selectedModel === 'Firestorm') {
        mifi_template_schema_json = template_schema_json.triggers.filter(item => item.trigger_type !== "Ethernet interface discovery")
        mifi_template_schema_json = mifi_template_schema_json.filter(item => item.trigger_type !== "System local time")
        template_schema_json.triggers = JSON.parse(JSON.stringify(mifi_template_schema_json))
      }
      this.setState({
        template_schema_json
      })
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {

    {/*Modified template_schema_json for selected model*/ }
    if (nextProps?.selectedModelList !== undefined) {
      let selectedModelList = Common.getModels(nextProps.selectedModelList);
      if (!isEqual(this.state?.selectedModelList, selectedModelList)) {
        let template_schema_json = JSON.parse(JSON.stringify(this.state.temp_template_schema_json));
        switch (selectedModelList) {
          case 'vCPE':
            template_schema_json.triggers = template_schema_json.triggers?.filter(item => item.trigger_type !== "Wifi interface discovery");
            template_schema_json.triggers?.forEach(item => {
              if (item.trigger_type === "Data usage") {
                delete item["Cellular: Data Usage"];
                delete item["WiFi : Data Usage"];
                delete item["WiFi_2.4GHz: Data Usage"];
                delete item["WiFi_5GHz : Data Usage"];
              }
            });
            break;
          case 'Moretti_Model':
          case 'Duvel_Model':
          case 'Stormbreaker':
          case 'Firestorm':
            template_schema_json.triggers = template_schema_json.triggers.filter(item =>
              item.trigger_type !== "Ethernet interface discovery" && item.trigger_type !== "System local time"
            );
            break;
          default:
            break;
        }
        this.setState({
          template_schema_json,
          selectedModelList
        });
      }
    }
    if (Object.keys(nextProps.templateData).length !== 0) {
      this.setState({
        selectedTemplateId: nextProps.id ? nextProps.id : '',
        template_type: nextProps?.templateData?.template_type || "",
        template_name: nextProps?.templateData?.template_name || "",
        template_desc: nextProps?.templateData?.template_desc || "",
        device_model: nextProps?.templateData?.device_model || "",
        collection_version: nextProps?.templateData?.collection_version || "",
        fw_version: nextProps?.templateData?.fw_version || ""
      });
    }
    // Updated vales not retained in edit & reapply
    if (!isEqual(this.props.template_schema_json, nextProps.template_schema_json)) {
      let template_schema_json = JSON.parse(JSON.stringify(nextProps.template_schema_json));
      let vCPE_template_schema_json;
      let mifi_template_schema_json;
      if (nextProps.selectedModel === 'vCPE') {
        vCPE_template_schema_json = template_schema_json.triggers.filter(item => item.trigger_type !== "Wifi interface discovery")
        vCPE_template_schema_json = vCPE_template_schema_json.map(item => {
          if (item.trigger_type === "Data usage") {
            delete item["Cellular: Data Usage"];
            delete item["WiFi : Data Usage"];
            delete item["WiFi_2.4GHz: Data Usage"];
            delete item["WiFi_5GHz : Data Usage"];
            return item;
          }
          else {
            return item;
          }
        })
        template_schema_json.triggers = JSON.parse(JSON.stringify(vCPE_template_schema_json))
      }
      else if (nextProps.selectedModel === 'Moretti_Model' || nextProps.selectedModel === 'Duvel_Model'
        || nextProps.selectedModel === 'Stormbreaker' || nextProps.selectedModel === 'Firestorm') {
        mifi_template_schema_json = template_schema_json.triggers.filter(item => item.trigger_type !== "Ethernet interface discovery")
        mifi_template_schema_json = mifi_template_schema_json.filter(item => item.trigger_type !== "System local time")
        template_schema_json.triggers = JSON.parse(JSON.stringify(mifi_template_schema_json))
      }
      this.setState({
        template_schema_json,
      })
    }
    if (!isEqual(this.props?.chimay, nextProps?.chimay) ||
      !isEqual(this.props?.cbr, nextProps?.cbr) ||
      !isEqual(this.props?.mifi, nextProps?.mifi)) {
      this.setState({
        chimay: nextProps.chimay,
        cbr: nextProps.cbr,
        mifi: nextProps.mifi
      })
    }
  }
  openAddTrigger = (data) => {
    let { selectedTriggerData } = this.state;
    if (data === "editClicked") {
      selectedTriggerData = this.state.template_schema_json.triggers.filter(element => element.trigger_type === this.state.triggerType)
    }
    else {
      selectedTriggerData = this.state.template_schema_json.triggers.filter(element => element.trigger_type === data)
      this.setState({
        triggerType: data,
      })
    }
    this.setState({
      selectedTriggerData: JSON.parse(JSON.stringify(selectedTriggerData)),
      addTrigger: true
    })
  }
  closeAddTrigger = () => {
    this.setState({
      addTrigger: false,
      triggerType: ''
    })
    if (this.state.count === 1) {
      this.setState({
        count: 0
      })
    }
  }
  checkboxChecked = (e) => {
    let { count } = this.state;
    if (e.target.checked === true) {
      count = 1
      this.setState({
        triggerType: e.target.value,
        count
      })
    }
    else {
      count = 0
      this.setState({
        triggerType: '',
        count
      })
    }
  }
  selectTrigger = (e) => {
    this.setState({
      triggerType: e.target.value
    })
  }
  //LONG-3252-EM - When Applying Trigger configuration template got failed
  checkSeverity = () => {
    let result = false;
    if (
      this.state.selectedTriggerData?.[0]?.["Wireless Client Connected"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Wireless Client Disconnected"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Link down"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Link UP"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Link down"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Client Connected"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Client Disconnected"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Disk space is critically low"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["70% of disk space is utilized"]?.severity === "" ||
      //EMGT-54-EM page got freezed in edit and reapply case of Trigger configuration	
      this.state.selectedTriggerData?.[0]?.["50% of disk space is utilized"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Operation status"]?.["Link down"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Operation status"]?.["Link UP"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["High error rate"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["High memory utilization"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["memory_above50"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["memory_below50"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["System time is out of sync"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["high_cpu_utilization"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["cpu_above50"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["cpu_below50"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Wifi Client Connected"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Wifi Client Disconnected"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["device_offline"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Device Online"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["data_usage"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["average_data_usage"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["high_data_usage"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["data_usage"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["average_data_usage"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["high_data_usage"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["data_usage"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["average_data_usage"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["high_data_usage"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["data_usage"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["average_data_usage"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["high_data_usage"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["data_usage"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["average_data_usage"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["high_data_usage"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["WiFi_2.4GHz: Data Usage"]?.["data_usage"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["WiFi_2.4GHz: Data Usage"]?.["average_data_usage"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["WiFi_2.4GHz: Data Usage"]?.["high_data_usage"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["data_usage"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["average_data_usage"]?.severity === "" ||
      this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["high_data_usage"]?.severity === ""
    ) {
      result = true;
    }
    return result;
  }
  setTrigger = (e, name1) => {
    let data = this.state.selectedTriggerData
    if (e.target.type === "checkbox") {
      data[0][name1][e.target.name] = e.target.value === "0" ? "1" : "0"
    }
    else {
      data[0][name1][e.target.name] = e.target.value
    }
    this.setState({
      selectedTriggerData: data
    })
  }
  // LONG-2332 : Slider changes for threshold
  setSlider = (e, name1, name) => {
    let data = this.state.selectedTriggerData
    if (e.x !== undefined) {
      // LONG-2798 : Trigger template failing as threshold value was not passing as string 
      data[0][name1][name] = e.x.toString();
    }
    this.setState({
      selectedTriggerData: data
    })
  }
  setTriggerValues = (e, dataUsageType, name1) => {
    let data = this.state.selectedTriggerData
    if (e.target.type === "checkbox") {
      data[0][dataUsageType][name1][e.target.name] = e.target.value === "0" ? "1" : "0"
    }
    else {
      data[0][dataUsageType][name1][e.target.name] = e.target.value
    }
    this.setState({
      selectedTriggerData: data
    })
  }
  // LONG-2332 : Slider changes for threshold
  //EMGT-4-EM - Trigger configuration field validations 
  setSliderValues = (e, dataUsageType, name1, name) => {
    let data = this.state.selectedTriggerData
    if (e.x !== undefined) {
      data[0][dataUsageType][name1][name] = `${e.x}M`
    }
    this.setState({
      selectedTriggerData: data
    })
  }
  updateTrigger = () => {
    let template_schema_json = this.state.template_schema_json;
    template_schema_json.triggers.map((item, index) => {
      if (item.trigger_type === this.state.triggerType) {
        this.state.template_schema_json.triggers.splice(index, 1, this.state.selectedTriggerData[0])
        return item;
      }
      else {
        return item;
      }
    })
    this.setState({
      template_schema_json
    })
    if (this.state.count === 1) {
      this.setState({
        count: 0
      })
    }
    this.closeAddTrigger()
  }
  saveData = () => {
    let data = {};
    data.template_name = this.state.template_name;
    data.template_desc = this.state.template_desc;
    data.device_model = this.props?.selectedModelList ? this.props?.selectedModelList?.toString() : this.state.device_model.toString();
    data.template_type = this.state.template_type;
    data.collection_version = this.state.collection_version;
    data.fw_version = this.state.fw_version;
    data.template_schema_json = this.state.template_schema_json;
    data.template_order = 11;
    if (this.state.selectedTemplateId === "") {
      data.template_version = {
        chimay: this.state.chimay,
        cbr: this.state.cbr,
        mifi: this.state.mifi
      }
    }
    this.setState({
      showLoader: true,
    });
    if (this.state.selectedTemplateId !== '') {
      ApiCall(urlMapping.updateTemplate(this.state.selectedTemplateId, data), (response) => {
        this.setState({
          showLoader: false,
        });
        if (response?.Success) {
          this.props.refreshList();
          this.props.closeAddTemplate();
          this.props.openApplyTemplate(this.state.selectedTemplateId);
        } else {
          if (parseInt(response?.response?.status) === 401) {
            this.setState({ showRefreshModal: true })
          } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          } else {
            this.setState({
              title: 'Update Template',
              message: response?.message,
              show: true,
              error: true,
            }, () => $("#errorMessage").modal("show"));
          }
        }
      });
    } else {
      ApiCall(urlMapping.createTemplate(data), (response) => {
        this.setState({
          showLoader: false,
        });
        if (response?.success) {
          this.props.refreshList();
          this.props.closeAddTemplate();
          this.props.openApplyTemplate(response.data.id);
        }
        else {
          if (parseInt(response?.response?.status) === 401) {
            this.setState({ showRefreshModal: true })
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState({
              title: 'Create Template',
              message: response?.message,
              show: true,
              error: true,
            }, () => $("#errorMessage").modal("show"));
          }
        }
      });
    }
  };
  //EMGT-381-EM - "Template name already exists" pop-up is not shown while creating custom template.
  resetPopup = () => {
    this.setState(
      {
        show: false,
        error: false,
      },
      () => {
        $("#errorMessage").modal("hide");
        this.props.refreshList();
        this.props.closeAddTemplate();
      }
    );
  };
  applyTemplate = () => {
    let { template_schema_json } = this.state;
    this.props.dispatch({
      type: Action.SET_APPLY_TEMPLATE,
    });
    this.props.applyTemplate(template_schema_json);
  }
  debounceApply = debounce((fn, event) => {
    fn(event)
  }, 500);
  closeAll = () => {
    if (this.props.template_schema_json !== undefined) {
      this.setState({
        template_schema_json: this.props.template_schema_json,
        triggerType: ''
      })
    }
    this.props.dispatch({
      type: Action.CLOSE_APPLY_TEMPLATE,
    });
    this.props.closeAddTemplate()
  }
  render() {
    return (
      <div>
        <div className="warning-alert-config">
          <div className="page-sub-title">
            {/* LONG-2329-EM - Update trigger got freeze when click 'X' button and Cancel button of Apply template */}
            <div className={this.state.addTrigger || this.state.openAddTrigger ? "disabled-slide" : ""}></div>
            <div>
              <strong>Triggers</strong>
            </div>
            <div className="device-action">
              <span className={(this.state.count === 1) ?
                "icon-box" : "icon-box disabled"}><img src="/images/svg-icons/edit.svg" alt=""
                  onClick={(this.state.count === 1) ? () => this.openAddTrigger("editClicked") : undefined} /></span>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th width="40" className="text-center"><input type="checkbox" /></th>
                <th>Trigger Type</th>
                <th>Module</th>
                <th>Status</th>
                <th>Severity</th>
              </tr>
            </thead>
            <tbody>
              {(this.state.template_schema_json.triggers.map(item => item.trigger_type === 'device_tunnel_discovery')) &&
                <tr>
                  <td className="text-center"><input type="checkbox"
                    value="device_tunnel_discovery"
                    checked={this.state.triggerType === "device_tunnel_discovery"}
                    onChange={e => this.checkboxChecked(e)}
                  />
                  </td>
                  <td>
                    <span title="Data Tunnel Link" style={{ width: "auto" }} className="cursor-pointer">
                      <button className="txt-blue" onClick={() => this.openAddTrigger("device_tunnel_discovery")} >
                        Data Tunnel Link</button>
                    </span>
                  </td>
                  <td>
                    <ul className="trigger-list">
                      <li>Link Down</li>
                      <li>Link Up</li>
                    </ul>
                  </td>
                  <td>
                    <ul className="trigger-list">
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'device_tunnel_discovery')?.[0]["Link down"]?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'device_tunnel_discovery')?.[0]["Link UP"]?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                    </ul>
                  </td>
                  <td>
                    <ul className="trigger-list">
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'device_tunnel_discovery')?.[0]?.["Link down"]?.severity)}</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'device_tunnel_discovery')?.[0]?.["Link UP"]?.severity)}</li>
                    </ul>
                  </td>
                </tr>
              }
              {/* Trigger Configuration View & Edit issue fix */}
              {(this.state.template_schema_json.triggers.filter(item => item.trigger_type === 'Ethernet interface discovery').length > 0) &&
                (this.props.selectedModel === undefined || this.props.selectedModel === 'vCPE' || this.props.selectedModel === 'CHIMAY') &&
                <>
                  <tr>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        value="Ethernet interface discovery"
                        checked={this.state.triggerType === "Ethernet interface discovery"}
                        onChange={e => this.checkboxChecked(e)}
                      />
                    </td>
                    <td>
                      <span title="Client Connection Status" style={{ width: "auto" }} className="cursor-pointer">
                        <button className="txt-blue" onClick={() => this.openAddTrigger("Ethernet interface discovery")} >
                          Client Connection Status</button>
                      </span>
                    </td>
                    <td>
                      <ul className="trigger-list">
                        <li>Ethernet Client Connect</li>
                        <li>Ethernet Client Disconnect</li>
                      </ul>
                    </td>
                    <td>
                      <ul className="trigger-list">
                        <li>{this.state.template_schema_json.triggers.filter(item =>
                          item.trigger_type === 'Ethernet interface discovery')?.[0]?.["Client Connected"]?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                        <li>{this.state.template_schema_json.triggers.filter(item =>
                          item.trigger_type === 'Ethernet interface discovery')?.[0]?.["Client Disconnected"]?.status === '1' ? 'Enabled' : 'Disabled'}</li>

                      </ul>
                    </td>
                    <td>
                      <ul className="trigger-list">
                        <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                          item.trigger_type === 'Ethernet interface discovery')?.[0]?.["Client Connected"]?.severity)}</li>
                        <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                          item.trigger_type === 'Ethernet interface discovery')?.[0]?.["Client Disconnected"]?.severity)}</li>

                      </ul>
                    </td>
                  </tr>
                </>}
              {(this.state.template_schema_json.triggers.filter(item => item.trigger_type === 'Mounted filesystem discovery').length > 0) &&
                <tr>
                  <td className="text-center"><input type="checkbox"
                    value="Mounted filesystem discovery"
                    checked={this.state.triggerType === "Mounted filesystem discovery"}
                    onChange={e => this.checkboxChecked(e)}
                  />
                  </td>
                  {/* LONG-EM- Trigger Configuration Incorrect popup will shown when move cursor over the field */}
                  <td>
                    <span title="Disk Space" style={{ width: "auto" }} className="cursor-pointer">
                      <button className="txt-blue" onClick={() => this.openAddTrigger("Mounted filesystem discovery")} >
                        Disk Space</button>
                    </span>
                  </td>
                  <td>
                    <ul className="trigger-list">
                      <li>Low Disk Space</li>
                      <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Mounted filesystem discovery')?.[0]?.["Disk space is critically low"]?.threshold}%</strong></li>
                      <li>Disk Space Above 50%</li>
                      <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Mounted filesystem discovery')?.[0]?.["70% of disk space is utilized"]?.threshold}%</strong></li>

                      <li>50% Disk Space</li>
                      <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Mounted filesystem discovery')?.[0]?.["50% of disk space is utilized"]?.threshold}%</strong></li>
                    </ul>
                  </td>
                  <td>
                    <ul className="trigger-list">
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Mounted filesystem discovery')?.[0]?.["Disk space is critically low"]?.status === '1' ?
                        'Enabled' : 'Disabled'}</li>
                    </ul>
                    <ul className="trigger-list">
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Mounted filesystem discovery')?.[0]?.["70% of disk space is utilized"]?.status === '1' ?
                        'Enabled' : 'Disabled'}</li>
                    </ul>
                    <ul className="trigger-list">
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Mounted filesystem discovery')?.[0]?.["50% of disk space is utilized"]?.status === '1' ?
                        'Enabled' : 'Disabled'}</li>
                    </ul>
                  </td>
                  <td>
                    <ul className="trigger-list">
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Mounted filesystem discovery')?.[0]?.["Disk space is critically low"]?.severity)}</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Mounted filesystem discovery')?.[0]?.["70% of disk space is utilized"]?.severity)}</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Mounted filesystem discovery')?.[0]?.["50% of disk space is utilized"]?.severity)}</li>
                    </ul>
                  </td>
                </tr>
              }
              {(this.state.template_schema_json.triggers.filter(item => item.trigger_type === 'Network Interface Discovery').length > 0) &&
                <tr>
                  <td className="text-center">
                    <input
                      type="checkbox"
                      value="Network Interface Discovery"
                      checked={this.state.triggerType === "Network Interface Discovery"}
                      onChange={e => this.checkboxChecked(e)}
                    />
                  </td>
                  {/* LONG-EM- Trigger Configuration Incorrect popup will shown when move cursor over the field */}
                  <td>
                    <span title="Interface Status" style={{ width: "auto" }} className="cursor-pointer">
                      <button className="txt-blue" onClick={() => this.openAddTrigger("Network Interface Discovery")} >
                        Interface Status</button>
                    </span>
                  </td>
                  <td>
                    <ul className="trigger-list">
                      <li>Link Down</li>
                      <li>Link Up</li>
                      <li>High Error Rate</li>
                    </ul>
                  </td>
                  <td>
                    <ul className="trigger-list">
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Network Interface Discovery')?.[0]?.['Operation status']?.["Link down"]?.status === '1' ?
                        'Enabled' : 'Disabled'}</li>
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Network Interface Discovery')?.[0]?.['Operation status']?.["Link UP"]?.status === '1' ?
                        'Enabled' : 'Disabled'}</li>
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Network Interface Discovery')?.[0]?.["High error rate"]?.status === '1' ?
                        'Enabled' : 'Disabled'}</li>
                    </ul>
                  </td>
                  <td>
                    <ul className="trigger-list">
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Network Interface Discovery')?.[0]['Operation status']?.["Link down"]?.severity)}</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Network Interface Discovery')?.[0]['Operation status']?.["Link UP"]?.severity)}</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Network Interface Discovery')?.[0]["High error rate"]?.severity)}</li>
                    </ul>
                  </td>
                </tr>
              }
              {(this.state.template_schema_json.triggers.filter(item => item.trigger_type === 'Memory utilization').length > 0) &&
                <tr>
                  <td className="text-center">
                    <input
                      type="checkbox"
                      value="Memory utilization"
                      checked={this.state.triggerType === "Memory utilization"}
                      onChange={e => this.checkboxChecked(e)}
                    />
                  </td>
                  {/* LONG-EM- Trigger Configuration Incorrect popup will shown when move cursor over the field */}
                  <td>
                    <span title="Memory" style={{ width: "auto" }} className="cursor-pointer">
                      <button className="txt-blue" onClick={() => this.openAddTrigger("Memory utilization")} >
                        Memory</button>
                    </span>
                  </td>
                  <td>
                    <ul className="trigger-list">
                      <li>High Memory Utilisation</li>
                      <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Memory utilization')?.[0]?.["High memory utilization"]?.threshold}%</strong></li>
                      <li>Avg Memory Utilisation</li>
                      <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Memory utilization')?.[0]?.["memory_above50"]?.threshold}%</strong></li>
                      <li>Lack of Available Memory</li>
                      <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Memory utilization')?.[0]?.["memory_below50"]?.threshold}%</strong></li>
                    </ul>
                  </td>
                  <td>
                    <ul className="trigger-list">
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Memory utilization')?.[0]?.['High memory utilization']?.status === '1' ?
                        'Enabled' : 'Disabled'}</li>
                      <li>&nbsp;</li>
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Memory utilization')?.[0]?.['memory_above50']?.status === '1' ?
                        'Enabled' : 'Disabled'}</li>
                      <li>&nbsp;</li>
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Memory utilization')?.[0]?.['memory_below50']?.status === '1' ?
                        'Enabled' : 'Disabled'}</li>
                    </ul>
                  </td>
                  <td>
                    <ul className="trigger-list">
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Memory utilization')?.[0]?.['High memory utilization']?.severity)}</li>
                      <li>&nbsp;</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Memory utilization')?.[0]?.['memory_above50']?.severity)}</li>
                      <li>&nbsp;</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Memory utilization')?.[0]?.['memory_below50']?.severity)}</li>
                    </ul>
                  </td>
                </tr>
              }
              {/* Trigger Configuration View & Edit issue fix */}
              {(this.state.template_schema_json.triggers.filter(item => item.trigger_type === 'System local time').length > 0) &&
                (this.props.selectedModel === undefined || this.props.selectedModel === 'vCPE' || this.props.selectedModel === 'CHIMAY') &&
                <>
                  <tr>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        value="System local time"
                        checked={this.state.triggerType === "System local time"}
                        onChange={e => this.checkboxChecked(e)}
                      />
                    </td>
                    {/* LONG-EM- Trigger Configuration Incorrect popup will shown when move cursor over the field */}
                    <td>
                      <span title="System Local Time" style={{ width: "auto" }} className="cursor-pointer">
                        <button className="txt-blue" onClick={() => this.openAddTrigger("System local time")} >
                          System Local Time</button>
                      </span>
                    </td>
                    <td>
                      <ul className="trigger-list">
                        <li>Host Time Sync</li>
                      </ul>
                    </td>
                    <td>
                      <ul className="trigger-list">
                        <li>{this.state.template_schema_json.triggers.filter(item =>
                          item.trigger_type === 'System local time')?.[0]?.['System time is out of sync']?.status === '1' ?
                          'Enabled' : 'Disabled'}</li>
                      </ul>
                    </td>
                    <td>
                      <ul className="trigger-list">
                        <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                          item.trigger_type === 'System local time')?.[0]?.['System time is out of sync']?.severity)}</li>
                      </ul>
                    </td>
                  </tr>
                </>
              }
              {/* {(this.state.template_schema_json.triggers.map(item => item.trigger_type === 'CPU utilization')) &&
                <tr>
                  <td className="text-center">
                    <input
                      type="checkbox"
                      value="CPU utilization"
                      checked={this.state.triggerType === "CPU utilization"}
                      onChange={e => this.checkboxChecked(e)}
                    />
                  </td>
                   <td>
                <span title="CPU" style={{ width: "auto" }} className="cursor-pointer">
                  <button className="txt-blue" onClick={() => this.openAddTrigger("CPU utilization")} >
                    CPU</button>
                </span>
              </td>
              <td>
                <ul className="trigger-list">
                  <li>High CPU Utilisation</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'CPU utilization')?.[0]?.["high_cpu_utilization"]?.threshold}%</strong></li>
                  <li>Avg CPU Utilisation</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'CPU utilization')?.[0]?.["cpu_above50"]?.threshold}%</strong></li>
                  <li>Lack of Available CPU</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'CPU utilization')?.[0]?.["cpu_below50"]?.threshold}%</strong></li>
                </ul>
              </td>
              <td>
                <ul className="trigger-list">
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'CPU utilization')?.[0]?.['high_cpu_utilization']?.status === '1' ?
                    'Enabled' : 'Disabled'}</li>
                  <li>&nbsp;</li>
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'CPU utilization')?.[0]?.['cpu_above50']?.status === '1' ?
                    'Enabled' : 'Disabled'}</li>
                  <li>&nbsp;</li>
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'CPU utilization')?.[0]?.['cpu_below50']?.status === '1' ?
                    'Enabled' : 'Disabled'}</li>
                </ul>
              </td>
              <td>
                <ul className="trigger-list">
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'CPU utilization')?.[0]?.['high_cpu_utilization']?.severity)}</li>
                  <li>&nbsp;</li>
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'CPU utilization')?.[0]?.['cpu_above50']?.severity)}</li>
                  <li>&nbsp;</li>
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'CPU utilization')?.[0]?.['cpu_below50']?.severity)}</li>
                </ul>
              </td>
            </tr>
              } */}
              {/* Trigger Configuration View & Edit issue fix */}
              {
                (this.state.template_schema_json.triggers.filter(item => item.trigger_type === 'Wifi interface discovery').length > 0) &&
                (this.props.selectedModel === undefined || this.props.selectedModel === 'CHIMAY' || this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model' ||
                  this.props.selectedModel === 'Stormbreaker' || this.props.selectedModel === 'Firestorm') &&
                <>
                  <tr>
                    <td className="text-center"><input type="checkbox"
                      value="Wifi interface discovery"
                      checked={this.state.triggerType === "Wifi interface discovery"}
                      onChange={e => this.checkboxChecked(e)}
                    /></td>
                    {/* LONG-EM- Trigger Configuration Incorrect popup will shown when move cursor over the field */}
                    <td><span title="WiFi Connection Status" style={{ width: "auto" }} className="cursor-pointer">
                      <button className="txt-blue" onClick={() => this.openAddTrigger("Wifi interface discovery")} >
                        WiFi Connection Status</button></span></td>
                    <td>
                      <ul className="trigger-list">
                        <li>WiFi Client Connect</li>
                        <li>WiFi Client Disconnect</li>
                      </ul>
                    </td>
                    <td>
                      <ul className="trigger-list">
                        <li>{this.state.template_schema_json.triggers.filter(item =>
                          item.trigger_type === 'Wifi interface discovery')?.[0]?.["Wifi Client Connected"]?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                        <li>{this.state.template_schema_json.triggers.filter(item =>
                          item.trigger_type === 'Wifi interface discovery')?.[0]?.["Wifi Client Disconnected"]?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      </ul>
                    </td>
                    <td>
                      <ul className="trigger-list">
                        <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                          item.trigger_type === 'Wifi interface discovery')?.[0]?.["Wifi Client Connected"]?.severity)}</li>
                        <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                          item.trigger_type === 'Wifi interface discovery')?.[0]?.["Wifi Client Disconnected"]?.severity)}</li>
                      </ul>
                    </td>
                  </tr>
                </>
              }
              {(this.state.template_schema_json.triggers.map(item => item.trigger_type === 'VPN status')) &&
                <tr>
                  <td className="text-center"><input type="checkbox"
                    value="VPN status"
                    checked={this.state.triggerType === "VPN status"}
                    onChange={e => this.checkboxChecked(e)}
                  /></td>
                  {/* LONG-EM- Trigger Configuration Incorrect popup will shown when move cursor over the field */}
                  <td><span title="System Status" style={{ width: "auto" }} className="cursor-pointer">
                    <button className="txt-blue" onClick={() => this.openAddTrigger("VPN status")} >
                      {/* LONG-2331: In trigger configuration "System" not as per wire frame  */}
                      System Status</button></span></td>
                  <td>
                    <ul className="trigger-list">
                      <li>Device Offline</li>
                      <li>Device Online</li>
                    </ul>
                  </td>
                  <td>
                    <ul className="trigger-list">
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'VPN status')?.[0]?.["device_offline"]?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'VPN status')?.[0]?.["Device Online"]?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                    </ul>
                  </td>
                  <td>
                    <ul className="trigger-list">
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'VPN status')?.[0]?.["device_offline"]?.severity)}</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'VPN status')?.[0]?.["Device Online"]?.severity)}</li>
                    </ul>
                  </td>
                </tr>
              }
              {(this.state.template_schema_json.triggers.map(item => item.trigger_type === 'Data usage')) &&
                <tr>
                  <td className="text-center"><input type="checkbox"
                    value="Data usage"
                    checked={this.state.triggerType === "Data usage"}
                    onChange={e => this.checkboxChecked(e)}
                  /></td>
                  {/* LONG-EM- Trigger Configuration Incorrect popup will shown when move cursor over the field */}
                  <td><span title=" Data Usage" style={{ width: "auto" }} className="cursor-pointer">
                    <button className="txt-blue" onClick={() => this.openAddTrigger("Data usage")} >
                      Data Usage</button></span></td>
                  <td>
                    {this.props.selectedModel !== 'vCPE' && Object.keys(this.state.template_schema_json.triggers.filter(item =>
                      item.trigger_type === 'Data usage')?.[0])?.filter(item => item === "Cellular: Data Usage").length > 0 &&
                      <>
                        <ul className="trigger-list">
                          {/* LONG-2333-EM - Instead of MB it shows MMB in Edge manager Trigger configuration template */}
                          <li>Cellular Data Usage</li>
                          <li><strong>Threshold:{parseInt(this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["Cellular: Data Usage"]?.['data_usage']?.threshold)}MB</strong></li>
                          <li>Cellular Avg Data Usage</li>
                          <li><strong>Threshold:{parseInt(this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]["Cellular: Data Usage"]?.['average_data_usage']?.threshold)}MB</strong></li>
                          <li>Cellular High Data Usage</li>
                          <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]["Cellular: Data Usage"]?.['high_data_usage']?.threshold}B</strong></li>
                        </ul>
                      </>}
                    <ul className="trigger-list">
                      <li>Mgmt. Plane Data Usage</li>
                      <li><strong>Threshold:{parseInt(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["Control tunnel: Data Usage"]?.['data_usage']?.threshold)}MB</strong></li>
                      <li>Mgmt. Plane Avg Data Usage</li>
                      <li><strong>Threshold:{parseInt(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["Control tunnel: Data Usage"]?.['average_data_usage']?.threshold)}MB</strong></li>
                      <li>Mgmt. Plane High Data Usage</li>
                      <li><strong>Threshold:{parseInt(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["Control tunnel: Data Usage"]?.['high_data_usage']?.threshold)}MB</strong></li>
                    </ul>
                    <ul className="trigger-list">
                      <li>Data tunnel Data Usage</li>
                      <li><strong>Threshold:{parseInt(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["Data tunnel: Data Usage"]?.['data_usage']?.threshold)}MB</strong></li>
                      <li>Data tunnel Avg Data Usage</li>
                      <li><strong>Threshold:{parseInt(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["Data tunnel: Data Usage"]?.['average_data_usage']?.threshold)}MB</strong></li>
                      <li>Data tunnel High Data Usage</li>
                      <li><strong>Threshold:{parseInt(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["Data tunnel: Data Usage"]?.['high_data_usage']?.threshold)}MB</strong></li>
                    </ul>
                    <ul className="trigger-list">
                      <li>WAN Data Usage</li>
                      <li><strong>Threshold:{parseInt(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WAN:  Data Usage"]?.['data_usage']?.threshold)}MB</strong></li>
                      <li>WAN Avg Data Usage</li>
                      <li><strong>Threshold:{parseInt(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WAN:  Data Usage"]?.['average_data_usage']?.threshold)}MB</strong></li>
                      <li>WAN High Data Usage</li>
                      <li><strong>Threshold:{parseInt(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WAN:  Data Usage"]?.['high_data_usage']?.threshold)}MB</strong></li>
                    </ul>
                    {this.props.selectedModel !== 'vCPE' && Object.keys(this.state.template_schema_json.triggers.filter(item =>
                      item.trigger_type === 'Data usage')?.[0])?.filter(item => item === "WiFi : Data Usage").length > 0 &&
                      <>
                        <ul className="trigger-list">
                          <li>WiFi Data Usage</li>
                          <li><strong>Threshold:{parseInt(this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi : Data Usage"]?.['data_usage']?.threshold)}MB</strong></li>
                          <li>WiFi Avg Data Usage</li>
                          <li><strong>Threshold:{parseInt(this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')[0]?.["WiFi : Data Usage"]?.['average_data_usage']?.threshold)}MB</strong></li>
                          <li>WiFi High Data Usage</li>
                          <li><strong>Threshold:{parseInt(this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')[0]?.["WiFi : Data Usage"]?.['high_data_usage']?.threshold)}MB</strong></li>
                        </ul>
                        <ul className="trigger-list">
                          <li>WiFi 2.4GHz Data Usage</li>
                          <li><strong>Threshold:{parseInt(this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]["WiFi_2.4GHz: Data Usage"]?.['data_usage']?.threshold)}MB</strong></li>
                          <li>WiFi 2.4GHz Avg Data Usage</li>
                          <li><strong>Threshold:{parseInt(this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi_2.4GHz: Data Usage"]?.['average_data_usage']?.threshold)}MB</strong></li>
                          <li>WiFi 2.4GHz High Data Usage</li>
                          <li><strong>Threshold:{parseInt(this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi_2.4GHz: Data Usage"]?.['high_data_usage']?.threshold)}MB</strong></li>
                        </ul>
                        <ul className="trigger-list">
                          <li>WiFi 5GHz Data Usage</li>
                          <li><strong>Threshold:{parseInt(this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi_5GHz : Data Usage"]?.['data_usage']?.threshold)}MB</strong></li>
                          <li>WiFi 5GHz Avg Data Usage</li>
                          <li><strong>Threshold:{parseInt(this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi_5GHz : Data Usage"]?.['average_data_usage']?.threshold)}MB</strong></li>
                          <li>WiFi 5GHz High Data Usage</li>
                          <li><strong>Threshold:{parseInt(this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi_5GHz : Data Usage"]?.['high_data_usage']?.threshold)}MB</strong></li>
                        </ul>
                      </>
                    }
                  </td>
                  <td>
                    {this.props.selectedModel !== 'vCPE' && Object.keys(this.state.template_schema_json.triggers.filter(item =>
                      item.trigger_type === 'Data usage')?.[0])?.filter(item => item === "Cellular: Data Usage").length > 0 &&
                      <ul className="trigger-list">
                        <li>{this.state.template_schema_json.triggers.filter(item =>
                          item.trigger_type === 'Data usage')?.[0]?.["Cellular: Data Usage"]?.['data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                        <li>&nbsp;</li>
                        <li>{this.state.template_schema_json.triggers.filter(item =>
                          item.trigger_type === 'Data usage')?.[0]?.["Cellular: Data Usage"]?.['average_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                        <li>&nbsp;</li>
                        <li>{this.state.template_schema_json.triggers.filter(item =>
                          item.trigger_type === 'Data usage')?.[0]?.["Cellular: Data Usage"]?.['high_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                        <li>&nbsp;</li>
                      </ul>}
                    <ul className="trigger-list">
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["Control tunnel: Data Usage"]?.['data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>&nbsp;</li>
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["Control tunnel: Data Usage"]?.['average_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>&nbsp;</li>
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["Control tunnel: Data Usage"]?.['high_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>&nbsp;</li>
                    </ul>
                    <ul className="trigger-list">
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["Data tunnel: Data Usage"]?.['data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>&nbsp;</li>
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["Data tunnel: Data Usage"]?.['average_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>&nbsp;</li>
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["Data tunnel: Data Usage"]?.['high_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>&nbsp;</li>
                    </ul>
                    <ul className="trigger-list">
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WAN:  Data Usage"]?.['data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>&nbsp;</li>
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WAN:  Data Usage"]?.['average_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>&nbsp;</li>
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WAN:  Data Usage"]?.['high_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>&nbsp;</li>
                    </ul>
                    {this.props.selectedModel !== 'vCPE' && Object.keys(this.state.template_schema_json.triggers.filter(item =>
                      item.trigger_type === 'Data usage')?.[0])?.filter(item => item === "WiFi : Data Usage").length > 0 &&
                      <>
                        <ul className="trigger-list">
                          <li>{this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi : Data Usage"]?.['data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                          <li>&nbsp;</li>
                          <li>{this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi : Data Usage"]?.['average_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                          <li>&nbsp;</li>
                          <li>{this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi : Data Usage"]?.['high_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                          <li>&nbsp;</li>
                        </ul>
                        <ul className="trigger-list">
                          <li>{this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi_2.4GHz: Data Usage"]?.['data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                          <li>&nbsp;</li>
                          <li>{this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi_2.4GHz: Data Usage"]?.['average_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                          <li>&nbsp;</li>
                          <li>{this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi_2.4GHz: Data Usage"]?.['high_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                          <li>&nbsp;</li>
                        </ul>
                        <ul className="trigger-list">
                          <li>{this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi_5GHz : Data Usage"]?.['data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                          <li>&nbsp;</li>
                          <li>{this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi_5GHz : Data Usage"]?.['average_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                          <li>&nbsp;</li>
                          <li>{this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi_5GHz : Data Usage"]?.['high_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                          <li>&nbsp;</li>
                        </ul>
                      </>
                    }
                  </td>
                  <td>
                    {this.props.selectedModel !== 'vCPE' && Object.keys(this.state.template_schema_json.triggers.filter(item =>
                      item.trigger_type === 'Data usage')?.[0])?.filter(item => item === "Cellular: Data Usage").length > 0 &&
                      <ul className="trigger-list">
                        <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                          item.trigger_type === 'Data usage')?.[0]?.["Cellular: Data Usage"]?.['data_usage']?.severity)}</li>
                        <li>&nbsp;</li>
                        <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                          item.trigger_type === 'Data usage')?.[0]?.["Cellular: Data Usage"]?.['average_data_usage']?.severity)}</li>
                        <li>&nbsp;</li>
                        <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                          item.trigger_type === 'Data usage')?.[0]?.["Cellular: Data Usage"]?.['high_data_usage']?.severity)}</li>
                        <li>&nbsp;</li>
                      </ul>}
                    <ul className="trigger-list">
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["Control tunnel: Data Usage"]?.['data_usage']?.severity)}</li>
                      <li>&nbsp;</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["Control tunnel: Data Usage"]?.['average_data_usage']?.severity)}</li>
                      <li>&nbsp;</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["Control tunnel: Data Usage"]?.['high_data_usage']?.severity)}</li>
                      <li>&nbsp;</li>
                    </ul>
                    <ul className="trigger-list">
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["Data tunnel: Data Usage"]?.['data_usage']?.severity)}</li>
                      <li>&nbsp;</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["Data tunnel: Data Usage"]?.['average_data_usage']?.severity)}</li>
                      <li>&nbsp;</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["Data tunnel: Data Usage"]?.['high_data_usage']?.severity)}</li>
                      <li>&nbsp;</li>
                    </ul>
                    <ul className="trigger-list">
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WAN:  Data Usage"]?.['data_usage']?.severity)}</li>
                      <li>&nbsp;</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WAN:  Data Usage"]?.['average_data_usage']?.severity)}</li>
                      <li>&nbsp;</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WAN:  Data Usage"]?.['high_data_usage']?.severity)}</li>
                      <li>&nbsp;</li>
                    </ul>
                    {this.props.selectedModel !== 'vCPE' && Object.keys(this.state.template_schema_json.triggers.filter(item =>
                      item.trigger_type === 'Data usage')?.[0])?.filter(item => item === "WiFi : Data Usage").length > 0 &&
                      <>
                        <ul className="trigger-list">
                          <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi : Data Usage"]?.['data_usage']?.severity)}</li>
                          <li>&nbsp;</li>
                          <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi : Data Usage"]?.['average_data_usage']?.severity)}</li>
                          <li>&nbsp;</li>
                          <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi : Data Usage"]?.['high_data_usage']?.severity)}</li>
                          <li>&nbsp;</li>
                        </ul>
                        <ul className="trigger-list">
                          <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi_2.4GHz: Data Usage"]?.['data_usage']?.severity)}</li>
                          <li>&nbsp;</li>
                          <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi_2.4GHz: Data Usage"]?.['average_data_usage']?.severity)}</li>
                          <li>&nbsp;</li>
                          <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi_2.4GHz: Data Usage"]?.['high_data_usage']?.severity)}</li>
                          <li>&nbsp;</li>
                        </ul>
                        <ul className="trigger-list">
                          <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi_5GHz : Data Usage"]?.['data_usage']?.severity)}</li>
                          <li>&nbsp;</li>
                          <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi_5GHz : Data Usage"]?.['average_data_usage']?.severity)}</li>
                          <li>&nbsp;</li>
                          <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                            item.trigger_type === 'Data usage')?.[0]?.["WiFi_5GHz : Data Usage"]?.['high_data_usage']?.severity)}</li>
                          <li>&nbsp;</li>
                        </ul>
                      </>
                    }
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        {/* add new trigger */}
        <div className={this.state.addTrigger ? "sidebar-slide  zindex10 w-350 r-757 second-open" : "sidebar-slide  zindex10 w-350 r-757"}>
          <div className="s-header">
            <h1 className="ss-title">Update Trigger</h1>
            <button onClick={this.closeAddTrigger}><img src="/images/svg-icons/s-cancel.svg" alt="" /></button>
          </div>
          <div className="s-body">
            <div className="form-group">
              <label>Trigger Type
                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Specifies the type of Trigger">
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </label>
              <select className="form-control"
                value={this.state.triggerType}
                disabled={true}
                onChange={e => this.selectTrigger(e)}
                style={{ cursor: "not-allowed" }}
              >
                {/* LONG-2331: In trigger configuration "System" not as per wire frame */}
                <option value="">Select</option>
                <option value="Ethernet interface discovery">Client Connection Status</option>
                <option value="Mounted filesystem discovery">Disk Space</option>
                <option value="Network Interface Discovery">Interface Status</option>
                <option value="Memory utilization">Memory</option>
                <option value="System local time">System Local Time</option>
                {/* <option value="CPU utilization">CPU</option> */}
                <option value="Data usage">Data Usage</option>
                <option value="device_tunnel_discovery">Data Tunnel Link</option>
                <option value="Wifi interface discovery">WiFi Connection Status</option>
                <option value="VPN status">System Status</option>
              </select>
            </div>
            {/* Data Tunnel Link */}
            {this.state.selectedTriggerData[0].trigger_type === "device_tunnel_discovery" &&
              <div className="client-connection-status">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Link down
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when data tunnel link is down">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox"
                      name="status"
                      onClick={(e) => this.setTrigger(e, "Link down")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["Link down"]?.status)}
                      value={parseInt(this.state.selectedTriggerData?.[0]?.["Link down"]?.status)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control"
                    name="severity"
                    onChange={(e) => this.setTrigger(e, "Link down")}
                    value={this.state.selectedTriggerData?.[0]?.["Link down"]?.severity}
                  >
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Link Up
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when data tunnel link is up">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox"
                      name="status"
                      onClick={(e) => this.setTrigger(e, "Link UP")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["Link UP"]?.status)}
                      value={parseInt(this.state.selectedTriggerData?.[0]?.["Link UP"]?.status)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control"
                    name="severity"
                    onChange={e => this.setTrigger(e, "Link UP")}
                    value={this.state.selectedTriggerData?.[0]?.["Link UP"]?.severity}
                  >
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
              </div>
            }
            {/* Data Tunnel Link Closes */}
            {/* Client Connection Status */}
            {this.state.selectedTriggerData[0].trigger_type === "Ethernet interface discovery" &&
              <div className="client-connection-status">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Ethernet Client Connect
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when a wired client gets connected">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox"
                      name="status"
                      onClick={(e) => this.setTrigger(e, "Client Connected")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["Client Connected"]?.status)}
                      value={parseInt(this.state.selectedTriggerData?.[0]?.["Client Connected"]?.status)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control"
                    name="severity"
                    onChange={(e) => this.setTrigger(e, "Client Connected")}
                    value={this.state.selectedTriggerData[0]["Client Connected"]?.severity}
                  >
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Ethernet Client Disconnect
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when a wired client gets disconnected">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox"
                      name="status"
                      onClick={(e) => this.setTrigger(e, "Client Disconnected")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["Client Disconnected"]?.status)}
                      value={parseInt(this.state.selectedTriggerData?.[0]?.["Client Disconnected"]?.status)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control"
                    name="severity"
                    onChange={e => this.setTrigger(e, "Client Disconnected")}
                    value={this.state.selectedTriggerData[0]["Client Disconnected"]?.severity}
                  >
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
              </div>
            }
            {/* Client Connection Status close */}
            {/* Disk Space */}
            {this.state.selectedTriggerData[0].trigger_type === "Mounted filesystem discovery" &&
              <div className="disk-space">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Low Disk Space
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when disc space is critically low">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" name="status"
                      onClick={e => this.setTrigger(e, "Disk space is critically low")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["Disk space is critically low"]?.status)}
                      value={parseInt(this.state.selectedTriggerData?.[0]?.["Disk space is critically low"]?.status)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group mb-4">
                  <label>Threshold
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  {/* LONG-2332 : Slider changes for threshold */}
                  <div className="slidecontainer d-flex align-items-center">
                    <div className="rsbox">
                      <Slider xmin={81} xmax={100}
                        name="threshold"
                        x={this.state.selectedTriggerData?.[0]?.["Disk space is critically low"]?.threshold}
                        onChange={e => this.setSlider(e, "Disk space is critically low", "threshold")}
                      />
                      <div className="min-value">81%</div>
                      <div className="max-value">100%</div>
                    </div>
                    <input className="form-control"
                      value={this.state.selectedTriggerData?.[0]?.["Disk space is critically low"]?.threshold + "%"}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control"
                    name="severity"
                    value={this.state.selectedTriggerData[0]["Disk space is critically low"]?.severity}
                    onChange={e => this.setTrigger(e, "Disk space is critically low")}
                  >
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">70% Disk Space Utilized
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when disc space is 70% Utilized">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" name="status"
                      onClick={e => this.setTrigger(e, "70% of disk space is utilized")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["70% of disk space is utilized"]?.status)}
                      value={parseInt(this.state.selectedTriggerData?.[0]?.["70% of disk space is utilized"]?.status)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group mb-4">
                  <label>Threshold
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  {/* LONG-2332 : Slider changes for threshold */}
                  <div className="slidecontainer d-flex align-items-center">
                    <div className="rsbox">
                      <Slider xmin={51} xmax={80}
                        name="threshold"
                        x={this.state.selectedTriggerData[0]["70% of disk space is utilized"]?.threshold}
                        onChange={e => this.setSlider(e, "70% of disk space is utilized", "threshold")}
                      />
                      <div className="min-value">51%</div>
                      <div className="max-value">80%</div>
                    </div>
                    <input className="form-control"
                      value={this.state.selectedTriggerData?.[0]?.["70% of disk space is utilized"]?.threshold + "%"} />
                  </div>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control"
                    name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["70% of disk space is utilized"]?.severity}
                    onChange={e => this.setTrigger(e, "70% of disk space is utilized")}
                  >
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">50% Disk Space Utilized
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when  disc space is utilized 50%">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" name="status"
                      onClick={e => this.setTrigger(e, "50% of disk space is utilized")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["50% of disk space is utilized"]?.status)}
                      value={this.state.selectedTriggerData?.[0]?.["50% of disk space is utilized"]?.status && parseInt(this.state.selectedTriggerData[0]["50% of disk space is utilized"]?.status)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group mb-4">
                  <label>Threshold
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  {/* LONG-2332 : Slider changes for threshold */}
                  <div className="slidecontainer d-flex align-items-center">
                    <div className="rsbox">
                      <Slider xmin={0} xmax={50}
                        name="threshold"
                        x={this.state.selectedTriggerData?.[0]?.["50% of disk space is utilized"]?.threshold}
                        onChange={e => this.setSlider(e, "50% of disk space is utilized", "threshold")}
                        className="rangeslider" id="myRange" />
                      <div className="min-value">0%</div>
                      <div className="max-value">50%</div>
                    </div>
                    <input className="form-control"
                      value={this.state.selectedTriggerData?.[0]?.["50% of disk space is utilized"]?.threshold + "%"} />
                  </div>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control"
                    name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["50% of disk space is utilized"]?.severity}
                    onChange={e => this.setTrigger(e, "50% of disk space is utilized")}
                  >
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
              </div>
            }
            {/* Disk Space close */}
            {/* Interface status */}
            {this.state.selectedTriggerData[0].trigger_type === "Network Interface Discovery" &&
              <div className="interface-status">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Link Down
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when data tunnel link is down">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox"
                      name="status"
                      onClick={e => this.setTriggerValues(e, "Operation status", "Link down")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["Operation status"]?.["Link down"]?.status)}
                      value={parseInt(this.state.selectedTriggerData?.[0]?.["Operation status"]?.["Link down"]?.status)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control" name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["Operation status"]?.["Link down"]?.severity}
                    onChange={e => this.setTriggerValues(e, "Operation status", "Link down")}>
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Link Up
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when data tunnel link is up">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox"
                      name="status"
                      onClick={e => this.setTriggerValues(e, "Operation status", "Link UP")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["Operation status"]?.["Link UP"]?.status)}
                      value={this.state.selectedTriggerData?.[0]?.["Operation status"]?.["Link UP"]?.status && parseInt(this.state.selectedTriggerData[0]["Operation status"]["Link UP"]?.status)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control" name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["Operation status"]?.["Link UP"]?.severity}
                    onChange={e => this.setTriggerValues(e, "Operation status", "Link UP")}>
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">High Error Rate
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when data Error Rate is high">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" name="status"
                      onClick={e => this.setTrigger(e, "High error rate")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["High error rate"]?.status)}
                      value={this.state.selectedTriggerData?.[0]?.["High error rate"]?.status && parseInt(this.state.selectedTriggerData?.[0]?.["High error rate"]?.status)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control" name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["High error rate"]?.severity}
                    onChange={e => this.setTrigger(e, "High error rate")}>
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
              </div>
            }
            {/* Interface status close */}
            {/* Memory */}
            {this.state.selectedTriggerData[0].trigger_type === "Memory utilization" &&
              <div className="memory">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">High Memory Utilization
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when memory utilization is high">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" name="status"
                      onClick={e => this.setTrigger(e, "High memory utilization")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["High memory utilization"]?.status)}
                      value={parseInt(this.state.selectedTriggerData?.[0]?.["High memory utilization"]?.status)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group mb-4">
                  <label>Threshold
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  {/* LONG-2332 : Slider changes for threshold */}
                  <div className="slidecontainer d-flex align-items-center">
                    <div className="rsbox">
                      <Slider xmin={81} xmax={100}
                        name="threshold"
                        x={this.state.selectedTriggerData?.[0]?.["High memory utilization"]?.threshold}
                        onChange={e => this.setSlider(e, "High memory utilization", "threshold")}
                      />
                      <div className="min-value">81%</div>
                      <div className="max-value">100%</div>
                    </div>
                    <input className="form-control"
                      value={this.state.selectedTriggerData?.[0]?.["High memory utilization"]?.threshold + "%"} />
                  </div>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control" name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["High memory utilization"]?.severity}
                    onChange={e => this.setTrigger(e, "High memory utilization")}>
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Above 50% Memory Utilized
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when Memory Utilized Above 50%">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" name="status"
                      onClick={e => this.setTrigger(e, "memory_above50")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["memory_above50"]?.status)}
                      value={this.state.selectedTriggerData?.[0]?.["memory_above50"]?.status && parseInt(this.state.selectedTriggerData?.[0]?.["memory_above50"]?.status)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group mb-4">
                  <label>Threshold
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  {/* LONG-2332 : Slider changes for threshold */}
                  <div className="slidecontainer d-flex align-items-center">
                    <div className="rsbox">
                      <Slider xmin={51} xmax={80}
                        name="threshold"
                        x={this.state.selectedTriggerData?.[0]?.["memory_above50"]?.threshold}
                        onChange={e => this.setSlider(e, "memory_above50", "threshold")}
                      />
                      <div className="min-value">51%</div>
                      <div className="max-value">80%</div>
                    </div>
                    <input className="form-control"
                      value={this.state.selectedTriggerData?.[0]?.["memory_above50"]?.threshold + "%"} />
                  </div>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control" name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["memory_above50"]?.severity}
                    onChange={e => this.setTrigger(e, "memory_above50")}>
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Lack of Available Memory
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when there is a lack of Available Memory">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" name="status"
                      onClick={e => this.setTrigger(e, "memory_below50")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["memory_below50"]?.status)}
                      value={this.state.selectedTriggerData?.[0]?.["memory_below50"]?.status && parseInt(this.state.selectedTriggerData?.[0]?.["memory_below50"]?.status)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group mb-4">
                  <label>Threshold
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  {/* LONG-2332 : Slider changes for threshold */}
                  <div className="slidecontainer d-flex align-items-center">
                    <div className="rsbox">
                      <Slider xmin={0} xmax={50}
                        name="threshold"
                        x={this.state.selectedTriggerData?.[0]?.["memory_below50"]?.threshold}
                        onChange={e => this.setSlider(e, "memory_below50", "threshold")}
                      />
                      <div className="min-value">0%</div>
                      <div className="max-value">50%</div>
                    </div>
                    <input className="form-control"
                      value={this.state.selectedTriggerData?.[0]?.["memory_below50"]?.threshold + "%"} />
                  </div>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control" name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["memory_below50"]?.severity}
                    onChange={e => this.setTrigger(e, "memory_below50")}>
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
              </div>
            }
            {/* Memory close */}
            {/* System */}
            {this.state.selectedTriggerData?.[0]?.trigger_type === "System local time" &&
              <div className="system">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Host Time Sync
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when Time sync on Host">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox"
                      name="status"
                      onClick={e => this.setTrigger(e, "System time is out of sync")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["System time is out of sync"]?.status)}
                      value={parseInt(this.state.selectedTriggerData?.[0]?.["System time is out of sync"]?.status)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control" name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["System time is out of sync"]?.severity}
                    onChange={e => this.setTrigger(e, "System time is out of sync")}>
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
              </div>
            }
            {/* System close */}
            {/* CPU */}
            {/*  {this.state.selectedTriggerData?.[0]?.trigger_type === "CPU utilization" &&
              <div className="cpu">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">High CPU Utilization
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when CPU utilization is high">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="status"
                      onClick={e => this.setTrigger(e, "high_cpu_utilization")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["high_cpu_utilization"]?.status)}
                      value={parseInt(this.state.selectedTriggerData?.[0]?.["high_cpu_utilization"]?.status)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group mb-4">
                  <label>Threshold
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  
                  <div className="slidecontainer d-flex align-items-center">
                    <div className="rsbox">
                      <Slider xmin={81} xmax={100}
                        name="threshold"
                        x={this.state.selectedTriggerData?.[0]?.["high_cpu_utilization"]?.threshold}
                        onChange={e => this.setSlider(e, "high_cpu_utilization", "threshold")}
                      />
                      <div className="min-value">81%</div>
                      <div className="max-value">100%</div>
                    </div>
                    <input className="form-control"
                      value={this.state.selectedTriggerData?.[0]?.["high_cpu_utilization"]?.threshold + "%"} />
                  </div>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select
                    className="form-control"
                    name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["high_cpu_utilization"]?.severity}
                    onChange={e => this.setTrigger(e, "high_cpu_utilization")}>
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                    <option>Information</option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">CPU Utilization above 50%
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when CPU utilization above 50%">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" name="status"
                      onClick={e => this.setTrigger(e, "cpu_above50")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["cpu_above50"]?.status)}
                      value={parseInt(this.state.selectedTriggerData?.[0]?.["cpu_above50"]?.status)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group mb-4">
                  <label>Threshold
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  
                  <div className="slidecontainer d-flex align-items-center">
                    <div className="rsbox">
                      <Slider xmin={51} xmax={80}
                        name="threshold"
                        x={this.state.selectedTriggerData?.[0]?.["cpu_above50"]?.threshold}
                        onChange={e => this.setSlider(e, "cpu_above50", "threshold")}
                      />
                      <div className="min-value">51%</div>
                      <div className="max-value">80%</div>
                    </div>
                    <input className="form-control"
                      value={this.state.selectedTriggerData?.[0]?.["cpu_above50"]?.threshold + "%"} />
                  </div>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control" name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["cpu_above50"]?.severity}
                    onChange={e => this.setTrigger(e, "cpu_above50")}>
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Low CPU Utilization
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when CPU utilization is low">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" name="status"
                      onClick={e => this.setTrigger(e, "cpu_below50")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["cpu_below50"]?.status)}
                      value={parseInt(this.state.selectedTriggerData?.[0]?.["cpu_below50"]?.status)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group mb-4">
                  <label>Threshold
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                 
                  <div className="slidecontainer d-flex align-items-center">
                    <div className="rsbox">
                      <Slider xmin={0} xmax={50}
                        name="threshold"
                        x={this.state.selectedTriggerData?.[0]?.["cpu_below50"]?.threshold}
                        onChange={e => this.setSlider(e, "cpu_below50", "threshold")}
                      />
                      <div className="min-value">0%</div>
                      <div className="max-value">50%</div>
                    </div>
                    <input className="form-control"
                      value={this.state.selectedTriggerData?.[0]?.["cpu_below50"]?.threshold + "%"} />
                  </div>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control" name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["cpu_below50"]?.severity}
                    onChange={e => this.setTrigger(e, "cpu_below50")}>
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
              </div>
            }*/}
            {/* CPU close */}
            {/* WiFi Connection Status */}
            {this.state.selectedTriggerData[0].trigger_type === "Wifi interface discovery" &&
              <div className="client-connection-status">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">WiFi Client Connect
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when a WiFi client gets connected">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox"
                      name="status"
                      onClick={(e) => this.setTrigger(e, "Wifi Client Connected")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["Wifi Client Connected"]?.status)}
                      value={parseInt(this.state.selectedTriggerData?.[0]?.["Wifi Client Connected"]?.status)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control"
                    name="severity"
                    onChange={(e) => this.setTrigger(e, "Wifi Client Connected")}
                    value={this.state.selectedTriggerData?.[0]?.["Wifi Client Connected"]?.severity}
                  >
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">WiFi Client Disconnect
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when a WiFi client gets disconnected">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox"
                      name="status"
                      onClick={(e) => this.setTrigger(e, "Wifi Client Disconnected")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["Wifi Client Disconnected"]?.status)}
                      value={parseInt(this.state.selectedTriggerData?.[0]?.["Wifi Client Disconnected"]?.status)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control"
                    name="severity"
                    onChange={e => this.setTrigger(e, "Wifi Client Disconnected")}
                    value={this.state.selectedTriggerData?.[0]?.["Wifi Client Disconnected"]?.severity}
                  >
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
              </div>
            }
            {/* WiFi Connection Status Closes */}
            {/* VPN */}
            {this.state.selectedTriggerData?.[0]?.trigger_type === "VPN status" &&
              <div className="client-connection-status">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Device Offline
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when device offline">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox"
                      name="status"
                      onClick={(e) => this.setTrigger(e, "device_offline")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["device_offline"]?.status)}
                      value={parseInt(this.state.selectedTriggerData?.[0]?.["device_offline"]?.status)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control"
                    name="severity"
                    onChange={(e) => this.setTrigger(e, "device_offline")}
                    value={this.state.selectedTriggerData?.[0]?.["device_offline"]?.severity}
                  >
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Link Up
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when VPN link is up">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox"
                      name="status"
                      onClick={(e) => this.setTrigger(e, "Device Online")}
                      checked={parseInt(this.state.selectedTriggerData?.[0]?.["Device Online"]?.status)}
                      value={parseInt(this.state.selectedTriggerData?.[0]?.["Device Online"]?.status)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control"
                    name="severity"
                    onChange={e => this.setTrigger(e, "Device Online")}
                    value={this.state.selectedTriggerData?.[0]["Device Online"]?.severity}
                  >
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
              </div>
            }
            {/* VPN Closes*/}
            {/* Data Usages */}
            {/* EMGT-4-EM - Trigger configuration field validations */}
            {this.state.selectedTriggerData?.[0].trigger_type === "Data usage" &&
              <div className="data-usages">
                {/* condition to show cellular && WiFi data usages for Chimay device */}
                {this.props.selectedModel !== 'vCPE' && Object.keys(this.state.template_schema_json.triggers.filter(item =>
                  item.trigger_type === 'Data usage')?.[0])?.filter(item => item === "Cellular: Data Usage").length > 0 &&
                  <>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <span className="mr-5">Cellular Data Usage
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when data usage is Cellular">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </span>
                      <label className="switch">
                        <input type="checkbox" name="status"
                          onClick={e => this.setTriggerValues(e, "Cellular: Data Usage", "data_usage")}
                          checked={this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["data_usage"]?.status)}
                          value={this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["data_usage"]?.status)} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div className="form-group mb-4">
                      <label>Threshold
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      {/* LONG-2332 : Slider changes for threshold */}
                      <div className="slidecontainer d-flex align-items-center">
                        <div className="rsbox">
                          <Slider xmin={100} xmax={500}
                            name="threshold"
                            x={this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"] && this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["data_usage"]?.threshold?.split("M")?.[0]}
                            onChange={e => this.setSliderValues(e, "Cellular: Data Usage", "data_usage", "threshold")}
                          />
                          <div className="min-value">100MB</div>
                          <div className="max-value">500MB</div>
                        </div>
                        <input className="form-control"
                          value={this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["data_usage"]?.threshold) + "MB"} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Severity
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <select className="form-control" name="severity"
                        value={this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"] && this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["data_usage"]?.severity}
                        onChange={e => this.setTriggerValues(e, "Cellular: Data Usage", "data_usage")}>
                        <option value="">Select</option>
                        <option value="INFO">Information</option>
                        <option value="WARNING">Low</option>
                        <option value="AVERAGE">Medium</option>
                        <option value="HIGH">High</option>
                        <option value="DISASTER">Critical</option>
                      </select>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <span className="mr-5">Cellular Average Data Usage
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when average data usage is Cellular">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </span>
                      <label className="switch">
                        <input type="checkbox" name="status"
                          onClick={e => this.setTriggerValues(e, "Cellular: Data Usage", "average_data_usage")}
                          checked={this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["average_data_usage"]?.status)}
                          value={this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["average_data_usage"]?.status)} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div className="form-group mb-4">
                      <label>Threshold
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      {/* LONG-2332 : Slider changes for threshold */}
                      <div className="slidecontainer d-flex align-items-center">
                        <div className="rsbox">
                          <Slider xmin={500} max={1000}
                            name="threshold"
                            x={this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"] && this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["average_data_usage"]?.threshold?.split("M")?.[0]}
                            onChange={e => this.setSliderValues(e, "Cellular: Data Usage", "average_data_usage", "threshold")}
                          />
                          <div className="min-value">500MB</div>
                          <div className="max-value">1000MB</div>
                        </div>
                        <input className="form-control"
                          value={this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["average_data_usage"]?.threshold && parseInt(this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["average_data_usage"]?.threshold) + "MB"} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Severity
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <select className="form-control" name="severity"
                        value={this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"] && this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["average_data_usage"]?.severity}
                        onChange={e => this.setTriggerValues(e, "Cellular: Data Usage", "average_data_usage")}>
                        <option value="">Select</option>
                        <option value="INFO">Information</option>
                        <option value="WARNING">Low</option>
                        <option value="AVERAGE">Medium</option>
                        <option value="HIGH">High</option>
                        <option value="DISASTER">Critical</option>
                      </select>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <span className="mr-5">Cellular High Data Usage
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when high data usage is Cellular">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </span>
                      <label className="switch">
                        <input type="checkbox" name="status"
                          onClick={e => this.setTriggerValues(e, "Cellular: Data Usage", "high_data_usage")}
                          checked={this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["high_data_usage"]?.status)}
                          value={this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["high_data_usage"]?.status && parseInt(this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["high_data_usage"]?.status)} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div className="form-group mb-4">
                      <label>Threshold
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      {/* LONG-2332 : Slider changes for threshold */}
                      <div className="slidecontainer d-flex align-items-center">
                        <div className="rsbox">
                          <Slider xmin={1000} xmax={10000}
                            name="threshold"
                            x={this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"] && this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["high_data_usage"]?.threshold.split("M")[0]}
                            onChange={e => this.setSliderValues(e, "Cellular: Data Usage", "high_data_usage", "threshold")}
                          />
                          <div className="min-value">1000MB</div>
                          <div className="max-value">10000MB</div>
                        </div>
                        <input className="form-control"
                          value={this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["high_data_usage"]?.threshold && parseInt(this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["high_data_usage"]?.threshold) + "MB"} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Severity
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <select className="form-control" name="severity"
                        value={this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"] && this.state.selectedTriggerData?.[0]?.["Cellular: Data Usage"]?.["high_data_usage"]?.severity}
                        onChange={e => this.setTriggerValues(e, "Cellular: Data Usage", "high_data_usage")}>
                        <option value="">Select</option>
                        <option value="INFO">Information</option>
                        <option value="WARNING">Low</option>
                        <option value="AVERAGE">Medium</option>
                        <option value="HIGH">High</option>
                        <option value="DISASTER">Critical</option>
                      </select>
                    </div>
                  </>
                }
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Mgmt. Plane Data Usage
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when data usage is Mgmt. Plane">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" name="status"
                      onClick={e => this.setTriggerValues(e, "Control tunnel: Data Usage", "data_usage")}
                      checked={this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["data_usage"]?.status)}
                      value={this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["data_usage"]?.status)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group mb-4">
                  <label>Threshold
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  {/* LONG-2332 : Slider changes for threshold */}
                  <div className="slidecontainer d-flex align-items-center">
                    <div className="rsbox">
                      <Slider xmin={100} xmax={500}
                        name="threshold"
                        x={this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"] && this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["data_usage"]?.threshold?.split("M")?.[0]}
                        onChange={e => this.setSliderValues(e, "Control tunnel: Data Usage", "data_usage", "threshold")}
                      />
                      <div className="min-value">100MB</div>
                      <div className="max-value">500MB</div>
                    </div>
                    <input className="form-control"
                      value={this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["data_usage"]?.threshold && parseInt(this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["data_usage"]?.threshold) + "MB"} />
                  </div>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control" name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"] && this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["data_usage"]?.severity}
                    onChange={e => this.setTriggerValues(e, "Control tunnel: Data Usage", "data_usage")}>
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Mgmt. Plane Average Data Usage
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when average data usage is Mgmt. Plane">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" name="status"
                      onClick={e => this.setTriggerValues(e, "Control tunnel: Data Usage", "average_data_usage")}
                      checked={this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["average_data_usage"]?.status)}
                      value={this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["average_data_usage"]?.status)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group mb-4">
                  <label>Threshold
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  {/* LONG-2332 : Slider changes for threshold */}
                  <div className="slidecontainer d-flex align-items-center">
                    <div className="rsbox">
                      <Slider xmin={500} xmax={1000}
                        name="threshold"
                        x={this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"] && this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["average_data_usage"]?.threshold?.split("M")?.[0]}
                        onChange={e => this.setSliderValues(e, "Control tunnel: Data Usage", "average_data_usage", "threshold")}
                      />
                      <div className="min-value">500MB</div>
                      <div className="max-value">1000MB</div>
                    </div>
                    <input className="form-control"
                      value={this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["average_data_usage"]?.threshold && parseInt(this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["average_data_usage"]?.threshold) + "MB"} />
                  </div>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control" name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"] && this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["average_data_usage"]?.severity}
                    onChange={e => this.setTriggerValues(e, "Control tunnel: Data Usage", "average_data_usage")}>
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Mgmt. Plane High Data Usage
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when high data usage is Control Tunnel">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" name="status"
                      onClick={e => this.setTriggerValues(e, "Control tunnel: Data Usage", "high_data_usage")}
                      checked={this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["high_data_usage"]?.status)}
                      value={this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["high_data_usage"]?.status && parseInt(this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["high_data_usage"]?.status)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group mb-4">
                  <label>Threshold
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  {/* LONG-2332 : Slider changes for threshold */}
                  <div className="slidecontainer d-flex align-items-center">
                    <div className="rsbox">
                      <Slider xmin={1000} xmax={10000}
                        name="threshold"
                        x={this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"] && this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["high_data_usage"]?.threshold?.split("M")?.[0]}
                        onChange={e => this.setSliderValues(e, "Control tunnel: Data Usage", "high_data_usage", "threshold")}
                      />
                      <div className="min-value">1000MB</div>
                      <div className="max-value">10000MB</div>
                    </div>
                    <input className="form-control"
                      value={this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["high_data_usage"]?.threshold) + "MB"} />
                  </div>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control" name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"] && this.state.selectedTriggerData?.[0]?.["Control tunnel: Data Usage"]?.["high_data_usage"]?.severity}
                    onChange={e => this.setTriggerValues(e, "Control tunnel: Data Usage", "high_data_usage")}>
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Data Tunnel Data Usage
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when data usage is Data Tunnel">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" name="status"
                      onClick={e => this.setTriggerValues(e, "Data tunnel: Data Usage", "data_usage")}
                      checked={this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["data_usage"]?.status)}
                      value={this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["data_usage"]?.status && parseInt(this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["data_usage"]?.status)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group mb-4">
                  <label>Threshold
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  {/* LONG-2332 : Slider changes for threshold */}
                  <div className="slidecontainer d-flex align-items-center">
                    <div className="rsbox">
                      <Slider xmin={100} xmax={500}
                        name="threshold"
                        x={this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"] && this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["data_usage"]?.threshold?.split("M")?.[0]}
                        onChange={e => this.setSliderValues(e, "Data tunnel: Data Usage", "data_usage", "threshold")}
                      />
                      <div className="min-value">100MB</div>
                      <div className="max-value">500MB</div>
                    </div>
                    <input className="form-control"
                      value={this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["data_usage"]?.threshold && parseInt(this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["data_usage"]?.threshold) + "MB"} />
                  </div>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control" name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"] && this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["data_usage"]?.severity}
                    onChange={e => this.setTriggerValues(e, "Data tunnel: Data Usage", "data_usage")}>
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Data Tunnel Average Data Usage
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when average data usage is Data Tunnel">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" name="status"
                      onClick={e => this.setTriggerValues(e, "Data tunnel: Data Usage", "average_data_usage")}
                      checked={this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["average_data_usage"]?.status)}
                      value={this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["average_data_usage"]?.status && parseInt(this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["average_data_usage"]?.status)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group mb-4">
                  <label>Threshold
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  {/* LONG-2332 : Slider changes for threshold */}
                  <div className="slidecontainer d-flex align-items-center">
                    <div className="rsbox">
                      <Slider xmin={500} xmax={1000}
                        name="threshold"
                        x={this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"] && this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["average_data_usage"]?.threshold?.split("M")?.[0]}
                        onChange={e => this.setSliderValues(e, "Data tunnel: Data Usage", "average_data_usage", "threshold")}
                      />
                      <div className="min-value">500MB</div>
                      <div className="max-value">1000MB</div>
                    </div>
                    <input className="form-control"
                      value={this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["average_data_usage"]?.threshold && parseInt(this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["average_data_usage"]?.threshold) + "MB"} />
                  </div>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control" name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"] && this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["average_data_usage"]?.severity}
                    onChange={e => this.setTriggerValues(e, "Data tunnel: Data Usage", "average_data_usage")}>
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">Data Tunnel High Data Usage
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when high data usage is Data Tunnel">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" name="status"
                      onClick={e => this.setTriggerValues(e, "Data tunnel: Data Usage", "high_data_usage")}
                      checked={this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["high_data_usage"]?.status)}
                      value={this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["high_data_usage"]?.status)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group mb-4">
                  <label>Threshold
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  {/* LONG-2332 : Slider changes for threshold */}
                  <div className="slidecontainer d-flex align-items-center">
                    <div className="rsbox">
                      <Slider xmin={1000} xmax={10000}
                        name="threshold"
                        x={this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"] && this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["high_data_usage"]?.threshold?.split("M")?.[0]}
                        onChange={e => this.setSliderValues(e, "Data tunnel: Data Usage", "high_data_usage", "threshold")}
                      />
                      <div className="min-value">1000MB</div>
                      <div className="max-value">10000MB</div>
                    </div>
                    <input className="form-control"
                      value={this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["high_data_usage"]?.threshold && parseInt(this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["high_data_usage"]?.threshold) + "MB"} />
                  </div>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control" name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"] && this.state.selectedTriggerData?.[0]?.["Data tunnel: Data Usage"]?.["high_data_usage"]?.severity}
                    onChange={e => this.setTriggerValues(e, "Data tunnel: Data Usage", "high_data_usage")}>
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">WAN Data Usage
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when data usage is WAN">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" name="status"
                      onClick={e => this.setTriggerValues(e, "WAN:  Data Usage", "data_usage")}
                      checked={this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["data_usage"]?.status)}
                      value={this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["data_usage"]?.status && parseInt(this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["data_usage"]?.status)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group mb-4">
                  <label>Threshold
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  {/* LONG-2332 : Slider changes for threshold */}
                  <div className="slidecontainer d-flex align-items-center">
                    <div className="rsbox">
                      <Slider xmin={100} xmax={500}
                        name="threshold"
                        x={this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"] && this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["data_usage"]?.threshold?.split("M")?.[0]}
                        onChange={e => this.setSliderValues(e, "WAN:  Data Usage", "data_usage", "threshold")}
                      />
                      <div className="min-value">100MB</div>
                      <div className="max-value">500MB</div>
                    </div>
                    <input className="form-control"
                      value={this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["data_usage"]?.threshold && parseInt(this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["data_usage"]?.threshold) + "MB"} />
                  </div>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control" name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"] && this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["data_usage"]?.severity}
                    onChange={e => this.setTriggerValues(e, "WAN:  Data Usage", "data_usage")}>
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">WAN Average Data Usage
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when average data usage is WAN">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" name="status"
                      onClick={e => this.setTriggerValues(e, "WAN:  Data Usage", "average_data_usage")}
                      checked={this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["average_data_usage"]?.status && parseInt(this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["average_data_usage"]?.status)}
                      value={this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["average_data_usage"]?.status && parseInt(this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["average_data_usage"]?.status)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group mb-4">
                  <label>Threshold
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  {/* LONG-2332 : Slider changes for threshold */}
                  <div className="slidecontainer d-flex align-items-center">
                    <div className="rsbox">
                      <Slider xmin={500} xmax={1000}
                        name="threshold"
                        x={this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"] && this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["average_data_usage"]?.threshold?.split("M")?.[0]}
                        onChange={e => this.setSliderValues(e, "WAN:  Data Usage", "average_data_usage", "threshold")}
                      />
                      <div className="min-value">500MB</div>
                      <div className="max-value">1000MB</div>
                    </div>
                    <input className="form-control"
                      value={this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["average_data_usage"]?.threshold && parseInt(this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["average_data_usage"]?.threshold) + "MB"} />
                  </div>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control" name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"] && this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["average_data_usage"]?.severity}
                    onChange={e => this.setTriggerValues(e, "WAN:  Data Usage", "average_data_usage")}>
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span className="mr-5">WAN High Data Usage
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when high data usage is WAN">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" name="status"
                      onClick={e => this.setTriggerValues(e, "WAN:  Data Usage", "high_data_usage")}
                      checked={this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["high_data_usage"]?.status && parseInt(this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["high_data_usage"]?.status)}
                      value={this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["high_data_usage"]?.status && parseInt(this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["high_data_usage"]?.status)} />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="form-group mb-4">
                  <label>Threshold
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  {/* LONG-2332 : Slider changes for threshold */}
                  <div className="slidecontainer d-flex align-items-center">
                    <div className="rsbox">
                      <Slider xmin={1000} xmax={10000}
                        name="threshold"
                        x={this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"] && this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["high_data_usage"]?.threshold?.split("M")?.[0]}
                        onChange={e => this.setSliderValues(e, "WAN:  Data Usage", "high_data_usage", "threshold")}
                      />
                      <div className="min-value">1000MB</div>
                      <div className="max-value">10000MB</div>
                    </div>
                    <input className="form-control"
                      value={this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["high_data_usage"]?.threshold && parseInt(this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["high_data_usage"]?.threshold) + "MB"} />
                  </div>
                </div>
                <div className="form-group">
                  <label>Severity
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control" name="severity"
                    value={this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"] && this.state.selectedTriggerData?.[0]?.["WAN:  Data Usage"]?.["high_data_usage"]?.severity}
                    onChange={e => this.setTriggerValues(e, "WAN:  Data Usage", "high_data_usage")}>
                    <option value="">Select</option>
                    <option value="INFO">Information</option>
                    <option value="WARNING">Low</option>
                    <option value="AVERAGE">Medium</option>
                    <option value="HIGH">High</option>
                    <option value="DISASTER">Critical</option>
                  </select>
                </div>
                {/* condition to show cellular && WiFi data usages for Chimay device */}
                {this.props.selectedModel !== 'vCPE' && Object.keys(this.state.template_schema_json.triggers.filter(item =>
                  item.trigger_type === 'Data usage')?.[0])?.filter(item => item === "WiFi : Data Usage").length > 0 &&
                  <>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <span className="mr-5">WiFi Data Usage
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when data usage is WiFi">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </span>
                      <label className="switch">
                        <input type="checkbox" name="status"
                          onClick={e => this.setTriggerValues(e, "WiFi : Data Usage", "data_usage")}
                          checked={this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["data_usage"]?.status)}
                          value={this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["data_usage"]?.status)} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div className="form-group mb-4">
                      <label>Threshold
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <div className="slidecontainer d-flex align-items-center">
                        <div className="rsbox">
                          <Slider xmin={100} xmax={500}
                            name="threshold"
                            x={this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"] && this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["data_usage"]?.threshold?.split("M")?.[0]}
                            onChange={e => this.setSliderValues(e, "WiFi : Data Usage", "data_usage", "threshold")}
                          />
                          <div className="min-value">1000MB</div>
                          <div className="max-value">10000MB</div>
                        </div>
                        <input className="form-control"
                          value={this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["data_usage"]?.threshold && parseInt(this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["data_usage"]?.threshold) + "MB"} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Severity
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <select className="form-control" name="severity"
                        value={this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"] && this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["data_usage"]?.severity}
                        onChange={e => this.setTriggerValues(e, "WiFi : Data Usage", "data_usage")}>
                        <option value="">Select</option>
                        <option value="INFO">Information</option>
                        <option value="WARNING">Low</option>
                        <option value="AVERAGE">Medium</option>
                        <option value="HIGH">High</option>
                        <option value="DISASTER">Critical</option>
                      </select>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <span className="mr-5">WiFi Average Data Usage
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when average data usage is WiFi">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </span>
                      <label className="switch">
                        <input type="checkbox" name="status"
                          onClick={e => this.setTriggerValues(e, "WiFi : Data Usage", "average_data_usage")}
                          checked={this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["average_data_usage"]?.status && parseInt(this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["average_data_usage"]?.status)}
                          value={this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["average_data_usage"]?.status && parseInt(this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["average_data_usage"]?.status)} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div className="form-group mb-4">
                      <label>Threshold
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <div className="slidecontainer d-flex align-items-center">
                        <div className="rsbox">
                          <Slider xmin={500} xmax={1000}
                            name="threshold"
                            x={this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"] && this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["average_data_usage"]?.threshold?.split("M")?.[0]}
                            onChange={e => this.setSliderValues(e, "WiFi : Data Usage", "average_data_usage", "threshold")}
                          />
                          <div className="min-value">500MB</div>
                          <div className="max-value">1000MB</div>
                        </div>
                        <input className="form-control"
                          value={this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["average_data_usage"]?.threshold && parseInt(this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["average_data_usage"]?.threshold) + "MB"} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Severity
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <select className="form-control" name="severity"
                        value={this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"] && this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["average_data_usage"]?.severity}
                        onChange={e => this.setTriggerValues(e, "WiFi : Data Usage", "average_data_usage")}>
                        <option value="">Select</option>
                        <option value="INFO">Information</option>
                        <option value="WARNING">Low</option>
                        <option value="AVERAGE">Medium</option>
                        <option value="HIGH">High</option>
                        <option value="DISASTER">Critical</option>
                      </select>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <span className="mr-5">WiFi High Data Usage
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when high data usage is WiFi">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </span>
                      <label className="switch">
                        <input type="checkbox" name="status"
                          onClick={e => this.setTriggerValues(e, "WiFi : Data Usage", "high_data_usage")}
                          checked={this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["high_data_usage"]?.status && parseInt(this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["high_data_usage"]?.status)}
                          value={this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["high_data_usage"]?.status && parseInt(this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["high_data_usage"]?.status)} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div className="form-group mb-4">
                      <label>Threshold
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <div className="slidecontainer d-flex align-items-center">
                        <div className="rsbox">
                          <Slider xmin={1000} xmax={10000}
                            name="threshold"
                            x={this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"] && this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["high_data_usage"]?.threshold?.split("M")?.[0]}
                            onChange={e => this.setSliderValues(e, "WiFi : Data Usage", "high_data_usage", "threshold")}
                          />
                          <div className="min-value">1000MB</div>
                          <div className="max-value">10000MB</div>
                        </div>
                        <input className="form-control"
                          value={this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["high_data_usage"]?.threshold && parseInt(this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["high_data_usage"]?.threshold) + "MB"} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Severity
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <select className="form-control" name="severity"
                        value={this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"] && this.state.selectedTriggerData?.[0]?.["WiFi : Data Usage"]?.["high_data_usage"]?.severity}
                        onChange={e => this.setTriggerValues(e, "WiFi : Data Usage", "high_data_usage")}>
                        <option value="">Select</option>
                        <option value="INFO">Information</option>
                        <option value="WARNING">Low</option>
                        <option value="AVERAGE">Medium</option>
                        <option value="HIGH">High</option>
                        <option value="DISASTER">Critical</option>
                      </select>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <span className="mr-5">WiFi 2.4GHz Data Usage
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when data usage is WiFi 2.4GHz">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </span>
                      <label className="switch">
                        <input type="checkbox" name="status"
                          onClick={e => this.setTriggerValues(e, "WiFi_2.4GHz: Data Usage", "data_usage")}
                          checked={parseInt(this.state.selectedTriggerData?.[0]?.["WiFi_2.4GHz: Data Usage"]?.["data_usage"]?.status)}
                          value={parseInt(this.state.selectedTriggerData?.[0]?.["WiFi_2.4GHz: Data Usage"]?.["data_usage"]?.status)} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div className="form-group mb-4">
                      <label>Threshold
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <div className="slidecontainer d-flex align-items-center">
                        <div className="rsbox">
                          <Slider xmin={100} xmax={500}
                            name="threshold"
                            x={this.state.selectedTriggerData?.[0]?.["WiFi_2.4GHz: Data Usage"]?.["data_usage"]?.threshold?.split("M")?.[0]}
                            onChange={e => this.setSliderValues(e, "WiFi_2.4GHz: Data Usage", "data_usage", "threshold")}
                          />
                          <div className="min-value">100MB</div>
                          <div className="max-value">500MB</div>
                        </div>
                        <input className="form-control"
                          value={parseInt(this.state.selectedTriggerData?.[0]?.["WiFi_2.4GHz: Data Usage"]?.["data_usage"]?.threshold) + "MB"} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Severity
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <select className="form-control" name="severity"
                        value={this.state.selectedTriggerData?.[0]?.["WiFi_2.4GHz: Data Usage"]?.["data_usage"]?.severity}
                        onChange={e => this.setTriggerValues(e, "WiFi_2.4GHz: Data Usage", "data_usage")}>
                        <option value="">Select</option>
                        <option value="INFO">Information</option>
                        <option value="WARNING">Low</option>
                        <option value="AVERAGE">Medium</option>
                        <option value="HIGH">High</option>
                        <option value="DISASTER">Critical</option>
                      </select>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <span className="mr-5">WiFi 2.4GHz Average Data Usage
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when average data usage is WiFi 2.4GHz ">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </span>
                      <label className="switch">
                        <input type="checkbox" name="status"
                          onClick={e => this.setTriggerValues(e, "WiFi_2.4GHz: Data Usage", "average_data_usage")}
                          checked={parseInt(this.state.selectedTriggerData?.[0]?.["WiFi_2.4GHz: Data Usage"]?.["average_data_usage"]?.status)}
                          value={parseInt(this.state.selectedTriggerData?.[0]?.["WiFi_2.4GHz: Data Usage"]?.["average_data_usage"]?.status)} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div className="form-group mb-4">
                      <label>Threshold
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <div className="slidecontainer d-flex align-items-center">
                        <div className="rsbox">
                          <Slider xmin={500} xmax={1000}
                            name="threshold"
                            x={this.state.selectedTriggerData?.[0]?.["WiFi_2.4GHz: Data Usage"]?.["average_data_usage"]?.threshold?.split("M")?.[0]}
                            onChange={e => this.setSliderValues(e, "WiFi_2.4GHz: Data Usage", "average_data_usage", "threshold")}
                          />
                          <div className="min-value">500MB</div>
                          <div className="max-value">1000MB</div>
                        </div>
                        <input className="form-control"
                          value={parseInt(this.state.selectedTriggerData?.[0]?.["WiFi_2.4GHz: Data Usage"]?.["average_data_usage"]?.threshold) + "MB"} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Severity
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <select className="form-control" name="severity"
                        value={this.state.selectedTriggerData?.[0]?.["WiFi_2.4GHz: Data Usage"]?.["average_data_usage"]?.severity}
                        onChange={e => this.setTriggerValues(e, "WiFi_2.4GHz: Data Usage", "average_data_usage")}>
                        <option value="">Select</option>
                        <option value="INFO">Information</option>
                        <option value="WARNING">Low</option>
                        <option value="AVERAGE">Medium</option>
                        <option value="HIGH">High</option>
                        <option value="DISASTER">Critical</option>
                      </select>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <span className="mr-5">WiFi 2.4GHz High Data Usage
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when high data usage is WiFi 2.4GHz">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </span>
                      <label className="switch">
                        <input type="checkbox" name="status"
                          onClick={e => this.setTriggerValues(e, "WiFi_2.4GHz: Data Usage", "high_data_usage")}
                          checked={parseInt(this.state.selectedTriggerData?.[0]?.["WiFi_2.4GHz: Data Usage"]?.["high_data_usage"]?.status)}
                          value={parseInt(this.state.selectedTriggerData?.[0]?.["WiFi_2.4GHz: Data Usage"]?.["high_data_usage"]?.status)} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div className="form-group mb-4">
                      <label>Threshold
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <div className="slidecontainer d-flex align-items-center">
                        <div className="rsbox">
                          <Slider xmin={1000} xmax={10000}
                            name="threshold"
                            x={this.state.selectedTriggerData?.[0]?.["WiFi_2.4GHz: Data Usage"]?.["high_data_usage"]?.threshold?.split("M")?.[0]}
                            onChange={e => this.setSliderValues(e, "WiFi_2.4GHz: Data Usage", "high_data_usage", "threshold")}
                          />
                          <div className="min-value">1000MB</div>
                          <div className="max-value">10000MB</div>
                        </div>
                        <input className="form-control"
                          value={parseInt(this.state.selectedTriggerData?.[0]?.["WiFi_2.4GHz: Data Usage"]?.["high_data_usage"]?.threshold) + "MB"} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Severity
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <select className="form-control" name="severity"
                        value={this.state.selectedTriggerData?.[0]?.["WiFi_2.4GHz: Data Usage"]?.["high_data_usage"]?.severity}
                        onChange={e => this.setTriggerValues(e, "WiFi_2.4GHz: Data Usage", "high_data_usage")}>
                        <option value="">Select</option>
                        <option value="INFO">Information</option>
                        <option value="WARNING">Low</option>
                        <option value="AVERAGE">Medium</option>
                        <option value="HIGH">High</option>
                        <option value="DISASTER">Critical</option>
                      </select>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <span className="mr-5">WiFi 5GHz Data Usage
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when data usage is WiFi 5GHz">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </span>
                      <label className="switch">
                        <input type="checkbox" name="status"
                          onClick={e => this.setTriggerValues(e, "WiFi_5GHz : Data Usage", "data_usage")}
                          checked={this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["data_usage"]?.status)}
                          value={this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["data_usage"]?.status && parseInt(this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["data_usage"]?.status)} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div className="form-group mb-4">
                      <label>Threshold
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <div className="slidecontainer d-flex align-items-center">
                        <div className="rsbox">
                          <Slider xmin={100} xmax={500}
                            name="threshold"
                            x={this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"] && this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["data_usage"]?.threshold?.split("M")?.[0]}
                            onChange={e => this.setSliderValues(e, "WiFi_5GHz : Data Usage", "data_usage", "threshold")}
                          />
                          <div className="min-value">100MB</div>
                          <div className="max-value">500MB</div>
                        </div>
                        <input className="form-control"
                          value={this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["data_usage"]?.threshold && parseInt(this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["data_usage"]?.threshold) + "MB"} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Severity
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <select className="form-control" name="severity"
                        value={this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"] && this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["data_usage"]?.severity}
                        onChange={e => this.setTriggerValues(e, "WiFi_5GHz : Data Usage", "data_usage")}>
                        <option value="">Select</option>
                        <option value="INFO">Information</option>
                        <option value="WARNING">Low</option>
                        <option value="AVERAGE">Medium</option>
                        <option value="HIGH">High</option>
                        <option value="DISASTER">Critical</option>
                      </select>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <span className="mr-5">WiFi 5GHz Average Data Usage
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when average data usage is WiFi 5GHz">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </span>
                      <label className="switch">
                        <input type="checkbox" name="status"
                          onClick={e => this.setTriggerValues(e, "WiFi_5GHz : Data Usage", "average_data_usage")}
                          checked={this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["average_data_usage"]?.status)}
                          value={this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["average_data_usage"]?.status && parseInt(this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["average_data_usage"]?.status)} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div className="form-group mb-4">
                      <label>Threshold
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <div className="slidecontainer d-flex align-items-center">
                        <div className="rsbox">
                          <Slider xmin={500} xmax={1000}
                            name="threshold"
                            x={this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"] && this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["average_data_usage"]?.threshold?.split("M")?.[0]}
                            onChange={e => this.setSliderValues(e, "WiFi_5GHz : Data Usage", "average_data_usage", "threshold")}
                          />
                          <div className="min-value">500MB</div>
                          <div className="max-value">1000MB</div>
                        </div>
                        <input className="form-control"
                          value={this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["average_data_usage"]?.threshold && parseInt(this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["average_data_usage"]?.threshold) + "MB"} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Severity
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <select className="form-control" name="severity"
                        value={this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"] && this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["average_data_usage"]?.severity}
                        onChange={e => this.setTriggerValues(e, "WiFi_5GHz : Data Usage", "average_data_usage")}>
                        <option value="">Select</option>
                        <option value="INFO">Information</option>
                        <option value="WARNING">Low</option>
                        <option value="AVERAGE">Medium</option>
                        <option value="HIGH">High</option>
                        <option value="DISASTER">Critical</option>
                      </select>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <span className="mr-5">WiFi 5GHz High Data Usage
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to generate a trigger when hgh data usage is WiFi 5GHz">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </span>
                      <label className="switch">
                        <input type="checkbox" name="status"
                          onClick={e => this.setTriggerValues(e, "WiFi_5GHz : Data Usage", "high_data_usage")}
                          checked={this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"] && parseInt(this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["high_data_usage"]?.status)}
                          value={this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["high_data_usage"]?.status && parseInt(this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["high_data_usage"]?.status)} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div className="form-group mb-4">
                      <label>Threshold
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger threshold defines the threshold limit for the trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <div className="slidecontainer d-flex align-items-center">
                        <div className="rsbox">
                          <Slider xmin={1000} xmax={10000}
                            name="threshold"
                            x={this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"] && this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["high_data_usage"]?.threshold?.split("M")?.[0]}
                            onChange={e => this.setSliderValues(e, "WiFi_5GHz : Data Usage", "high_data_usage", "threshold")}
                          />
                          <div className="min-value">1000MB</div>
                          <div className="max-value">10000MB</div>
                        </div>
                        <input className="form-control"
                          value={this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["high_data_usage"]?.threshold && parseInt(this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["high_data_usage"]?.threshold) + "MB"} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Severity
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Trigger severity defines type of severity for a trigger">
                          <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                      </label>
                      <select className="form-control" name="severity"
                        value={this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"] && this.state.selectedTriggerData?.[0]?.["WiFi_5GHz : Data Usage"]?.["high_data_usage"]?.severity}
                        onChange={e => this.setTriggerValues(e, "WiFi_5GHz : Data Usage", "high_data_usage")}>
                        <option value="">Select</option>
                        <option value="INFO">Information</option>
                        <option value="WARNING">Low</option>
                        <option value="AVERAGE">Medium</option>
                        <option value="HIGH">High</option>
                        <option value="DISASTER">Critical</option>
                      </select>
                    </div>
                  </>
                }
              </div>
            }
            {/* Data Usages close */}
          </div>
          <div className="s-footer">
            <button className="btn btn-light" onClick={this.closeAddTrigger}>Cancel</button>
            {/* LONG-3252-EM - When Applying Trigger configuration template got failed */}
            <button className="btn btn-primary" disabled={this.checkSeverity()}
              onClick={this.updateTrigger}>Update</button>
          </div>
        </div>
        {/* add new trigger close */}
        {!this.props.view && <div className="s-footer">
          <button className="btn btn-light" onClick={
            (this.props.apply && !this.props.edit && !this.props.reapply) ? () => this.props.closeAddTemplate() :
              () => this.closeAll()}>
            {(this.props.apply && !this.props.edit && !this.props.reapply) ? 'Back' : 'Cancel'}</button>
          {/* LONG-1691 If Apply is given continuously,Template are getting pushed more times-Issue fixed */}
          <button className="btn btn-primary"
            disabled={!this.state.template_name || this.props.applying}
            onClick={
              this.props.apply ? (event) => this.debounceApply(this.applyTemplate, event) :
                () => this.saveData()}
          >{this.props.apply ? 'Apply' : (this.state.selectedTemplateId !== '' ? 'Update' : 'Save')}</button>
        </div>}
        {/*EMGT-381-EM - "Template name already exists" pop-up is not shown while creating custom template. */}
        {(this.state.show) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            reset={() => this.resetPopup()}
          />
        }
        {(this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    applying: state.displayAddTemplate.applying,
    openStaticTemplate: state.displayAddTemplate.openStaticTemplate,
  };
};
export default connect(mapStateToProps)(WATemplate);
