/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SideMenu from '../../layOuts/SideMenu';
import Header from '../../layOuts/Header';
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import { ErrorPopup } from '../../layOuts/errorPopup';
import $ from 'jquery';
import moment from 'moment';
import * as Constant from '../../utils/constants';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import Select from 'react-select';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../DataTableDemo.css';
import { Dropdown } from 'primereact/dropdown';
import ErrorHandlePopup from "../ErrorHandlePopup";
import { Calendar } from 'primereact/calendar';
import * as Common from '../../utils/common';
import "react-datepicker/dist/react-datepicker.css";
var interval = null
class OperationsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            multipleSelect: "",
            toggledClearRows: false,
            showLoader: false,
            title: '',
            message: '',
            pageNo: 1,
            pageSize: 10,
            first: 1,
            last: 10,
            next: true,
            prev: true,
            firstDefault: 0,
            show: false,
            showCancel: false,
            cancel: false,
            error: false,
            delete: false,
            retry: false,
            operation_id: "",
            count: 0,
            openLog: false,
            selectedOption: [],
            showFilter: false,
            OperationLog: [],
            deviceMac: this?.props?.location?.state?.failedOperation ? this?.props?.location?.state?.failedOperation?.device_mac : (
                this?.props?.location?.state?.pendingOperation ? this?.props?.location?.state?.pendingOperation.device_mac :
                    this?.props?.location?.state?.deviceMac ? this?.props?.location?.state?.deviceMac : ''),
            deviceStatus: this?.props?.location?.state?.failedOperation ? 'Failed' : this?.props?.location?.state?.deviceMac ? 'Pending' : '',
            group_uuid: this.props.location?.state?.group_uuid ? this.props.location?.state?.group_uuid : '',
            macFilterList: [],
            statusFilterList: [],
            newOperationData: [],
            OperationData: [],
            getDeviceLookUp: [],
            getAllFilter: [],
            newcount: 0,
            url: "",
            filterList: [],
            order: '',
            sortOrder: 1,
            flag: 0,
            sortField: '',
            data: '',
            filterApplied: false,
            sortOn: false,
            startDate: '',
            endDate: '',
            timeFilter: false,
            isAxiosError: false,
            OperationColumns: [
                {
                    field: 'device_name', header: 'Device Name', value: 'Device Name',
                    body: this.operationTemplate,
                    frozen: false,
                    sortable: false,
                    style: { width: '200px', flexGrow: 1, minWidth: '150px' }
                },
                {
                    field: 'cmd_status', header: 'Status', value: 'Status',
                    body: this.statusTemplate,
                    frozen: false,
                    sortable: true,
                    style: { width: '150px', flexGrow: 1, minWidth: '130px' }
                },
                {
                    field: 'device_operation_status', header: 'State', frozen: false, value: 'State',
                    sortable: false,
                    body: this.operationStatusTemplate,
                    style: { width: '150px', flexGrow: 1, minWidth: '130px' }
                },
                {
                    field: 'device_operation_type', header: 'Operation Type', frozen: false, value: 'Operation Type',
                    sortable: true, body: this.operationTypeTemplate,
                    style: { width: '200px', flexGrow: 1, minWidth: '150px' }
                },
                {
                    field: 'device_operation_name', header: 'Operation Name', frozen: false, value: 'Operation Name',
                    sortable: false,
                    style: { width: '200px', flexGrow: 1, minWidth: '150px' }
                },
                {
                    field: 'device_mac', header: 'Device MAC', frozen: false, value: 'Device MAC',
                    sortable: true,
                    body: this.deviceMacTemplate,
                    style: { width: '200px', flexGrow: 1, minWidth: '130px' }
                },
                {
                    field: 'created_by', header: 'Created By', frozen: false, value: 'Created By',
                    sortable: false,
                    body: this.createdByTemplate,
                    style: { width: '200px', flexGrow: 1, minWidth: '130px' }
                },
                {
                    field: 'updated_by', header: 'Updated By', frozen: false, value: 'Updated By',
                    sortable: false,
                    body: this.updatedByTemplate,
                    style: { width: '200px', flexGrow: 1, minWidth: '130px' }
                },
                {
                    field: 'scheduled_time', header: 'Scheduled Time', frozen: false, value: 'Scheduled Time',
                    sortable: true,
                    body: this.scheduled_Time,
                    style: { width: '250px', flexGrow: 1, minWidth: '220px' }
                },
                {
                    field: 'operation_start_time', header: 'Start', frozen: false, value: 'Start',
                    sortable: true,
                    body: this.startTimeTemplate,
                    style: { width: '250px', flexGrow: 1, minWidth: '220px' }
                },
                {
                    field: 'operation_end_time', header: 'End', frozen: false, value: 'End',
                    sortable: true,
                    body: this.endTimeTemplate,
                    style: { width: '250px', flexGrow: 1, minWidth: '200px' }
                },
                {
                    field: 'execution_time', header: 'Execution Time', frozen: false, value: 'Execution Time',
                    body: this.executionTimeTemplate,
                    style: { width: '160px', flexGrow: 1, minWidth: '130px' }
                },
                {
                    field: 'remarks', header: 'Remarks', frozen: false, value: 'Remarks',
                    sortable: false,
                    body: this.remarksTemplate,
                    style: { width: '200px', flexGrow: 1, minWidth: '150px' }
                },
                {
                    field: 'Action', header: '', body: this.actionTemplate, value: 'Action',
                    style: { flexGrow: 1, flexBasis: '120px' },
                    sortable: false,
                    alignFrozen: 'right', frozen: true
                }
            ],
            conditionalRowStyles: [
                {
                    when: row => (this.props.location.state !== undefined) ?
                        row.operation_uuid === this.state.data : '',
                    style: {
                        backgroundColor: '#d5fdd6 !important',
                        color: 'white',
                    },
                },
            ]
        };

    }
    componentDidMount() {
        this.getDeviceLookUpMac();
        this.props.location?.state?.failedOperation || this.props.location?.state?.pendingOperation ? this.getFinalFilterList() : this.getOperationDeviceList();
        let newArray = this.state.OperationColumns;
        const defaultFields = [
            'device_name',
            'cmd_status',
            'device_operation_status',
            'device_operation_type',
            'device_mac',
            'operation_start_time',
            'execution_time',
            'remarks',
            'Action'
        ];
        newArray = newArray.filter(item => defaultFields.includes(item.field));
        const localValue = JSON.parse(localStorage.getItem(Constant.OPERATION_LIST));
        if (!!localValue) {
            newArray = [];
            localValue.map((item) => {
                const column = this.valueReturn(item);
                if (column !== undefined)
                    newArray.push(column);
            })

        }
        const id = this.props.location.state ? this.props.location.state.data : '';
        this.setState({ selectedOption: newArray, data: id });
    }
    getDeviceLookUpMac = () => {
        ApiCall(urlMapping.deviceLookUp(), (response) => {
            if (response?.data) {
                const data = response?.data
                this.setState({
                    getDeviceLookUp: data,
                });
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true
                    })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }

    componentWillUnmount() {
        clearInterval(interval)
        interval = null
    }
    returnState = (state) => {
        switch (state) {
            case 0:
                return "In Queue";
            case 1:
                return "In Progress";
            case 2:
                return "Completed";
            case 3:
                return "Upgrading";
            case 4:
                return "Scheduled";
            default:
                return "Completed";
        }
    }

    returnStateImage = (state) => {
        switch (state) {
            case 0:
                return "/images/12-layes-outline.gif";
            case 1:
            case 3:
                return "/images/settings.gif";
            case 2:
                return "/images/check.png";
            case 4:
                return "/images/clock.svg";
            default:
                return "/images/check.png";
        }
    }
    executionTime = (start, end) => {
        if (end === "Invalid date") {
            return '-';
        }
        else {
            let diff = moment.utc(moment(end, 'DD MMMM YYYY hh:mm:ss A').diff(moment(start, 'DD MMMM YYYY hh:mm:ss A'))).format("HH:mm:ss")
            return diff;
        }
    }

    getTime = (time) => {
        let date = moment.utc(time);
        date = date.local();
        return date.format('DD MMMM YYYY hh:mm:ss A');
    }
    sortAlphaNum = (a, b) => {
        var reA = /[^a-zA-Z]/g;
        var reN = /[^0-9]/g;
        var aA = a.replace(reA, "");
        var bA = b.replace(reA, "");
        if (aA === bA) {
            var aN = parseInt(a.replace(reN, ""), 10);
            var bN = parseInt(b.replace(reN, ""), 10);
            return aN === bN ? 0 : aN > bN ? 1 : -1;
        } else {
            return aA > bA ? 1 : -1;
        }
    }
    handleAddTemplate = () => {
        this.setState(prevState => ({
            addTemplate: !prevState.addTemplate
        }));
    }
    operationsSort = (columnSort) => {
        if (this.state.flag < 2) {
            this.setState({
                sortField: columnSort.sortField,
                sortOrder: columnSort.sortOrder,
                order: columnSort.sortOrder === 1 ? 'ascending' : 'descending',
                sortOn: true,
                flag: this.state.flag + 1,
            }, this.state.filterApplied ? () => this.getFinalFilterList('sort') : () => this.getOperationDeviceList("sort")
            )
        } else {
            this.setState({
                sortField: null,
                order: 'descending',
                sortOn: true,
                flag: 0,
            }, this.state.filterApplied ? () => this.getFinalFilterList('sort') : () => this.getOperationDeviceList("sort")
            )
        }
    }
    getOperationDeviceList = (sort) => {
        if (this.state.deviceMac !== "" || this.state.deviceStatus !== "" || (this.state.startDate !== '' && this.state.endDate !== '')) {
            this.getFinalFilterList()
        }
        else {
            this.setState({
                showLoader: true,
                data: ''
            });
            let sorting_parameter = sort === 'sort' && this.state.sortField !== null ? this.state.sortField : 'created_date'
            let sorting_type = sort === 'sort' ? this.state.order : 'descending'
            ApiCall(urlMapping.getOperationDeviceDetails(sorting_parameter, sorting_type, this.state.pageNo, this.state.pageSize), (response) => {
                this.setState({
                    showLoader: false,
                });
                if (response?.results) {
                    const data = response?.results
                    const first = (this.state.pageSize * (this.state.pageNo - 1)) + 1;
                    const last = response?.next ? this.state.pageSize * this.state.pageNo : response?.count;
                    this.setState({
                        next: response?.next ? false : true,
                        prev: response?.previous ? false : true,
                        first: first,
                        last: last,
                        OperationData: data,
                        filterList: data,
                        count: response?.count,
                        toggledClearRows: !this.state.toggledClearRows,
                        showIcon: false,
                    });
                }
                else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({
                            showRefreshModal: true
                        })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                }
            })
        }
    }

    handleChange = (e) => {
        let array = [];
        this.state.selectedOption.map((option) => (
            array.push(option.value)
        ));
        const result = e.target.value;
        if (result === "selectAll") {
            array = [];
            if (e.target.checked) {
                this.state.OperationColumns.map((column) => (
                    array.push(column.value)
                ));
            }
            else {
                array = ["Action"]
            }
        } else {
            if (e.target.checked)
                array.push(result);
            else
                array.splice(array.indexOf(result), 1);
        }
        let availableColumns = [
            "Device Name",
            "Status",
            "State",
            "Operation Type",
            "Operation Name",
            "Device MAC",
            "Created By",
            "Updated By",
            "Scheduled Time",
            "Start",
            "End",
            "Execution Time",
            "Remarks",
            "Action"
        ];
        array = availableColumns?.filter(field => array?.includes(field));
        if (array.includes("Action")) {
            array.splice(array.indexOf("Action"), 1);
            array.push("Action");
        }
        const selectedVlaues = [];
        array.map((item) => {
            const column = this.valueReturn(item);
            selectedVlaues.push(column);
        })
        localStorage.setItem(Constant.OPERATION_LIST, JSON.stringify(array));
        this.setState({ selectedOption: selectedVlaues });
    }
    valueReturn = (item) => {
        return this.state.OperationColumns.filter(column => column.value === item)[0];
    }

    confirmDelete = () => {
        this.setState({
            title: this.state.multipleSelect.length > 1 ? 'Delete Device Operation' : 'Delete Device Operation',
            message: this.state.multipleSelect.length > 1 ? 'Are you sure you want to delete device operations?' : 'Are you sure you want to delete device operation?',
            show: true,
            error: true,
            delete: true
        }, () => {
            $("#errorMessage").modal("show")
        });
    }
    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
            delete: false,
            toggledClearRows: !this.state.toggledClearRows,
            multipleSelect: '',
            retry: false,
            cancel: false,
            showOperationCheck: false
        }, () => $("#errorMessage").modal("hide"))
    }

    deleteOperations = () => {
        let operationId = []
        this.state.multipleSelect.forEach(element => {
            operationId.push(element.operation_id)
        });

        let data = { operation_ids: operationId }
        this.setState({
            showLoader: true
        });
        ApiCall(urlMapping.deleteDeviceOperation(data), (response) => {
            this.setState({
                showLoader: false
            });
            if (response?.success === true) {
                let newPage = this.state.pageNo;
                if (this.state.pageNo > 1) {
                    newPage = this.state.count - (this.state.pageSize * (this.state.pageNo - 1)) -
                        this.state.multipleSelect?.length === 0 ? this.state.pageNo - 1 : this.state.pageNo;
                }
                $("#errorMessage").modal("hide");
                this.setState({
                    title: 'Delete device operations',
                    message: response?.message,
                    show: true,
                    error: false,
                    delete: false,
                    toggledClearRows: !this.state.toggledClearRows,
                    pageNo: newPage
                }, () => $("#errorMessage").modal("show"));
                this.getOperationDeviceList();
            } else {
                $("#errorMessage").modal("hide");
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true });
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({
                        title: 'Delete device operations',
                        message: response?.message,
                        show: true,
                        error: true,
                        delete: false
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        })
    }

    confirmRetryOperation = () => {
        this.setState({
            title: this.state.multipleSelect.length > 1 ? 'Retry Operations' : 'Retry Operation',
            message: this.state.multipleSelect.length > 1 ? 'Are you sure you want to retry operations?' :
                'Are you sure you want to retry operation?',
            show: true,
            error: true,
            delete: true,
            retry: true
        }, () => {
            $("#errorMessage").modal("show")
        });
    }
    confirmCancelOperation = () => {
        this.setState({
            title: this.state.multipleSelect.length > 1 ? 'Cancel Operations' : 'Cancel Operation',
            message: this.state.multipleSelect.length > 1 ? 'Are you sure you want to cancel operations?' :
                'Are you sure you want to cancel operation?',
            showCancel: true,
            error: true,
            delete: true,
            cancel: true
        }, () => {
            $("#errorMessage").modal("show")
        });
    }
    checkDeviceTask = async () => {
        let deviceMac = [];
        this.state.multipleSelect.map(item => (
            deviceMac.push(item.device_mac)
        ));
        let data = { device_mac: deviceMac, operation_type: ['Firmware Upgrade'] }
        this.setState({
            showLoader: true
        });

        const trueArr = [];
        await ApiCall(urlMapping.checkDeviceTask(data), response => {
            this.setState({
                showLoader: false,
                show: false
            });
            $("#errorMessage").modal("hide");

            if (response?.success === true) {
                const data = response.data;
                Object.keys(data)?.forEach(item => {
                    if (data?.[item]?.status === true) {
                        trueArr?.push(data?.[item]);
                    }
                });
                if (trueArr?.length > 0) {
                    this.setState({
                        title: 'Device Task',
                        message: trueArr?.[0]?.message,
                        show: true,
                        error: true,
                        delete: false,
                        toggledClearRows: !this.state.toggledClearRows,
                    }, () => $("#errorMessage").modal("show"));
                }
                this.setState({
                    show: false,
                    error: false,
                    delete: false,
                    multipleSelect: '',
                })

            } else {
                $("#errorMessage").modal("hide");
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true
                    })
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({
                        title: 'Device Task',
                        message: response?.message,
                        show: true,
                        error: true,
                        delete: false
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        })
        return trueArr;
    }
    chooseAction = (deleteAct, retryAct, cancelAct) => {
        if (deleteAct && retryAct) {
            this.retryOperation();
            this.setState({ retry: false })
        }
        else if (deleteAct && cancelAct) {
            this.cancelOperation();
            this.setState({ cancel: false })
        }
        else if (deleteAct) {
            this.deleteOperations();
        }

        else {
            return "undefined";
        }
    }
    cancelOperation = () => {
        let task_id = ""
        task_id = this.state.multipleSelect?.[0]?.task_id

        let data = { task_id }
        this.setState({
            showLoader: true
        });
        ApiCall(urlMapping.cancelOperations(data), (response) => {
            this.setState({
                showLoader: false
            });
            if (response?.success) {
                this.setState({
                    title: 'Cancel Operation',
                    message: response?.message,
                    showCancel: false,
                    error: false,
                    toggledClearRows: !this.state.toggledClearRows,
                    multipleSelect: '',
                    delete: false,
                }, () => $("#errorMessage").modal("show"));
                this.getOperationDeviceList();
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        title: 'Cancel Operation',
                        message: response?.message,
                        showCancel: true,
                        error: true,
                        delete: false
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        })
    }
    retryOperation = async () => {
        let operationId = []
        this.state.multipleSelect.forEach(element => {
            operationId.push(element.operation_id)
        });
        const haveTask = await this.checkDeviceTask();

        let data = { operation_ids: operationId }
        this.setState({
            showLoader: true
        });
        if (haveTask.length === 0) {
            ApiCall(urlMapping.retryOperation(data), (response) => {
                this.setState({
                    showLoader: false
                });
                if (response?.success) {
                    $("#errorMessage").modal("hide");
                    this.setState({
                        title: 'Retry Operation',
                        message: response?.message,
                        show: true,
                        error: false,
                        toggledClearRows: !this.state.toggledClearRows,
                        multipleSelect: '',
                        delete: false,
                    }, () => $("#errorMessage").modal("show"));
                    this.getOperationDeviceList();
                }
                else {
                    $("#errorMessage").modal("hide");
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({
                            showRefreshModal: true
                        });
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({
                            title: 'Retry Operation',
                            message: response?.message,
                            show: true,
                            error: true,
                            delete: false
                        }, () => $("#errorMessage").modal("show"));
                    }
                }
            })
        } else {
            this.setState({
                showLoader: false
            });
        }
    }

    checkOperationStatus = () => {
        let result = false;
        // Long-1893  MA - Retry option is not enabled after selecting the checkbox for failed operations
        if (this.state.multipleSelect.filter(item => item.cmd_status === "Successful" || item.cmd_status === "Pending" || item.cmd_status !== "Failed").length > 0)
            result = true;
        return result;
    }
    getOperationLog = (e) => {
        let data = { operation_id: e };
        this.setState({ showLoader: true, operation_id: e })
        ApiCall(urlMapping.operationLog(data), (response) => {
            if (response?.success) {
                this.setState({ OperationLog: response?.data, showLoader: false, openLog: true })
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false,
                        openLog: false,
                        title: 'Operation Logs',
                        message: response.message,
                        show: true,
                        error: true,
                        delete: false
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        })
    }

    getDeviceOperations = () => {
        let data = '';
        if (this.state.deviceMac !== '') {
            data = { device_mac: this.state.deviceMac, status: this.state.deviceStatus };
        }
        ApiCall(urlMapping.getDeviceOperations(data), (response) => {
            this.setState({ showLoader: false })
            if (response?.results) {
                this.setState({
                    newOperationData: response?.results,
                    newcount: response?.count
                })
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }
    getFinalFilterList = (sort) => {
        let data = {
            device_mac: this.state.deviceMac, status: this.state.deviceStatus,
            start_time: this.state.startDate ? parseInt(new Date(this.state.startDate).getTime().toString().slice(0, 10)) : '',
            end_time: this.state.endDate ? parseInt(new Date(this.state.endDate).getTime().toString().slice(0, 10)) : '',
            group_uuid: this.state?.group_uuid ? this.state?.group_uuid : '',
            sorting_parameter: sort === 'sort' && this.state.sortField !== null ? this.state.sortField : 'created_date',
            sorting_type: sort === 'sort' ? this.state.order : 'descending'
        };
        //EMGT-151-EM- Unable to refresh the page, after filter is applied
        this.setState({ showLoader: true });
        if (this.state.deviceMac !== "" || this.state.deviceStatus !== "" || this.state.startDate !== '' || this.state.endDate !== '') {
            ApiCall(urlMapping.getDeviceOperations(data, this.state.pageNo, this.state.pageSize), (response) => {
                this.setState({ showLoader: false })
                if (response?.results) {
                    let OperationData = response?.results;
                    const first = (this.state.pageSize * (this.state.pageNo - 1)) + 1;
                    const last = response?.next ? this.state.pageSize * this.state.pageNo : response?.count;
                    if (this.state.deviceStatus === "") {
                        this.setState({
                            next: response?.next ? false : true,
                            prev: response?.previous ? false : true,
                            first: first,
                            last: last,
                            OperationData,
                            count: response?.count,
                            showFilter: false,
                            showIcon: true,
                        })
                    } else {
                        OperationData = OperationData.filter((item) =>
                            item.cmd_status === this.state.deviceStatus || (item.cmd_status === 'Pending' && this.state.deviceStatus === 'Scheduled')
                        );
                        const first = (this.state.pageSize * (this.state.pageNo - 1)) + 1;
                        const last = response?.next ? this.state.pageSize * this.state.pageNo : response?.count;
                        this.setState({
                            next: response?.next ? false : true,
                            prev: response?.previous ? false : true,
                            first: first,
                            last: last,
                            OperationData,
                            count: response?.count,
                            showFilter: false,
                            showIcon: true,
                        })
                    }
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({
                            showRefreshModal: true
                        });
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                }
            })
        }
    };


    hideDropdown = (e) => {
        var popup = $("#parentDiv");
        if (e.target.localName === "div") {
            if (!$('#open').is(e.target) && !popup.is(e.target) && popup.has(e.target).length === 0) {
                this.setState({
                    showFilter: false,
                    startDate: this.state.showIcon ? this.state.startDate : '',
                    endDate: this.state.showIcon ? this.state.endDate : '',
                    deviceMac: this.state.showIcon ? this.state.deviceMac : "",
                    deviceStatus: this.state.showIcon ? this.state.deviceStatus : '',
                })
            }
        }
    }
    resetValue = () => {
        this.setState({ timeFilter: false, startDate: '', endDate: '', deviceMac: '', deviceStatus: '', filterList: [], macFilterList: [], statusFilterList: [], showFilter: false, showIcon: false, pageNo: 1, group_uuid: "" }, () => this.getOperationDeviceList())
    }

    clearDropdown = (type) => {
        if (type === "Model") {
            this.setState({ deviceMac: "", deviceStatus: '', filterApplied: false })
        }
        else {
            this.setState({ deviceStatus: '', filterApplied: false })
        }

    }

    getOptions = () => {
        let options = [];
        options = this.state.getDeviceLookUp.map((item) => {
            if (item !== "")
                return { label: item.device_name, value: item.device_mac }
        })
        return options;
    }
    filterOptions = (option, inputValue) => {
        const { label } = option;
        const otherKey = this.getOptions().filter(
            (opt) => opt.label.toLowerCase() === label.toLowerCase() && opt.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        return label.toLowerCase().includes(inputValue.toLowerCase()) || otherKey.length > 0;
    }
    getSelectedMac = (e) => {
        if (e && e.label) {
            this.setState({
                deviceMac: e.value
            })
        }
        else {
            this.setState({
                deviceMac: '',
                deviceStatus: ''
            })
        }

    }
    setDeviceStatus = (e) => {
        if (e.target.value === 'select') {
            this.setState({ deviceStatus: '' })
        }
        else {
            this.setState({ deviceStatus: e.target.value })
        }
    }
    setFilterList = () => {
        this.setState((prevState) => ({
            showFilter: !prevState.showFilter, macFilterList: [...new Set(this.state.filterList.map((obj) => obj.device_mac))],
            statusFilterList: [...new Set(this.state.filterList.map((obj) => obj.cmd_status))],
            timeFilter: true
        }));

    }
    manageAutoRefresh = (e) => {
        if (e.target.checked === true) {
            interval = setInterval(() => {
                this.setState({ toggleStatus: true })
                this.getOperationDeviceList();
            }, 5000)
        } else {
            clearInterval(interval)
            interval = null
            this.setState({ toggleStatus: false })
        }
    }

    onSelectRow = (e) => {
        if (e.value.length === 0) {
            this.setState({
                multipleSelect: ''
            })
        } else {
            this.setState({
                multipleSelect: e.value
            })
            //LONG-2146-MA - Operation log not able to download
            // this.confirmDownload(e.value[0].operation_id)
        }
    }
    operationTemplate = (row) => {
        return (
            <span
                title={row.device_name}
                style={{ width: 'auto' }}
                className={
                    (row?.operation_type === 'ZabbixConfiguration')
                        ? "cursor-pointer disabled"
                        : "cursor-pointer"
                }>
                <button
                    className={
                        (row?.device_operation_type === 'ZabbixConfiguration')
                            ? "txt-blue disabled"
                            : "txt-blue"
                    }
                    disabled={row?.device_operation_type === 'ZabbixConfiguration'}
                    onClick={() => this.getOperationLog(row.operation_id)}>
                    {row.device_name}
                </button>
            </span>
        )
    }
    statusTemplate = (row) => {
        return (
            <div id={`col${row.cmd_status}`} >
                <span id={`icon${row.cmd_status}`}><i className={row.cmd_status === "Pending" ? "status pending" : (row.cmd_status === "Failed" ? "status offline" : (row.cmd_status === "Canceled") ? "status offline" : "status completed")}></i>
                    {row.cmd_status}</span>
            </div>
        )
    }
    checkForFailed = (row) => {
        let duration = moment.duration((moment(new Date())).diff(moment(row.scheduled_time)));
        duration = duration.asMinutes();
        if (((row.device_operation_status === 0 || row.device_operation_status === 1) && row.cmd_status === "Pending") ||
            ((row.device_operation_status === 4 && row.cmd_status === "Pending" && duration >= 5))) {
            return true;
        }
    }
    operationStatusTemplate = (row) => {
        return (
            <div id={`col${row.device_operation_status}`} >
                <span id={`icon${row.device_operation_status}`}>
                    {<img className={row.device_operation_status === 4 ? "clock-sche" : "op-image"} src={this.returnStateImage(row.device_operation_status)} alt="" />}
                    {this.returnState(row.device_operation_status)}</span>
            </div>
        )
    }
    deviceMacTemplate = (row) => {
        return (
            <span title={row.device_mac.toUpperCase()}>
                {row.device_mac.toUpperCase()}</span>
        )
    }
    createdByTemplate = (row) => {
        return (
            <span title={row?.created_by || "-"}>
                {row?.created_by || "-"}</span>
        )
    }
    updatedByTemplate = (row) => {
        return (
            <span title={row?.updated_by || "-"}>
                {row?.updated_by || "-"}</span>
        )
    }
    operationTypeTemplate = (row) => {
        return (
            <span title={Common.getTemplateType(row.device_operation_type)}>
                {Common.getTemplateType(row.device_operation_type)}</span>
        )
    }
    scheduled_Time = (row) => {
        return (
            <span className="date-time" title={row.scheduled_time ? this.getTime(row.scheduled_time) : ""}>
                {row.scheduled_time ? this.getTime(row.scheduled_time) : "-"}</span>
        )
    }
    startTimeTemplate = (row) => {
        return (
            <span className="date-time" title={this.getTime(row.operation_start_time)}>
                {this.getTime(row.operation_start_time)}</span>
        )
    }
    endTimeTemplate = (row) => {
        return (
            <span className="date-time" title={row.operation_end_time ? this.getTime(row.operation_end_time) : ''}>
                {row.operation_end_time ? this.getTime(row.operation_end_time) : '-'}</span>
        )
    }
    executionTimeTemplate = (row) => {
        return (
            <span className="date-time">
                {this.executionTime(this.getTime(row.operation_start_time), this.getTime(row.operation_end_time))}</span>
        )
    }
    actionTemplate = (row) => {
        return (
            <div className={"da-box dropleft"}>
                <img className={this.state.toggleStatus === true ? "a-dots disabled" : "a-dots"}
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" src="/images/svg-icons/ellipsis.svg" alt="" />
                <div className="drop-box dropdown-menu">
                    <ul >
                        {
                            row.cmd_status === "Failed" &&
                            <li
                                className={(this.props?.userRole === "Viewer_Group" ||
                                    this.props?.userRole === "Operator_Group")
                                    ? "disabled"
                                    : ""}
                                onClick={() =>
                                    (this.props?.userRole === "Viewer_Group" ||
                                        this.props?.userRole === "Operator_Group")
                                        ? undefined
                                        : this.setState({ multipleSelect: [row] }, () => this.confirmRetryOperation())
                                }>
                                <img className="edit" src="/images/svg-icons/retry.svg" alt="" /> Retry</li>
                        }
                        {/* LONG-3524-EM - while deleting the operations EM got freezed. */}
                        {/* {
                            row.cmd_status !== "Pending" &&
                            <li
                                onClick={() => { this.setState({ multipleSelect: [row] }); this.confirmDelete() }}
                            ><img className="delete" src="/images/svg-icons/delete.svg" alt="" /> Delete</li>
                        } */}
                        {
                            this.checkForFailed(row) &&
                            <li
                                className={(this.props?.userRole === "Viewer_Group" ||
                                    this.props?.userRole === "Operator_Group")
                                    ? "disabled"
                                    : ""}
                                onClick={() => (this.props?.userRole === "Viewer_Group" || this.props?.userRole === "Operator_Group")
                                    ? undefined
                                    : this.setState({ multipleSelect: [row] }, () => this.confirmUpdateOperationStatus())
                                }
                            >
                                Mark as Failed</li>
                        }
                        {
                            row.cmd_status !== "Pending" && row.cmd_status !== "Cancelled" &&
                            <li onClick={() => { this.setState({ multipleSelect: [row] }); this.downloadLogs(row) }}>
                                <img src="/images/svg-icons/download.svg" alt="" /> Download Logs</li>
                        }
                        {
                            (new Date(row.scheduled_time) > new Date() && row.cmd_status !== 'Cancelled') &&
                            <li
                                className={(this.props?.userRole === "Viewer_Group" ||
                                    this.props?.userRole === "Operator_Group")
                                    ? "disabled"
                                    : ""}
                                onClick={() =>
                                    (this.props?.userRole === "Viewer_Group" ||
                                        this.props?.userRole === "Operator_Group")
                                        ? undefined
                                        : this.setState({ multipleSelect: [row] }, () => this.confirmRevoke())
                                }
                            >
                                <img src="/images/svg-icons/error-cross.svg" alt="" /> Cancel</li>
                        }
                    </ul>
                </div>
            </div >
        )
    }
    confirmRevoke = () => {
        this.setState({
            title: this.state.multipleSelect.length > 1 ? 'Cancel Scheduled Tasks' : 'Cancel Scheduled Task',
            message: this.state.multipleSelect.length > 1 ? 'Are you sure you want to cancel all scheduled tasks' : 'Are you sure you want to cancel the scheduled task',
            checkSceduledTask: true,
            error: true,
            delete: true,
            cancel: true
        }, () => {
            $("#errorMessage").modal("show")
        });
    }
    revokeScheduledTask = () => {
        let taskIds = '';
        taskIds = this.state.multipleSelect.map(item => item.scheduler_job_id);
        let data = { scheduled_task_id: taskIds }
        this.setState({
            showLoader: true,
            checkSceduledTask: false
        });
        ApiCall(urlMapping.taskCancel(data), (response) => {
            this.setState({
                showLoader: false
            });
            if (response?.success === true) {
                $("#errorMessage").modal("hide");
                this.setState({
                    title: 'Cancel Scheduled Tasks',
                    message: response.message,
                    show: true,
                    error: false,
                    delete: false,
                    toggledClearRows: !this.state.toggledClearRows,
                }, () => $("#errorMessage").modal("show"));
                this.getOperationDeviceList();
            } else {
                $("#errorMessage").modal("hide");
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({
                        title: 'Cancel Scheduled Tasks',
                        message: response?.message,
                        show: true,
                        error: true,
                        delete: false
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        })
    }

    checkSceduledTime = () => {
        let result = false;
        if (this.state.toggleStatus === true || this.state.multipleSelect === "" || (this.state.multipleSelect.length > 0 && (this.state.multipleSelect.filter(item => new Date(item.scheduled_time) < new Date()).length > 0
            || this.state.multipleSelect.filter(status => status.cmd_status === 'Cancelled').length > 0))) {
            result = true
        }
        return result;
    }
    downloadLogs = (row) => {
        let operationId = '';
        operationId = row.operation_id;

        ApiCall(urlMapping.downloadOperationLogfile(operationId), (response) => {
            if (parseInt(response?.response?.status) === 200) {
                const url = window.URL.createObjectURL(new Blob([response?.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Operation Logs.txt');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        });
    }
    remarksTemplate = (row) => {
        return (
            <span title={row.remarks}>{row.remarks ? row.remarks : '-'}</span>
        )
    }

    setPerPage = (e) => {
        this.setState({
            pageNo: 1,
            pageSize: e.value
        }, () => this.state.sortOn !== true ? this.getOperationDeviceList() : this.getOperationDeviceList('sort'));
    };

    nextPageChangeHandle = () => {
        this.setState({
            pageNo: this.state.pageNo + 1
        }, () => this.state.sortOn !== true ? this.getOperationDeviceList() : this.getOperationDeviceList('sort'));
    };

    prevPageChangeHandle = () => {
        this.setState({
            pageNo: this.state.pageNo - 1
        }, () => this.state.sortOn !== true ? this.getOperationDeviceList() : this.getOperationDeviceList('sort'));
    }
    firstPageChangeHandle = () => {
        this.setState({
            pageNo: 1
        }, () => this.state.sortOn !== true ? this.getOperationDeviceList() : this.getOperationDeviceList('sort'));
    }
    lastPageChangeHandle = () => {
        this.setState({
            pageNo: Math.ceil(this.state.count / this.state.pageSize),
        }, () => this.state.sortOn !== true ? this.getOperationDeviceList() : this.getOperationDeviceList('sort'));
    }
    confirmUpdateOperationStatus = () => {
        this.setState({
            title: 'Update Operation Status',
            message: 'You are not supposed to mark as fail the operations that are running as the correct flow, this mark as fail is basically a fail-safe is some operation is stuck for longer than expected period.',
            showOperationCheck: true,
            error: true,
            delete: true,
            cancel: true
        }, () => {
            $("#errorMessage").modal("show")
        });
    }
    updateOperationStatus = () => {
        let operationId = '';
        operationId = this.state.multipleSelect[0].operation_id;
        let data = { operation_id: parseInt(operationId) }

        this.setState({
            showLoader: true,
            showOperationCheck: false
        });
        ApiCall(urlMapping.updateOperationStatus(data), (response) => {
            this.setState({
                showLoader: false
            });
            if (response?.success === true) {
                $("#errorMessage").modal("hide");
                this.setState({
                    title: 'Update Operation Status',
                    message: response?.message,
                    show: true,
                    error: false,
                    delete: false,
                    toggledClearRows: !this.state.toggledClearRows,
                }, () => $("#errorMessage").modal("show"));
                this.getOperationDeviceList();
            } else {
                $("#errorMessage").modal("hide");
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true });
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({
                        title: 'Update Operation Status',
                        message: response?.message,
                        show: true,
                        error: true,
                        delete: false
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        })
    }
    setStartTime = (e) => {
        this.setState({
            startDate: e.value ? e.value : '',
            endDate: ''
        })
    }
    setEndTime = (e) => {
        this.setState({
            endDate: e.value ? e.value : '',
        })
    }
    checkApply = () => {
        let result = false;
        if ((this.state.deviceMac === "" || this.state.deviceMac === "Select")
            && (this.state.deviceStatus === '' || this.state.deviceStatus === 'Select')
            && (this.state.startDate === '' || this.state.endDate === '')) {
            result = true;
        }
        else if (this.state.deviceMac !== "" || this.state.deviceStatus !== '') {
            if ((this.state.startDate !== '' && this.state.endDate === '')
                || (this.state.startDate === '' && this.state.endDate !== '')) {
                result = true;
            }
        }
        return result;
    }
    render() {
        let colArray = this.state.selectedOption.length === 0 ? this.state.OperationColumns : this.state.selectedOption;
        const dynamicColumns = colArray.map((col, i) => {
            return <Column key={col.field} field={col.field} resizable={col.resizable}
                sortable={col.sortable}
                header={col.header} body={col.body} style={col.style} alignFrozen={col.alignFrozen} frozen={col.frozen} />;
        });

        const template2 = {
            layout: 'RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink',
            'RowsPerPageDropdown': (options) => {
                const dropdownOptions = [
                    { label: "10", value: 10 },
                    { label: "20", value: 20 },
                    { label: "30", value: 30 },
                    { label: "40", value: 40 },
                    { label: "50", value: 50 }
                ];

                return (
                    <React.Fragment>
                        <span className="p-mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Rows per page: </span>
                        <Dropdown value={this.state.pageSize} options={dropdownOptions} onChange={(e) => this.setPerPage(e)} />
                    </React.Fragment>
                );
            },
            'CurrentPageReport': (options) => {
                return (
                    <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                        {this.state.first} - {this.state.last} of {this.state.count}
                    </span>
                )
            },
            'FirstPageLink': () => {
                return (
                    <button type="button"
                        className=" p-link1"
                        onClick={() => this.firstPageChangeHandle()}
                        disabled={this.state.prev}
                    >
                        <img
                            className={this.state.prev ? "p-link3" : "p-link2"}
                            src={this.state.prev ? "/images/svg-icons/arrow-pre-disabled.svg" : "/images/svg-icons/arrow-pre.svg"}
                            alt=""
                        />
                    </button>
                )
            },
            'PrevPageLink': () => {
                return (
                    <button type="button"
                        className=" p-link1"
                        onClick={() => this.prevPageChangeHandle()}
                        disabled={this.state.prev}
                    >
                        <i className="pi pi-chevron-left"></i>
                    </button>
                )
            },
            'NextPageLink': () => {
                return (
                    <button
                        type="button"
                        className="p-link1"
                        onClick={() => this.nextPageChangeHandle()}
                        disabled={this.state.next}
                    >
                        <i className="pi pi-chevron-right"></i>
                    </button>
                )
            },
            'LastPageLink': () => {
                return (
                    <button type="button"
                        onClick={() => this.lastPageChangeHandle()}
                        className=" p-link1"
                        disabled={this.state.next}
                    >
                        <img
                            className={this.state.next ? "p-link3" : "p-link2"}
                            src={this.state.next ? "/images/svg-icons/arrow-next-disabled.svg" : "/images/svg-icons/arrow-next.svg"}
                            alt=""
                        />
                    </button>
                )
            }
        };


        return (
            <div onMouseDown={(e) => this.hideDropdown(e)}>
                <SideMenu />
                {this.state.showLoader && <Loader />}
                <section id="rhsMain" className={this.props.open ? "rhs-main" : "rhs-main expand-w"}>
                    <Header />
                    <div className="white-bg  page-height">
                        <div className="ta-box">
                            <h1 className="page-title">Operations</h1>
                            <div className="action-btn">
                                {/* Long-1893  MA - Retry option is not enabled after selecting the checkbox for failed operations */}
                                <button
                                    className="button-lst"
                                    onClick={() => this.confirmRetryOperation()}
                                    disabled={
                                        (this.props?.userRole === "Viewer_Group" ||
                                            this.props?.userRole === "Operator_Group") ||
                                        this.state.toggleStatus === true ||
                                        this.state.multipleSelect === "" ||
                                        (this.state.multipleSelect.length > 0 &&
                                            this.checkOperationStatus())}
                                >
                                    <img src="/images/svg-icons/retry.svg" alt="" /> Retry</button>
                                <button
                                    className="button-lst"
                                    onClick={() => this.getOperationDeviceList()}
                                    disabled={this.state.toggleStatus === true}>
                                    <img src="/images/svg-icons/refreshing.svg" alt="" /> Refresh</button>
                                <button
                                    className="button-lst"
                                    onClick={() => this.confirmRevoke()}
                                    disabled={this.checkSceduledTime() ||
                                        (this.props?.userRole === "Viewer_Group" || this.props?.userRole === "Operator_Group")}>
                                    <img src="/images/svg-icons/error-cross.svg" alt="" /> Cancel</button>
                                {/* LONG-3524-EM - while deleting the operations EM got freezed. */}
                                {/* <button disabled={this.state.multipleSelect === "" || this.state.toggleStatus === true || (this.state.multipleSelect.filter(item => item.cmd_status === "Pending").length)}
                                    onClick={() => this.confirmDelete()} ><img src="/images/svg-icons/delete.svg" alt="" /> Delete</button> */}
                                <button className="dropdown-toggle filter-btn button-lst" data-toggle="dropdown" disabled={this.state.toggleStatus === true}><img src="/images/svg-icons/columns.svg" alt="" /> Columns</button>
                                <div className="filter-section dropdown-menu dropdown-menu-right" onChange={(e) => this.handleChange(e)}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="selectAll" checked={this.state.selectedOption.length === this.state.OperationColumns.length}
                                            style={{ cursor: "pointer" }} />
                                        <label className="form-check-label">Select All</label>
                                    </div>
                                    {
                                        this.state.OperationColumns.map((item, index) => {
                                            if (item.value !== "Action")
                                                return (
                                                    <div className="form-check" key={index}>
                                                        <input className="form-check-input" type="checkbox" value={item.value}
                                                            checked={JSON.stringify(this.state.selectedOption).includes(item.value)}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                        <label className="form-check-label">{item.value}</label>
                                                    </div>
                                                )
                                        })
                                    }
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="Action" checked={true} disabled
                                            style={{ cursor: "not-allowed" }}
                                        />
                                        <label className="form-check-label">Action</label>
                                    </div>
                                </div>
                                <div className="btn-group" >
                                    {/* disabled filter button if no data */}
                                    {/* crash issue on auto refresh fixed */}
                                    <button type="button" className="dropdown-toggle  filter-btn button-lst" onClick={() => this.setFilterList()}
                                        // Filter not getting disabled when Auto Refresh was turned on
                                        disabled={this.state.toggleStatus === true || (this.state.OperationData.length === 0 && !this.state.showIcon)}>
                                        {this.state.showIcon ?
                                            <img src="/images/svg-icons/info-icon.svg" alt="" />
                                            :
                                            <img src="/images/svg-icons/filter.svg" alt="" />
                                        }
                                        Filter</button>
                                    {this.state.showFilter && <div className={this.state.showFilter ?
                                        "dropdown-menu show  dropdown-menu-right filter-section operation-filter-w  overflow-visible"
                                        : "dropdown-menu dropdown-menu-right filter-section width360 overflow-visible"} id="parentDiv">
                                        <div className="form-group">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label>Device Name</label>
                                                <span onClick={() => this.clearDropdown("Model")}>Clear</span>
                                            </div>

                                            <Select options={this.getOptions()}
                                                placeholder={"Select Device Name"}
                                                filterOption={(o, i) => this.filterOptions(o, i)}
                                                isClearable
                                                isSearchable
                                                className="filter-dropdown"
                                                classNamePrefix="react-select-container"
                                                value={
                                                    this.getOptions().filter(option =>
                                                        option.value === this.state.deviceMac)
                                                }
                                                onChange={(e) => this.getSelectedMac(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label>Status</label>
                                                <span onClick={() => this.clearDropdown()}>Clear</span>
                                            </div>
                                            <select className="form-control" value={this.state.deviceStatus ? this.state.deviceStatus : "Select"} onChange={(e) => this.setDeviceStatus(e)}>
                                                <option value="">Select Status</option>
                                                <option value="Failed">Failed</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Cancelled">Cancelled</option>
                                                <option value="Successful">Successful</option>
                                                <option value="Scheduled">Scheduled</option>


                                            </select>
                                        </div>
                                        <div className="d-flex">
                                            <div className="form-group mr-2" id="schedulerDiv">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <label>Start Time</label>
                                                </div>
                                                {this.state.timeFilter &&
                                                    <div className="pt-2"><Calendar
                                                        id="navigatorstemplate"
                                                        value={this.state.startDate}
                                                        onChange={(e) => this.setStartTime(e)}
                                                        showTime
                                                        hourFormat="12"
                                                        monthNavigator={true}
                                                        yearNavigator={true}
                                                        hideOnDateTimeSelect={true}
                                                        yearRange={`${new Date().getFullYear() - 10}:${new Date().getFullYear() + 10}`}
                                                    // minDate={new Date()}
                                                    // monthNavigatorTemplate={this.monthNavigatorTemplate}
                                                    // yearNavigatorTemplate={this.yearNavigatorTemplate}
                                                    />
                                                    </div>
                                                }
                                            </div>
                                            <div className="form-group ml-2" id="schedulerDiv">
                                                <div className={this.state.startDate ? "d-flex align-items-center justify-content-between" : "disabled"}>
                                                    <label>End Time</label>
                                                </div>
                                                {this.state.timeFilter &&
                                                    <div className="pt-2"><Calendar
                                                        id="navigatorstemplate"
                                                        value={this.state.endDate}
                                                        onChange={(e) => this.setEndTime(e)}
                                                        disabled={this.state.startDate === '' ? true : false}
                                                        showTime
                                                        hourFormat="12"
                                                        monthNavigator={true}
                                                        yearNavigator={true}
                                                        hideOnDateTimeSelect={true}
                                                        yearRange={`${new Date().getFullYear() - 10}:${new Date().getFullYear() + 10}`}
                                                        minDate={this.state.startDate}
                                                    // monthNavigatorTemplate={this.monthNavigatorTemplate}
                                                    // yearNavigatorTemplate={this.yearNavigatorTemplate}
                                                    />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="action-box">
                                            <button className="btn btn-light" onClick={() => this.resetValue()}>Reset</button>
                                            {/* Filter Apply Button Disable problem in Operations is fixed */}
                                            <button className="btn btn-primary" onClick={() => { this.setState({ pageNo: 1, pageSize: this.state.pageSize, filterApplied: true, group_uuid: '' }, () => this.getFinalFilterList()) }}
                                                disabled={this.checkApply()}
                                            >Apply</button>
                                        </div>
                                    </div>}
                                </div>
                                <div className="auto-refresh">
                                    <label className="switch mr-2 ml-3">
                                        <input type="checkbox" onClick={(e) => this.manageAutoRefresh(e)}></input>
                                        <span className="slider round"></span>
                                    </label>
                                    <span>Auto Refresh</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <DataTable
                                value={this.state.OperationData}
                                onSort={(columnSort) => {
                                    this.operationsSort(columnSort);
                                }}
                                sortField={this.state.sortField}
                                sortOrder={this.state.sortOrder}
                                scrollable scrollHeight="400px"
                                loading={this.state.loading}
                                removableSort
                                resizableColumns
                                columnResizeMode="fit"
                                showGridlines
                                paginator
                                paginatorTemplate={template2}
                                first={this.state.firstDefault}
                                rows={this.state.pageSize}
                                paginatorClassName="p-jc-end"
                                selectionMode="checkbox"
                                selection={this.state.multipleSelect}
                                onSelectionChange={e => this.onSelectRow(e)}
                                dataKey="operation_id" scrollDirection="both" className="p-mt-3">
                                <Column selectionMode="multiple" style={{ flexGrow: 1, flexBasis: '80px' }} ></Column>
                                {dynamicColumns}
                            </DataTable>
                        </div>

                    </div>
                </section>
                {(this.state.show || this.state.showCancel) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.resetPopup()}
                        onSubmit={() => this.chooseAction(this.state.delete, this.state.retry, this.state.cancel)}
                    />
                }
                {(this.state.showOperationCheck || this.state.checkSceduledTask) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.resetPopup()}
                        onSubmit={() => this.state.showOperationCheck ? this.updateOperationStatus() : this.revokeScheduledTask()}
                    />
                }
                {/* operation log */}
                <div>
                    <div className={this.state.openLog ? "slide-overlay zindex10 open" : "slide-overlay zindex10"} ></div>
                    <div className={this.state.openLog ? "sidebar-slide zindex10 open " : "sidebar-slide zindex10"}>
                        <div className="s-header">
                            <h1 className="ss-title">Operation Logs</h1>
                            <button onClick={() => this.setState({ openLog: false })}>
                                <img src="/images/svg-icons/s-cancel.svg" alt="" />
                            </button>
                        </div>
                        <div>
                            <div className="s-body pb-3">
                                <div className="txt-blue">
                                    Operation ID: <strong>{this.state.operation_id}</strong>
                                </div>
                                {this.state.OperationLog.length > 0 && this.state.OperationLog.map((item) => {
                                    return (<div className="operationlogs-box">
                                        <div className="logs-list wa-card gray-bg">
                                            <div className="time">{this.getTime(item.created_date)}</div>
                                            {/* Long-1602 opeartion logs repeat title deleted */}
                                            {/* <div className="card-title txt-blue">Operation Logs</div> */}
                                            {item.logs.includes('"TASK debug') ?
                                                <>
                                                    <p>{item.logs.slice(0, item.logs.indexOf('"TASK debug'))}</p>
                                                    <div className="operation-title">Configuration Logs</div>
                                                    <div className="time">{this.getTime(item.created_date)}</div><p>
                                                        {item.logs.slice(item.logs.indexOf('"TASK debug'))}</p>  </> :
                                                <p>{item.logs}</p>}
                                        </div>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div></div>
                {/* end of operation  */}
                {this.state.showRefreshModal && <ErrorHandlePopup />}
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        userRole: String(state.auth.role)
    });
}
export default withRouter(connect(mapStateToProps)(OperationsList));