/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import ApiCall from "../../../../redux/API/apiCall";
import urlMapping from "../../../../redux/API/api";
import { Loader } from '../../../../layOuts/Loader';
import { ErrorPopup } from '../../../../layOuts/errorPopup';
import * as Common from "../../../../utils/common";
import Slider from 'react-input-slider';
import { Netmask } from 'netmask';
import debounce from 'lodash/debounce';
import * as Action from "../../../../redux/actionTypes";
import isEqual from 'lodash/isEqual';
class QOSTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addConfig: false,
      openAdvance: false,
      advanceConfig: false,
      queue: [],
      title: '',
      name: '',
      message: '',
      update: false,
      show: false,
      error: false,
      delete: false,
      showError: false,
      showLoader: false,
      selectedConfig: [],
      selectedTab: 'wanConfig',
      selectedTemplateId: this.props.id ? this.props.id : '',
      template_type: this.props?.templateData?.template_type || '',
      template_name: this.props?.templateData?.template_name || '',
      template_desc: this.props?.templateData?.template_desc || '',
      device_model: this.props?.templateData?.device_model || '',
      collection_version: this.props?.templateData?.collection_version || '',
      fw_version: this.props?.templateData?.fw_version || '',
      chimay: this.props.chimay,
      cbr: this.props.cbr,
      mifi: this.props.mifi,
      isAxiosError: false,
      template_schema_json: {
        "Enabled": "1",
        "restartInt": "24",
        "QueueConfig": [{
          "type": "wanConfig",
          "name": 'wanConfig1',
          "uuid": Math.random().toString(36).substring(2, 11).toUpperCase(),
          "networkName": "",
          "speed": "100",
          'speedtest': '0',
          'wanBandwidth': '',
          "setmark": "p123-AF42,p53-AF42",
          "rate1": "10",
          "ceil1": "30",
          "priority1": "1",
          "config1": "tcp-ack tos-min-delay",
          "rate2": "20",
          "ceil2": "50",
          "priority2": "2",
          "config2": "dscp-36 dscp-46 dscp-48 port-22 port-53 control_tunnel",
          "rate3": "30",
          "ceil3": "100",
          "priority3": "3",
          "config3": "dscp-8 udp",
          "rate4": "35",
          "ceil4": "100",
          "priority4": "4",
          "config4": "default",
          "rate5": "5",
          "ceil5": "100",
          "priority5": "0",
          "config5": "data_tunnel",
          "is_deleted": "0"
        },
        ],
        "RateLimit": [
        ]
      },
      usedPriority: ['1', '2', '3', '4', '0'],
      configData: {
        "type": "wanConfig",
        "name": '',
        "uuid": Math.random().toString(36).substring(2, 11).toUpperCase(),
        "networkName": "",
        "speed": "100",
        'speedtest': '0',
        'wanBandwidth': '',
        "setmark": "p123-AF42,p53-AF42",
        "rate1": "10",
        "ceil1": "30",
        "priority1": "1",
        "config1": "tcp-ack tos-min-delay",
        "rate2": "20",
        "ceil2": "50",
        "priority2": "2",
        "config2": "dscp-36 dscp-46 dscp-48 22 port-53 control_tunnel",
        "rate3": "30",
        "ceil3": "100",
        "priority3": "3",
        "config3": "dscp-8 udp",
        "rate4": "35",
        "ceil4": "100",
        "priority4": "4",
        "config4": "default",
        "rate5": "5",
        "ceil5": "100",
        "priority5": "0",
        "config5": "data_tunnel",
        "is_deleted": "0"
      },
      networkViewData: [],
      availableNetworks: [],
      availableLanRateNetworks: []
    };
  }
  componentDidMount() {
    if (this.props.template_schema_json !== undefined) {
      this.setState({
        template_schema_json: JSON.parse(JSON.stringify(this.props.template_schema_json)),
      });
    }
    if (this.props.edit && this.props.networkViewData) {
      let { networkViewData } = this.props;
      let availableNetworks = networkViewData;
      let availableLanRateNetworks = networkViewData.filter(item => item.network_zone === 'lan');
      this.state.template_schema_json.RateLimit.map((item) => {
        return item.networkName.split(' ').map((network) => {
          return availableLanRateNetworks = availableLanRateNetworks.filter(value => value.networkName !== network)
        })
      })
      this.state.template_schema_json.QueueConfig?.map((item) => {
        return item.networkName?.split(' ')?.map((network) => {
          return availableNetworks = availableNetworks.filter(value => value.networkName !== network)
        })
      })
      this.state.template_schema_json.QueueConfig.map((item) => {
        let newArr = []
        let newValue = ""
        item.networkName !== "" && item.networkName.split(' ').map((val) => {
          newArr = networkViewData.filter(element => (element.name !== "Control Tunnel")).filter((item1) => item1.networkName === val)
          if (newArr.length !== 0)
            newValue = newValue.length === 0 ? newValue + newArr?.[0]?.networkName : newValue + " " + newArr?.[0]?.networkName
          else
            newValue = newValue
        })
        item.networkName = newValue;
        return item;
      })
      this.setState({
        availableNetworks,
        networkViewData,
        availableLanRateNetworks
      })
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.templateData).length !== 0) {
      this.setState({
        selectedTemplateId: nextProps.id ? nextProps.id : '',
        template_type: nextProps?.templateData?.template_type || "",
        template_name: nextProps?.templateData?.template_name || "",
        template_desc: nextProps?.templateData?.template_desc || "",
        device_model: nextProps?.templateData?.device_model || "",
        collection_version: nextProps?.templateData?.collection_version || "",
        fw_version: nextProps?.templateData?.fw_version || ""
      });
    }
    if (nextProps.edit && nextProps.template_schema_json !== undefined) {
      if (nextProps.networkViewData) {
        let { networkViewData } = nextProps;
        let availableNetworks = networkViewData;
        let availableLanRateNetworks = networkViewData.filter(item => item.network_zone === 'lan');
        this.state.template_schema_json.RateLimit?.map((item) => {
          return item.networkName?.split(' ')?.map((network) => {
            return availableLanRateNetworks = availableLanRateNetworks.filter(value => value.networkName !== network)
          })
        })
        this.state.template_schema_json.QueueConfig.map((item) => {
          return item.networkName.split(' ').map((network) => {
            return availableNetworks = availableNetworks.filter(value => value.networkName !== network)
          })
        })
        this.state.template_schema_json.QueueConfig.map((item) => {
          let newArr = []
          let newValue = ""
          item.networkName !== "" && item.networkName.split(' ').map((val) => {
            newArr = networkViewData.filter(element => (element.name !== "Control Tunnel")).filter((item1) => item1.networkName === val)
            if (newArr.length !== 0)
              newValue = newValue.length === 0 ? newValue + newArr?.[0]?.networkName : newValue + " " + newArr?.[0]?.networkName
            else
              newValue = newValue
          })
          item.networkName = newValue;
          return item;
        })
        // deleted data tunnel are not removed from template_schema_json
        let availableDataTunnels = []
        networkViewData?.forEach(i => {
          if (i.network_zone === 'datatunnel') {
            availableDataTunnels?.push(i?.networkName)
          }
        })
        //EMGT-156-EM - Qos Page field validation
        let queueConfig = nextProps.template_schema_json.QueueConfig.map((item) => {
          if (item.type === 'tunnelConfig') {
            let network = item.networkName.trim().replace('/  /g', ' ').split(' ')
            return {
              ...item,
              networkName: availableDataTunnels.filter(subItem => network.includes(subItem)).join(" ")
            }
          }
          return item
        })
        //EMGT-156-EM - Qos Page field validation
        let template_schema_json = {
          ...nextProps.template_schema_json,
          QueueConfig: queueConfig
        }
        this.setState({
          networkViewData,
          availableNetworks,
          availableLanRateNetworks,
          template_schema_json
        })
      }
    }
    if (!isEqual(this.props?.chimay, nextProps?.chimay) ||
      !isEqual(this.props?.cbr, nextProps?.cbr) ||
      !isEqual(this.props?.mifi, nextProps?.mifi)) {
      this.setState({
        chimay: nextProps.chimay,
        cbr: nextProps.cbr,
        mifi: nextProps.mifi
      })
    }
  }
  openAddConfig = (data, name) => {
    const elements = document.getElementsByClassName('error-txt');
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.cssText = "display: none;"
    }
    if (data) {
      let rowData = JSON.parse(JSON.stringify(data));;
      this.setState({
        configData: rowData,
        addConfig: true,
        update: true,
        advanceOption: false,
        advanceConfig: false,
        queue: []
      })
    } else {
      let data = {};
      switch (name) {
        case 'lanRateLimit':
          data = {
            "type": "lanRateLimit",
            "name": "",
            "uuid": Math.random().toString(36).substring(2, 11).toUpperCase(),
            "networkName": "",
            "speed": "100",
            "srcIPs": "0.0.0.0/0",
            "is_deleted": "0"
          };
          break;
        case 'wanConfig':
          data = {
            "type": name,
            "name": "",
            "uuid": Math.random().toString(36).substring(2, 11).toUpperCase(),
            "networkName": "",
            "speed": "100",
            'speedtest': '0',
            'wanBandwidth': '',
            "setmark": "p123-AF42,p53-AF42",
            "rate1": "10",
            "ceil1": "30",
            "priority1": "1",
            "config1": "tcp-ack tos-min-delay",
            "rate2": "20",
            "ceil2": "50",
            "priority2": "2",
            "config2": "dscp-36 dscp-46 dscp-48 port-22 port-53 control_tunnel",
            "rate3": "30",
            "ceil3": "100",
            "priority3": "3",
            "config3": "dscp-8 udp",
            "rate4": "35",
            "ceil4": "100",
            "priority4": "4",
            "config4": "default",
            "rate5": "5",
            "ceil5": "100",
            "priority5": "0",
            "config5": "data_tunnel",
            "is_deleted": "0"
          };
          break;
        case 'lanConfig':
          data = {
            "type": "lanConfig",
            "name": "",
            "uuid": Math.random().toString(36).substring(2, 11).toUpperCase(),
            "networkName": "",
            "speed": "100",
            'speedtest': '0',
            'wanBandwidth': '',
            "setmark": "p123-AF42,p53-AF42",
            "rate1": "10",
            "ceil1": "30",
            "priority1": "1",
            "config1": "tcp-ack tos-min-delay",
            "rate2": "20",
            "ceil2": "50",
            "priority2": "2",
            "config2": "dscp-36 dscp-46 dscp-48 port-22 port-53",
            "rate3": "30",
            "ceil3": "100",
            "priority3": "3",
            "config3": "dscp-8 udp",
            "rate4": "40",
            "ceil4": "100",
            "priority4": "4",
            "config4": "default",
            "rate5": "",
            "ceil5": "",
            "priority5": "",
            "config5": "",
            "is_deleted": "0"
          };
          break;
        default:
          data = {
            "type": name,
            "name": "",
            "uuid": Math.random().toString(36).substring(2, 11).toUpperCase(),
            "networkName": "",
            "speed": "100",
            'speedtest': '0',
            'wanBandwidth': '',
            "setmark": "p123-AF42,p53-AF42",
            "rate1": "10",
            "ceil1": "30",
            "priority1": "1",
            "config1": "tcp-ack tos-min-delay",
            "rate2": "20",
            "ceil2": "50",
            "priority2": "2",
            "config2": "dscp-36 dscp-46 dscp-48 port-22 port-53",
            "rate3": "30",
            "ceil3": "100",
            "priority3": "3",
            "config3": "dscp-8 udp",
            "rate4": "40",
            "ceil4": "100",
            "priority4": "4",
            "config4": "default",
            "rate5": "",
            "ceil5": "",
            "priority5": "",
            "config5": "",
            "is_deleted": "0"
          }
      }
      this.setState({
        configData: data,
        addConfig: true,
        advanceOption: false,
        advanceConfig: false,
        queue: [],
        // Fix for not able to add lan & tunnel config rule
        usedPriority: name === 'wanConfig' ? ['1', '2', '3', '4', '0'] : ['1', '2', '3', '4']
        // usedPriority: this.state.configData.type === 'wanConfig' ? ['1', '2', '3', '4', '0'] : ['1', '2', '3', '4']
      })
    }
  }
  closeConfig = () => {
    const elements = document.getElementsByClassName('error-txt');
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.cssText = "display: none;"
    }
    $("input[name=srcIPs]").val("");
    this.setState({
      addConfig: false,
      update: false,
      advanceConfig: false,
      advanceOption: false,
      queue: [],
      selectedConfig: []
    })
  }
  addConfigData = () => {
    let { template_schema_json } = this.state;
    let { QueueConfig } = template_schema_json;
    let found = false;
    if (this.state.configData.type === 'lanRateLimit') {
      template_schema_json.RateLimit.map((item, index) => {
        if (item.uuid === this.state.configData.uuid) {
          found = true;
          template_schema_json.RateLimit[index] = this.state.configData;
        }
        return item;
      })
      if (!found) {
        template_schema_json.RateLimit.push(this.state.configData);
      }
    } else {
      QueueConfig.map((item, index) => {
        if (item.uuid === this.state.configData.uuid) {
          found = true;
          QueueConfig[index] = this.state.configData;
        }
        return item;
      })
      if (!found) {
        template_schema_json.QueueConfig.push(this.state.configData);
      }
    }
    template_schema_json.QueueConfig = QueueConfig;
    this.setState({
      template_schema_json,
      addConfig: false,
      update: false,
      selectedConfig: []
    })
  }
  removeError = (e) => {
    const element = document.getElementById(e.target.name);
    if (element)
      element.style.display = 'none';
    this.setState({
      showError: false
    })
  }
  validateIPaddress = (ipaddress, name) => {
    if (ipaddress !== '') {
      if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress) &&
        ipaddress !== '255.255.255.255') {
        if (ipaddress === "0.0.0.0")
          return true;
        else if (!(/(^127\.)|(^192\.168\.)|(^10\.)|(^172\.1[6-9]\.)|(^172\.2[0-9]\.)|(^172\.3[0-1]\.)|(^::1$)|(^[fF][cCdD])/.test(ipaddress))) {
          const element = document.getElementById(name);
          if (element) {
            element.style.display = 'block';
            element.innerHTML = 'This is restricted IP address';
            this.setState({
              showError: true,
            })
          }
          return false;
        } else
          return (true)
      } else {
        const element = document.getElementById(name);
        element.style.display = 'block';
        this.setState({
          showError: true
        })
        return (false);
      }
    }
  }
  handleDelete = item => {
    let data = this.state.configData;
    data.srcIPs = data.srcIPs.split(' ').filter(i => i !== item);
    data.srcIPs = data.srcIPs.join(' ');
    this.setState({
      configData: data
    });
  };
  handleKeyDown = evt => {
    this.removeError(evt);
    let valid = false;
    if (evt.target.value && (["Enter", "Tab", " ", ","]?.includes(evt.key) || evt.type === "blur")) {
      evt.preventDefault();
      let ip;
      if (evt.target.value?.includes('/') && this.validateIPaddress(evt.target.value.split('/')[0], evt.target.name)) {
        ip = new Netmask(evt.target.value);
        valid = this.validateIPaddress(evt.target.value.split('/')[0], evt.target.name) && ip.contains(evt.target.value.split('/')[0]);
      } else
        valid = this.validateIPaddress(evt.target.value, evt.target.name);
      let data = this.state.configData;
      if (valid) {
        if (evt.target.value && !data.srcIPs?.split(' ')?.includes(evt.target.value)) {
          //LONG-2681-0.0.0.0/0 IP should get removed from rate limiter rule when providing any specific IP
          data.srcIPs = data.srcIPs === '' || data.srcIPs === '0.0.0.0/0' ? evt.target.value : data.srcIPs + ' ' + evt.target.value;
          this.setState({
            configData: data
          });
        }
        $("input[name=srcIPs]").val("");
      }
    }
  };
  checkWanBandwidth = (e) => {
    if (e.target.value !== '' && (!Common.validateNumeric(e) ||
      (parseInt(e.target.value) < 1 || parseInt(e.target.value) > 1000))) {
      const element = document.getElementById(e.target.name);
      if (element) {
        element.style.display = "block";
        this.setState({
          showError: true
        })
      }
    }
  }
  setConfigData = (e, name, name1) => {
    let { configData } = this.state;
    if (e.x !== undefined) {
      if (e.x === 0 && name !== 'rate5') {
        const element = document.getElementById(name);
        if (element)
          element.style.display = "block";
      }
      const element = document.getElementById(`rate-${this.state.selectedTab}`);
      if (element)
        element.style.display = "none";
      configData[name] = e.x;
    } else {
      if (Common.removeError(e)) {
        this.setState({
          showError: true
        })
      }
      configData[name] = e.target.type === "checkbox" ?
        (e.target.checked === true ? "1" : "0") : e.target.value;
    }
    if (name === "speedtest" && configData.speedtest === '1')
      configData.wanBandwidth = ""
    this.setState({
      configData
    })
    // LONG-2287 : QOS Validations
    if (e.x !== undefined) {
      //LONG-2544 : Apply template page got freeze after editing default wan config rule
      let errorName = name.slice(0, -1)
      this.setState({
        errorName: errorName,
      })
      if (name.includes("rate") && this.state.configData[name1] < e.x) {
        const element = document.getElementById(`${name}-${this.state.selectedTab}`);
        if (element) {
          element.style.display = "block";
          element.innerHTML = "Ceil Rate cannot be less than Min Rate";
          this.setState({
            showError: true,
          })
        }
      }
      else if (name.includes("ceil") && this.state.configData[name1] > e.x) {
        const element = document.getElementById(`${name}-${this.state.selectedTab}`);
        if (element) {
          element.style.display = "block";
          element.innerHTML = "Ceil Rate cannot be less than Min Rate";
          this.setState({
            showError: true,
          })
        }
      }
      else {
        if (name.includes("rate") || name.includes("ceil")) {
          const element = document.getElementById(`${name}-${this.state.selectedTab}`);
          if (element) {
            element.style.display = "none";
            element.innerHTML = "";
            this.setState({
              showError: false,
            });
          }
        }
      }
    }
  }
  setAdvanceOption = (e) => {
    let { queue } = this.state;
    if (e.target.checked) {
      if (!queue.includes(e.target.name)) queue.push(e.target.name)
      this.setState({
        openAdvance: true,
        queue
      })
    } else {
      if (queue.includes(e.target.name))
        queue = queue.filter(item => item !== e.target.name)
      this.setState({
        queue
      })
    }
  }
  setAdvanceConfig = (e) => {
    if (e.target.checked) {
      this.setState({
        advanceConfig: true
      })
    } else {
      this.setState({
        advanceConfig: false,
        openAdvance: false,
        queue: []
      })
    }
  }
  resetPopup = () => {
    this.setState({
      show: false,
      error: false,
      delete: false,
    }, () => {
      $("#errorMessage").modal("hide");
      if (this.state.hide) {
        this.props.refreshList();
        this.props.closeAddTemplate();
      }
    })
  }
  checkConfigName = (e) => {
    let valid = true;
    if (Common.validateAlphaNumeric(e)) {
      if (
        this.state.template_schema_json.QueueConfig.filter(
          (item) => (item.name.toUpperCase() === e.target.value.toUpperCase() && item.uuid !== this.state.configData.uuid &&
            item.is_deleted !== "1")
        ).length > 0
      ) {
        valid = false;
        const element = document.getElementById(e.target.name);
        if (element) {
          element.style.display = "block";
          element.innerHTML = "Config already exists!";
          this.setState({
            showError: true,
          });
        }
      }
      if (e.target.value.length < 1 || e.target.value.length > 32) {
        const element = document.getElementById(e.target.name);
        if (element) {
          element.style.display = "block";
          element.innerHTML = "Enter 1 to 32 characters";
          this.setState({
            showError: true,
          });
        }
      }
      // LONG-2287 : QOS Validations
      if (e.target.value.indexOf(' ') >= 0) {
        const element = document.getElementById(e.target.name);
        if (element) {
          element.style.display = "block";
          element.innerHTML = "Enter alphanumeric value without spaces";
          this.setState({
            showError: true,
          });
        }
      }
      else {
        const element = document.getElementById(e.target.name);
        if (element) {
          element.style.display = "none";
          element.innerHTML = "";
          this.setState({
            showError: false,
          });
        }
      }
      const data = this.state.configData;
      data[e.target.name] = e.target.value;
      this.setState({
        configData: data
      })
    }
    return valid;
  }
  checkConfigError = () => {
    let rateSum = 0;
    let result = false;
    rateSum = parseInt(this.state.configData.rate1) + parseInt(this.state.configData.rate2) +
      parseInt(this.state.configData.rate3) + parseInt(this.state.configData.rate4);
    rateSum = this.state.configData.rate5 ? rateSum + parseInt(this.state.configData.rate5) : rateSum;
    const elements = document.getElementsByClassName('error-txt');
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].style.cssText === "display: block;")
        result = true;
    }
    if (rateSum > 100) {
      const element = document.getElementById(`rate-${this.state.selectedTab}`);
      if (element)
        element.style.display = "block";
      result = true;
    }
    if (this.state.configData.name === '')
      result = true;
    // Fix for not able to add lan & tunnel config rule
    if (this.state.selectedTab === 'wanConfig') {
      if (this.state.usedPriority.length < 5)
        result = true;
    }
    else {
      if (this.state.usedPriority.length < 4)
        result = true;
    }
    return result;
  }
  checkError = () => {
    let result = false;
    if (this.props.applying)
      result = true;
    const elements = document.getElementsByClassName('error-txt');
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].style.cssText === "display: block;")
        result = true;
    }
    if (this.state.template_name === '' ||
      this.state.template_type === '')
      result = true;
    if (this.state.template_schema_json.QueueConfig.length === 0 && this.state.template_schema_json.RateLimit.length === 0)
      result = true;
    // LONG-2992 : Without selecting Interface name able to save the Qos Configuration 
    if (this.props.edit) {
      this.state.template_schema_json.QueueConfig.map(item => {
        if (item.networkName === '')
          result = true;
      })
      this.state.template_schema_json.RateLimit.map(item => {
        if (item.networkName === '')
          result = true;
      })
    }
    return result;
  }
  saveData = () => {
    let data = {};
    data.template_name = this.state.template_name;
    data.template_desc = this.state.template_desc;
    data.device_model = this.props?.selectedModelList ? this.props?.selectedModelList?.toString() : this.state.device_model.toString();
    data.template_type = this.state.template_type;
    data.collection_version = this.state.collection_version;
    data.fw_version = this.state.fw_version;
    data.template_order = 5;
    data.template_schema_json = this.state.template_schema_json;
    if (this.state.selectedTemplateId === "") {
      data.template_version = {
        chimay: this.state.chimay,
        cbr: this.state.cbr,
        mifi: this.state.mifi
      }
    }
    this.setState({
      showLoader: true,
    });
    if (this.state.selectedTemplateId !== '') {
      ApiCall(urlMapping.updateTemplate(this.state.selectedTemplateId, data), (response) => {
        if (response?.Success) {
          this.setState({
            showLoader: false,
          });
          this.props.refreshList();
          this.props.openApplyTemplate(this.state.selectedTemplateId);
          this.props.closeAddTemplate();
        }
        else {
          if (parseInt(response?.response?.status) === 401) {
            this.setState({
              showLoader: false,
              showRefreshModal: true
            });
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState({
              title: 'Update Template',
              message: response?.message,
              showLoader: false,
              show: true,
              error: true,
            }, () => $("#errorMessage").modal("show"));
          }
        }
      });
    } else {
      ApiCall(urlMapping.createTemplate(data), (response) => {
        if (response?.success) {
          this.setState({
            showLoader: false,
          });
          this.props.refreshList();
          this.props.openApplyTemplate(response.data.id);
          this.props.closeAddTemplate();
        }
        else {
          if (parseInt(response?.response?.status) === 401) {
            this.setState({
              showLoader: false,
              showRefreshModal: true
            });
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState({
              title: 'Create Template',
              message: response?.message,
              showLoader: false,
              show: true,
              error: true,
            }, () => $("#errorMessage").modal("show"));
          }
        }
      });
    }
  }
  selectConfig = (e, config) => {
    let { selectedConfig } = this.state;
    if (e.target.checked === true) {
      selectedConfig.push(config);
    } else {
      selectedConfig = selectedConfig.filter(item => item.uuid !== config.uuid);
    }
    this.setState({
      selectedConfig
    })
  }
  deleteConfig = (configuration) => {
    let { template_schema_json } = this.state;
    let { availableNetworks } = this.state;
    if (this.props.edit) {
      let found = false;
      this.state.selectedConfig.map((item) => {
        if (configuration === 'lanRateLimit') {
          template_schema_json.RateLimit.map((value, index) => {
            if (value.uuid === item.uuid) {
              template_schema_json.RateLimit[index].is_deleted = '1';
              found = true;
            }
          })
          // LONG-2169 : Not able to delete config rules for WAN,LAN,Tunnel config 
          if (found) {
            template_schema_json.RateLimit = template_schema_json.RateLimit.filter(config =>
              config.uuid !== item.uuid);
          }
        } else
          template_schema_json.QueueConfig.map((value, index) => {
            if (value.uuid === item.uuid) {
              template_schema_json.QueueConfig[index].is_deleted = '1';
              // LONG-2563 : Interface not displayed in dropdown after deleting that interface mapped rule
              const interfaceName = template_schema_json.QueueConfig[index].networkName.split(" ");
              interfaceName.map((inter) => {
                const network = this.state.networkViewData.filter(item => item.networkName === inter)[0];
                if (network)
                  availableNetworks.push(network)
                found = true;
              })
            }
          })
        if (found) {
          template_schema_json.QueueConfig = template_schema_json.QueueConfig.filter(config =>
            config.uuid !== item.uuid);
        }
      })
    } else {
      this.state.selectedConfig.map((item) => {
        if (configuration === 'lanRateLimit') {
          template_schema_json.RateLimit = template_schema_json.RateLimit.filter(config =>
            config.uuid !== item.uuid);
        } else
          template_schema_json.QueueConfig = template_schema_json.QueueConfig.filter(config =>
            config.uuid !== item.uuid);
      })
    }
    this.setState({
      template_schema_json,
      selectedConfig: []
    })
  }
  setPriority = (e, index) => {
    let { configData } = this.state;
    let { usedPriority } = this.state;
    if (configData[e.target.name] === '') {
      if (!usedPriority.includes(e.target.value)) {
        usedPriority.push(e.target.value);
      }
      configData[e.target.name] = e.target.value;
    }
    else {
      if (e.target.value === '') { /// selects select option
        usedPriority = usedPriority.filter(item => item !== configData[e.target.name]);
        configData[e.target.name] = e.target.value;
      }
      else {
        if (!usedPriority.includes(e.target.value)) {
          usedPriority.push(e.target.value);
          usedPriority = usedPriority.filter(item => item !== configData[e.target.name]);
          configData[e.target.name] = e.target.value;
        }
      }
    }
    this.setState({
      configData,
      usedPriority
    })
  }
  getNetworkName = (name) => {
    const network = this.state.networkViewData.filter(item => item.networkName === name)[0];
    let networkName = ''
    if (network)
      networkName = network.name;
    return networkName;
  }
  handleChange = (e, row) => {
    const { template_schema_json } = this.state;
    let { availableNetworks } = this.state;
    let { availableLanRateNetworks } = this.state;
    if (e.target.checked) {
      if (row.type === 'lanRateLimit') {
        template_schema_json.RateLimit.map((item) => {
          if (item.uuid === row.uuid) {
            item.networkName = item.networkName === '' ? e.target.id : item.networkName + ' ' + e.target.id;
            availableLanRateNetworks = availableLanRateNetworks.filter(value => value.networkName !== e.target.id)
          }
          return item;
        })
      } else {
        template_schema_json.QueueConfig.map((item) => {
          if (item.uuid === row.uuid) {
            item.networkName = item.networkName === '' ? e.target.id : item.networkName + ' ' + e.target.id;
            availableNetworks = availableNetworks.filter(value => value.networkName !== e.target.id)
          }
          return item;
        })
      }
    } else {
      if (row.type === 'lanRateLimit') {
        template_schema_json.RateLimit.map((item) => {
          if (item.uuid === row.uuid) {
            // LONG-2179 : Fix for unable to delete the interface name in Qos for Rate Limiter
            const networks = item.networkName.split(' ');
            item.networkName = networks.filter(value => value !== e.target.id).join(' ');
            const network = this.state.networkViewData.filter(item => item.networkName === e.target.id)[0];
            if (network)
              availableLanRateNetworks.push(network)
          }
          return item;
        })
      } else {
        template_schema_json.QueueConfig.map((item) => {
          if (item.uuid === row.uuid) {
            const networks = item.networkName.split(' ');
            item.networkName = networks.filter(value => value !== e.target.id).join(' ');
            const network = this.state.networkViewData.filter(item => item.networkName === e.target.id)[0];
            if (network)
              availableNetworks.push(network)
          }
          return item;
        })
      }
    }
    this.setState({
      template_schema_json,
      availableNetworks,
      availableLanRateNetworks
    })
  }
  applyTemplate = () => {
    let { template_schema_json } = this.state;
    this.props.dispatch({
      type: Action.SET_APPLY_TEMPLATE,
    });
    this.props.applyTemplate(template_schema_json);
  }
  debounceApply = debounce((fn, event) => {
    fn(event)
  }, 500);
  //LONG-3831-EM - Apply button is disabled when editing default WAN configuration rule in QoS configuration
  selectedTabChange = (name) => {
    let usedPriorityValue
    if (name === "wanConfig") {
      usedPriorityValue = ['1', '2', '3', '4', '0'];
    }
    else {
      usedPriorityValue = ['1', '2', '3', '4'];
    }
    this.setState({
      usedPriority: usedPriorityValue,
      selectedTab: name,
      selectedConfig: [],
      advanceConfig: false,
      advanceOption: false
    })
  }
  //LONG-3015 : Cancel Operation is not working for QOS template in edit and reapply case
  closeAll = () => {
    //LONG-3014-EM - Issue in checkbox enable/disable case.
    this.setState({
      selectedConfig: []
    })
    this.props.dispatch({
      type: Action.CLOSE_APPLY_TEMPLATE,
    });
    this.props.closeAddTemplate()
  }
  setEnabled = (e) => {
    let { template_schema_json } = this.state;
    template_schema_json.Enabled = e.target.checked ? "1" : "0";
    this.setState({
      template_schema_json
    })
  }
  setRestartInt = (e) => {
    let { template_schema_json } = this.state;
    let element = document.getElementById("restartInt")
    if (
      Common.validateNumeric(e) &&
      parseInt(e?.target?.value) > 0 &&
      parseInt(e?.target?.value) < 25
    ) {
      element.style.display = 'none';
      this.setState({ showError: false })
    }
    else {
      element.style.display = 'block';
      this.setState({ showError: true })
    }
    template_schema_json.restartInt = e?.target?.value;
    this.setState({
      template_schema_json
    })
  }
  render() {
    return (
      <div className="qos-box">
        {this.state.showLoader && <Loader />}
        <div className="form-group">
          <div className="d-flex justify-content-between align-items-center mb-3">
            {/* LONG-2287 : QOS Validations */}
            <span className="mr-5"><strong>Enable QoS</strong>
              <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Option to enable/disable QoS">
                <img src="/images/svg-icons/info.svg" alt="" />
              </div>
            </span>
            <label className="switch">
              <input
                type="checkbox"
                name="Enabled"
                onChange={this.setEnabled}
                checked={this.state.template_schema_json?.Enabled === '1'} />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="d-flex">
            <span className="mr-5 mt-1">Restart Interval</span>
            <input
              className={this.state.template_schema_json?.Enabled === '1' ? "form-control w-1" : "form-control w-1 disabled"}
              type="name"
              name="restartInt"
              onChange={(e) => this.state.template_schema_json?.Enabled === '1' ? this.setRestartInt(e) : undefined}
              value={this.state.template_schema_json?.restartInt} />
            <span style={{ marginTop: "8px", marginLeft: "5px" }}>hr</span>
          </div>
          <div className="error-txt" id='restartInt'>The restart interval should be within 1 to 24 hours.</div>
        </div>
        <div className={this.state.template_schema_json?.Enabled === '1' ? "tab-box" : 'tab-box disabled'}>
          <ul className="tab-nav nav nav-pills bg-gray" role="tablist">
            <li className="nav-item" role="presentation">
              <a className="nav-link active" id="wan-configuration-tab" data-toggle="tab" href="#wan-configuration" role="tab"
                aria-controls="wan-configuration" aria-selected="true" onClick={() => this.selectedTabChange('wanConfig')}>WAN Configuration</a>
            </li>
            <li className="nav-item" role="presentation">
              <a className="nav-link" id="lan-configuration-tab" data-toggle="tab" href="#lan-configuration" role="tab"
                aria-controls="lan-configuration" aria-selected="false" onClick={() => this.selectedTabChange('lanConfig')}>LAN Configuration</a>
            </li>
            <li class="nav-item" role="presentation" style={this.state.template_schema_json.QueueConfig.filter(item => item.type === 'wanConfig').length < 1
              ? { cursor: "not-allowed" } : { cursor: "pointer" }}>
              <a class={this.state.template_schema_json.QueueConfig.filter(item => item.type === 'wanConfig').length < 1 ?
                "nav-link disabled" : "nav-link"} id="tunnel-configuration-tab" data-toggle="tab" href="#tunnel-configuration" role="tab"
                aria-controls="tunnel-configuration" aria-selected="false"
                onClick={this.state.template_schema_json.QueueConfig.filter(item => item.type === 'wanConfig').length < 1 ?
                  undefined : () => this.selectedTabChange('tunnelConfig')}>
                Wireguard Configuration</a>
            </li>
          </ul>
          <div className="tab-content mt-2">
            <div className="tab-pane active" id="wan-configuration" role="tabpanel" aria-labelledby="wan-configuration-tab">
              <div className="page-sub-title mt-4">
                <div className="d-flex align-items-center">
                  <strong>WAN Queuing</strong>
                  <img
                    src="/images/svg-icons/add.svg"
                    className="add-btn ml-2"
                    onClick={this.state.template_schema_json?.Enabled === '1' ? () => this.openAddConfig(undefined, 'wanConfig') : undefined} alt=""
                    style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} />
                </div>
                <div className="device-action">
                  <span className={(this.state.selectedConfig.length === 1 && this.state.selectedConfig[0].type === 'wanConfig') ?
                    "icon-box" : "icon-box disabled"}>
                    <img src="/images/svg-icons/edit.svg" alt="" onClick={(this.state.selectedConfig.length === 1 && this.state.selectedConfig[0].type === 'wanConfig') ?
                      () => this.openAddConfig(this.state.selectedConfig[0], 'wanConfig') : undefined} style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} />
                  </span >
                  <span className={(this.state.selectedConfig.length > 0 && this.state.selectedConfig[0].type === 'wanConfig') ?
                    "icon-box" : "icon-box disabled"}>
                    <img src="/images/svg-icons/delete.svg" alt="" onClick={(this.state.selectedConfig.length > 0 && this.state.selectedConfig[0].type === 'wanConfig') ?
                      () => this.deleteConfig('wanConfig') : undefined} style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} />
                  </span>
                </div>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th width="30"></th>
                    <th className="name">Name</th>
                    {this.props.edit && <th className="inte-name">Interface Name<span className="red-txt">*</span></th>}
                    <th className="name">WAN Bandwidth</th>
                    <th width="100">Uplink Speed</th>
                    <th>Advance Options</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.template_schema_json.QueueConfig.filter(item => item.type === 'wanConfig').map((row) => {
                    return (
                      <tr>
                        <>
                          <td>
                            <input
                              type="checkbox"
                              checked={this.state.selectedConfig.filter(item => (item?.uuid === row?.uuid))?.length > 0}
                              onClick={this.state.template_schema_json?.Enabled === '1' ? (e) => this.selectConfig(e, row) : undefined}
                              style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} />
                          </td>
                          <td>{row?.name}</td>
                          {this.props.edit && <td>
                            <div className="dropdown">
                              <ul className="chip-list z-index-9 radius">
                                {row?.networkName.split(' ').map((item) => {
                                  if (item && this.getNetworkName(item))
                                    return <li>{this.getNetworkName(item)}{this.getNetworkName(item) && <img src="/images/svg-icons/cancel.svg" id={item}
                                      onClick={this.state.template_schema_json?.Enabled === '1' ? (e) => this.handleChange(e, row) : undefined} alt=""
                                      style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} />}</li>
                                })}
                              </ul>
                              <div
                                className={this.state.template_schema_json?.Enabled === '1' ? "chip-box add-arrow" : "chip-box add-arrow disabled"}
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                              </div>
                              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <ul>{
                                  this.state.availableNetworks.filter(item => item.network_zone === 'wan').map((item) => {
                                    return <li >
                                      <input
                                        type="checkbox"
                                        id={item.networkName}
                                        checked={row.networkName?.split(' ')?.includes(item.networkName)}
                                        onClick={this.state.template_schema_json?.Enabled === '1' ? (e) => this.handleChange(e, row) : undefined}
                                        style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }}
                                      />
                                      {item.name}</li>
                                  })
                                }
                                </ul>
                              </div>
                            </div>
                          </td>}
                          <td>{row?.speedtest === "1" ? 'Based on speedtest' : (row?.wanBandwidth === '' ? 'Based on Default Speed' : `${row?.wanBandwidth} MB`)}</td>
                          <td>{row?.speed}%</td>
                          <td>
                            <div className="gray-bg">
                              <ul className="table-list">
                                <li className="head-list">
                                  <span>Name</span>
                                  <span>Min Rate</span>
                                  <span>Ceil Rate</span>
                                  <span>Priority</span>
                                  <span>Config</span>
                                </li>
                                <li className="body-list">
                                  <span>Queue1</span>
                                  <span>{row?.rate1}%</span>
                                  <span>{row?.ceil1}%</span>
                                  <span>{row?.priority1}</span>
                                  <span>{Common.getConfig(row?.config1)}</span>
                                </li>
                                <li className="body-list">
                                  <span>Queue2</span>
                                  <span>{row?.rate2}%</span>
                                  <span>{row?.ceil2}%</span>
                                  <span>{row?.priority2}</span>
                                  <span>{Common.getConfig(row?.config2)}</span>
                                </li>
                                <li className="body-list">
                                  <span>Queue3</span>
                                  <span>{row?.rate3}%</span>
                                  <span>{row?.ceil3}%</span>
                                  <span>{row?.priority3}</span>
                                  <span>{Common.getConfig(row?.config3)}</span>
                                </li>
                                <li className="body-list">
                                  <span>Queue4</span>
                                  <span>{row?.rate4}%</span>
                                  <span>{row?.ceil4}%</span>
                                  <span>{row?.priority4}</span>
                                  <span>{Common.getConfig(row?.config4)}</span>
                                </li>
                                <li className="body-list">
                                  <span>Queue5</span>
                                  <span>{row?.rate5}%</span>
                                  <span>{row?.ceil5}%</span>
                                  <span>{row?.priority5}</span>
                                  <span>{Common.getConfig(row?.config5)}</span>
                                </li>
                              </ul>
                            </div>
                          </td></>
                      </tr>)
                  })}
                </tbody>
              </table>
            </div>
            <div className="tab-pane" id="lan-configuration" role="tabpanel" aria-labelledby="lan-configuration-tab">
              <div className="page-sub-title mt-4">
                <div className="d-flex align-items-center">
                  <strong>Rate Limiter</strong>
                  <img src="/images/svg-icons/add.svg" className="add-btn ml-2"
                    onClick={this.state.template_schema_json?.Enabled === '1' ? () => this.openAddConfig(undefined, 'lanRateLimit') : undefined} alt=""
                    style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} />
                </div>
                <div className="device-action">
                  <span className={(this.state.selectedConfig.length === 1 && this.state.selectedConfig[0].type === 'lanRateLimit') ?
                    "icon-box" : "icon-box disabled"}>
                    <img src="/images/svg-icons/edit.svg" alt="" onClick={(this.state.selectedConfig.length === 1 && this.state.selectedConfig[0].type === 'lanRateLimit') ?
                      () => this.openAddConfig(this.state.selectedConfig[0], 'lanRateLimit') : undefined}
                      style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} />
                  </span >
                  <span className={(this.state.selectedConfig.length > 0 && this.state.selectedConfig[0].type === 'lanRateLimit') ?
                    "icon-box" : "icon-box disabled"}>
                    <img src="/images/svg-icons/delete.svg" alt="" onClick={(this.state.selectedConfig.length > 0 && this.state.selectedConfig[0].type === 'lanRateLimit') ?
                      () => this.deleteConfig('lanRateLimit') : undefined}
                      style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} />
                  </span>
                </div>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th width="30"></th>
                    <th className="name">Name</th>
                    {this.props.edit && <th className="inte-name">Interface Name<span className="red-txt">*</span></th>}
                    <th>Uplink Speed</th>
                    <th>Source IP</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.template_schema_json.RateLimit.map((row) => {
                    return (
                      <tr>
                        <td><input type="checkbox" checked={this.state.selectedConfig?.filter(item => (item?.uuid === row?.uuid)).length > 0}
                          onClick={this.state.template_schema_json?.Enabled === '1' ? (e) => this.selectConfig(e, row) : ''}
                          style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} /></td>
                        <td>{row?.name}</td>
                        {this.props.edit && <td>
                          <div className="dropdown">
                            <ul className="chip-list z-index-9 radius">
                              {row.networkName?.split(' ')?.map((item) => {
                                if (item && this.getNetworkName(item))
                                  return <li>{this.getNetworkName(item)}{this.getNetworkName(item) && <img src="/images/svg-icons/cancel.svg" id={item}
                                    onClick={this.state.template_schema_json?.Enabled === '1' ? (e) => this.handleChange(e, row) : undefined} alt=""
                                    style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} />}</li>
                              })}
                            </ul>
                            <div className={this.state.template_schema_json?.Enabled === '1' ? "chip-box add-arrow" : "chip-box add-arrow disabled"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            </div>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <ul>{
                                this.state.availableLanRateNetworks.filter(item => item.network_zone === 'lan').map((item) => {
                                  return <li >
                                    <input type="checkbox" id={item.networkName} checked={row.networkName?.split(' ')?.includes(item.networkName)}
                                      onClick={this.state.template_schema_json?.Enabled === '1' ? (e) => this.handleChange(e, row) : undefined}
                                      style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} />
                                    {item.name}</li>
                                })
                              }
                              </ul>
                            </div>
                          </div>
                        </td>}
                        <td>{row?.speed}%</td>
                        <td>{row?.srcIPs}</td>
                      </tr>)
                  })}
                </tbody>
              </table>
              <div className="page-sub-title mt-4">
                <div className="d-flex align-items-center">
                  <strong>LAN Queuing</strong>
                  <img src="/images/svg-icons/add.svg" className="add-btn ml-2"
                    onClick={this.state.template_schema_json?.Enabled === '1' ? () => this.openAddConfig(undefined, 'lanConfig') : undefined} alt=""
                    style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} />
                </div>
                <div className="device-action">
                  <span className={(this.state.selectedConfig.length === 1 && this.state.selectedConfig[0].type === 'lanConfig') ?
                    "icon-box" : "icon-box disabled"}>
                    <img src="/images/svg-icons/edit.svg" alt="" onClick={(this.state.selectedConfig.length === 1 && this.state.selectedConfig[0].type === 'lanConfig') ?
                      () => this.openAddConfig(this.state.selectedConfig[0], 'lanConfig') : undefined}
                      style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} />
                  </span >
                  <span className={(this.state.selectedConfig.length > 0 && this.state.selectedConfig[0].type === 'lanConfig') ?
                    "icon-box" : "icon-box disabled"}>
                    <img src="/images/svg-icons/delete.svg" alt="" onClick={(this.state.selectedConfig.length > 0 && this.state.selectedConfig[0].type === 'lanConfig') ?
                      () => this.deleteConfig('lanConfig') : undefined}
                      style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} />
                  </span>
                </div>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th width="30"></th>
                    <th className="name">Name</th>
                    {this.props.edit && <th className="inte-name">Interface Name<span className="red-txt">*</span></th>}
                    <th width="100">Downlink Speed</th>
                    <th>Advance Options</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.template_schema_json.QueueConfig.filter(item => item.type === 'lanConfig').map((row) => {
                    return (
                      <tr>
                        <td>
                          <input type="checkbox" checked={this.state.selectedConfig.filter(item => (item.uuid === row.uuid))?.length > 0}
                            onClick={(e) => this.selectConfig(e, row)}
                            style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} /></td>
                        <td>{row?.name}</td>
                        {this.props.edit && <td>
                          <div className="dropdown">
                            <ul className="chip-list z-index-9 radius">
                              {row.networkName.split(' ').map((item) => {
                                if (item && this.getNetworkName(item))
                                  return <li>{this.getNetworkName(item)}{this.getNetworkName(item) && <img src="/images/svg-icons/cancel.svg" id={item}
                                    onClick={this.state.template_schema_json?.Enabled === '1' ? (e) => this.handleChange(e, row) : undefined}
                                    style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} alt="" />}</li>
                              })}
                            </ul>
                            <div className={this.state.template_schema_json?.Enabled === '1' ? "chip-box add-arrow" : "chip-box add-arrow disabled"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            </div>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <ul>{
                                this.state.availableNetworks.filter(item => item.network_zone === 'lan').map((item) => {
                                  return <li >
                                    <input type="checkbox" id={item.networkName} checked={row.networkName?.split(' ')?.includes(item.networkName)}
                                      onClick={this.state.template_schema_json?.Enabled === '1' ? (e) => this.handleChange(e, row) : undefined}
                                      style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} />
                                    {item.name}</li>
                                })
                              }
                              </ul>
                            </div>
                          </div></td>}
                        <td>{row?.speed}%</td>
                        <td>
                          <div className="gray-bg">
                            <ul className="table-list">
                              <li className="head-list">
                                <span>Name</span>
                                <span>Min Rate</span>
                                <span>Ceil Rate</span>
                                <span>Priority</span>
                                <span>Config</span>
                              </li>
                              <li className="body-list">
                                <span>Queue1</span>
                                <span>{row?.rate1}%</span>
                                <span>{row?.ceil1}%</span>
                                <span>{row?.priority1}</span>
                                <span>{Common.getConfig(row?.config1)}</span>
                              </li>
                              <li className="body-list">
                                <span>Queue2</span>
                                <span>{row?.rate2}%</span>
                                <span>{row?.ceil2}%</span>
                                <span>{row?.priority2}</span>
                                <span>{Common.getConfig(row?.config2)}</span>
                              </li>
                              <li className="body-list">
                                <span>Queue3</span>
                                <span>{row?.rate3}%</span>
                                <span>{row?.ceil3}%</span>
                                <span>{row?.priority3}</span>
                                <span>{Common.getConfig(row?.config3)}</span>
                              </li>
                              <li className="body-list">
                                <span>Queue4</span>
                                <span>{row?.rate4}%</span>
                                <span>{row?.ceil4}%</span>
                                <span>{row?.priority4}</span>
                                <span>{Common.getConfig(row?.config4)}</span>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>)
                  })}
                </tbody>
              </table>
            </div>
            <div className="tab-pane" id="tunnel-configuration" role="tabpanel" aria-labelledby="tunnel-configuration-tab">
              <div className="page-sub-title mt-4">
                <div className="d-flex align-items-center">
                  <strong>Tunnel Queuing</strong>
                  <img src="/images/svg-icons/add.svg" className="add-btn ml-2"
                    onClick={this.state.template_schema_json?.Enabled === '1' ? () => this.openAddConfig(undefined, 'tunnelConfig') : undefined}
                    style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} alt="" />
                </div>
                <div className="device-action">
                  <span className={(this.state.selectedConfig.length === 1 && this.state.selectedConfig[0].type === 'tunnelConfig') ?
                    "icon-box" : "icon-box disabled"}>
                    <img src="/images/svg-icons/edit.svg" alt="" onClick={(this.state.selectedConfig.length === 1 && this.state.selectedConfig[0].type === 'tunnelConfig') ?
                      () => this.openAddConfig(this.state.selectedConfig[0], 'tunnelConfig') : undefined}
                      style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} />
                  </span >
                  <span className={(this.state.selectedConfig.length > 0 && this.state.selectedConfig[0].type === 'tunnelConfig') ?
                    "icon-box" : "icon-box disabled"}>
                    <img src="/images/svg-icons/delete.svg" alt="" onClick={(this.state.selectedConfig.length > 0 && this.state.selectedConfig[0].type === 'tunnelConfig') ?
                      () => this.deleteConfig('tunnelConfig') : undefined}
                      style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} />
                  </span>
                </div>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th width="30"></th>
                    <th className="name">Name</th>
                    {this.props.edit && <th className="inte-name">Interface Name<span className="red-txt">*</span></th>}
                    <th width="100">Uplink Speed</th>
                    <th>Advance Options</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.template_schema_json.QueueConfig.filter(item => item?.type === 'tunnelConfig')?.map((row) => {
                    return (
                      <tr>
                        <td><input type="checkbox" checked={this.state.selectedConfig.filter(item => (item.uuid === row?.uuid))?.length > 0}
                          onClick={this.state.template_schema_json?.Enabled === '1' ? (e) => this.selectConfig(e, row) : undefined}
                          style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} /></td>
                        <td>{row.name}</td>
                        {this.props.edit && <td>
                          <div className="dropdown">
                            {/* <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
                            <ul className="chip-list z-index-9 radius">
                              {row.networkName?.split(' ')?.map((item) => {
                                if (item && this.getNetworkName(item))
                                  return <li>{this.getNetworkName(item)}{this.getNetworkName(item) && <img src="/images/svg-icons/cancel.svg" id={item}
                                    onClick={this.state.template_schema_json?.Enabled === '1' ? (e) => this.handleChange(e, row) : undefined}
                                    style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} alt="" />}</li>
                              })}
                            </ul>
                            <div className={this.state.template_schema_json?.Enabled === '1' ? "chip-box add-arrow" : "chip-box add-arrow disabled"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            </div>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <ul>{
                                this.state.availableNetworks.filter(item => item.network_zone === 'datatunnel' && item.ip_address !== '').map((item) => {
                                  return <li >
                                    <input type="checkbox" id={item.networkName} checked={row.networkName?.split(' ')?.includes(item.networkName)}
                                      onClick={this.state.template_schema_json?.Enabled === '1' ? (e) => this.handleChange(e, row) : undefined}
                                      style={{ cursor: this.state.template_schema_json?.Enabled === '0' && 'not-allowed' }} />
                                    {item.name}</li>
                                })
                              }
                              </ul>
                            </div>
                          </div></td>}
                        <td>{row?.speed}%</td>
                        <td>
                          <div className="gray-bg">
                            <ul className="table-list">
                              <li className="head-list">
                                <span>Name</span>
                                <span>Min Rate</span>
                                <span>Ceil Rate</span>
                                <span>Priority</span>
                                <span>Config</span>
                              </li>
                              <li className="body-list">
                                <span>Queue1</span>
                                <span>{row?.rate1}%</span>
                                <span>{row?.ceil1}%</span>
                                <span>{row?.priority1}</span>
                                <span>{Common.getConfig(row?.config1)}</span>
                              </li>
                              <li className="body-list">
                                <span>Queue2</span>
                                <span>{row?.rate2}%</span>
                                <span>{row?.ceil2}%</span>
                                <span>{row?.priority2}</span>
                                <span>{Common.getConfig(row?.config2)}</span>
                              </li>
                              <li className="body-list">
                                <span>Queue3</span>
                                <span>{row?.rate3}%</span>
                                <span>{row?.ceil3}%</span>
                                <span>{row?.priority3}</span>
                                <span>{Common.getConfig(row?.config3)}</span>
                              </li>
                              <li className="body-list">
                                <span>Queue4</span>
                                <span>{row?.rate4}%</span>
                                <span>{row?.ceil4}%</span>
                                <span>{row?.priority4}</span>
                                <span>{Common.getConfig(row?.config4)}</span>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>)
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* add WAN Configuration slides */}
        <div className={this.state.addConfig && this.state.configData.type === 'wanConfig' ? "sidebar-slide  zindex10 second-open w-350 r-757" : "sidebar-slide  zindex10"}>
          <div className="s-header">
            <h1 className="ss-title">WAN Configuration</h1>
            <button><img src="/images/svg-icons/s-cancel.svg" alt="" onClick={this.closeConfig} /></button>
          </div>
          <div className="s-body">
            <div className="form-group">
              <label>Name
                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Unique name for a Configuration">
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </label>
              <input type="text" className="form-control" value={this.state.configData?.name} name='name'
                onChange={(e) => this.setConfigData(e, 'name')} onBlur={(e) => this.checkConfigName(e)}
                minLength='1' maxLength='32' />
              <div className="error-txt" id="name">Invalid value</div>
            </div>
            <div className="form-group">
              <div className="d-flex justify-content-between align-items-center mb-3">
                {/* LONG-2287 : QOS Validations */}
                <span className="mr-5">Speed Test
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Speedtest is disabled, linkspeed will be used">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </span>
                <label className="switch">
                  <input type="checkbox" name="speedtest" onChange={(e) => this.setConfigData(e, 'speedtest')}
                    checked={this.state.configData?.speedtest === '1'} />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            {this.state.configData.speedtest === '0' && <div className="form-group">
              <label>WAN Bandwidth
                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Bandwidth for WAN interface.">
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </label>
              <input type="text" className="form-control" value={this.state.configData?.wanBandwidth} name='wanBandwidth'
                onChange={(e) => this.setConfigData(e, 'wanBandwidth')} onBlur={this.checkWanBandwidth} />
              <span className="units">MB</span>
              <div className="error-txt" id="wanBandwidth">Value must be between 1 to 1000 MB</div>
            </div>}
            <div className="form-group mb-4">
              <label>Uplink Speed
                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Percentage of the Uplink Speed. Speed calculated based on speed test">
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </label>
              <div className="slidecontainer d-flex align-items-center">
                <div className="rsbox">
                  <Slider xmin={10} xmax={100} x={this.state.configData?.speed} onChange={(e) => this.setConfigData(e, 'speed')} name='speed' />
                  <div className="min-value">10%</div>
                  <div className="max-value">100%</div>
                </div>
                <input className="form-control" value={`${this.state.configData?.speed}%`} />
              </div>
            </div>
            <div className="form-group">
              <div className="d-flex justify-content-between align-items-center mb-3">
                {/* LONG-2287 : QOS Validations */}
                <span className="mr-5">Edit Advanced Options
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Advance Options">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </span>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="advancedOptions"
                    onChange={this.setAdvanceConfig}
                    checked={this.state.advanceConfig && this.state.selectedTab === 'wanConfig'} />
                  <span className="slider round"
                  ></span>
                </label>
              </div>
            </div>
            {this.state.advanceConfig && <div className="gray-bg p-3 rounded">
              <p className="border-bottom pb-1"><strong>Queue 1 Transmission Rate</strong></p>
              <div className="form-group mb-4">
                <label>Min Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    <Slider xmin={1} xmax={100} x={this.state.configData?.rate1} onChange={(e) => this.setConfigData(e, 'rate1', 'ceil1')} name='rate1' />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.rate1}%`} />
                </div>
              </div>
              <div className="form-group mb-4">
                <label>Ceil Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Ceil Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    <Slider xmin={1} xmax={100} x={this.state.configData?.ceil1} onChange={(e) => this.setConfigData(e, 'ceil1', 'rate1')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.ceil1}%`} />
                </div>
              </div>
              <div className="error-txt"
                id={`${this.state.errorName}1-wanConfig`}>Invalid value</div>
              <div className="form-group">
                <label>Priority
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Priority of the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <select className="form-control" onChange={(e) => this.setPriority(e)} name='priority1' value={this.state.configData?.priority1} >
                  <option value="">Select</option>
                  <option value='0' disabled={this.state.usedPriority?.includes('0')}>0</option>
                  <option value='1' disabled={this.state.usedPriority?.includes('1')}>1</option>
                  <option value='2' disabled={this.state.usedPriority?.includes('2')}>2</option>
                  <option value='3' disabled={this.state.usedPriority?.includes('3')}>3</option>
                  <option value='4' disabled={this.state.usedPriority?.includes('4')}>4</option>
                </select>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  {/* LONG-2287 : QOS Validations */}
                  {/* <span className="mr-5">Advance Options */}
                  <span className="mr-5">View Config
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Advance Options">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={this.setAdvanceOption}
                      name="queue1"
                      key={`${this.state.selectedConfig} queue1`}
                      checked={this.state.openAdvance === true && this.state.queue?.includes("queue1")} />
                    <span className="slider round" ></span>
                  </label>
                </div>
              </div>
              {(this.state.openAdvance === true && this.state.queue?.includes("queue1")) && <div className="form-group">
                <label>Default Configuration
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Type of Packet passed in the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="dropdown c-dropdown">
                  <div className="dc-value" aria-haspopup="true" aria-expanded="false">
                    {this.state.configData.config1?.split(',')?.map((item) => {
                      return (<ul className="chip-list radius">
                        <li value={item}>{Common.getConfig(item)}</li>
                      </ul>)
                    })}
                  </div>
                </div>
              </div>}
              <p className="border-bottom pb-1 mt-4"><strong>Queue 2 Transmission Rate</strong></p>
              <div className="form-group mb-4">
                <label>Min Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    <Slider xmin={1} xmax={100} x={this.state.configData?.rate2} name="rate2" onChange={(e) => this.setConfigData(e, 'rate2', 'ceil2')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.rate2}%`} />
                </div>
              </div>
              <div className="form-group mb-4">
                <label>Ceil Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Ceil Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    <Slider xmin={1} xmax={100} x={this.state.configData?.ceil2} onChange={(e) => this.setConfigData(e, 'ceil2', 'rate2')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.ceil2}%`} />
                </div>
              </div>
              {/* LONG-2287 : QOS Validations */}
              <div className="error-txt" id={`${this.state.errorName}2-wanConfig`}>Invalid value</div>
              <div className="form-group">
                <label>Priority
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Priority of the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <select className="form-control" onChange={(e) => this.setPriority(e)} name='priority2' value={this.state.configData.priority2}>
                  {/* LONG-2168 : Fix for being able to set same priority values for Queue in WAN configuration  */}
                  <option value="">Select</option>
                  <option value='0' disabled={this.state.usedPriority?.includes('0')}>0</option>
                  <option value='1' disabled={this.state.usedPriority?.includes('1')}>1</option>
                  <option value='2' disabled={this.state.usedPriority?.includes('2')}>2</option>
                  <option value='3' disabled={this.state.usedPriority?.includes('3')}>3</option>
                  <option value='4' disabled={this.state.usedPriority?.includes('4')}>4</option>
                </select>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  {/* LONG-2287 : QOS Validations */}
                  {/* <span className="mr-5">Advance Options */}
                  <span className="mr-5">View Config
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Advance Options">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" onChange={this.setAdvanceOption} name="queue2" />
                    <span className="slider round" checked={this.state.openAdvance === true && this.state.queue.includes("queue2")}></span>
                  </label>
                </div>
              </div>
              {(this.state.openAdvance === true && this.state.queue?.includes("queue2")) && <div className="form-group">
                <label>Default Configuration
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Type of Packet passed in the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="dropdown c-dropdown">
                  <div className="dc-value" aria-haspopup="true" aria-expanded="false">
                    {this.state.configData?.config2?.split(',')?.map((item) => {
                      return (<ul className="chip-list radius">
                        <li value={item}>{Common.getConfig(item)}</li>
                      </ul>)
                    })}
                  </div>
                </div>
              </div>}
              <p className="border-bottom pb-1 mt-4"><strong>Queue 3 Transmission Rate</strong></p>
              <div className="form-group mb-4">
                <label>Min Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    <Slider xmin={1} xmax={100} x={this.state.configData?.rate3} name="rate3" onChange={(e) => this.setConfigData(e, 'rate3', 'ceil3')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.rate3}%`} />
                </div>
              </div>
              <div className="form-group mb-4">
                <label>Ceil Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Ceil Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    <Slider xmin={1} xmax={100} x={this.state.configData?.ceil3} onChange={(e) => this.setConfigData(e, 'ceil3', 'rate3')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.ceil3}%`} />
                </div>
              </div>
              {/* LONG-2287 : QOS Validations */}
              <div className="error-txt" id={`${this.state.errorName}3-wanConfig`}>Invalid value</div>
              <div className="form-group">
                <label>Priority
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Priority of the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <select className="form-control" onChange={(e) => this.setPriority(e)} name='priority3' value={this.state.configData?.priority3}>
                  <option value="">Select</option>
                  <option value='0' disabled={this.state.usedPriority?.includes('0')}>0</option>
                  <option value='1' disabled={this.state.usedPriority?.includes('1')}>1</option>
                  <option value='2' disabled={this.state.usedPriority?.includes('2')}>2</option>
                  <option value='3' disabled={this.state.usedPriority?.includes('3')}>3</option>
                  <option value='4' disabled={this.state.usedPriority?.includes('4')}>4</option>
                </select>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  {/* LONG-2287 : QOS Validations */}
                  {/* <span className="mr-5">Advance Options */}
                  <span className="mr-5">View Config
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Advance Options">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" onChange={this.setAdvanceOption} name="queue3"
                      checked={this.state.openAdvance === true && this.state.queue?.includes("queue3")} />
                    <span className="slider round" ></span>
                  </label>
                </div>
              </div>
              {this.state.openAdvance === true && this.state.queue?.includes("queue3") && <div className="form-group">
                <label>Default Configuration
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Type of Packet passed in the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="dropdown c-dropdown">
                  <div className="dc-value" aria-haspopup="true" aria-expanded="false">
                    {this.state.configData?.config3?.split(',')?.map((item) => {
                      return (<ul className="chip-list radius">
                        <li value={item}>{Common.getConfig(item)}</li>
                      </ul>)
                    })}
                  </div>
                </div>
              </div>}
              <p className="border-bottom pb-1 mt-4"><strong>Queue 4 Transmission Rate</strong></p>
              <div className="form-group mb-4">
                <label>Min Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    <Slider xmin={1} xmax={100} x={this.state.configData?.rate4} name="rate4" onChange={(e) => this.setConfigData(e, 'rate4', 'ceil4')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.rate4}%`} />
                </div>
              </div>
              <div className="form-group mb-4">
                <label>Ceil Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Ceil Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    <Slider xmin={1} xmax={100} x={this.state.configData?.ceil4} onChange={(e) => this.setConfigData(e, 'ceil4', 'rate4')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.ceil4}%`} />
                </div>
              </div>
              {/* LONG-2287 : QOS Validations */}
              <div className="error-txt" id={`${this.state.errorName}4-wanConfig`}>Invalid value</div>
              <div className="form-group">
                <label>Priority
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Priority of the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <select className="form-control" onChange={(e) => this.setPriority(e)} name='priority4' value={this.state.configData.priority4}>
                  <option value="">Select</option>
                  <option value='0' disabled={this.state.usedPriority?.includes('0')}>0</option>
                  <option value='1' disabled={this.state.usedPriority?.includes('1')}>1</option>
                  <option value='2' disabled={this.state.usedPriority?.includes('2')}>2</option>
                  <option value='3' disabled={this.state.usedPriority?.includes('3')}>3</option>
                  <option value='4' disabled={this.state.usedPriority?.includes('4')}>4</option>
                </select>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  {/* LONG-2287 : QOS Validations */}
                  {/* <span className="mr-5">Advance Options */}
                  <span className="mr-5">View Config
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Advance Options">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" onChange={this.setAdvanceOption} name="queue4"
                      checked={this.state.openAdvance === true && this.state.queue.includes("queue4")} />
                    <span className="slider round" ></span>
                  </label>
                </div>
              </div>
              {this.state.openAdvance === true && this.state.queue.includes("queue4") && <div className="form-group">
                <label>Default Configuration
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Type of Packet passed in the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="dropdown c-dropdown">
                  <div className="dc-value" aria-haspopup="true" aria-expanded="false">
                    {this.state.configData?.config4?.split(',')?.map((item) => {
                      return (<ul className="chip-list radius">
                        <li value={item}>{Common.getConfig(item)}</li>
                      </ul>)
                    })}
                  </div>
                </div>
              </div>}
              <p className="border-bottom pb-1 mt-4"><strong>Queue 5 Transmission Rate</strong></p>
              <div className="form-group mb-4">
                <label>Min Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    {/* LONG-2287 : QOS Validations */}
                    <Slider xmin={1} xmax={100} x={this.state.configData?.rate5} name="rate5" onChange={(e) => this.setConfigData(e, 'rate5', 'ceil5')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state?.configData.rate5}%`} />
                </div>
              </div>
              <div className="form-group mb-4">
                <label>Ceil Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Ceil Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    <Slider xmin={1} xmax={100} x={this.state.configData?.ceil5} onChange={(e) => this.setConfigData(e, 'ceil5', 'rate5')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.ceil5}%`} />
                </div>
              </div>
              {/* LONG-2287 : QOS Validations */}
              <div className="error-txt" id={`${this.state.errorName}5-wanConfig`}>Invalid value</div>
              <div className="form-group">
                <label>Priority
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Priority of the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <select className="form-control" onChange={(e) => this.setPriority(e)} name='priority5' value={this.state.configData.priority5}>
                  <option value="">Select</option>
                  <option value='0' disabled={this.state.usedPriority?.includes('0')}>0</option>
                  <option value='1' disabled={this.state.usedPriority?.includes('1')}>1</option>
                  <option value='2' disabled={this.state.usedPriority?.includes('2')}>2</option>
                  <option value='3' disabled={this.state.usedPriority?.includes('3')}>3</option>
                  <option value='4' disabled={this.state.usedPriority?.includes('4')}>4</option>
                </select>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  {/* LONG-2287 : QOS Validations */}
                  {/* <span className="mr-5">Advance Options */}
                  <span className="mr-5">View Config
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Advance Options">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" onChange={this.setAdvanceOption} name="queue5"
                      checked={this.state.openAdvance === true && this.state.queue.includes("queue5")} />
                    <span className="slider round" ></span>
                  </label>
                </div>
              </div>
              {this.state.openAdvance === true && this.state.queue.includes("queue5") && <div className="form-group">
                <label>Default Configuration
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Type of Packet passed in the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="dropdown c-dropdown">
                  <div className="dc-value" aria-haspopup="true" aria-expanded="false">
                    {this.state.configData?.config5?.split(',')?.map((item) => {
                      return (<ul className="chip-list radius">
                        <li value={item}>{Common.getConfig(item)}</li>
                      </ul>)
                    })}
                  </div>
                </div>
              </div>}
            </div>}
          </div>
          <div className="s-footer">
            <div className="error-txt" id="rate-wanConfig">Sum of Queue rate can't exceed 100</div>
            <button className="btn btn-light"
              onClick={() => this.closeConfig()}
            >Cancel</button>
            {<button className="btn btn-primary"
              disabled={this.checkConfigError()}
              onClick={this.addConfigData}
            >{this.state.update ? 'Update' : 'Add'}</button>}
          </div>
        </div>
        {/* add WAN Configuration slides close */}
        {/* add rate limiter slides */}
        <div className={this.state.addConfig && this.state.configData.type === 'lanRateLimit' ? "sidebar-slide  zindex10 w-350 second-open r-757" : "sidebar-slide  zindex10 w-350"}>
          <div className="s-header">
            <h1 className="ss-title">Rate Limiter</h1>
            <button><img src="/images/svg-icons/s-cancel.svg" alt="" onClick={() => this.closeConfig()} /></button>
          </div>
          <div className="s-body">
            <div className="form-group">
              <label>Name
                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Unique name for a Configuration">
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </label>
              <input
                type="text"
                value={this.state.configData?.name}
                className="form-control"
                name='nameRate'
                onChange={(e) => this.setConfigData(e, 'name')}
                onBlur={(e) => this.checkConfigName(e)} />
              {/* LONG-2287 : QOS Validations */}
              <div className="error-txt" id="name">Invalid value</div>
            </div>
            <div className="form-group mb-4">
              <label>Uplink Speed
                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Percentage of the Uplink Speed.Speed is 1000Mbps if it is bridge or linkspeed of interface">
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </label>
              <div className="slidecontainer d-flex align-items-center">
                <div className="rsbox">
                  <Slider xmin={10} xmax={100} x={this.state.configData?.speed} onChange={(e) => this.setConfigData(e, 'speed')} />
                  <div className="min-value">10%</div>
                  <div className="max-value">100%</div>
                </div>
                <input className="form-control" value={`${this.state.configData?.speed}%`} />
              </div>
            </div>
            <div className="form-group">
              <label>Source IP
                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Source IP in which rate limiting need">
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </label>
              <div className="allowedip-box with-arrow">
                {this.state.configData.srcIPs && this.state.configData?.srcIPs?.split(' ')?.map((item) => {
                  return (
                    (item !== null && item !== 'null' && item !== '') && <div className="tag-item" key={item} >
                      {item}
                      <button
                        type="button"
                        className="button"
                        onClick={() => this.handleDelete(item)}
                      >
                        <img src="/images/svg-icons/cancel.svg" alt="" />
                      </button>
                    </div>
                  )
                })}
                <input type="text"
                  name="srcIPs"
                  onKeyDown={(e) => this.handleKeyDown(e)}
                  onBlur={(e) => this.handleKeyDown(e)}
                  autoComplete="off"
                />
              </div>
              <div className="error-txt" id="srcIPs">IP address not valid</div>
            </div>
          </div>
          <div className="s-footer">
            <button className="btn btn-light"
              onClick={() => this.closeConfig()}
            >Cancel</button>
            {<button className="btn btn-primary"
              disabled={this.checkConfigError()}
              onClick={this.addConfigData}
            >{this.state.update ? 'Update' : 'Add'}</button>}
          </div>
        </div >
        {/* add rate limiter slides close */}
        {/* add LAN Queuing slides */}
        <div className={this.state.addConfig && this.state.configData.type === 'lanConfig' ? "sidebar-slide  zindex10 second-open w-350 r-757" : "sidebar-slide  zindex10"}>
          <div className="s-header">
            <h1 className="ss-title">LAN Queuing</h1>
            <button><img src="/images/svg-icons/s-cancel.svg" alt="" onClick={() => this.closeConfig()} /></button>
          </div>
          <div className="s-body">
            <div className="form-group">
              <label>Name
                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Unique name for a Configuration">
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </label>
              <input type="text" value={this.state.configData?.name} className="form-control" name='nameLan'
                onChange={(e) => this.setConfigData(e, 'name')}
                onBlur={(e) => this.checkConfigName(e)}
                minLength='1' maxLength='32' />
              {/* LONG-2287 : QOS Validations */}
              <div className="error-txt" id="nameLan">Invalid value</div>
            </div>
            <div className="form-group mb-4">
              <label>Downlink Speed
                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Percentage of the Downlink Speed.Speed is 1000Mbps if it is bridge or linkspeed of interface ">
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </label>
              <div className="slidecontainer d-flex align-items-center">
                <div className="rsbox">
                  <Slider xmin={10} xmax={100} x={this.state.configData?.speed} onChange={(e) => this.setConfigData(e, 'speed')} name='speed' />
                  <div className="min-value">10%</div>
                  <div className="max-value">100%</div>
                </div>
                <input className="form-control" value={`${this.state.configData?.speed}%`} />
              </div>
            </div>
            <div className="form-group">
              <div className="d-flex justify-content-between align-items-center mb-3">
                {/* LONG-2287 : QOS Validations */}
                <span className="mr-5">Edit Advanced Options
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Advance Options">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </span>
                <label className="switch">
                  <input type="checkbox" name="advancedOptions" onChange={this.setAdvanceConfig}
                    checked={this.state.advanceConfig && this.state.selectedTab === 'lanConfig'} />
                  <span className="slider round" ></span>
                </label>
              </div>
            </div>
            {this.state.advanceConfig && <div className="gray-bg p-3 rounded">
              <p className="border-bottom pb-1"><strong>Queue 1 Transmission Rate</strong></p>
              <div className="form-group mb-4">
                <label>Min Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    {/* LONG-2287 : QOS Validations */}
                    <Slider xmin={1} xmax={100} x={this.state.configData?.rate1} onChange={(e) => this.setConfigData(e, "rate1", 'ceil1')} name="rate1" />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.rate1}%`} />
                </div>
                <div className="error-txt" id="rate1">Rate can't be 0</div>
              </div>
              <div className="form-group mb-4">
                <label>Ceil Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Ceil Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    <Slider xmin={1} xmax={100} x={this.state.configData?.ceil1} onChange={(e) => this.setConfigData(e, "ceil1", 'rate1')} name="ceil1" />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.ceil1}%`} />
                </div>
              </div>
              {/* LONG-2287 : QOS Validations */}
              <div className="error-txt" id={`${this.state.errorName}1-lanConfig`}>Invalid value</div>
              <div className="form-group">
                <label>Priority
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Priority of the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <select className="form-control" onChange={(e) => this.setPriority(e)} name='priority1' value={this.state.configData.priority1}>
                  <option value="">Select</option>
                  <option value='1' disabled={this.state.usedPriority?.includes('1')}>1</option>
                  <option value='2' disabled={this.state.usedPriority?.includes('2')}>2</option>
                  <option value='3' disabled={this.state.usedPriority?.includes('3')}>3</option>
                  <option value='4' disabled={this.state.usedPriority?.includes('4')}>4</option>
                </select>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  {/* LONG-2287 : QOS Validations */}
                  {/* <span className="mr-5">Advance Options */}
                  <span className="mr-5">View Config
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Advance Options">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" onChange={this.setAdvanceOption} name="queue1"
                      checked={this.state.openAdvance === true && this.state.queue?.includes("queue1")} />
                    <span className="slider round" ></span>
                  </label>
                </div>
              </div>
              {(this.state.openAdvance === true && this.state.queue?.includes("queue1")) && <div className="form-group">
                <label>Default Configuration
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Type of Packet passed in the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="dropdown c-dropdown">
                  <div className="dc-value" aria-haspopup="true" aria-expanded="false">
                    {this.state.configData?.config1?.split(',')?.map((item) => {
                      return (<ul className="chip-list radius">
                        <li value={item}>{Common.getConfig(item)}</li>
                      </ul>)
                    })}
                  </div>
                </div>
              </div>}
              <p className="border-bottom pb-1 mt-4"><strong>Queue 2 Transmission Rate</strong></p>
              <div className="form-group mb-4">
                <label>Min Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    {/* LONG-2287 : QOS Validations */}
                    <Slider xmin={1} xmax={100} x={this.state.configData?.rate2} name="rate2" onChange={(e) => this.setConfigData(e, 'rate2', 'ceil2')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.rate2}%`} />
                  <div className="error-txt" id="rate2">Rate can't be 0</div>
                </div>
              </div>
              <div className="form-group mb-4">
                <label>Ceil Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Ceil Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    <Slider xmin={1} xmax={100} x={this.state.configData?.ceil2} onChange={(e) => this.setConfigData(e, 'ceil2', 'rate2')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.ceil2}%`} />
                </div>
              </div>
              {/* LONG-2287 : QOS Validations */}
              <div className="error-txt" id={`${this.state.errorName}2-lanConfig`}>Invalid value</div>
              <div className="form-group">
                <label>Priority
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Priority of the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <select className="form-control" onChange={(e) => this.setPriority(e)} name='priority2' value={this.state.configData?.priority2}>
                  <option value="">Select</option>
                  <option value='1' disabled={this.state.usedPriority?.includes('1')}>1</option>
                  <option value='2' disabled={this.state.usedPriority?.includes('2')}>2</option>
                  <option value='3' disabled={this.state.usedPriority?.includes('3')}>3</option>
                  <option value='4' disabled={this.state.usedPriority?.includes('4')}>4</option>
                </select>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  {/* LONG-2287 : QOS Validations */}
                  {/* <span className="mr-5">Advance Options */}
                  <span className="mr-5">View Config
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Advance Options">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" onChange={this.setAdvanceOption} name="queue2"
                      checked={this.state.openAdvance === true && this.state.queue?.includes("queue2")} />
                    <span className="slider round" ></span>
                  </label>
                </div>
              </div>
              {(this.state.openAdvance === true && this.state.queue?.includes("queue2")) && <div className="form-group">
                <label>Default Configuration
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Type of Packet passed in the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="dropdown c-dropdown">
                  <div className="dc-value" aria-haspopup="true" aria-expanded="false">
                    {this.state.configData?.config2?.split(',')?.map((item) => {
                      return (<ul className="chip-list radius">
                        <li value={item}>{Common.getConfig(item)}</li>
                      </ul>)
                    })}
                  </div>
                </div>
              </div>}
              <p className="border-bottom pb-1 mt-4"><strong>Queue 3 Transmission Rate</strong></p>
              <div className="form-group mb-4">
                <label>Min Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    {/* LONG-2287 : QOS Validations */}
                    <Slider xmin={1} xmax={100} x={this.state.configData?.rate3} name="lanRate3" onChange={(e) => this.setConfigData(e, 'rate3', 'ceil3')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.rate3}%`} />
                  <div className="error-txt" id="rate3">Rate can't be 0</div>
                </div>
              </div>
              <div className="form-group mb-4">
                <label>Ceil Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Ceil Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    {/* <input type="range" xmin={1} xmax={100} value="25" className="rangeslider" id="myRange" />
                  </div>
                  <input className="form-control" value="25%" /> */}
                    <Slider xmin={1} xmax={100} x={this.state.configData?.ceil3} onChange={(e) => this.setConfigData(e, 'ceil3', 'rate3')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.ceil3}%`} />
                </div>
              </div>
              {/* LONG-2287 : QOS Validations */}
              <div className="error-txt" id={`${this.state.errorName}3-lanConfig`}>Invalid value</div>
              <div className="form-group">
                <label>Priority
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Priority of the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <select className="form-control" onChange={(e) => this.setPriority(e)} name='priority3' value={this.state.configData?.priority3}>
                  <option value="">Select</option>
                  <option value='1' disabled={this.state.usedPriority?.includes('1')}>1</option>
                  <option value='2' disabled={this.state.usedPriority?.includes('2')}>2</option>
                  <option value='3' disabled={this.state.usedPriority?.includes('3')}>3</option>
                  <option value='4' disabled={this.state.usedPriority?.includes('4')}>4</option>
                </select>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  {/* LONG-2287 : QOS Validations */}
                  {/* <span className="mr-5">Advance Options */}
                  <span className="mr-5">View Config
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Advance Options">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" onChange={this.setAdvanceOption} name="queue3"
                      checked={this.state.openAdvance === true && this.state.queue.includes("queue3")} />
                    <span className="slider round" ></span>
                  </label>
                </div>
              </div>
              {this.state.openAdvance === true && this.state.queue?.includes("queue3") && <div className="form-group">
                <label>Default Configuration
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Type of Packet passed in the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="dropdown c-dropdown">
                  <div className="dc-value" aria-haspopup="true" aria-expanded="false">
                    {this.state.configData?.config3?.split(',')?.map((item) => {
                      return (<ul className="chip-list radius">
                        <li value={item}>{Common.getConfig(item)}</li>
                      </ul>)
                    })}
                  </div>
                </div>
              </div>}
              <p className="border-bottom pb-1 mt-4"><strong>Queue 4 Transmission Rate</strong></p>
              <div className="form-group mb-4">
                <label>Min Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    {/* LONG-2287 : QOS Validations */}
                    <Slider xmin={1} xmax={100} x={this.state.configData?.rate4} name="lanRate4" onChange={(e) => this.setConfigData(e, 'rate4', 'ceil4')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.rate4}%`} />
                  <div className="error-txt" id="rate4">Rate can't be 0</div>
                </div>
              </div>
              <div className="form-group mb-4">
                <label>Ceil Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Ceil Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    {/* <input type="range" xmin={1} xmax={100} value="25" className="rangeslider" id="myRange" />
                  </div>
                  <input className="form-control" value="25%" /> */}
                    <Slider xmin={1} xmax={100} x={this.state.configData?.ceil4} onChange={(e) => this.setConfigData(e, 'ceil4', 'rate4')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.ceil4}%`} />
                </div>
              </div>
              {/* LONG-2287 : QOS Validations */}
              <div className="error-txt" id={`${this.state.errorName}4-lanConfig`}>Invalid value</div>
              <div className="form-group">
                <label>Priority
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Priority of the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <select className="form-control" onChange={(e) => this.setPriority(e)} name='priority4' value={this.state.configData?.priority4}>
                  <option value="">Select</option>
                  <option value='1' disabled={this.state.usedPriority?.includes('1')}>1</option>
                  <option value='2' disabled={this.state.usedPriority?.includes('2')}>2</option>
                  <option value='3' disabled={this.state.usedPriority?.includes('3')}>3</option>
                  <option value='4' disabled={this.state.usedPriority?.includes('4')}>4</option>
                </select>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  {/* LONG-2287 : QOS Validations */}
                  {/* <span className="mr-5">Advance Options */}
                  <span className="mr-5">View Config
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Advance Options">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" onChange={this.setAdvanceOption} name="queue4"
                      checked={this.state.openAdvance === true && this.state.queue?.includes("queue4")} />
                    <span className="slider round" ></span>
                  </label>
                </div>
              </div>
              {this.state.openAdvance === true && this.state.queue?.includes("queue4") && <div className="form-group">
                <label>Default Configuration
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Type of Packet passed in the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="dropdown c-dropdown">
                  <div className="dc-value" aria-haspopup="true" aria-expanded="false">
                    {this.state.configData.config4?.split(',')?.map((item) => {
                      return (<ul className="chip-list radius">
                        <li value={item}>{Common.getConfig(item)}</li>
                      </ul>)
                    })}
                  </div>
                </div>
              </div>}
            </div>}
          </div>
          <div className="s-footer">
            <div className="error-txt" id="rate-lanConfig">Sum of Queue rate can't exceed 100</div>
            <button className="btn btn-light"
              onClick={() => this.closeConfig()}
            >Cancel</button>
            {<button className="btn btn-primary"
              disabled={this.checkConfigError()}
              onClick={this.addConfigData}
            >{this.state.update ? 'Update' : 'Add'}</button>}
          </div>
        </div>
        {/* add LAN Queuing slides close */}
        {/* add Wireguard Configuration slides */}
        <div className={this.state.addConfig && this.state.configData?.type === 'tunnelConfig' ? "sidebar-slide  zindex10 second-open w-350 r-757" : "sidebar-slide  zindex10"}>
          <div className="s-header">
            <h1 className="ss-title">Wireguard Configuration</h1>
            <button><img src="/images/svg-icons/s-cancel.svg" alt="" onClick={() => this.closeConfig()} /></button>
          </div>
          <div className="s-body">
            <div className="form-group">
              <label>Name
                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Unique name for a Configuration">
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </label>
              {/* LONG-2287 : QOS Validations */}
              <input type="text" value={this.state.configData?.name} className="form-control" name='nameTunnel' onChange={(e) => this.setConfigData(e, 'name')}
                onBlur={(e) => this.checkConfigName(e)}
                minLength='1' maxLength='32' />
              <div className="error-txt" id="nameTunnel">Invalid value</div>
            </div>
            <div className="form-group mb-4">
              <label>Uplink Speed
                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Percentage of the Queue 5 Ceil Rate or Max Uplink Speed.Speed calculated based on speed test">
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </label>
              <div className="slidecontainer d-flex align-items-center">
                <div className="rsbox">
                  {/* <input type="range" xmin={1} xmax={100} value="25" className="rangeslider" id="myRange" /> */}
                  <Slider xmin={10} xmax={100} x={this.state.configData?.speed} onChange={(e) => this.setConfigData(e, 'speed')} name='speed' />
                  <div className="min-value">10%</div>
                  <div className="max-value">100%</div>
                </div>
                <input className="form-control" value={`${this.state.configData?.speed}%`} />
              </div>
            </div>
            <div className="form-group">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <span className="mr-5">Edit Advanced Options
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Advance Options">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </span>
                <label className="switch">
                  <input type="checkbox" name="advancedOptions" onChange={this.setAdvanceConfig}
                    checked={this.state.advanceConfig && this.state.selectedTab === 'tunnelConfig'} />
                  <span className="slider round" ></span>
                </label>
              </div>
            </div>
            {this.state.advanceConfig && <div className="gray-bg p-3 rounded">
              <p className="border-bottom pb-1"><strong>Queue 1 Transmission Rate</strong></p>
              <div className="form-group mb-4">
                <label>Min Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    {/* <input type="range" xmin={1} xmax={100} value="25" className="rangeslider" id="myRange" /> */}
                    <Slider xmin={1} xmax={100} x={this.state.configData?.rate1} onChange={(e) => this.setConfigData(e, "rate1", 'ceil1')} name="rate1" />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.rate1}%`} />
                </div>
              </div>
              <div className="form-group mb-4">
                <label>Ceil Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Ceil Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    {/* <input type="range" xmin={1} xmax={100} value="25" className="rangeslider" id="myRange" /> */}
                    <Slider xmin={1} xmax={100} x={this.state.configData?.ceil1} onChange={(e) => this.setConfigData(e, "ceil1", 'rate1')} name="ceil1" />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.ceil1}%`} />
                </div>
              </div>
              <div className="error-txt" id={`${this.state.errorName}1-tunnelConfig`}>Invalid value</div>
              <div className="form-group">
                <label>Priority
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Priority of the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <select className="form-control" onChange={(e) => this.setPriority(e)} name='priority1' value={this.state.configData.priority1}>
                  <option value="">Select</option>
                  <option value='1' disabled={this.state.usedPriority?.includes('1')}>1</option>
                  <option value='2' disabled={this.state.usedPriority?.includes('2')}>2</option>
                  <option value='3' disabled={this.state.usedPriority?.includes('3')}>3</option>
                  <option value='4' disabled={this.state.usedPriority?.includes('4')}>4</option>
                </select>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  {/* <span className="mr-5">Advance Options */}
                  <span className="mr-5">View Config
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Advance Options">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" onChange={this.setAdvanceOption} name="queue1"
                      checked={this.state.openAdvance === true && this.state.queue?.includes("queue1")} />
                    <span className="slider round" ></span>
                  </label>
                </div>
              </div>
              {(this.state.openAdvance === true && this.state.queue?.includes("queue1")) && <div className="form-group">
                <label>Default Configuration
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Type of Packet passed in the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="dropdown c-dropdown">
                  <div className="dc-value" aria-haspopup="true" aria-expanded="false">
                    {this.state.configData?.config1?.split(',')?.map((item) => {
                      return (<ul className="chip-list radius">
                        <li value={item}>{Common.getConfig(item)}</li>
                      </ul>)
                    })}
                  </div>
                </div>
              </div>}
              <p className="border-bottom pb-1 mt-4"><strong>Queue 2 Transmission Rate</strong></p>
              <div className="form-group mb-4">
                <label>Min Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    <Slider xmin={1} xmax={100} x={this.state.configData?.rate2} onChange={(e) => this.setConfigData(e, 'rate2', 'ceil2')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.rate2}%`} />
                </div>
              </div>
              <div className="form-group mb-4">
                <label>Ceil Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Ceil Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    <Slider xmin={1} xmax={100} x={this.state.configData?.ceil2} onChange={(e) => this.setConfigData(e, 'ceil2', 'rate2')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.ceil2}%`} />
                </div>
              </div>
              <div className="error-txt" id={`${this.state.errorName}2-tunnelConfig`}>Invalid value</div>
              <div className="form-group">
                <label>Priority
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Priority of the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <select className="form-control" onChange={(e) => this.setPriority(e)} name='priority2' value={this.state.configData.priority2}>
                  <option value="">Select</option>
                  <option value='1' disabled={this.state.usedPriority?.includes('1')}>1</option>
                  <option value='2' disabled={this.state.usedPriority?.includes('2')}>2</option>
                  <option value='3' disabled={this.state.usedPriority?.includes('3')}>3</option>
                  <option value='4' disabled={this.state.usedPriority?.includes('4')}>4</option>
                </select>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  {/* <span className="mr-5">Advance Options */}
                  <span className="mr-5">View Config
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Advance Options">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input type="checkbox" onChange={this.setAdvanceOption} name="queue2"
                      checked={this.state.openAdvance === true && this.state.queue?.includes("queue2")} />
                    <span className="slider round" ></span>
                  </label>
                </div>
              </div>
              {(this.state.openAdvance === true && this.state.queue?.includes("queue2")) && <div className="form-group">
                <label>Default Configuration
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Type of Packet passed in the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="dropdown c-dropdown">
                  <div className="dc-value" aria-haspopup="true" aria-expanded="false">
                    {this.state.configData?.config2?.split(',')?.map((item) => {
                      return (<ul className="chip-list radius">
                        <li value={item}>{Common.getConfig(item)}</li>
                      </ul>)
                    })}
                  </div>
                </div>
              </div>}
              <p className="border-bottom pb-1 mt-4"><strong>Queue 3 Transmission Rate</strong></p>
              <div className="form-group mb-4">
                <label>Min Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    <Slider xmin={1} xmax={100} x={this.state.configData?.rate3} onChange={(e) => this.setConfigData(e, 'rate3', 'ceil3')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.rate3}%`} />
                </div>
              </div>
              <div className="form-group mb-4">
                <label>Ceil Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Ceil Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    <Slider xmin={1} xmax={100} x={this.state.configData?.ceil3} onChange={(e) => this.setConfigData(e, 'ceil3', 'rate3')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.ceil3}%`} />
                </div>
              </div>
              <div className="error-txt" id={`${this.state.errorName}3-tunnelConfig`}>Invalid value</div>
              <div className="form-group">
                <label>Priority
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Priority of the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <select className="form-control" onChange={(e) => this.setPriority(e)} name='priority3' value={this.state.configData?.priority3}>
                  <option value="">Select</option>
                  <option value='1' disabled={this.state.usedPriority?.includes('1')}>1</option>
                  <option value='2' disabled={this.state.usedPriority?.includes('2')}>2</option>
                  <option value='3' disabled={this.state.usedPriority?.includes('3')}>3</option>
                  <option value='4' disabled={this.state.usedPriority?.includes('4')}>4</option>
                </select>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  {/* <span className="mr-5">Advance Options */}
                  <span className="mr-5">View Config
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Advance Options">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={this.setAdvanceOption}
                      name="queue3"
                      checked={this.state.openAdvance === true && this.state.queue?.includes("queue3")} />
                    <span className="slider round" ></span>
                  </label>
                </div>
              </div>
              {this.state.openAdvance === true && this.state.queue?.includes("queue3") && <div className="form-group">
                <label>Default Configuration
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Type of Packet passed in the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="dropdown c-dropdown">
                  <div className="dc-value" aria-haspopup="true" aria-expanded="false">
                    {this.state.configData?.config3?.split(',')?.map((item) => {
                      return (<ul className="chip-list radius">
                        <li value={item}>{Common.getConfig(item)}</li>
                      </ul>)
                    })}
                  </div>
                </div>
              </div>}
              <p className="border-bottom pb-1 mt-4"><strong>Queue 4 Transmission Rate</strong></p>
              <div className="form-group mb-4">
                <label>Min Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    <Slider xmin={1} xmax={100} x={this.state.configData?.rate4} onChange={(e) => this.setConfigData(e, 'rate4', 'ceil4')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state?.configData?.rate4}%`} />
                </div>
              </div>
              <div className="form-group mb-4">
                <label>Ceil Rate
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Ceil Rate of transmission for Queue - Percentage of Upload Speed">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="slidecontainer d-flex align-items-center">
                  <div className="rsbox">
                    <Slider xmin={1} xmax={100} x={this.state.configData?.ceil4} onChange={(e) => this.setConfigData(e, 'ceil4', 'rate4')} />
                    <div className="min-value">1%</div>
                    <div className="max-value">100%</div>
                  </div>
                  <input className="form-control" value={`${this.state.configData?.ceil4}%`} />
                </div>
              </div>
              <div className="error-txt" id={`${this.state.errorName}4-tunnelConfig`}>Invalid value</div>
              <div className="form-group">
                <label>Priority
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Priority of the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <select
                  className="form-control"
                  onChange={(e) => this.setPriority(e)}
                  name='priority4'
                  value={this.state.configData?.priority4}>
                  <option value="">Select</option>
                  <option value='1' disabled={this.state?.usedPriority?.includes('1')}>1</option>
                  <option value='2' disabled={this.state?.usedPriority?.includes('2')}>2</option>
                  <option value='3' disabled={this.state?.usedPriority?.includes('3')}>3</option>
                  <option value='4' disabled={this.state?.usedPriority?.includes('4')}>4</option>
                </select>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  {/* <span className="mr-5">Advance Options */}
                  <span className="mr-5">View Config
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Advance Options">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={this.setAdvanceOption}
                      name="queue4"
                      checked={this.state.openAdvance === true && this.state.queue?.includes("queue4")} />
                    <span className="slider round" ></span>
                  </label>
                </div>
              </div>
              {this.state.openAdvance === true && this.state.queue?.includes("queue4") && <div className="form-group">
                <label>Default Configuration
                  <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Type of Packet passed in the Queue">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
                <div className="dropdown c-dropdown">
                  <div className="dc-value" aria-haspopup="true" aria-expanded="false">
                    {this.state.configData?.config4?.split(',')?.map((item) => {
                      return (<ul className="chip-list radius">
                        <li value={item}>{Common.getConfig(item)}</li>
                      </ul>)
                    })}
                  </div>
                </div>
              </div>}
            </div>}
          </div>
          <div className="s-footer">
            <div className="error-txt" id="rate-tunnelConfig">Sum of Queue rate can't exceed 100</div>
            <button className="btn btn-light"
              onClick={() => this.closeConfig()}
            >Cancel</button>
            {<button className="btn btn-primary"
              disabled={this.checkConfigError()}
              onClick={this.addConfigData}
            >{this.state.update ? 'Update' : 'Add'}</button>}
          </div>
        </div>
        {/* add Wireguard Configuration slides close */}
        {
          <div className="s-footer">
            {/* LONG-2989-EM-When Open the Device Qos Template and click the Cancel button it was redirecting to the device Qos Bootstatp template page */}
            <button className="btn btn-light"
              onClick={(this.props.apply && this.props.edit && this.props.reapply) ? () => this.props.closeApplyTemplate() :
                () => this.closeAll()}>{this.props.apply && !this.props.edit && !this.props.reapply ? 'Back' : 'Cancel'}</button>
            <button className="btn btn-primary"
              disabled={this.checkError()}
              onClick={
                this.props.apply ? (event) => this.debounceApply(this.applyTemplate, event) :
                  () => this.saveData()}
            >{this.props.apply ? 'Apply' : (this.state.selectedTemplateId !== '' ? 'Update' : 'Save')}</button>
          </div>
        }
        <div className={this.state.addConfig ? "disabled-slide" : ""}></div>
        {
          (this.state.show) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.resetPopup()}
          />
        }
        {(this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </div >
    );
  }
}
const mapStateToProps = (state) => {
  return {
    applying: state.displayAddTemplate.applying,
    openStaticTemplate: state.displayAddTemplate.openStaticTemplate,
  };
};
export default connect(mapStateToProps)(QOSTemplate);