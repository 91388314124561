import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import urlMapping from "../../redux/API/api";
import apiCall from "../../redux/API/apiCall";
import $ from 'jquery';
import isEqual from 'lodash/isEqual';
import { Loader } from "../../layOuts/Loader";
import { ErrorPopup } from "../../layOuts/errorPopup";
import * as Common from "../../utils/common";

class TunnelsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceID: this.props?.data?.device_id || "",
      deviceIP: this.props?.data?.device_control_tunnel_ip || "",
      deviceMac: this.props.mac || "",
      showLoader: false,
      deviceData: this.props?.data,
      isAxiosError: false,
      wireguardData: [],
      ipsecData: [],
      selectedTab: "Wireguard"
    };
  };

  componentDidMount() {
    this.getTunnelsView();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.data).length !== 0 && !isEqual(this.props.data, nextProps.data)) {
      this.setState({
        deviceData: nextProps.data,
      });
    }
    if (
      (nextProps?.mac !== "" && !isEqual(this.state.deviceMac, nextProps.mac)) ||
      (nextProps?.data?.device_control_tunnel_ip !== "" && !isEqual(this.state.deviceIP, nextProps.mac)) ||
      (nextProps?.data.device_id !== "" && !isEqual(this.state.deviceID, nextProps.mac))
    ) {
      this.setState(
        {
          deviceMac: nextProps?.mac || "",
          deviceIP: nextProps?.data?.device_control_tunnel_ip || "",
          deviceID: nextProps?.data?.device_id || "",
        },
        () => this.getTunnelsView()
      );
    }
  };

  getTunnelsView = () => {
    const data = {
      device_mac: this.state.deviceMac,
    };
    this.setState({ showLoader: true });
    apiCall(urlMapping.getTunnelsView(data), (response) => {
      if (response?.success) {
        const { wireguard_tunnels, ipsec_tunnels } = response?.data || {};
        this.setState({
          wireguardData: wireguard_tunnels || [],
          ipsecData: ipsec_tunnels || [],
          showLoader: false
        });
      } else {
        if (
          parseInt(response?.response?.status) === 401
        ) {
          this.setState({
            showRefreshModal: true,
            showLoader: false
          });
        } else if (
          response?.isAxiosError &&
          response?.success === undefined &&
          response?.response?.data?.success === undefined
        ) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else {
          this.setState({
            showLoader: false,
            wireguardData: [],
            ipsecData: []
          });
        }
      }
    });
  };

  refreshTelemetry = (key, val) => {
    this.setState({ showLoader: true });
    let tunnelData
    if (key === "wireguard") {
      tunnelData = {
        device_A: {
          name: (val.device_A.device_A_name),
          control_ip: val.device_A.device_c_tunnel_ip
        },
        device_B: {
          name: val.device_B.device_B_name,
          control_ip: val.device_B.device_c_tunnel_ip
        },
        interface_name: val.tunnel_interface_name
      }
    }
    const data = {
      device_ip: this.state.deviceIP
    }
    if (key === "wireguard") {
      data.tunnel = tunnelData;
      data.device_id = this.state.deviceID;
    }
    else {
      data.device_mac = this.state.deviceMac;
      data.tunnel_id = val?.raw_json?.vpnConfig?.vpn_data?.tunnel_id;
    }
    apiCall(urlMapping.get_Global_refresh(data), (response) => {
      if (response?.success) {
        this.getTunnelsView();
      }
      else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showLoader: false,
            showRefreshModal: true
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false
          });
        }
      }
    })
  };

  tunnelEdit = (value) => {
    const deviceAStatus = parseInt(value?.device_a_status);
    const deviceBStatus = parseInt(value?.device_b_status);
    let result = false;
    if (deviceAStatus === 1) {
      result = true;
    }
    if (value?.device_b_status !== undefined && value?.device_b_status !== "") {
      if (deviceBStatus === 1) {
        result = true;
      } else {
        result = false;
      }
    }
    if (result) {
      this.props.history.push({
        pathname: '/tunnels',
        state: {
          data: value
        }
      })
    } else {
      this.setState({
        title: "Edit Tunnel",
        message: 'You cannot edit this tunnel as some device is offline. Please make sure all devices in the tunnel are online to edit.',
        show: true,
        error: true
      }, () => {
        $("#errorMessage").modal("show");
      });
    }
  };

  render() {
    return (
      <>
        {this.state.showLoader && <Loader />}
        <div className="no-bg page-height">
          <div className="page-header device-head">
            <ul
              className="tab-nav nav nav-pills troubleshootResponsive ml-0 mr-0"
              id="pills-tab"
              role="tablist"
            >
              {
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    id="pills-wireguard-tab"
                    data-toggle="pill"
                    href="#pills-wireguard"
                    role="tab"
                    aria-controls="pills-wireguard"
                    aria-selected="true"
                    onClick={() =>
                      this.setState({
                        selectedTab: "Wireguard",
                      })
                    }
                  >
                    Wireguard
                  </a>
                </li>
              }
              {
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="pills-ipsec-tab"
                    data-toggle="pill"
                    href="#pills-ipsec"
                    role="tab"
                    aria-controls="pills-ipsec"
                    aria-selected="false"
                    onClick={() => this.setState({ selectedTab: "IPSec" })
                    }
                  >
                    IPSec
                  </a>
                </li>
              }
            </ul>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-content" id="myTabContent">
              <div
                className={this.state.selectedTab === "Wireguard" ? "tab-pane fade show active" : "tab-pane fade show"}
                id="pills-diagnostic"
                role="tabpanel"
                aria-labelledby="pills-diagnostic-tab"
              >
                <div className="ta-box set-pos mb-0">
                  <div className="action-btn d-flex align-items-center">
                    <button
                      className="short-btn"
                      onClick={() => this.getTunnelsView()}
                      title="Refresh"
                    >
                      <img src="/images/svg-icons/refreshing.svg" alt="" /> Refresh
                    </button>
                  </div>
                </div>
                <div className="white-bg pl-2 pr-2">
                  {this.state?.wireguardData?.length > 0
                    ? (
                      <>
                        <div className="networks-view">
                          <div className="row no-gutters">
                            {this.state?.wireguardData?.map((item, index) => {
                              return (
                                <div key={index} className="col-md-4 mb-3">
                                  <div className="network-card rounded">
                                    <div className="network-header tunnel-head rounded-top d-flex justify-content-between p-2">
                                      <div>
                                        Tunnel Name <strong>{item?.tunnel_name}</strong>
                                      </div>
                                      <img
                                        style={{ width: "40px", cursor: "pointer" }}
                                        src="/images/refreshT.png"
                                        title="Refresh Telemetry data"
                                        onClick={() => this.refreshTelemetry("wireguard", item)}
                                      />
                                      <div>
                                        Status{" "}
                                        <strong className="text-center">
                                          <i
                                            className={
                                              (item?.tunnel_status === "Online")
                                                ? "status online"
                                                : "status offline"
                                            }
                                          ></i>
                                        </strong>
                                      </div>
                                      <img
                                        style={{
                                          width: "20px",
                                          cursor: "pointer",
                                          marginRight: "5px",
                                          filter: "invert(29%) sepia(99%) saturate(2901%) hue-rotate(180deg) brightness(92%) contrast(92%)"
                                        }}
                                        src="/images/svg-icons/edit.svg"
                                        onClick={() => this.tunnelEdit(item)}
                                        alt=""
                                      />
                                    </div>
                                    <div className="p-2 tunnel-body">
                                      <ul>
                                        <li className="system-connection">
                                          <span>&nbsp;</span>
                                          <span className="dotted-line">
                                            <img
                                              src={
                                                (item?.tunnel_type === "Hub and spoke" &&
                                                  item?.device_A?.device_A_name ===
                                                  this?.state?.deviceData?.device_name)
                                                  ? "/images/svg-icons/hub.svg"
                                                  : "/images/svg-icons/computer.svg"
                                              }
                                              alt="hub/computer"
                                            />
                                            <div>{item?.device_A?.device_A_name}</div>
                                          </span>
                                          <span>
                                            <img
                                              src={
                                                (item?.tunnel_type === "Hub and spoke" &&
                                                  item?.device_B?.device_B_name ===
                                                  this.state?.deviceData?.device_name)
                                                  ? "/images/svg-icons/hub.svg"
                                                  : "/images/svg-icons/computer.svg"
                                              }
                                              alt="hub/computer"
                                            />
                                            <div>{item?.device_B?.device_B_name}</div>
                                          </span>
                                        </li>
                                        <li>
                                          <span>IP Address:</span>
                                          <strong>{item?.device_A?.ip_address ? item?.device_A?.ip_address : "-"}</strong>
                                          <strong>{item?.device_B?.ip_address ? item?.device_B?.ip_address : "-"}</strong>
                                        </li>
                                        <li>
                                          <span>Interface Name:</span>
                                          <strong>{item?.device_A?.interfaceName ? item?.device_A?.interfaceName : "-"}</strong>
                                          <strong>{item?.device_B?.interfaceName ? item?.device_B?.interfaceName : "-"}</strong>
                                        </li>
                                        <li>
                                          <span>Drop Rate TX:</span>
                                          <strong>{item?.txBytes ? item?.txBytes : "-"}</strong>
                                          <strong>{item?.txBytes ? item?.txBytes : "-"}</strong>
                                        </li>
                                        <li>
                                          <span>Drop Rate RX:</span>
                                          <strong>{item?.rxBytes ? item?.rxBytes : "-"}</strong>
                                          <strong>{item?.rxBytes ? item?.rxBytes : "-"}</strong>
                                        </li>
                                        <li>
                                          <span>Public Key:</span>
                                          <strong>{item?.device_A?.public_key ? item?.device_A?.public_key : "-"}</strong>
                                          <strong>{item?.device_B?.public_key ? item?.device_B?.public_key : "-"}</strong>
                                        </li>
                                        <li>
                                          <span>End Point IP:</span>
                                          <strong>{item?.device_A?.endpoint_ip ? item?.device_A?.endpoint_ip : "-"}</strong>
                                          <strong>{item?.device_B?.endpoint_ip ? item?.device_B?.endpoint_ip : "-"}</strong>
                                        </li>
                                        <li>
                                          <span>Listen Port:</span>
                                          <strong>{item?.device_A?.listen_port ? item?.device_A?.listen_port : "-"}</strong>
                                          <strong>{item?.device_B?.listen_port ? item?.device_B?.listen_port : "-"}</strong>
                                        </li>
                                        <li>
                                          <span>BGP Enable:</span>
                                          <strong>
                                            {(item?.device_A?.bgp === true) ? "Yes" : "No"}
                                          </strong>
                                          <strong>
                                            {(item?.device_B?.bgp === true) ? "Yes" : "No"}
                                          </strong>
                                        </li>
                                        <li>
                                          <span className="braek-word">
                                            Add interface to Zone:
                                          </span>
                                          <strong>
                                            {
                                              (item?.device_A?.interface_to_zone === true)
                                                ? "Enabled"
                                                : "-"
                                            }
                                          </strong>
                                          <strong>
                                            {
                                              (item?.device_B?.interface_to_zone === true)
                                                ? "Enabled"
                                                : "-"
                                            }
                                          </strong>
                                        </li>
                                        <li>
                                          <span className="braek-word">
                                            Device Group Name:
                                          </span>
                                          <strong>
                                            {
                                              item?.device_A?.device_group_name
                                                ? item?.device_A?.device_group_name
                                                : "-"
                                            }
                                          </strong>
                                          <strong>
                                            {
                                              item?.device_B?.device_group_name
                                                ? item?.device_B?.device_group_name
                                                : "-"
                                            }
                                          </strong>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    )
                    : (
                      <div className="no-tunnels">
                        No tunnels found for this device
                      </div>
                    )}
                </div>
              </div>
              <div
                className={this.state.selectedTab === "IPSec" ? "tab-pane fade show active" : "tab-pane fade show"}
                id="pills-ipsec"
                role="tabpanel"
                aria-labelledby="pills-ipsec-tab"
              >
                <div className="ta-box set-pos mb-0">
                  <div className="action-btn d-flex align-items-center">
                    <button
                      className="short-btn"
                      onClick={() => this.getTunnelsView()}
                      title="Refresh"
                    >
                      <img src="/images/svg-icons/refreshing.svg" alt="" /> Refresh
                    </button>
                  </div>
                </div>
                <div className="white-bg pl-2 pr-2">
                  {this.state?.ipsecData?.length > 0
                    ? (
                      <>
                        <div className="networks-view">
                          <div className="row no-gutters">
                            {
                              this.state.ipsecData?.map((item, index) => {
                                return (
                                  <div key={index} className="col-md-4 mb-3">
                                    <div className="network-card rounded">
                                      <div className="network-header tunnel-head rounded-top d-flex justify-content-between p-2">
                                        <div className="word-break">
                                          Tunnel Name <strong>{item?.raw_json?.vpnConfig?.vpn_data?.tunnel_name || "-"}</strong>
                                        </div>
                                        <img
                                          style={{
                                            width: "40px",
                                            cursor: "pointer"
                                          }}
                                          src="/images/refreshT.png"
                                          title="Refresh Telemetry data"
                                          onClick={() => this.refreshTelemetry("ipsec", item)}
                                        />
                                        <div
                                          title={
                                            (
                                              JSON.parse(item?.tunnel_status?.replace(/'/g, '"'))?.IPlist !== "" &&
                                              JSON.parse(item?.tunnel_status?.replace(/'/g, '"'))?.IPlist !== undefined
                                            )
                                              ? (
                                                "The IPSec tunnel with remote subnet"
                                                + (
                                                  (JSON.parse(item?.tunnel_status?.replace(/'/g, '"'))?.IPlist)?.split(",")?.length > 1
                                                    ? "s "
                                                    : " "
                                                )
                                                + (JSON.parse(item?.tunnel_status?.replace(/'/g, '"'))?.IPlist)
                                                + (
                                                  (JSON.parse(item?.tunnel_status?.replace(/'/g, '"'))?.IPlist)?.split(",")?.length > 1
                                                    ? " are "
                                                    : " is "
                                                )
                                                + "not configured correctly"
                                              )
                                              : ""
                                          }
                                        >
                                          Status{" "}
                                          <strong className="text-center">
                                            <i className={
                                              (JSON.parse(item?.tunnel_status?.replace(/'/g, '"'))?.status === "Online")
                                                ? "status online"
                                                : (JSON.parse(item?.tunnel_status?.replace(/'/g, '"'))?.status === "Partial")
                                                  ? "status pending"
                                                  : "status offline"
                                            }></i>
                                          </strong>
                                        </div>
                                        <img
                                          style={{
                                            width: "20px",
                                            cursor: "pointer",
                                            marginRight: "5px",
                                            filter: "invert(29%) sepia(99%) saturate(2901%) hue-rotate(180deg) brightness(92%) contrast(92%)"
                                          }}
                                          src="/images/svg-icons/edit.svg"
                                          onClick={() => this.tunnelEdit(item)}
                                          alt=""
                                        />
                                      </div>
                                      <div className="p-2">
                                        <div className="row">
                                          <div className="col-4 word-break">{""}</div>
                                          <div className="col-4 word-break">Device A</div>
                                          <div className="col-4 word-break">
                                            {
                                              (item?.raw_json?.vpnConfig?.group?.group_name !== undefined)
                                                ? "Group"
                                                : "Device B"
                                            }
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-4 word-break">Device/Group Name</div>
                                          <div className="col-4 word-break"><strong>{item?.raw_json?.vpnConfig?.deviceA?.device_name || "-"}</strong></div>
                                          <div className="col-4 word-break">
                                            <strong>
                                              {
                                                (item?.raw_json?.vpnConfig?.group?.group_name !== undefined)
                                                  ? (item?.raw_json?.vpnConfig?.group?.group_name || "-")
                                                  : (item?.raw_json?.vpnConfig?.deviceB?.device_name || "-")
                                              }
                                            </strong>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-4 word-break">
                                            <span>Local Address:</span>
                                          </div>
                                          <div className="col-4 word-break"><strong>{item?.raw_json?.vpnConfig?.deviceA?.local_ip || "-"}</strong></div>
                                          <div className="col-4 word-break">
                                            <strong>{
                                              (item?.raw_json?.vpnConfig?.group?.group_name !== undefined)
                                                ? (item?.raw_json?.vpnConfig?.group?.local_ip || "-")
                                                : (item?.raw_json?.vpnConfig?.deviceB?.local_ip || "-")
                                            }
                                            </strong>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-4 word-break">
                                            <span>Remote Address:</span>
                                          </div>
                                          <div className="col-4 word-break"><strong>{item?.raw_json?.vpnConfig?.deviceA?.remote_ip || "-"}</strong></div>
                                          <div className="col-4 word-break">
                                            <strong>
                                              {
                                                (item?.raw_json?.vpnConfig?.group?.group_name !== undefined)
                                                  ? (item?.raw_json?.vpnConfig?.group?.remote_ip || "-")
                                                  : (item?.raw_json?.vpnConfig?.deviceB?.remote_ip || "-")
                                              }
                                            </strong>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-4 word-break">
                                            <span>Local Subnet:</span>
                                          </div>
                                          <div className="col-4 word-break"><strong>{(item?.raw_json?.vpnConfig?.deviceA?.local_network?.join(', ')) || "-"}</strong></div>
                                          <div className="col-4 word-break">
                                            <strong>
                                              {
                                                (item?.raw_json?.vpnConfig?.group?.group_name !== undefined)
                                                  ? ((item?.raw_json?.vpnConfig?.group?.local_network?.join(', ')) || "-")
                                                  : ((item?.raw_json?.vpnConfig?.deviceB?.local_network?.join(', ')) || "-")
                                              }
                                            </strong>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-4 word-break">
                                            <span>Remote Subnet:</span>
                                          </div>
                                          <div className="col-4 word-break"><strong>{(item?.raw_json?.vpnConfig?.deviceA?.remote_network)?.join(', ') || "-"}</strong></div>
                                          <div className="col-4 word-break">
                                            <strong>
                                              {
                                                (item?.raw_json?.vpnConfig?.group?.group_name !== undefined)
                                                  ? ((item?.raw_json?.vpnConfig?.group?.remote_network?.join(', ')) || "-")
                                                  : ((item?.raw_json?.vpnConfig?.deviceB?.remote_network?.join(', ')) || "-")
                                              }
                                            </strong>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-4 word-break">
                                            <span>Phase 1 Keylifetime:</span>
                                          </div>
                                          <div className="col-4 word-break"><strong>{item?.raw_json?.vpnConfig?.vpn_data?.keylife1 || "-"}</strong></div>
                                          <div className="col-4 word-break"><strong>{item?.raw_json?.vpnConfig?.vpn_data?.keylife1 || "-"}</strong> </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-4 word-break">
                                            <span>Phase 1 Encryption:</span>
                                          </div>
                                          <div className="col-4 word-break"><strong>{Common.getEncrytion(item?.raw_json?.vpnConfig?.vpn_data?.enc_algo1) || "-"}</strong></div>
                                          <div className="col-4 word-break"><strong>{Common.getEncrytion(item?.raw_json?.vpnConfig?.vpn_data?.enc_algo1) || "-"}</strong> </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-4 word-break">
                                            <span>Phase 1 Hash:</span>
                                          </div>
                                          <div className="col-4 word-break"><strong>{Common.getHash(item?.raw_json?.vpnConfig?.vpn_data?.hash_algo1) || "-"}</strong></div>
                                          <div className="col-4 word-break"><strong>{Common.getHash(item?.raw_json?.vpnConfig?.vpn_data?.hash_algo1) || "-"}</strong> </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-4 word-break">
                                            <span>Phase 1 Diffie-Hellman Group:</span>
                                          </div>
                                          <div className="col-4 word-break"><strong>{Common.getGroupValue(item?.raw_json?.vpnConfig?.vpn_data?.dh_group1) || "-"}</strong></div>
                                          <div className="col-4 word-break"><strong>{Common.getGroupValue(item?.raw_json?.vpnConfig?.vpn_data?.dh_group1) || "-"}</strong> </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-4 word-break">
                                            <span>Phase 2 Keylifetime:</span>
                                          </div>
                                          <div className="col-4 word-break"><strong>{item?.raw_json?.vpnConfig?.vpn_data?.keylife2 || "-"}</strong></div>
                                          <div className="col-4 word-break"><strong>{item?.raw_json?.vpnConfig?.vpn_data?.keylife2 || "-"}</strong> </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-4 word-break">
                                            <span>Phase 2 Encryption:</span>
                                          </div>
                                          <div className="col-4 word-break"><strong>{Common.getEncrytion(item?.raw_json?.vpnConfig?.vpn_data?.enc_algo2) || "-"}</strong></div>
                                          <div className="col-4 word-break"><strong>{Common.getEncrytion(item?.raw_json?.vpnConfig?.vpn_data?.enc_algo2) || "-"}</strong> </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-4 word-break">
                                            <span>Phase 2 Hash:</span>
                                          </div>
                                          <div className="col-4 word-break"><strong>{Common.getHash(item?.raw_json?.vpnConfig?.vpn_data?.hash_algo2) || "-"}</strong></div>
                                          <div className="col-4 word-break"><strong>{Common.getHash(item?.raw_json?.vpnConfig?.vpn_data?.hash_algo2) || "-"}</strong> </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-4 word-break">
                                            <span>Phase 2 Diffie-Hellman Group:</span>
                                          </div>
                                          <div className="col-4 word-break"><strong>{Common.getGroupValue(item?.raw_json?.vpnConfig?.vpn_data?.dh_group2) || "-"}</strong></div>
                                          <div className="col-4 word-break"><strong>{Common.getGroupValue(item?.raw_json?.vpnConfig?.vpn_data?.dh_group2) || "-"}</strong> </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-4 word-break">
                                            <span>DPD Status:</span>
                                          </div>
                                          <div className="col-4">
                                            <label className="switch">
                                              <input
                                                type="checkbox"
                                                checked={String(item?.raw_json?.vpnConfig?.vpn_data?.dpd_enable) === '1'}
                                              />
                                              <span className="slider round" style={{ cursor: "not-allowed" }}>
                                              </span>
                                            </label>
                                          </div>
                                          <div className="col-4">
                                            <label className="switch">
                                              <input
                                                type="checkbox"
                                                checked={String(item?.raw_json?.vpnConfig?.vpn_data?.dpd_enable) === '1'}
                                              />
                                              <span className="slider round"
                                                style={{ cursor: "not-allowed" }}>
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-4 word-break">
                                            <span>DPD Action:</span>
                                          </div>
                                          <div className="col-4 word-break"><strong>{item?.raw_json?.vpnConfig?.vpn_data?.dpd_action || "-"}</strong></div>
                                          <div className="col-4 word-break"><strong>{item?.raw_json?.vpnConfig?.vpn_data?.dpd_action || "-"}</strong> </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-4 word-break">
                                            <span>DPD Delay:</span>
                                          </div>
                                          <div className="col-4 word-break"><strong>{item?.raw_json?.vpnConfig?.vpn_data?.dpd_delay || "-"}</strong></div>
                                          <div className="col-4 word-break"><strong>{item?.raw_json?.vpnConfig?.vpn_data?.dpd_delay || "-"}</strong> </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-4 word-break">
                                            <span>DPD timeout:</span>
                                          </div>
                                          <div className="col-4 word-break"><strong>{item?.raw_json?.vpnConfig?.vpn_data?.dpd_timeout || "-"}</strong></div>
                                          <div className="col-4 word-break"><strong>{item?.raw_json?.vpnConfig?.vpn_data?.dpd_timeout || "-"}</strong> </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </>
                    )
                    : (
                      <div className="no-tunnels">
                        No tunnels found for this device
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div >
        {
          this.state.isAxiosError &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
        {
          this.state.show && (
            <ErrorPopup
              title={this.state.title}
              message={this.state.message}
              error={this.state.error}
              reset={() => this.setState({ show: false }, () => $("#errorMessage").modal("hide"))}
            />
          )
        }
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated
  };
};

export default withRouter(connect(mapStateToProps)(TunnelsView));