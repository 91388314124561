/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as Action from "../../redux/actionTypes";
import SideMenu from "../../layOuts/SideMenu";
import Header from "../../layOuts/Header";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from "../../layOuts/Loader";
import { ErrorPopup } from "../../layOuts/errorPopup";
import AddNewDevice from "./AddNewDevice";
import $ from "jquery";
import moment from "moment";
import * as Constant from "../../utils/constants";
import ErrorHandlePopup from "../ErrorHandlePopup";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import './DataTableDemo.css';

class DeviceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRefreshModal: false,
      multipleSelect: "",
      toggledClearRows: false,
      showLoader: false,
      title: "",
      selectedOption: [],
      message: "",
      show: false,
      error: false,
      delete: false,
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      first: 1,
      last: 10,
      rows: 10,
      next: true,
      prev: true,
      firstDefault: 0,
      sortOn: false,
      deviceData: [],
      favDevices: false,
      favKey: false,
      sortOrder: 1,
      flag: 0,
      sortField: '',
      isAxiosError: false,
      searchValue: "",
      searchClicked: false,
      columns: [
        {
          field: 'device_name',
          header: 'Device Name',
          value: 'Device Name',
          body: this.deviceNameTemplate,
          frozen: false,
          alignFrozen: 'left',
          sortable: true,
          style: { width: '170px', flexGrow: 1, minWidth: '130px' }
        },
        {
          field: 'device_status',
          header: 'Status',
          value: 'Status',
          body: this.statusTemplate,
          frozen: false,
          sortable: true,
          style: { width: '150px', flexGrow: 1, minWidth: '110px' }
        },
        {
          field: 'device_serial',
          header: 'Serial No.',
          value: 'Serial No.',
          body: this.deviceSerialTemplate,
          frozen: false,
          sortable: true,
          style: { width: '170px', flexGrow: 1, minWidth: '130px' }
        },
        {
          field: 'device_mac',
          header: 'MAC Address',
          frozen: false,
          value: 'MAC Address',
          sortable: false,
          body: this.deviceMacTemplate,
          style: { width: '200px', flexGrow: 1, minWidth: '130px' }
        },
        {
          field: 'device_model',
          header: 'Model No.',
          frozen: false,
          value: 'Model No.',
          sortable: false,
          body: this.deviceModelTemplate,
          style: { width: '150px', flexGrow: 1, minWidth: '130px' }
        },
        {
          field: 'device_eth_wan_ip',
          header: 'WAN IP', frozen: false,
          value: 'WAN IP',
          sortable: false,
          body: this.WANIPTemplate,
          style: { width: '200px', flexGrow: 1, minWidth: '130px' }
        },
        {
          field: 'device_imei',
          header: 'Device IMEI',
          frozen: false,
          value: 'Device IMEI',
          sortable: false,
          body: this.deviceImeiTemplate,
          style: { width: '200px', flexGrow: 1, minWidth: '130px' }
        },
        {
          field: 'created_date',
          header: 'Registered on',
          frozen: false,
          value: 'Registered on',
          sortable: true,
          body: this.registeredTemplate,
          style: { width: '220px', flexGrow: 1, minWidth: '180px' }
        },
        {
          field: 'Action',
          header: '',
          body: this.actionTemplate,
          value: 'Action',
          style: { flexGrow: 1, flexBasis: '120px' },
          sortable: false,
          alignFrozen: 'right',
          frozen: true
        }
      ],
    };
  };

  componentDidMount() {
    this.getDeviceList();
    this.closeAddDevice();
    let newArray = this.state.columns;
    const localValue = JSON.parse(localStorage.getItem(Constant.DEVICE_LIST));
    if (!!localValue) {
      newArray = [];
      localValue?.map((item) => {
        const column = this.valueReturn(item);
        if (!!column) newArray.push(column);
      });
    }
    this.setState({ selectedOption: newArray });
  };

  returnClassStatus = (device_status) => {
    switch (device_status) {
      case 0:
        return "status offline";
      case 1:
        return "status online";
      case 2:
        return "status added";
      case 3:
        return "status available";
      case 4:
        return "status approve";
      default:
        return "status offline";
    }
  };

  returnStatus = (device_status) => {
    switch (device_status) {
      case 0:
        return "Offline";
      case 1:
        return "Online";
      case 2:
        return "Added";
      case 3:
        return "Available";
      case 4:
        return "Auto Approved";
      default:
        return "Offline";
    }
  };

  getFavDevices = () => {
    this.setState({
      favDevices: !this.state.favDevices,
      pageNo: 1
    }, () => this.getDeviceList())
  };

  getTime = (time) => {
    let date = moment.utc(time);
    date = date.local();
    return date.format('DD MMMM YYYY hh:mm:ss A');
  };

  newDeviceSort = (columnSort) => {
    if (this.state.flag < 2) {
      this.setState({
        sortField: columnSort.sortField,
        sortOrder: columnSort.sortOrder,
        order: columnSort.sortOrder === 1 ? 'ascending' : 'descending',
        sortOn: true,
        flag: this.state.flag + 1,
      }, () => this.getDeviceList("sort")
      )
    } else {
      this.setState({
        sortField: null,
        order: 'descending',
        sortOn: true,
        flag: 0,
      }, () => this.getDeviceList("sort")
      )
    }
  };

  //Get DeviceType List
  getDeviceList = (sort) => {
    this.setState({
      showLoader: true,
    });
    let sorting_parameter = sort === 'sort' && this.state.sortField !== null ? this.state.sortField : 'created_date'
    let sorting_type = sort === 'sort' ? this.state.order : 'descending'
    let pinned = this.state.favDevices === true ? 1 : 0;
    ApiCall(
      urlMapping.getUnconfiguredDevicelist(
        sorting_parameter,
        sorting_type,
        pinned,
        this.state.pageNo,
        this.state.pageSize
      ),
      (response) => {
        this.setState({
          showLoader: false,
        });
        if (response?.results) {
          const data = response?.results;
          const totalCount = response?.count;
          const first = (this.state.pageSize * (this.state.pageNo - 1)) + 1;
          const last = response?.next ? this.state.pageSize * this.state.pageNo : response?.count;
          if (data) {
            if (data.length === 0 && pinned === 1 && this.state.favKey) {
              this.setState(
                {
                  favDevices: !this.state.favDevices,
                  favKey: !this.state.favKey
                }, () => this.getDeviceList())
            }
            else {
              if (data?.length === 0 && pinned === 1) {
                this.setState(
                  {
                    title: "Set as Favorite",
                    message: "No devices set as favorite to show. Select device and set as favorite from : option",
                    show: true,
                    error: false,
                    delete: false,
                    toggledClearRows: !this.state.toggledClearRows,
                    favDevices: !this.state.favDevices,
                  },
                  () => $("#errorMessage").modal("show")
                );
              } else {
                this.setState({
                  next: response.next ? false : true,
                  prev: response.previous ? false : true,
                  first: first,
                  last: last,
                  deviceData: data,
                  totalCount,
                  toggledClearRows: !this.state.toggledClearRows,
                  multipleSelect: []
                });
              }
            }
          }
        }
        else {
          if (parseInt(response?.response?.status) === 401) {
            this.setState({
              showRefreshModal: true,
              showLoader: false
            })
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState({
              showLoader: false
            })
          }
        }
      }
    );
  };

  setAsFavorite = (pinned) => {
    let data;
    this.setState({ showLoader: true });
    const device_ids = [];
    this.state.multipleSelect.forEach((element) => {
      device_ids.push(element.device_id);
    });
    data = {
      device_ids,
      pinning: pinned === true ? 1 : 0
    };
    ApiCall(
      urlMapping.setAsFavorite(
        data,
      ),
      (response) => {
        if (response?.success) {
          this.setState(
            {
              title: pinned === false ? "Remove from Favorite" : "Set as Favorite",
              message: response?.message,
              show: true,
              error: false,
              showLoader: false,
              favKey: true,
            },
            () => {
              $("#errorMessage").modal("show");
              this.getDeviceList();
            }
          );
        } else {
          if (parseInt(response?.response?.status) === 401) {
            this.setState({ showLoader: false, showRefreshModal: true });
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState(
              {
                title: "Pin Device",
                message: response?.message,
                show: true,
                error: true,
                showLoader: false
              },
              () => {
                $("#errorMessage").modal("show");
              }
            );
          }
        }
      })
  };

  confirmDelete = () => {
    this.setState(
      {
        title:
          this.state.multipleSelect.length > 1
            ? "Delete Devices"
            : "Delete Device",
        message:
          this.state.multipleSelect.length > 1
            ? `This operation will delete the devices from 5G SD EDGE Manager and onboard the devices again when ZTP got triggered from the devices either manually or through a scheduler. Click OK to proceed or click Cancel to cancel the operation?${"\n"}Note: Do Factory Reset from the Devices Local UI to stop ZTP trigger through the scheduler`
            : `This operation will delete the device from 5G SD EDGE Manager and onboard the device again when ZTP got triggered from the device either manually or through a scheduler. Click OK to proceed or click Cancel to cancel the operation?${"\n"}Note: Do Factory Reset from the Device Local UI to stop ZTP trigger through the scheduler`,
        show: true,
        error: true,
        delete: true,
      },
      () => {
        $("#errorMessage").modal("show");
      }
    );
  };

  deleteDevices = async () => {
    $("#errorMessage").modal("hide");
    let data = { device_ids: [] };
    const ids = [];
    this.state.multipleSelect?.forEach((element) => {
      ids?.push(element?.device_id);
    });
    data.device_ids = ids;
    this.setState({
      showLoader: true,
    });
    ApiCall(urlMapping.deleteDevice(data), (response) => {
      if (response?.success) {
        this.setState(
          {
            title: "Delete Devices",
            message: response?.message + ' to operation',
            show: true,
            error: false,
            delete: false,
            toggledClearRows: !this.state.toggledClearRows,
            showLoader: false
          },
          () => {
            $("#errorMessage").modal("show");
            this.getDeviceList();
          }
        );
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showLoader: false,
            showRefreshModal: true
          })
        } else if (
          response?.isAxiosError &&
          response?.success === undefined &&
          response?.response?.data?.success === undefined
        ) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState(
            {
              title: "Delete Devices",
              message: response?.message,
              show: true,
              error: true,
              delete: false,
            },
            () => $("#errorMessage").modal("show")
          );
        }
      }
    });
  };

  resetPopup = () => {
    this.setState(
      {
        show: false,
        error: false,
        delete: false,
        multipleSelect: "",
      },
      () => {
        $("#errorMessage").modal("hide");
      }
    );
  };

  openAddDeviceModel = () => {
    this.props.dispatch({
      type: Action.OPEN_ADD_DEVICE,
    });
  };

  closeAddDevice = () => {
    this.props.dispatch({
      type: Action.CLOSE_ADD_DEVICE,
    });
  };

  handleChange = (e) => {
    let array = [];
    this.state.selectedOption.map((option) => (
      array.push(option.value)
    ));
    const result = e.target.value;
    if (result === "selectAll") {
      array = [];
      if (e.target.checked) {
        this.state.columns.map((column) => (
          array.push(column.value)
        ));
      } else {
        array = ["Action"];
      }
    } else {
      if (e.target.checked) array.push(result);
      else array.splice(array.indexOf(result), 1);
    }
    let availableColumns = [
      "Device Name",
      "Status",
      "Serial No.",
      "MAC Address",
      "Model No.",
      "WAN IP",
      "Device IMEI",
      "Registered on",
      "Action"
    ];
    array = availableColumns?.filter(field => array?.includes(field));
    if (array.includes("Action")) {
      array.splice(array.indexOf("Action"), 1);
      array.push("Action");
    }
    const selectedVlaues = [];
    array.map((item) => {
      const column = this.valueReturn(item);
      selectedVlaues.push(column);
    });
    localStorage.setItem(Constant.DEVICE_LIST, JSON.stringify(array));
    this.setState({ selectedOption: selectedVlaues });
  };

  valueReturn = (item) => {
    return this.state.columns.filter(
      (column) => column.value === item
    )[0];
  };

  resetValue = () => {
    this.setState({
      pageNo: 1
    }, () =>
      this.getDeviceList(),
    );
  };

  deviceNameTemplate = (row) => {
    return (
      <span title={row.device_name} className="fav-icon">
        {
          row.is_pinned &&
          <img src="/images/svg-icons/star-filled-yellow.svg" alt="" />
        }
        {row.device_name !== "" &&
          row.device_name !== "null" &&
          row.device_name !== null
          ? row.device_name
          : "-"}
      </span>
    )
  };

  actionTemplate = (row) => {
    return (
      <div className="da-box dropleft">
        <img
          className="a-dots"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          src="/images/svg-icons/ellipsis.svg"
          alt=""
        />
        <div className="drop-box dropdown-menu">
          <ul>
            <li
              className={
                (
                  this.props?.userRole === "Viewer_Group" ||
                  this.props?.userRole === "Operator_Group"
                )
                  ? "disabled"
                  : ""
              }
              onClick={() =>
                (
                  this.props?.userRole === "Viewer_Group" ||
                  this.props?.userRole === "Operator_Group"
                )
                  ? undefined
                  : this.setState({ multipleSelect: [row] },
                    () => this.confirmDelete())
              }
            >
              <img
                className="delete"
                src="/images/svg-icons/delete.svg"
                alt=""
              />{" "}
              Delete
            </li>
            <li
              onClick={() =>
                this.setState({ multipleSelect: [row] },
                  () => this.setAsFavorite(row.is_pinned ? false : true))}
            >
              {
                row.is_pinned
                  ? <img src="/images/svg-icons/star.svg" alt="" />
                  : <img src="/images/svg-icons/star-filled.svg" alt="" />
              }
              {" "}
              {row.is_pinned ? "Remove from Favorite" : "Set as Favorite"}
            </li>
          </ul>
        </div>
      </div>
    )
  };

  statusTemplate = (row) => {
    return (
      <div id={`col${row.device_id}`}>
        <span id={`icon${row.device_id}`}> <i className={this.returnClassStatus(row.device_status)}></i>
          {this.returnStatus(row.device_status)}</span>
      </div>
    )
  };

  registeredTemplate = (row) => {
    return (
      <span className="date-time" title={this.getTime(row.created_date)} >
        {this.getTime(row.created_date)}
      </span>
    )
  };

  deviceSerialTemplate = (row) => {
    return (
      <span title={row.device_serial}>
        {(row.device_serial !== "" && row.device_serial !== "null" && row.device_serial !== null) ? row.device_serial : '-'}</span>
    )
  };

  deviceModelTemplate = (row) => {
    return (
      <span title={row.device_model}>
        {(row.device_model !== "" && row.device_model !== "null" && row.device_model !== null) ? row.device_model : '-'}</span>
    )
  };

  deviceImeiTemplate = (row) => {
    return (
      <span title={row.device_imei}>
        {(row.device_imei !== "" && row.device_imei !== "null" && row.device_imei !== null) ? row.device_imei : '-'}</span>
    )
  };

  WANIPTemplate = (row) => {
    return (
      <span title={row.device_eth_wan_ip}>
        {(row.device_eth_wan_ip !== "" && row.device_eth_wan_ip !== "null" && row.device_eth_wan_ip !== null) ? row.device_eth_wan_ip : '-'}</span>
    )
  };

  deviceMacTemplate = (row) => {
    return (
      <span title={row.device_mac.toUpperCase()}>
        {row.device_mac.toUpperCase()}</span>
    )
  };

  onCancel = () => {
    this.setState(
      {
        title: "",
        message: "",
        show: false,
        error: false,
      }, () => this.deleteDevices()
    );
  };

  onSelectRow = (e) => {
    if (e.value.length === 0) {
      this.setState({
        multipleSelect: ''
      })
    } else {
      this.setState({
        multipleSelect: e.value
      })
    }
  };

  setPerPage = (e) => {
    this.setState({
      pageSize: e.value
    }, () => {
      this.state.sortOn === true && !this.state.searchClicked
        ? this.getDeviceList('sort')
        : (this.state.sortOn === true && this.state.searchClicked
          ? this.handleSearch('sort')
          : (this.state.searchClicked
            ? this.handleSearch()
            : this.getDeviceList()
          )
        )
    });
  };

  nextPageChangeHandle = () => {
    this.setState({
      pageNo: this.state.pageNo + 1
    }, () => {
      this.state.sortOn === true && !this.state.searchClicked
        ? this.getDeviceList('sort')
        : (this.state.sortOn === true && this.state.searchClicked
          ? this.handleSearch('sort')
          : (this.state.searchClicked
            ? this.handleSearch()
            : this.getDeviceList()
          )
        )
    });
  };

  prevPageChangeHandle = () => {
    this.setState({
      pageNo: this.state.pageNo - 1
    }, () => {
      this.state.sortOn === true && !this.state.searchClicked
        ? this.getDeviceList('sort')
        : (this.state.sortOn === true && this.state.searchClicked
          ? this.handleSearch('sort')
          : (this.state.searchClicked
            ? this.handleSearch()
            : this.getDeviceList()
          )
        )
    });
  }

  firstPageChangeHandle = () => {
    this.setState({
      pageNo: 1
    }, () => {
      this.state.sortOn === true && !this.state.searchClicked
        ? this.getDeviceList('sort')
        : (this.state.sortOn === true && this.state.searchClicked
          ? this.handleSearch('sort')
          : (this.state.searchClicked
            ? this.handleSearch()
            : this.getDeviceList()
          )
        )
    });
  }

  lastPageChangeHandle = () => {
    this.setState({
      pageNo: Math.ceil(this.state.totalCount / this.state.pageSize)
    }, () => {
      this.state.sortOn === true && !this.state.searchClicked
        ? this.getDeviceList('sort')
        : (this.state.sortOn === true && this.state.searchClicked
          ? this.handleSearch('sort')
          : (this.state.searchClicked
            ? this.handleSearch()
            : this.getDeviceList()
          )
        )
    });
  };

  handleOnChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
    if (e.target.value === "") {
      this.state.sortOn === true ? this.getDeviceList('sort') : this.getDeviceList();
    }
  };

  handleKeypress = (e) => {
    if (e.key === 'Enter') {
      this.setState(
        {
          pageSize: 10,
          searchClicked: true,
          pageNo: 1
        },
        () => { this.state.sortOn === true ? this.handleSearch('sort') : this.handleSearch() });
    }
  };

  handleSearch = (sort) => {
    this.setState({
      showLoader: true
    });
    let data = {
      device_data: this.state.searchValue,
      sorting_parameter: sort === 'sort' && this.state.sortField !== null ? this.state.sortField : 'created_date',
      sorting_type: sort === 'sort' ? this.state.order : 'descending',
      pinned: this.state.favDevices === true ? 1 : 0,
      approved: 0
    };
    ApiCall(
      urlMapping.searchDeviceList(
        this.state.pageNo,
        this.state.pageSize,
        data
      ),
      (response) => {
        if (response) {
          if (response?.results) {
            const first =
              this.state.pageSize * (this.state.pageNo - 1) + 1;
            const last = response.next
              ? this.state.pageSize * this.state.pageNo
              : response?.count;
            this.setState(
              {
                showLoader: false,
                next: response?.next ? false : true,
                prev: response?.previous ? false : true,
                first: first,
                last: last,
                deviceData: response?.results.filter(
                  (item) => item.device_delete_status === 0
                ),
                totalCount: response?.count,
              }
            );
          } else {
            if (parseInt(response?.response?.status) === 401) {
              this.setState({ showRefreshModal: true });
            }
            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
              this.setState({
                title: 'Network Error',
                message: 'Something went wrong. Please try again later.',
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"))
            }
          }
        }
      }
    )
  };

  clearSearch = () => {
    this.setState(
      {
        searchValue: "",
        pageNo: 1,
        searchClicked: false
      },
      () => { this.state.sortOn === true ? this.getDeviceList('sort') : this.getDeviceList() });
  };

  generateActivation = () => {
    this.setState({ showLoader: true });
    const device_ids = [];
    this.state.multipleSelect.forEach((element) => {
      device_ids.push(element.device_id);
    });
    const data = {
      device_id: device_ids
    }
    ApiCall(urlMapping.activationKey(data), (response) => {
      if (response) {
        this.setState({
          showLoader: false,
          multipleSelect: "",
        });
        const csvLines = Object.values(response).join('').split('\n');
        if (csvLines[csvLines.length - 1].includes('[object Object]')) {
          csvLines.pop();
        }
        const csvData = csvLines.join('\n');
        const blobData = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        const url = window.URL.createObjectURL(blobData);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Activation_Code.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({ showRefreshModal: true, showLoader: false });
        } else if (
          response?.isAxiosError &&
          response?.success === undefined &&
          response?.response?.data?.success === undefined
        ) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true,
            multipleSelect: "",
          }, () => {
            $("#errorMessage").modal("show");
          });
        } else {
          this.setState({
            showLoader: false,
            multipleSelect: "",
          });
        }
      }
    });
  };

  render() {

    let colArray = this.state.selectedOption.length === 0 ? this.state.columns : this.state.selectedOption;

    const dynamicColumns = colArray.map((col, i) => {
      return <Column key={col.field} field={col.field} resizable={col.resizable}
        sortable={col.sortable}
        header={col.header} body={col.body} style={col.style} alignFrozen={col.alignFrozen} frozen={col.frozen} />;
    });

    const template2 = {
      layout: 'RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink',
      'RowsPerPageDropdown': () => {
        const dropdownOptions = [
          { label: "10", value: 10 },
          { label: "20", value: 20 },
          { label: "30", value: 30 },
          { label: "40", value: 40 },
          { label: "50", value: 50 }
        ];

        return (
          <React.Fragment>
            <span
              className="p-mx-1"
              style={{ color: 'var(--text-color)', userSelect: 'none' }}
            >Rows per page: </span>
            <Dropdown
              value={this.state.pageSize}
              options={dropdownOptions}
              onChange={(e) => this.setPerPage(e)}
            />
          </React.Fragment>
        );
      },

      'CurrentPageReport': () => {
        return (
          <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
            {this.state.first} - {this.state.last} of {this.state.totalCount}
          </span>
        )
      },

      'FirstPageLink': () => {
        return (
          <button type="button"
            className=" p-link1"
            onClick={() => this.firstPageChangeHandle()}
            disabled={this.state.prev}
          >
            <img
              className={this.state.prev ? "p-link3" : "p-link2"}
              src={this.state.prev ? "images/svg-icons/arrow-pre-disabled.svg" : "/images/svg-icons/arrow-pre.svg"}
              alt=""
            />
          </button>
        )
      },

      'PrevPageLink': () => {
        return (
          <button type="button"
            className=" p-link1"
            onClick={() => this.prevPageChangeHandle()}
            disabled={this.state.prev}
          >
            <i className="pi pi-chevron-left"></i>
          </button>
        )
      },

      'NextPageLink': () => {
        return (
          <button
            type="button"
            className="p-link1"
            onClick={() => this.nextPageChangeHandle()}
            disabled={this.state.next}
          >
            <i className="pi pi-chevron-right"></i>
          </button>
        )
      },

      'LastPageLink': () => {
        return (
          <button type="button"
            onClick={() => this.lastPageChangeHandle()}
            className=" p-link1"
            disabled={this.state.next}
          >
            <img
              className={this.state.next ? "p-link3" : "p-link2"}
              src={this.state.next ? "images/svg-icons/arrow-next-disabled.svg" : "/images/svg-icons/arrow-next.svg"}
              alt=""
            />
          </button>
        )
      },
    };

    return (
      <div>
        <SideMenu />
        {this.state.showLoader && <Loader />}
        <section
          id="rhsMain"
          className={this.props.open ? "rhs-main" : "rhs-main expand-w"}
        >
          <Header />
          <div className="white-bg  page-height">
            <div className="ta-box">
              <h1 className="page-title">
                Onboarding Devices{" "}
                <img
                  src="/images/svg-icons/add.svg"
                  className={
                    (this.props?.userRole === "Viewer_Group" ||
                      this.props?.userRole === "Operator_Group")
                      ? "add-btn disabled"
                      : "add-btn"
                  }
                  alt=""
                  onClick={() =>
                    (this.props?.userRole === "Viewer_Group" ||
                      this.props?.userRole === "Operator_Group")
                      ? undefined
                      : this.openAddDeviceModel()
                  }
                />
                <span
                  className="fav-list"
                  onClick={this.getFavDevices}>
                  {
                    this.state.favDevices
                      ? <img src="/images/svg-icons/star-filled-yellow.svg" alt="" />
                      : <img src="/images/svg-icons/star.svg" alt="" />
                  }
                  Favorite
                </span>
              </h1>
              <div className="action-btn d-flex">
                <div className="search-box">
                  <input
                    type="text"
                    className="show"
                    placeholder="Enter the WAN IP, IMEI, or MAC address"
                    onChange={(e) => this.handleOnChange(e)}
                    value={this.state.searchValue}
                    onKeyPress={(e) => this.handleKeypress(e)}
                  />
                  {
                    this.state.searchValue &&
                    <img
                      className="clear-icon show"
                      src="/images/svg-icons/cancel.svg"
                      onClick={() => this.clearSearch()}
                      alt=""
                    />
                  }
                  <button
                    className="search-btn"
                    disabled={this.state.searchValue === ""}
                    onClick={() => {
                      this.setState(
                        {
                          pageSize: 10,
                          searchClicked: true,
                          pageNo: 1
                        },
                        () => {
                          (this.state.sortOn === true)
                            ? this.handleSearch('sort')
                            : this.handleSearch()
                        });
                    }
                    }
                  >
                    <img src="/images/svg-icons/search.svg" alt="" />
                  </button>
                </div>
                <button
                  onClick={() =>
                    this.setState({
                      searchValue: "",
                      pageNo: 1,
                      searchClicked: false
                    }, () => {
                      (this.state.sortOn === true)
                        ? this.getDeviceList('sort')
                        : this.getDeviceList()
                    })
                  }
                  title="Refresh">
                  <img src="/images/svg-icons/refreshing.svg" alt="" />
                  {/* Refresh */}
                </button>
                <button
                  className="button-lst"
                  disabled={
                    this.state.multipleSelect === "" ||
                    this.state.favDevices ||
                    this.state.multipleSelect.filter(item => item.is_pinned === false).length === 0
                  }
                  onClick={() => this.setAsFavorite(true)}
                  title="Set As Favorite"
                >
                  <img src="/images/svg-icons/star-filled.svg" alt="" />
                </button>
                <button
                  className="button-lst"
                  disabled={
                    this.state.multipleSelect === "" ||
                    this.state.multipleSelect.filter(item => item.is_pinned === true).length === 0
                  }
                  onClick={() => this.setAsFavorite(false)}
                  title="Remove from Favorite"
                >
                  <img src="/images/svg-icons/star.svg" alt="" />
                </button>
                <button
                  className="button-lst"
                  disabled={
                    this.state.multipleSelect.length === 0 ||
                    (this.props?.userRole === "Viewer_Group" ||
                      this.props?.userRole === "Operator_Group")
                  }
                  onClick={() => this.confirmDelete()} title="Delete"
                >
                  <img src="/images/svg-icons/delete.svg" alt="" />
                  {/* Delete */}
                </button>
                <button
                  className="button-lst"
                  disabled={
                    String(this.state.multipleSelect.length) === "0" ||
                    (this.state.multipleSelect.filter((val) => (val?.device_mac !== "")))?.length !== 0
                  }
                  onClick={() => this.generateActivation()}
                  title="Download the activation key"
                >
                  <img src="/images/svg-icons/download-arrow.svg" />
                </button>
                <button
                  className="dropdown-toggle filter-btn"
                  data-toggle="dropdown" title="Columns"
                >
                  <img src="/images/svg-icons/columns.svg" alt="" />
                  {/* Columns */}
                </button>
                <div
                  className="filter-section dropdown-menu dropdown-menu-right"
                  onChange={(e) => this.handleChange(e)}
                >
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="selectAll"
                      checked={
                        this.state.selectedOption.length ===
                        this.state.columns.length
                      }
                      style={{ cursor: "pointer" }}
                    />
                    <label className="form-check-label">Select All</label>
                  </div>
                  {
                    this.state.columns.map((item, index) => {
                      if (item.value !== "Action")
                        return (
                          <div className="form-check" key={index}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={item.value}
                              checked={JSON.stringify(this.state.selectedOption)?.includes(item?.value)}
                              style={{ cursor: "pointer" }}
                            />
                            <label className="form-check-label">
                              {item.value}
                            </label>
                          </div>
                        );
                    })}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Action"
                      checked={true}
                      disabled
                      style={{ cursor: "not-allowed" }}
                    />
                    <label className="form-check-label">Action</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <DataTable
                value={this.state.deviceData}
                onSort={(columnSort) => {
                  this.newDeviceSort(columnSort);
                }}
                sortField={this.state.sortField}
                sortOrder={this.state.sortOrder}
                scrollable scrollHeight="400px"
                loading={this.state.loading}
                removableSort
                resizableColumns
                columnResizeMode="fit"
                showGridlines
                paginator
                paginatorTemplate={template2}
                first={this.state.firstDefault}
                rows={this.state.pageSize}
                paginatorClassName="p-jc-end"
                selectionMode="checkbox"
                selection={this.state.multipleSelect}
                onSelectionChange={e => this.onSelectRow(e)}
                dataKey="device_id"
                scrollDirection="both"
                className="p-mt-3">
                <Column selectionMode="multiple" style={{ flexGrow: 1, flexBasis: '80px' }} ></Column>
                {dynamicColumns}
              </DataTable>
            </div>
            {
              this.props?.openAddDevice &&
              <AddNewDevice
                getDeviceList={() => this.getDeviceList()}
              />
            }
          </div>
        </section>
        {
          this.state.show &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            onCancel={() => this.onCancel()}
            reset={() => this.resetPopup()}
            onSubmit={
              this.state.delete
                ? () => this.deleteDevices()
                : undefined
            }
          />
        }
        {
          this.state.showRefreshModal &&
          <ErrorHandlePopup />
        }
        {
          this.state.isAxiosError &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    open: state.displaySidebarMenu.openSide,
    openAddDevice: state.displayAddDevice.openAddDevice,
    userRole: String(state.auth.role)
  };
};

export default withRouter(connect(mapStateToProps)(DeviceList));