import React, { Component } from "react";
// import * as Action from '../../redux/actionTypes';
import { connect } from "react-redux";
import StepWizard from "react-step-wizard";
import PfApiCall from "../../../redux/API/pfApiCall";
import pfUrlMapping from "../../../redux/API/pfAPI";
import ApiCall from "../../../redux/API/apiCall";
import urlMapping from "../../../redux/API/api";
import { Loader } from '../../../layOuts/Loader';
import $ from 'jquery';
import { ErrorPopup } from '../../../layOuts/errorPopup';
import ErrorHandlePopup from "../../ErrorHandlePopup";
import Interfaces from "./interfaces";
import Authorization from "./authorization";
import ConfigurePF from "./ConfigurePF";
import {Buffer} from 'buffer';

class AAAServer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            showLoader: false,
            title: '',
            message: '',
            show: false,
            error: false,
            selected: 1,
            task_id: '',
            userPassword: '',
            configuring: false,
            gateway: '',
            hostname: '',
            interfaceData: [],
            test_root: false,
            test_pf: false,
            root_password: '',
            moveToAAA: false,
            isAxiosError: false,
        }
    }
    componentDidMount() {
        if (this.props.server_ip !== '') {
            this.refreshPage();
        }
    }
    refreshPage = () => {
        this.getGateway();
        this.getHostname();
        this.getDNS();
        this.getInterfaces();
    }
    getGateway = () => {
        this.setState({
            showLoader: true,
        });
        PfApiCall(pfUrlMapping.getGateway(), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response.status === 200 || response.status === "200") {
                const data = response.item;
                this.setState({
                    gateway: data,
                })
            }
        });
    }
    getHostname = () => {
        this.setState({
            showLoader: true,
        });
        PfApiCall(pfUrlMapping.getHostname(), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response.status === 200 || response.status === "200") {
                const data = response.item;
                this.setState({
                    hostname: data,
                })
            }
        });
    }
    getDNS = () => {
        this.setState({
            showLoader: true,
        });
        PfApiCall(pfUrlMapping.getDNS(), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response.status === 200 || response.status === "200") {
                const data = response.dns_servers;
                this.setState({
                    DNS: data,
                })
            }
        });
    }
    getInterfaces = () => {
        this.setState({
            showLoader: true,
        });
        PfApiCall(pfUrlMapping.getInterfaces(), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response.status === 200 || response.status === "200") {
                const data = response.items;
                this.setState({
                    interfaceData: data,
                })
            }
        });
    }
    setDNS = () => {
        const data = {
            dns_servers: this.state.DNS
        }
        this.setState({
            showLoader: true,
        });
        PfApiCall(pfUrlMapping.setDNS(data), (response) => {
            this.setState({
                showLoader: false,
            });
        });
    }
    start = () => {
        this.setState({
            showLoader: true,
        });
        PfApiCall(pfUrlMapping.start(), (response) => {
            this.setState({
                showLoader: false,
            });
        });
    }
    getSummary = () => {
        this.setState({
            showLoader: true,
        });
        PfApiCall(pfUrlMapping.getSummary(), (response) => {
            this.setState({
                showLoader: false,
            });
        });
    }
    getGeneralData = () => {
        this.setState({
            showLoader: true,
        });
        PfApiCall(pfUrlMapping.getGeneralData(), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response.status === 200 || response.status === "200") {
                const data = response.item;
                this.setState({
                    generalData: data
                })
            }
        });
    }
    getAlertingData = () => {
        this.setState({
            showLoader: true,
        });
        PfApiCall(pfUrlMapping.getAlertingData(), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response.status === 200 || response.status === "200") {
                const data = response.item;
                this.setState({
                    AlertingData: data
                })
            }
        });
    }
    getAdminData = () => {
        this.setState({
            showLoader: true,
        });
        PfApiCall(pfUrlMapping.getAdminData(), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response.status === 200 || response.status === "200") {
                const data = response.item;
                this.setState({
                    AdminData: data
                })
            }
        });
    }
    getStatusData = () => {
        this.setState({
            showLoader: true,
        });
        PfApiCall(pfUrlMapping.getStatusData(), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response.status === 200 || response.status === "200") {
                const data = response;
                this.setState({
                    satusData: data
                })
            }
        });
    }
    getDatabaseData = () => {
        this.setState({
            showLoader: true,
        });
        PfApiCall(pfUrlMapping.getDBData(), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response.status === 200 || response.status === "200") {
                const data = response.item;
                this.setState({
                    DBData: data
                })
            }
        });
    }
    resetPopup = () => {
        if (this.state.moveToAAA) {
            this.props.setSelectedTab();
        }
        this.setState({
            show: false,
            error: false,
            moveToAAA: false,
        }, () => $("#errorMessage").modal("hide"))
    }
    stepChange = (currentStep, pass) => {
        if (currentStep === 1) {
            this.setState({
                selected: currentStep
            })
        } else if (currentStep === 2) {
            this.setDNS();
            this.start();
            this.getSummary();
            this.getGeneralData();
            this.getAdminData();
            this.getAlertingData();
            this.getStatusData();
            this.getDatabaseData();
            this.test();
            this.setState({
                selected: currentStep
            });
        } else if (currentStep === 3 && pass) {
            this.setState({
                userPassword: pass
            }, () => {
                this.installation();
            })
        }
    }
    startPolling = () => {
        let data = {};
        if (this.state.task_id !== '') {
            this.setState({
                showLoader: true
            })
            PfApiCall(pfUrlMapping.startPolling(this.state.task_id), (response) => {
                if (response.status === "200" || response.status === 200) {
                    data.database = "pf";
                    data.pf_password = this.state.password;
                    data.pf_username = "pf";
                    data.root_password = this.state.password;
                    data.root_username = "root";
                    PfApiCall(pfUrlMapping.assign(data), (response) => {
                        if (response.status === 200 || response.status === "200") {
                            this.savePassword();
                        } else if (response.message === 'Request failed with status code 500') {
                            // will come in this block as it is 
                            ApiCall(urlMapping.authServerConfiguration(), (response) => {
                                if (response?.success) {
                                    data.database = "pf";
                                    data.pf_password = this.state.root_password.trim();
                                    data.pf_username = "pf";
                                    data.root_password = this.state.root_password.trim();
                                    data.root_username = "root";
                                    PfApiCall(pfUrlMapping.assign(data), (response) => {
                                        if (response.status === 200 || response.status === "200") {
                                            this.savePassword();
                                        }
                                    })
                                }
                                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                                    this.setState({
                                        title: 'Network Error',
                                        message: 'Something went wrong. Please try again later.',
                                        showLoader: false,
                                        isAxiosError: true,
                                        delete: false,
                                        error: true
                                    }, () => $("#errorMessage").modal("show"))
                                }
                                else {
                                    data.database = "pf";
                                    data.pf_password = this.state.root_password.trim();
                                    data.pf_username = "pf";
                                    data.root_password = this.state.root_password.trim();
                                    data.root_username = "root";
                                    PfApiCall(pfUrlMapping.assign(data), (response) => {
                                        if (response.status === 200 || response.status === "200") {
                                            this.savePassword();
                                        }
                                    })
                                    // Pf DB already configured so save password directly
                                }
                            })
                        }
                    })
                } else {
                    this.setState({
                        showLoader: false
                    })
                }
            })
        }
    }
    generatePass = () => {
        const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const passwordLength = 9;
        let password = "";
        for (var i = 0; i <= passwordLength; i++) {
            var randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber + 1);
        }
        return password;
    }
    savePassword = () => {
        const data = {
            pid: 'admin',
            password: this.state.userPassword,
            quiet: true
        }
        PfApiCall(pfUrlMapping.savePassword(data), (response) => {
            if (response.status === 200 || response.status === "200") {
                PfApiCall(pfUrlMapping.getAdvanced(data), (response) => {
                    if (response.status === 200 || response.status === "200") {
                        this.setState({
                            advancedData: response.item,
                            showLoader: false,
                            selected: 3
                        }
                            // , () => {
                            //     PfApiCall(pfUrlMapping.restartPF(), (response) => {
                            //         if (response) {
                            //             this.setState({
                            //                 selected: 3,
                            //                 showLoader: false
                            //             })
                            //         } else {
                            //             // alert('failed')
                            //             this.setState({
                            //                 showLoader: false
                            //             })
                            //         }
                            //     })
                            // }
                        )
                    }
                })
            } else {
                this.setState({
                    title: 'Auth Server Configuration',
                    message: "Unable to configure authentication server. Please try again",
                    show: true,
                    error: true,
                    showLoader: false
                }, () => {
                    $("#errorMessage").modal("show");
                })
            }
        })
    }
    test = () => {
        let data = { username: 'root' };
        this.setState({
            showLoader: true,
        });
        PfApiCall(pfUrlMapping.test(data), (response) => {
            if (response.status === 200 || response.status === "200") {
                this.setState({
                    test_root: false
                })
                data.database = "pf";
                PfApiCall(pfUrlMapping.test(data), (response) => {
                    if (response.status === 200 || response.status === "200") {

                        data.password = "";
                        PfApiCall(pfUrlMapping.test(data), (response) => {
                            if (response) {
                                this.setState({
                                    showLoader: false,
                                });
                            } else {
                                // alert('failed')
                                this.setState({
                                    showLoader: false
                                })
                            }
                        })
                    } else if (response.status === "500") {
                        this.setState({
                            test_pf: true
                        })
                    }
                })
            } else if (response.status === "500") {
                this.setState({
                    test_root: true
                })
            }
        });
    }
    installation = () => {
        const password = this.generatePass();
        let data = {
            username: 'root',
            password
        };
        this.setState({
            showLoader: true,
            password,
            root_password: password
        });
        PfApiCall(pfUrlMapping.secureInstallation(data), (response) => {
            if (response.status === 200 || response.status === "200") {
                const user_data = {
                    hostname: this.state.hostname,
                    root_password: window.btoa(password.trim()),
                }
                ApiCall(urlMapping.savePFAdminUser(user_data), (response) => {
                    if (response?.success) {
                        this.setState({
                            showLoader: true,
                        });
                        data.async = true;
                        data.database = "pf";
                        this.setState({
                            showLoader: false
                        })
                        PfApiCall(pfUrlMapping.createDB(data), (response) => {
                            if (response.task_id) {
                                this.setState({
                                    task_id: response.task_id
                                }, () => {
                                    this.startPolling();
                                })
                            } else {
                                this.setState({
                                    showLoader: false
                                })
                            }
                        })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                })
            } else if (response.message === 'Request failed with status code 500') {
                // it will fail everytime because we are configuring server from device side Release-20.1
                // if installed already call createDB
                ApiCall(urlMapping.authServerConfiguration(), (response) => {
                    if (response?.success) {
                        data.password = window.atob(response?.data?.settings?.root_password?.trim());
                        data.async = true;
                        data.database = "pf";
                        this.setState({
                            showLoader: true,
                            root_password: window.atob(response?.data?.settings?.root_password?.trim())
                        });
                        PfApiCall(pfUrlMapping.createDB(data), (response) => {
                            if (response.task_id) {
                                this.setState({
                                    task_id: response.task_id
                                }, () => {
                                    this.startPolling();
                                })
                            } else {
                                this.setState({
                                    showLoader: false
                                })
                            }
                        })
                    } else {
                        // come to this case everytime as server is not configured from EM side
                        if (response?.data) {
                            data.password = window.atob(response?.data?.[0]?.pf_root_password?.trim());
                            data.async = true;
                            data.database = "pf";
                            this.setState({
                                showLoader: true,
                                root_password: window.atob(response?.data?.[0]?.pf_root_password?.trim())
                            });
                            PfApiCall(pfUrlMapping.createDB(data), (response) => {
                                if (response.task_id) {
                                    this.setState({
                                        task_id: response.task_id
                                    }, () => {
                                        this.startPolling();
                                    })
                                } else {
                                    // alert('failed')
                                    this.setState({
                                        showLoader: false
                                    })
                                }
                            })
                        }
                        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                            this.setState({
                                title: 'Network Error',
                                message: 'Something went wrong. Please try again later.',
                                showLoader: false,
                                isAxiosError: true,
                                delete: false,
                                error: true
                            }, () => $("#errorMessage").modal("show"))
                        }
                        else {
                            this.setState({
                                title: 'Auth Server Configuration',
                                message: response?.message,
                                show: true,
                                error: true,
                                showLoader: false
                            }, () => {
                                $("#errorMessage").modal("show");
                            })
                        }
                    }
                })
            }

        })
    }
    encodePassword = () => {
        const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const passwordLength = 6;
        let password = "";
        for (var i = 0; i < passwordLength; i++) {
            var randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber + 1);
        }
        password = password.concat(this.state.userPassword);
        let buff = Buffer(password);
        return buff.toString('base64');
    }
    continuePolling = (task_id) => {
        let polling = setInterval(() => {
            PfApiCall(pfUrlMapping.startPolling(task_id), (response) => {
                if (response.progress === "100" && response.message === "Completed") {
                    let data = this.state.advancedData;
                    data.configurator = "disabled"
                    PfApiCall(pfUrlMapping.setAdvanced(data), async (response) => {
                        if (response.status === 200 || response.status === "200") {
                            await clearInterval(polling);
                            await this.setUser();
                        } else {
                            this.setState({
                                showLoader: false
                            })
                        }
                    })
                } else {
                    if (parseInt(response.status) !== 202) {
                        this.setState({
                            showLoader: false
                        })
                        this.setUser()
                    }
                }
            })
        }, 10000);
    }
    setUser = () => {
        const data = {
            username: "admin",
            password: this.encodePassword(),
            hostname: this.state.hostname,
            root_password: window.btoa(this.state.root_password),
            is_auth_server_configured: true,
        }
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.savePFAdminUser(data), (response) => {
            this.setState({
                showLoader: false
            })
            if (response?.success) {
                this.setState({
                    configuring: false,
                    title: 'Auth Server Configuration',
                    message: "Authentication server configuration completed successfully!",
                    show: true,
                    error: false,
                    moveToAAA: true,
                }, () => {
                    $("#errorMessage").modal("show");
                })
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }
    startConfigure = () => {
        this.setState({
            showLoader: true,
            configuring: true
        });
        PfApiCall(pfUrlMapping.startConfig(), (response) => {
            if (response.status === 200 || response.status === "200") {
                const data = { async: true };
                PfApiCall(pfUrlMapping.updateSystem(data), (response) => {
                    if (response.task_id) {
                        this.setState({
                            task_id: response.task_id
                        }, () => {
                            let polling = setInterval(() => {
                                PfApiCall(pfUrlMapping.startPolling(response.task_id), (response) => {
                                    if (response.status === "200" || response.status === 200) {
                                        clearInterval(polling);
                                        PfApiCall(pfUrlMapping.restart(data), (response) => {
                                            if (response.task_id) {
                                                this.setState({
                                                    task_id: response.task_id
                                                }, () => {
                                                    this.continuePolling(response.task_id);
                                                })
                                            } else {
                                                this.setState({
                                                    showLoader: false
                                                })
                                            }
                                        })
                                    } else {
                                        if (parseInt(response.status) !== 202)
                                            this.setState({
                                                showLoader: false
                                            })
                                    }
                                })
                            }, 10000)
                        })
                    } else {
                        this.setState({
                            showLoader: false
                        })
                    }
                })
            }
        })
    }
    render() {
        return (
            <div>
                <div className={this.props.open ? "slide-overlay open" : "slide-overlay"}></div>
                <div className={this.props.open ? "sidebar-slide open" : "sidebar-slide"}></div>
                {this.state.showLoader && <Loader />}
                < div className="d-flex white-bg" >
                    <div className="ta-box set-pos mb-0">
                        <div className="action-btn d-flex align-items-center">
                            <button
                                onClick={() =>
                                    this.refreshPage()
                                }
                            >
                                <img src="/images/svg-icons/refreshing.svg" alt="" />{" "}
                                Refresh
                            </button>
                        </div>
                    </div>
                    <div className="step-nav">
                        <ul>
                            <li className={this.state.selected === 1 ? "first active" : "first"}>
                                <a href="#/">1</a>
                            </li>
                            <li className={this.state.selected === 2 ? "second active" : "second"}>
                                <a href="#/">2</a>
                            </li>
                            <li className={this.state.selected === 3 ? "last active" : "last"}>
                                <a href="#/">3</a>
                            </li>
                        </ul>
                        <div className="text-center col">
                            <img src="images/sd-network.png" alt=""></img>
                            <h1 className="m-3">{this.state.selected === 1 ? 'Configure Network' : (
                                this.state.selected === 2 ? 'Configure Packetfence' : 'Start Configuration')}</h1>
                        </div>
                    </div>
                    <div className="steps-box">
                        <StepWizard>
                            {this.state.selected === 1 ?
                                <Interfaces
                                    gateway={this.state.gateway}
                                    hostname={this.state.hostname}
                                    dns={this.state.DNS}
                                    interfaceData={this.state.interfaceData}
                                    stepChange={(value) => this.stepChange(value)}
                                    getInterfaces={() => this.getInterfaces()} />
                                : false}
                            {this.state.selected === 2 ?
                                <Authorization
                                    generalData={this.state.generalData}
                                    AlertingData={this.state.AlertingData}
                                    satusData={this.state.satusData}
                                    AdminData={this.state.AdminData}
                                    DBData={this.state.DBData}
                                    stepChange={(value, pass) => this.stepChange(value, pass)}
                                />
                                : false}
                            {this.state.selected === 3 ?
                                <ConfigurePF gateway={this.state.gateway}
                                    hostname={this.state.hostname}
                                    dns={this.state.DNS}
                                    startConfigure={() => this.startConfigure()}
                                    configuring={this.state.configuring} />
                                : false}
                            {/* <DeviceA devices={this.state.deviceData} stepChange={(step, data) => this.stepChange(step, data)} />
                            <DeviceB devices={this.state.deviceBData} stepChange={(step, data) => this.stepChange(step, data)} />
                            <TunnelSettings devices={this.state.tunnelDevicesData}
                                deviceA_networks={this.state.deviceA_networks}
                                deviceB_networks={this.state.deviceB_networks}
                                pass={this.state.pass}
                                getTunnelList={() => this.props.getTunnelList()}
                                closeAddTunnel={() => this.closeAddTunnel()}
                                back={this.state.back}
                                history={this.props.history}
                                disableCreate={this.state.disableCreate} /> */}
                        </StepWizard>
                    </div>
                    {(this.state.show) &&
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            reset={() => this.resetPopup()}
                        />
                    }
                    {(this.state.isAxiosError) &&
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            delete={this.state.delete}
                            reset={() => this.setState({ isAxiosError: false })}
                        />
                    }
                    {this.state.showRefreshModal && <ErrorHandlePopup />}
                </div >
            </div >)
    }
}
const mapStateToProps = (state) => {
    return ({
        server_ip: state.auth.server_ip,
        hostname: state.auth.server_hostname,
        is_auth_server_configured: state.auth.is_configured
    });
}
export default connect(mapStateToProps)(AAAServer);