import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ApiCall from "../../redux/API/apiCall";
import urlMapping, { guacamoleUrl } from "../../redux/API/api";
import { Loader } from "../../layOuts/Loader";
import * as Common from "../../utils/common";
import moment from "moment";
import DataTable from "react-data-table-component";
import { ErrorPopup } from "../../layOuts/errorPopup";
import $ from "jquery";
import ErrorHandlePopup from "../ErrorHandlePopup";
import axios from "axios";
import qs from "qs";
import Warnings from "../warnings/Warnings";
import DynamicRouteDetails from "../device/DynamicRouteDetails";
import Statistics from "../device/Statistics";
import DeviceLogs from '../device/DeviceLogs';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js"
import solidGauge from "highcharts/modules/solid-gauge.js";
import HighchartsReact from "highcharts-react-official";
import isEqual from 'lodash/isEqual';
import Cookies from "universal-cookie";
highchartsMore(Highcharts);
solidGauge(Highcharts);

const cookies = new Cookies();

class Troubleshoot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRefreshModal: false,
      deviceDetails: [],
      newdeviceDetails: this?.props?.location?.state ? this?.props?.location?.state : undefined,
      deviceModel: "",
      deviceDataProps: this?.props?.data || "",
      deviceIdWhenDropChanges: this?.props?.deviceIdWhenDropChanges || "",
      domainName: "",
      domainName1: "",
      contents: [],
      text: [],
      title: "",
      message: "",
      show: false,
      error: false,
      generatedDeviceLogsList: [],
      showLoader: false,
      showError: false,
      logsPageNo: 1,
      logsPageSize: 10,
      maximise: false,
      logsCount: 0,
      download: false,
      isConnected: false,
      connectionRoute: "",
      option: {},
      pingInterface: '',
      wanInterface: '',
      availableInterfaces: [{ label: "Default", value: "" }],
      keyUpdate: Math.random(),
      downloadSpeed: '',
      uploadSpeed: '',
      serverIP: '',
      isAxiosError: false,
      isAccess: false,
      history: [],
      columns: [
        {
          name: "TimeStamp",
          selector: "created_date",
          sortable: true,
          cell: (row) => (
            <span style={{ width: "auto" }}>
              {
                moment
                  .utc(row?.created_date)
                  .local()
                  .format("DD MMMM YYYY hh:mm:ss A")
              }
            </span >
          ),
        },
        {},
        {
          name: "Download Debug Logs",
          selector: "file_path",
          sortable: true,
          cell: (row) =>
            row.file_path !== null ? (
              <a href={row?.file_path} download target="blank">
                Download
              </a>
            ) : (
              "Download"
            ),
        },
      ],
      logsColumns: [
        {
          name: "Device",
          selector: "device_name",
          sortable: true,
        },
        {
          name: "Serial No.",
          selector: "device_serial",
          sortable: true,
        },
        {
          name: "Model No.",
          selector: "device_model",
          sortable: true,
        },
        {
          name: "Timestamp",
          sortable: true,
          selector: "created_date",
          cell: (row) => (
            <span style={{ width: "auto" }}>
              {moment
                .utc(row?.created_date)
                .local()
                .format("DD MMMM YYYY hh:mm:ss A")}
            </span>
          ),
        },
        {
          name: "Download Debug Logs",
          selector: "file_path",
          sortable: true,
          // LONG-3012 : Device snapshot page goes empty after clicking on download logs
          //LONG-3166-EM - Device snapshot download in device dashboard page is getting failed in EM
          cell: (row) =>
            row?.file_path !== null ? (
              <a href="#/" onClick={() => this.downloadDebugLogs(row)}>
                Download
              </a>
            ) : (
              "Download"
            ),
        },
      ],
      // Implemented Dynamic Route Details page
      neighbourInfoCommands: [
        {
          backendValue: "show IP bgp summary",
          command: "BGP Summary",
        },
        {
          backendValue: "show IP route bgp",
          command: "BGP Route Info with Connection Status",
        },
        {
          backendValue: "show IP route ospf",
          command: "OSPF Route Info with Connection Status",
        },
        {
          backendValue: "show IP ospf route",
          command: "OSPF Route Table",
        },
        {
          backendValue: "show IP ospf database",
          command: "OSPF Database",
        },
        {
          backendValue: "show IP bgp",
          command: "BGP Detailed Route Info",
        },
        {
          backendValue: "show IP route",
          command: "Route Info with Connection Status",
        },
      ],
      speedTestColumns: [
        {
          name: "Time",
          selector: "Time",
          value: "Time",
          cell: (row) => (<span title={moment(row?.created_date).format('YYYY-MM-DD HH:mm:ss') || '-'} style={{ width: 'auto' }}>
            {moment(row?.created_date).format('YYYY-MM-DD HH:mm:ss') || '-'}</span>
          ),
        },
        {
          name: "Download (Mbps)",
          selector: "Download",
          value: "Download",
          cell: (row) => (<span
            title={
              (this.state.deviceModel === "Stormbreaker" || this.state.deviceModel === "Firestorm" || this.state.deviceModel === "Bradshaw"
                || this.state.deviceModel === "Orval" || this.state.deviceModel === "Sander" || this.state.deviceModel === "Moretti_Model" || this.state.deviceModel === "Duvel_Model")
                ? (row?.speed_test_json?.download
                  ? parseFloat(row.speed_test_json?.download).toFixed(2)
                  : '-')
                : (row?.speed_test_json?.download
                  ? parseFloat(row.speed_test_json?.download / 1000000).toFixed(2)
                  : '-')
            }
            style={{ width: 'auto' }}>
            {
              (this.state.deviceModel === "Stormbreaker" || this.state.deviceModel === "Firestorm" || this.state.deviceModel === "Bradshaw"
                || this.state.deviceModel === "Orval" || this.state.deviceModel === "Sander" || this.state.deviceModel === "Moretti_Model" || this.state.deviceModel === "Duvel_Model")
                ? (row?.speed_test_json?.download
                  ? parseFloat(row.speed_test_json?.download).toFixed(2)
                  : '-')
                : (row?.speed_test_json?.download
                  ? parseFloat(row.speed_test_json?.download / 1000000).toFixed(2)
                  : '-')
            }
          </span>
          ),
        },
        {
          name: "Upload (Mbps)",
          selector: "Upload",
          value: "Upload",
          cell: (row) => (<span
            title={
              (this.state.deviceModel === "Stormbreaker" || this.state.deviceModel === "Firestorm" || this.state.deviceModel === "Bradshaw"
                || this.state.deviceModel === "Orval" || this.state.deviceModel === "Sander" || this.state.deviceModel === "Moretti_Model" || this.state.deviceModel === "Duvel_Model")
                ? (row?.speed_test_json?.upload
                  ? parseFloat(row?.speed_test_json?.upload)?.toFixed(2)
                  : '-')
                : (row?.speed_test_json?.upload
                  ? parseFloat(row?.speed_test_json?.upload / 1000000)?.toFixed(2)
                  : '-')
            }
            style={{ width: 'auto' }}>
            {
              (this.state.deviceModel === "Stormbreaker" || this.state.deviceModel === "Firestorm" || this.state.deviceModel === "Bradshaw"
                || this.state.deviceModel === "Orval" || this.state.deviceModel === "Sander" || this.state.deviceModel === "Moretti_Model" || this.state.deviceModel === "Duvel_Model")
                ? (row?.speed_test_json?.upload
                  ? parseFloat(row?.speed_test_json?.upload)?.toFixed(2)
                  : '-')
                : (row?.speed_test_json?.upload
                  ? parseFloat(row?.speed_test_json?.upload / 1000000)?.toFixed(2)
                  : '-')
            }</span>
          ),
        },
        {
          name: "Latency (ms)",
          selector: "Latency",
          value: "Latency",
          cell: (row) => (<span title={row?.speed_test_json?.server?.latency || "-"} style={{ width: 'auto' }}>
            {row?.speed_test_json?.server?.latency || "-"}</span>
          ),
        },
        {
          name: "Interface",
          selector: "Interface",
          value: "Interface",
          cell: (row) => (<span title={row.interface_name || "-"} style={{ width: 'auto' }}>
            {row.interface_name || "-"}</span>
          ),
        },
        {
          name: "Server IP",
          selector: "Server-IP",
          value: "Server-IP",
          cell: (row) => (<span title={row?.speed_test_json?.client?.ip || "-"} style={{ width: 'auto' }}>
            {row?.speed_test_json?.client?.ip || "-"}</span>
          ),
        },
        {
          name: "Server Details",
          selector: "Server-Details",
          value: "Server-Details",
          cell: (row) => (<span title={row?.speed_test_json?.server?.sponsor || "-"} style={{ width: 'auto' }}>
            {row?.speed_test_json?.server?.sponsor || "-"}</span>
          ),
        },
        {
          name: "Status",
          selector: "Status",
          value: "Status",
          cell: (row) => (<span
            title={row?.status || "-"} className=
            {row?.status === "Success"
              ? "green-txt"
              : (row?.status === "Pending"
                ? "yellow-txt"
                : "red-txt")
            }
            style={{ width: 'auto' }}>
            {row?.status || "-"}</span>
          ),
        }
      ]
    };
  }
  async componentDidMount() {
    localStorage.removeItem("GUAC_AUTH");
    await this.getSpeedHistory();
    await this.getInterfaceNames();
    await this.getDeviceList();
    await this.getDeviceDetails(false);
    this.getSpeedGraph()
    if (this.props?.tabValue) {
      this.setState({
        selectedTab: "Warning&Alerts",
        deviceSeverity: this.props?.deviceSeverity,
        tabValue: this.props?.tabValue
      }, () => {
        $("#pills-diagnostic-tab").attr('class', 'nav-link')
        $("#pills-wa-tab").attr('class', 'nav-link active')
        $("#pills-diagnostic").attr('class', 'tab-pane fade')
        $("#pills-wa").attr('class', 'tab-pane fade active show')
      })
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if ((nextProps.deviceIdWhenDropChanges && !isEqual(this.props.deviceIdWhenDropChanges, nextProps.deviceIdWhenDropChanges))
      || (nextProps.data && !isEqual(this.props.data, nextProps.data))) {
      this.setState(
        {
          deviceIdWhenDropChanges: nextProps?.deviceIdWhenDropChanges || "",
          deviceDataProps: nextProps?.data || "",
          generatedDeviceLogsList: [],
          contents: [],
          text: [],
          domainName: "",
          domainName1: "",
          availableInterfaces: [],
          downloadSpeed: '',
          uploadSpeed: '',
          serverIP: '',
          wanInterface: ""
        },
        // LONG-2978 : Generated logs are not showing when moving to other device and coming back
        async () => {
          await this.getSpeedHistory();
          await this.getInterfaceNames();
          await this.getDeviceList();
          await this.getDeviceDetails(this.state.selectedTab === "DeviceSnapShot" ? true : false);
          this.getSpeedGraph()
          this.state.selectedTab === "DeviceSnapShot" && this.getGeneratedDeviceLogList();
          const elements = document.getElementsByClassName('error-txt');
          for (let i = 0; i < elements.length; i++) {
            elements[i].style.cssText = "display: none;"
          }
        }
      );
    }
  }
  downloadDebugLogs = (row) => {
    this.setState({ showLoader: true })
    let device_snapshot_id = "";
    device_snapshot_id = row.id;
    ApiCall(
      urlMapping.downloadDeviceLogfile(device_snapshot_id),
      (response) => {
        if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Download Logs',
            message: "Unable to download!",
            show: true,
            error: true,
            showLoader: false
          }, () => $("#errorMessage").modal("show"));
        }
        else if (parseInt(response?.response?.status) === 200) {
          const url = window.URL.createObjectURL(new Blob([response?.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Debug_Logs_" + row.device_name + ".zip");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.setState({
            showLoader: false,
            download: true,
          });
        } else {
          if (parseInt(response?.response?.status) === 401) {
            this.setState({
              showRefreshModal: true,
              showLoader: false,
            });
          }
          else {
            this.setState({
              title: 'Download Logs',
              message: "Unable to download!",
              show: true,
              error: true,
              showLoader: false
            }, () => $("#errorMessage").modal("show"));
          }
        }
      }
    );
  };
  getInterfaceNames = () => {
    let data = {
      device_id: this.state?.deviceDataProps?.device_id,
    }
    this.setState({
      showLoader: true
    })
    ApiCall(urlMapping.getInterfaceNames(data), (response) => {
      if (response?.success) {
        let availableInterfaces = []
        availableInterfaces.push({ label: "Default", value: "" })
        if (response?.data !== 0) {
          Object.keys(response?.data).map((item) => {
            if (item === 'rmnet_data0')
              response.data[item] !== 0 && availableInterfaces.push({ label: "rmnet_data0(Cellular)", value: item })
            else
              response.data[item] !== 0 && availableInterfaces.push({ label: item, value: item })
          })
        }
        this.setState({
          availableInterfaces
        });
      }
      else {
        if (parseInt(response?.response?.results) === 401) {
          this.setState({
            showRefreshModal: true,
            showLoader: false
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false
          })
        }
      }
    })
  }
  // EMGT-265-EM-Qos statics and Dynamic route details tabs are disabled while device is in offline state
  getDeviceDetails = async (value) => {
    this.setState({ isAccess: false })
    if (this.state.newdeviceDetails !== undefined) {
      this.setState({
        showLoader: true
      });
      let data = { device_id: this.state?.deviceDataProps?.device_id };
      await ApiCall(urlMapping.getDeviceDetails(data), (response) => {
        if (response?.success) {
          let model = Common.getModels(response?.data?.[0]?.device_model);
          if (model === "Stormbreaker" || model === "Firestorm" || model === "Bradshaw"
            || model === "Orval" || model === "Sander" || model === "Moretti_Model" || model === "Duvel_Model"
          ) {
            // EMGT-496 : While Pagination Troubleshooting Contents are missing
            if (this.state.selectedTab === 'DynamicRoute' || this.state.selectedTab === 'Statistics' || this.state.selectedTab === 'DeviceLogs') {
              if (this.state.selectedTab === 'DynamicRoute') {
                $("#pills-DynamicRoute-tab").attr('class', 'nav-link')
                $("#pills-DynamicRoute").attr('class', 'tab-pane fade')
              }
              else if (this.state.selectedTab === 'Statistics') {
                $("#pills-QoS-Statistics-tab").attr('class', 'nav-link')
                $("#pills-QoS-Statistics").attr('class', 'tab-pane fade')
              }
              else if (this.state.selectedTab === 'DeviceLogs') {
                $("#pills-DeviceLogs-tab").attr('class', 'nav-link')
                $("#pills-DeviceLogs").attr('class', 'tab-pane fade')
              }
              $("#pills-diagnostic-tab").attr('class', 'nav-link active')
              $("#pills-diagnostic").attr('class', 'tab-pane fade active show')
              this.setState({
                selectedTab: 'Diagnostics'
              })
            }
          }
          else if (model === 'Packetfence') {
            if (this.state.selectedTab === 'DynamicRoute' || this.state.selectedTab === 'Statistics'
              || this.state.selectedTab === 'DeviceLogs' || this.state.selectedTab === 'Warning&Alerts') {
              if (this.state.selectedTab === 'DynamicRoute') {
                $("#pills-DynamicRoute-tab").attr('class', 'nav-link')
                $("#pills-DynamicRoute").attr('class', 'tab-pane fade')
              }
              else if (this.state.selectedTab === 'Statistics') {
                $("#pills-QoS-Statistics-tab").attr('class', 'nav-link')
                $("#pills-QoS-Statistics").attr('class', 'tab-pane fade')
              }
              else if (this.state.selectedTab === 'DeviceLogs') {
                $("#pills-DeviceLogs-tab").attr('class', 'nav-link')
                $("#pills-DeviceLogs").attr('class', 'tab-pane fade')
              }
              else if (this.state.selectedTab === 'Warning&Alerts') {
                $("#pills-wa-tab").attr('class', 'nav-link')
                $("#pills-wa").attr('class', 'tab-pane fade')
              }
              $("#pills-diagnostic-tab").attr('class', 'nav-link active')
              $("#pills-diagnostic").attr('class', 'tab-pane fade active show')
              this.setState({
                selectedTab: 'Diagnostics'
              })
            }
          }
          this.setState({
            deviceDetails: response?.data?.[0],
            deviceModel: String(model),
            showLoader: value ? true : false
          }, () => {
            if (
              response?.data?.[0]?.guacamole_identifier === undefined ||
              response?.data?.[0]?.guacamole_identifier === "" ||
              response?.data?.[0]?.guacamole_identifier === null ||
              !response?.data?.[0]?.hasOwnProperty("guacamole_identifier")
            ) {
              this.gcmlcon();
            }
          });
        } else {
          if (parseInt(response?.response?.results) === 401) {
            this.setState({
              showLoader: false,
              showRefreshModal: true
            });
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState({
              showLoader: false
            });
          }
        }
      });
    }
    else {
      this.setState({
        showLoader: false
      })
    }
  };
  getDeviceList = () => {
    this.setState({
      showLoader: true
    });
    let data = { device_id: this.state?.deviceDataProps?.device_id };
    ApiCall(urlMapping.getDeviceLookUp(data), (response) => {
      if (response?.success) {
        const data = response?.results;
        if (data.length > 0) {
          //EMGT-430 : Dynamic route details/Qos Statistics Tab get Vanish after updating and applying any template
          let model = Common.getModels(response?.results?.[0]?.device_model);
          this.setState(
            {
              device_status: response?.results?.[0]?.device_status,
              deviceModel: String(model)
            }
          );
        } else {
          this.setState({
            device_status: 0
          });
        }
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showLoader: false,
            showRefreshModal: true,
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false
          });
        }
      }
    });
  };
  handlePing = (val) => {
    let data = {
      device_id: this.state?.deviceDataProps?.device_id,
      diagnostic_type: val,
      diagnostic: this.state.domainName,
      interface: this.state.pingInterface
    };
    this.setState({
      showLoader: true
    });
    ApiCall(urlMapping.deviceTroubleshoot(data), (response) => {
      if (response.success) {
        this.setState({
          contents: [...response?.data?.[0], ...response?.data?.[1]],
          showLoader: false,
        });
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showLoader: false,
            showRefreshModal: true,
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else {
          this.setState({
            showLoader: false,
          });
        }
      }
    });
  };
  handleLookup = () => {
    let data = {
      device_id: this.state?.deviceDataProps?.device_id,
      diagnostic_type: "nslookup",
      diagnostic: this.state.domainName1,
    };
    this.setState({
      showLoader: true
    });
    ApiCall(urlMapping.deviceTroubleshoot(data), (response) => {
      if (response?.success) {
        this.setState({
          text: [...response?.data?.[0], ...response?.data?.[1]],
          showLoader: false,
        });
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showLoader: false,
            showRefreshModal: true,
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false,
          });
        }
      }
    });
  };
  handleIPdata = (e) => {
    this.setState({
      domainName: e.target.value,
    });
    if (!Common.removeError(e)) {
      this.setState({
        showError: false,
      });
    }
  };
  handleIP2data = (e) => {
    this.setState({
      domainName1: e.target.value,
    });
    if (!Common.removeError(e)) {
      this.setState({
        showError: false,
      });
    }
  };
  validatePingOrTrance = (e) => {
    if (Common.validateIPaddress(e) || Common.validateDomainName(e)) {
      const element = document.getElementById("ipAddress");
      if (element) {
        element.style.display = "none";
        this.setState({
          showError: false,
        });
      }
    }
    else {
      this.setState({
        showError: true,
      });
    }
  };
  validate2IP = (e) => {
    if (e.target.value.trim() === "" || !Common.validateDomainName(e)) {
      const element = document.getElementById("ip2Address");
      if (element) {
        element.style.display = "block";
        this.setState({
          showError: true,
        });
      }
    }
    else {
      const element = document.getElementById("ip2Address");
      if (element) {
        element.style.display = "none";
        this.setState({
          showError: false,
        });
      }
    }
  };
  resetPopup = () => {
    this.setState(
      {
        show: false,
        error: false,
      },
      () => {
        $("#errorMessage").modal("hide");
      }
    );
  };
  generateDeviceLogs = () => {
    if (this.state?.deviceDataProps?.device_status === 1) {
      let data = {
        // Device snapshot logs getting downloaded for wrong device
        device_id:
          this.state.deviceIdWhenDropChanges && this.state.download
            ? this.state.deviceIdWhenDropChanges
            : this.state?.deviceDataProps?.device_id,
        diagnostic_type: "debuglogs",
        diagnostic: "",
      };
      this.setState({ showLoader: true });
      ApiCall(urlMapping.deviceTroubleshoot(data), (response) => {
        this.setState({ showLoader: false });
        if (response?.success) {
          this.setState(
            {
              title: "Generate Snapshot",
              message: response?.message,
              show: true,
              error: false,
            },
            () => {
              $("#errorMessage").modal("show");
              this.getGeneratedDeviceLogList();
            }
          );
        } else {
          if (parseInt(response?.response?.status) === 401) {
            this.setState({
              showRefreshModal: true,
            });
          } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          } else {
            this.setState(
              {
                title: "Generate Snapshot",
                //LONG-2535-EM-after download the offline device snapshot it show error
                message: response?.message,
                show: true,
                error: true,
              },
              () => $("#errorMessage").modal("show")
            );
          }
        }
      });
    } else {
      this.setState(
        {
          title: "Device Logs",
          message: "Unable to generate logs! Device is Offline!",
          show: true,
          error: true,
        },
        () => $("#errorMessage").modal("show")
      );
    }
  };
  getGeneratedDeviceLogList = () => {
    // EMGT-5 : Device snapshot is not generated after downloading the generated snapshot and navigating to another device
    let device_id = "";
    device_id =
      this.state.deviceIdWhenDropChanges && this.state.download
        ? this.state.deviceIdWhenDropChanges
        : this.state?.deviceDataProps?.device_id;
    this.setState({
      showLoader: true
    });
    ApiCall(
      urlMapping.getGeneratedLogs(
        device_id,
        this.state.logsPageNo,
        this.state.logsPageSize
      ),
      (response) => {
        if (response?.results) {
          const totalCount = response?.count;
          let filterData = response?.results;
          this.setState({
            generatedDeviceLogsList: filterData,
            logsCount: totalCount,
            showLoader: false,
          });
        } else {
          if (parseInt(response?.response?.status) === 401) {
            this.setState({
              showLoader: false,
              showRefreshModal: true,
            });
          } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          } else {
            this.setState({ showLoader: false });
          }
        }
      }
    );
  };
  logsPaginationChange = (e) => {
    this.setState(
      {
        logsPageNo: e,
      },
      () => {
        this.getGeneratedDeviceLogList();
      }
    );
  };
  logPageSizeChange = (e) => {
    this.setState({ logsPageSize: e }, () => {
      this.getGeneratedDeviceLogList();
    });
  };
  getTimeExact = (time) => {
    let date = moment.utc(time);
    date = date.local();
    return date.format("DD MMMM YYYY hh:mm:ss A");
  };
  getSelectedDeviceName = (e) => {
    if (e.target.value !== "select") {
      this.setState({ deviceName: e.target.value });
    }
  };
  diagnosticPageRefresh = async () => {
    const elements = document.getElementsByClassName('error-txt');
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.cssText = "display: none;"
    }
    await this.getInterfaceNames();
    await this.getDeviceList();
    await this.getDeviceDetails(false);
    await this.dropDownRefresh();
    this.setState({
      contents: [],
      text: [],
      domainName: "",
      domainName1: "",
      downloadSpeed: '',
      uploadSpeed: '',
      serverIP: '',
      wanInterface: "",
      pingInterface: "",
    }, () => this.getSpeedGraph());
  };
  dropDownRefresh = async () => {
    await this.getDeviceLookUpDeviceList()
    let device = [];
    {
      this.state.DeviceLookUpDeviceList &&
        this.state.DeviceLookUpDeviceList.length > 0 &&
        this.state.DeviceLookUpDeviceList.sort((a, b) =>
          a?.device_name.localeCompare(b.device_name)
        ).map((item) => {
          device.push({
            value: this.state.deviceDetails?.device_id,
            label: this.state.deviceDetails?.device_name,
            icon: (
              <i
                className={
                  this.state.deviceDetails?.device_status === 1 ? "status online" : "status offline"
                }
              ></i>
            ),
          });
        });
    }
    await this.props.refreshDropDown(device);
  }
  getDeviceLookUpDeviceList = async () => {
    await ApiCall(urlMapping.deviceLookUp(), (response) => {
      if (response?.data) {
        const data = response?.data;
        this.setState({
          DeviceLookUpDeviceList: data,
        });
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true,
            showLoader: false
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    });
  };
  snapshotPageRefresh = async () => {
    await this.getDeviceList();
    await this.getDeviceDetails(true);
    await this.dropDownRefresh()
    await this.getGeneratedDeviceLogList();
  };
  checkPingError = () => {
    let result = false;
    const elements = document.getElementsByClassName("error-txt ping");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].style.cssText === "display: block;") result = true;
    }
    if (this.state.domainName === "") result = true;
    if (this.state?.deviceDataProps?.device_status === 0) result = true;
    return result;
  };
  checkLookupError = () => {
    let result = false;
    const elements = document.getElementsByClassName("error-txt lookup");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].style.cssText === "display: block;")
        result = true;
    }
    if (this.state.domainName1 === "")
      result = true;
    if (this.state?.deviceDataProps?.device_status === 0)
      result = true;
    return result;
  };
  connectToGuacamole = async (type) => {
    document.getElementById("connectButton")?.blur();
    if (type === "disconnect") {
      localStorage.removeItem("GUAC_AUTH");
      this.setState({
        isConnected: !this.state.isConnected,
        connectionRoute: "",
      });
      $("#accessDevice").modal("hide");
    } else {
      const clearCookies = setInterval(() => {
        cookies.remove("username", { path: '/shell_access' })
      }, 100);
      let response = await axios({
        method: "post",
        url: `${guacamoleUrl}/tokens`,
        data: qs.stringify(Common.guacamoleCredential),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const path = window
        .btoa(
          [this.state?.deviceDataProps?.guacamole_identifier, "c", "postgresql"].join("\0")
        )
        .replace(
          /[+/=]/g,
          (str) =>
          ({
            "+": "-",
            "/": "_",
            "=": "",
          }[str])
        );
      if (response?.data?.authToken) {
        localStorage.setItem("GUAC_AUTH", JSON.stringify(response?.data));
        this.setState(
          {
            isConnected: !this.state.isConnected,
            connectionRoute: path,
          }, () => $("#accessDevice").modal("show"));
        setTimeout(() => {
          this.focusOnIframe();
        }, 2000);
      }
      setTimeout(() => {
        clearInterval(clearCookies);
      }, 10000);
    }
  };
  focusOnIframe = () => {
    var iFrameTextArea =
      document.getElementById("accessDeviceFrame")?.contentWindow;
    iFrameTextArea?.focus();
  };
  handleResize = () => {
    if (!this.state.maximise)
      document
        .getElementById("accessDevice")
        .classList.add("fullScreen");
    else
      document
        .getElementById("accessDevice")
        .classList.remove("fullScreen");
    this.setState({
      maximise: !this.state.maximise
    });
  }
  getSpeedGraph = () => {
    let largestNumber = parseInt(this.state.uploadSpeed, 10) > parseInt(this.state.downloadSpeed, 10)
      ? parseInt(this.state.uploadSpeed, 10) + parseInt(this.state.uploadSpeed, 10) / 4
      : parseInt(this.state.downloadSpeed, 10) + parseInt(this.state.downloadSpeed, 10) / 4
    let data = {
      chart: {
        type: 'solidgauge',
        height: '60%',
      },
      title: {
        text: '',
        style: {
          fontSize: '24px'
        }
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        borderWidth: 0,
        backgroundColor: 'none',
        shadow: false,
        style: {
          fontSize: '14px'
        },
        valueSuffix: 'Mbps',
        pointFormat: '{series.name}<br><span style="font-size:1em; color: {point.color}; font-weight: bold">{point.y}</span>',
        positioner: function (labelWidth) {
          return {
            x: (this.chart.chartWidth - labelWidth) / 2,
            y: (this.chart.plotHeight) / 2 - 15
          };
        }
      },
      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [{
          outerRadius: '112%',
          innerRadius: '88%',
          backgroundColor: "#b9dbeb",
          borderWidth: 0
        }, {
          outerRadius: '87%',
          innerRadius: '63%',
          backgroundColor: "#d3edeb",
          borderWidth: 0
        }]
      },
      yAxis: {
        min: 0,
        max: largestNumber,
        lineWidth: 0,
        tickPositions: []
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true
        }
      },
      series: [{
        name: 'Download',
        data: [{
          color: "#0083bf",
          radius: '112%',
          innerRadius: '88%',
          y: parseInt(this.state.downloadSpeed, 10)
        }]
      }, {
        name: 'Upload',
        data: [{
          color: "#21B6A8",
          radius: '87%',
          innerRadius: '63%',
          y: parseInt(this.state.uploadSpeed, 10)
        }]
      }]
    }
    this.setState({
      option: data,
      keyUpdate: Math.random()
    })
  }
  getSpeedHistory = () => {
    let data = {
      device_id: this.state?.deviceDataProps?.device_id,
    }
    this.setState({ showLoader: true })
    ApiCall(urlMapping.getSpeedHistory(data), (response) => {
      this.setState({ showLoader: false })
      if (response?.success) {
        this.setState({
          history: response?.data,
          showLoader: false
        })
      }
      else {
        if (parseInt(response?.response?.results) === 401) {
          this.setState({ showLoader: false, showRefreshModal: true });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            history: [],
            showLoader: false,
          });
        }
      }
    })
  }
  getSpeedTest = () => {
    let data = {
      device_id: this.state?.deviceDataProps?.device_id,
      interface: this.state.wanInterface === "" ? 'default' : this.state.wanInterface
    }
    this.setState({ showLoader: true });
    ApiCall(urlMapping.getSpeedTest(data), (response) => {
      if (response.success) {
        this.setState({
          showLoader: false
        }, () => {
          this.getSpeedHistory()
        }
        )
      } else if (parseInt(response?.response?.status) === 401) {
        this.setState({
          showRefreshModal: true,
          showLoader: false
        });
      }
      else {
        this.setState({
          showRefreshModal: true,
          showLoader: false
        });
      }
    })
  }
  gcmlcon = () => {
    this.setState({
      showLoader: true
    })
    let id = this.state.deviceDetails?.device_id;
    let data = {
      device_id: parseInt(id)
    }
    ApiCall(urlMapping.gcmlcon(data), (response) => {
      if (response?.success) {
        this.setState({
          isAccess: false,
          showLoader: false
        }, () => this.props.refreshDeviceDetails());
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true,
            showLoader: false
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true,
            isAccess: true,
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            isAccess: true,
            showLoader: false
          })
        }
      }
    });
  }
  render() {
    return (
      <div>
        {/* <SideMenu /> */}
        {this.state.showLoader && <Loader />}
        <div className="no-bg page-height">
          <div className="page-header device-head">
            <ul
              className="tab-nav nav nav-pills troubleshootResponsive ml-0 mr-0"
              id="pills-tab"
              role="tablist"
            >
              {
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    id="pills-diagnostic-tab"
                    data-toggle="pill"
                    href="#pills-diagnostic"
                    role="tab"
                    aria-controls="pills-diagnostic"
                    aria-selected="true"
                    onClick={() =>
                      this.setState({
                        selectedTab: "Diagnostics",
                      }, () => this.diagnosticPageRefresh())
                    }
                  >
                    Diagnostic
                  </a>
                </li>
              }
              {
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="pills-device-snapshot-tab"
                    data-toggle="pill"
                    href="#pills-device-snapshot"
                    role="tab"
                    aria-controls="pills-device-snapshot"
                    aria-selected="false"
                    onClick={() => { this.snapshotPageRefresh(); this.setState({ selectedTab: "DeviceSnapShot" }) }}
                  >
                    Device Snapshot
                  </a>
                </li>
              }
              {
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="pills-drd-shell-tab"
                    data-toggle="pill"
                    href="#pills-drd-shell"
                    role="tab"
                    aria-controls="pills-drd-shell"
                    aria-selected="false"
                    onClick={() =>
                      this.setState({ selectedTab: "DeviceAccess" })
                    }
                  >
                    Device Access
                  </a>
                </li>
              }
              {this.state.deviceModel !== 'Packetfence'
                &&
                <>
                  {(this.state.deviceModel !== "Stormbreaker" && this.state.deviceModel !== "Firestorm" && this.state.deviceModel !== "Bradshaw"
                    && this.state.deviceModel !== "Orval" && this.state.deviceModel !== "Sander" && this.state.deviceModel !== "Moretti_Model" && this.state.deviceModel !== "Duvel_Model")
                    &&
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="pills-DeviceLogs-tab"
                        data-toggle="pill"
                        href="#pills-DeviceLogs"
                        role="tab"
                        aria-controls="pills-DeviceLogs"
                        aria-selected="false"
                        onClick={() =>
                          this.setState({ selectedTab: "DeviceLogs" })
                        }
                      >
                        Device Logs
                      </a>
                    </li>}
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-wa-tab"
                      data-toggle="pill"
                      href="#pills-wa"
                      role="tab"
                      aria-controls="pills-wa"
                      aria-selected="false"
                      onClick={() =>
                        this.setState({
                          selectedTab: "Warning&Alerts",
                          deviceSeverity: "",
                          selected_Tab: "",
                          tabValue: false
                        })
                      }
                    >
                      CPE Device Alerts
                    </a>
                  </li>
                </>
              }
              {this.state.deviceModel === "CHIMAY" ||
                this.state.deviceModel === "vCPE" ? (
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="pills-DynamicRoute-tab"
                    data-toggle="pill"
                    href="#pills-DynamicRoute"
                    role="tab"
                    aria-controls="pills-DynamicRoute"
                    aria-selected="false"
                    onClick={() =>
                      this.setState({ selectedTab: "DynamicRoute" })
                    }
                  >
                    {" "}
                    Dynamic Route Details
                  </a>
                </li>
              ) : (
                ""
              )}
              {(this.state.deviceModel === "CHIMAY" ||
                this.state.deviceModel === "vCPE") && (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-QoS-Statistics-tab"
                      data-toggle="pill"
                      href="#pills-QoS-Statistics"
                      role="tab"
                      aria-controls="pills-QoS-Statistics"
                      aria-selected="false"
                      onClick={() => this.setState({ selectedTab: "Statistics" })}
                    >
                      {" "}
                      Statistics
                    </a>
                  </li>
                )}
            </ul>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-content" id="myTabContent">
              <div
                className={"tab-pane fade show active"}
                id="pills-diagnostic"
                role="tabpanel"
                aria-labelledby="pills-diagnostic-tab"
              >
                <div>
                  <div className="white-bg">
                    <div className="ta-box set-pos mb-0">
                      <div className="action-btn d-flex align-items-center">
                        <div className="d-inline-flex flex-column mr-3">
                          <div className="ds-txt">Device Status</div>
                          <div className="d-status">
                            <span>
                              <i
                                className={
                                  this.state.device_status === 1
                                    ? "status online"
                                    : "status offline"
                                }
                              ></i>
                              {this.state.device_status === 1
                                ? "Online"
                                : "Offline"}
                            </span>
                          </div>
                        </div>
                        <button
                          className="short-refresh-btn"
                          onClick={() => this.diagnosticPageRefresh()}
                          title="Refresh">
                          <img src="/images/svg-icons/refreshing.svg" alt="" />{" "}
                          Refresh
                        </button>
                      </div>
                    </div>
                    <p className="headingDiagnostic">
                      Network Diagnostics help an administrator in scanning,
                      diagnosing and identifying problems within a device network.
                    </p>
                  </div>
                  {(this.state.deviceModel !== 'Packetfence')
                    &&
                    <div className="white-bg mt-3">
                      <div className="row no-gutters">
                        <div className="col-7">
                          <p className="font-weight-bold headingDiagnostic">Speedtest with interface</p>
                          <div className="red-txt mr-3" style={{ fontSize: "11.7px" }}>* A speed test may take up to 3 minutes to execute.</div>
                          <div className="red-txt mr-3" style={{ fontSize: "11.7px" }}>* Due to CPE HW acceleration, tests performed locally on the client can see higher download and upload speeds.</div>
                        </div>
                        <div className="col-5">
                          <div className="row no-gutters">
                            <div className="col-6">
                              <div>Active WAN Interfaces</div>
                              <select
                                className="form-control mt-2 w-10"
                                name=""
                                onChange={(e) => this.setState({ wanInterface: e?.target?.value })}
                                value={this.state?.wanInterface}
                              >
                                {this.state?.availableInterfaces?.map((item) => {
                                  return <option value={item?.value}>{item?.label}</option>
                                })}
                              </select>
                            </div>
                            <div className="col-3">
                              <button
                                className="btn btn-primary mt-4"
                                disabled={this.state?.deviceDataProps?.device_status === 0}
                                onClick={() => { this.getSpeedTest() }}>
                                Start
                              </button>
                            </div>
                            <div className="col-3">
                              <button
                                className="btn btn-light border text-secondary mt-4"
                                onClick={() => this.getSpeedHistory()}>
                                <img style={{ width: "15px" }} src="/images/svg-icons/refreshing.svg" alt="" />{" "}
                                Refresh
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row no-gutters pt-1">
                        <DataTable
                          className="new-device-list"
                          columns={this.state.speedTestColumns}
                          data={this.state.history}
                          highlightOnHover
                          fixedHeader
                        />
                      </div>
                    </div>}
                  <div className="row no-gutters">
                    <div className="col-6 pr-2">
                      <div className="white-bg">
                        <div className="row">
                          <div className="col-12">
                            <p className="mt-2 mb-2 ml-1">
                              <strong>{(this.state.deviceModel === "vCPE" ||
                                this.state.deviceModel === "CHIMAY") ? "Ping or trace with Interfaces" : "Ping or trace"}</strong>
                            </p>
                          </div>
                        </div>
                        <div className="tb-usages">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="nav nav-pills topNav">
                              <div className="nav-item topNav-item">
                                <input
                                  name="ipAddress"
                                  type="text"
                                  // style={{ width: "80%" }}
                                  className="form-control"
                                  onChange={this.handleIPdata}
                                  onBlur={(e) => this.validatePingOrTrance(e)}
                                  value={this.state?.domainName}
                                />
                                <div className="info-txt">(eg. : google.com,1.2.2.4)</div>
                                <div className="error-txt ping" id="ipAddress">
                                  Invalid value
                                </div>
                              </div>
                              {(this.state.deviceModel === "vCPE" ||
                                this.state.deviceModel === "CHIMAY") && <div className="nav-item topNav-item">
                                  <select
                                    className="form-control"
                                    // style={{ width: "50%" }}
                                    name=""
                                    onChange={(e) => this.setState({ pingInterface: e?.target?.value })}
                                    value={this.state?.pingInterface}
                                  >
                                    {this.state?.availableInterfaces.map((item) => {
                                      return <option value={item?.value}>{item?.label}</option>
                                    })}
                                  </select>
                                </div>}
                              <div className="nav-item topNav-item">
                                <button
                                  className="btn btn-primary"
                                  disabled={this.checkPingError()}
                                  onClick={() => {
                                    this.handlePing("ping");
                                  }}
                                >
                                  Ping
                                </button>
                              </div>
                              <div className="nav-item topNav-item">
                                <button
                                  className="btn btn-primary"
                                  disabled={this.checkPingError()}
                                  onClick={() => {
                                    this.handlePing("traceroute");
                                  }}
                                >
                                  Traceroute
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row no-gutters mt-3 mb-2">
                          <div className="col-md-8">
                            <ul className="drd-list">
                              {this.state?.contents?.map((item) => {
                                return <li>{item}</li>;
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="white-bg">
                        <p className="mt-2 mb-2">
                          <strong>Perform a DNS Lookup</strong>
                        </p>
                        <div className="row no-gutters">
                          <div className="col-md-4 col-sm-6">
                            <div className="form-group">
                              <label>Domain Name</label>
                              <input
                                name="ip2Address"
                                type="text"
                                className="form-control"
                                onChange={this.handleIP2data}
                                onBlur={(e) => this.validate2IP(e)}
                                value={this.state?.domainName1}
                              />
                              <div className="info-txt">(eg.: yahoo.com)</div>
                              <div className="error-txt lookup" id="ip2Address">
                                Invalid value
                              </div>
                            </div>
                          </div>
                          <div className="col-md-8 col-sm-6">
                            <label className="d-block">&nbsp;</label>
                            <button
                              className="btn btn-primary ml-3 btn-top-space"
                              disabled={this.checkLookupError()}
                              onClick={() => {
                                this.handleLookup();
                              }}
                            >
                              Lookup
                            </button>
                          </div>
                        </div>
                        <div className="row no-gutters">
                          <div className="col-md-8">
                            <ul className="drd-list">
                              {this.state?.text?.map((item) => {
                                return <li>{item}</li>;
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-device-snapshot"
                role="tabpanel"
                aria-labelledby="pills-device-snapshot-tab"
              >
                <div className="white-bg">
                  <div className="ta-box set-pos mb-0">
                    <div className="action-btn d-flex align-items-center">
                      <div className="d-inline-flex flex-column mr-3">
                        <div className="ds-txt">Device Status</div>
                        <div className="d-status">
                          <span>
                            <i
                              className={
                                this.state?.device_status === 1
                                  ? "status online"
                                  : "status offline"
                              }
                            ></i>
                            {this.state?.device_status === 1
                              ? "Online"
                              : "Offline"}
                          </span>
                        </div>
                      </div>
                      <button onClick={() => this.snapshotPageRefresh()}>
                        <img src="/images/svg-icons/refreshing.svg" alt="" />{" "}
                        Refresh
                      </button>
                    </div>
                  </div>
                  <p>
                    Collect debug logs and running configuration of the selected
                    device.
                  </p>
                  <div className="row no-gutters">
                    <div className="col-md-4 col-sm-5">
                      <div className="form-group ml-1">
                        <label>Category</label>
                        <select className="form-control">
                          <option>Debug Logs</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-2">
                      <label className="d-block">&nbsp;</label>
                      <button
                        className="btn btn-primary ml-2 btn-top-space"
                        onClick={this.generateDeviceLogs}
                      >
                        Generate
                      </button>
                    </div>
                  </div>
                  <div className="table-box">
                    <div className="d-flex align-items-center page-sub-title">
                      <strong>Debug Logs</strong>
                    </div>
                    <DataTable
                      className="new-device-list"
                      columns={this.state.logsColumns}
                      pagination
                      paginationServer
                      onChangePage={(e) => this.logsPaginationChange(e)}
                      onChangeRowsPerPage={(e) => this.logPageSizeChange(e)}
                      paginationTotalRows={this.state.logsCount}
                      data={this.state.generatedDeviceLogsList}
                      highlightOnHover
                      fixedHeader
                      paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                    />
                  </div>
                </div>
              </div>
              {this.state.selectedTab === 'DeviceAccess' &&
                <div
                  className={"tab-pane fade"}
                  id="pills-drd-shell"
                  role="tabpanel"
                  aria-labelledby="pills-drd-snapshot-tab"
                >
                  <div className="white-bg">
                    <p>Access Device</p>
                    {!this.state.isConnected && (
                      <div className="form-group">
                        <button
                          id="connectButton"
                          className="btn btn-primary ml-2 btn-top-space"
                          onClick={() => this.connectToGuacamole("connect")}
                          disabled={
                            this.state.isAccess ||
                            (this.props?.userRole === "Viewer_Group" ||
                              this.props?.userRole === "Operator_Group")
                          }
                        >
                          {"Connect"}
                        </button>
                        {this.state.isAccess && <div className="ml-2" style={{ color: "#f00" }}>Unable to access the device</div>}
                      </div>
                    )}
                    {this.state.isConnected && (
                      <div
                        className={"modal fade device-access"}
                        id="accessDevice"
                        tabIndex="-1"
                        role="dialog"
                        data-backdrop="static"
                        aria-hidden="true"
                        onClick={() => this.focusOnIframe()}
                      >
                        <div
                          className="modal-dialog modal-lg-device modal-lg modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content modal-device-content">
                            <div className="modal-device-header">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() =>
                                  this.connectToGuacamole("disconnect")
                                }
                              >
                                Disconnect
                              </button>
                              <img
                                src={
                                  this.state.maximise
                                    ? "./images/svg-icons/resize.svg"
                                    : "./images/svg-icons/fullscreen.svg"
                                }
                                className={`view-btn ${this.state.maximise ? "w-30" : "w-20"
                                  } ml-4`}
                                onClick={() => this.handleResize()}
                                alt=""
                              />
                            </div>
                            <div className="modal-body lg-body">
                              <div style={{ height: "100%" }}>
                                <iframe
                                  id="accessDeviceFrame"
                                  src={`${window.location.origin}/shell_access/#/client/${this.state.connectionRoute}`}
                                  title="Shell Acess"
                                  height="100%"
                                  width="100%"
                                ></iframe>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
              {this.state.selectedTab === "DeviceLogs" && (
                <div
                  className="tab-pane fade"
                  id="pills-DeviceLogs"
                  role="tabpanel"
                  aria-labelledby="pills-DeviceLogs-tab"
                >
                  <DeviceLogs
                    data={this.state?.deviceDataProps} />
                </div>
              )}
              {this.state.selectedTab === "Warning&Alerts" && (
                <div
                  className="tab-pane fade"
                  id="pills-wa"
                  role="tabpanel"
                  aria-labelledby="pills-wa-tab"
                >
                  <Warnings
                    data={this.state?.deviceDataProps?.device_id}
                    deviceSeverity={this.state.deviceSeverity}
                    tabValue={this.state.tabValue} />
                </div>
              )}
              {this.state.selectedTab === "DynamicRoute" && (
                <div
                  className="tab-pane fade"
                  id="pills-DynamicRoute"
                  role="tabpanel"
                  aria-labelledby="pills-DynamicRoute-tab"
                >
                  <DynamicRouteDetails
                    id={this.state?.deviceDataProps?.device_id}
                    data={this.state.deviceDetails}
                  />
                </div>
              )}
              {this.state.selectedTab === "Statistics" && (
                <div
                  className="tab-pane fade"
                  id="pills-QoS-Statistics"
                  role="tabpanel"
                  aria-labelledby="pills-QoS-Statistics-tab"
                >
                  <Statistics
                    id={this.state?.deviceDataProps?.device_id}
                    data={this.state.deviceDetails}
                    opne={this.props.open}
                  />
                </div>
              )}
            </div>
          </div>
          {this.state.show && (
            <ErrorPopup
              title={this.state.title}
              message={this.state.message}
              error={this.state.error}
              reset={() => this.resetPopup()}
            />
          )}
          {(this.state.isAxiosError) &&
            <ErrorPopup
              title={this.state.title}
              message={this.state.message}
              error={this.state.error}
              delete={this.state.delete}
              reset={() => this.setState({ isAxiosError: false })}
            />
          }
        </div>
        {this.state.showRefreshModal && <ErrorHandlePopup />}
        {(this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </div >
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    open: state.displaySidebarMenu.openSide,
    deviceIdWhenDropChanges: state.displayAddDevice.deviceIdWhenDropChanges,
    userRole: String(state.auth.role)
  };
};
export default withRouter(connect(mapStateToProps)(Troubleshoot));