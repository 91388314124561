import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Loader } from '../../layOuts/Loader';
import PfApiCall from "../../redux/API/pfApiCall";
import pfUrlMapping from "../../redux/API/pfAPI";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { ErrorPopup } from '../../layOuts/errorPopup';
import $ from 'jquery';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../DataTableDemo.css';
import ErrorHandlePopup from "../ErrorHandlePopup";
import * as Common from '../../utils/common';
class Packetfence extends Component {
    constructor(props) {
        super(props);
        this.state = {
            service: 'Local',
            username: '',
            password: '',
            realM: '',
            showLoader: false,
            showRefreshModal: false,
            auth_token: '',
            switchPass: '',
            enable: 'local',
            switchIp: "10.171.0.1",
            openSlide: false,
            tenant_id: "",
            data: {
                local: {},
                remote: {},
                azure_ad: {},
                settings: {}
            },
            localData: {},
            remoteData: {},
            azureData: {
                client_id: "",
                client_secret: "",
                tenant_id: "",
                realm: ""
            },
            azure: false,
            isAxiosError: false,
            createSwitch: false
        };
    }
    componentDidMount() {
        this.getAuthServerConfiguration();
    }
    getAuthServerConfiguration = () => {
        this.setState({
            showLoader: true,
        })
        ApiCall(urlMapping.authServerConfiguration(), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.success) {
                let localData = JSON.parse(JSON.stringify(response?.data?.local));
                localData.switch_ip = response?.data?.settings?.switch_ip;
                localData.username = '5gEdge';
                this.setState({
                    tokenUsername: response.data.settings.username,
                    tokenPassword: response.data.settings.password,
                    server_ip: response.data.local.server_ip,
                    enable: response.data.settings.enable,
                    service: response.data.settings.enable === 'remote' ? 'Remote' : 'Local',
                    switch_ip: response?.data?.settings?.switch_ip,
                    secret_passphrase: response.data?.settings?.secret_passphrase,
                    switchPass: response.data?.settings?.secret_passphrase,
                    data: response.data,
                    localData,
                    remoteData: JSON.parse(JSON.stringify(response.data.remote)),
                    azureData: response.data.azure_ad ? JSON.parse(JSON.stringify(response.data.azure_ad)) : {
                        client_id: "",
                        client_secret: "",
                        tenant_id: "",
                        realm: ""
                    }
                });
            } else {
                if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        title: 'Auth Server Configuration',
                        message: response?.message,
                        show: true,
                        error: true,
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        });
    }
    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
        }, () => {
            $("#errorMessage").modal("hide");
        })
        if (this.state.openSlide) {
            this.setState({
                openSlide: false,
            },
                () => this.getAuthServerConfiguration())
        }
    }
    updateNULLrealM = () => {
        this.setState({
            showLoader: true,
        })
        let data = {
            id: "NULL",
            options: "nostrip",
            radius_auth: ["CloudRadius"],
            radius_auth_proxy_type: "fail-over",
            eduroam_radius_auth_proxy_type: "keyed-balance",
            radius_acct_proxy_type: "load-balance",
            eduroam_radius_acct_proxy_type: "load-balance"
        };
        PfApiCall(pfUrlMapping.updateNULLrealM(data, this.state.auth_token), (response) => {
            if (response.status === 200) {
                PfApiCall(pfUrlMapping.restartRadius(this.state.auth_token), (response) => {
                    if (response.status === 200) {
                        PfApiCall(pfUrlMapping.restartWinBindService(this.state.auth_token), (response) => {
                            if (response.status === 200) {
                                this.putAuthServerConfiguration();
                            }
                        })
                    }
                })
            } else {
                this.setState({
                    title: 'Remote Service',
                    message: response.response.data.message,
                    show: true,
                    error: true,
                    realM_created: false
                }, () => $("#errorMessage").modal("show"));
            }
        });
    }
    openSlide = () => {
        this.login();
        this.setState({
            openSlide: true,
        })
    }
    closeSlide = () => {
        if (this.state.openSlide) {
            this.setState({
                service: this.state.data.settings.enable === 'local' ? 'Local' : 'Remote',
                showPassword: false,
                openSlide: false,
            })
        }
    }
    createSwitch = () => {
        this.setState({ createSwitch: true })
        let decodedPass = atob(this.state.tokenPassword)
        let data = {
            password: decodedPass.substring(6),
            username: this.state.tokenUsername,
        }
        this.setState({
            showLoader: true
        })
        PfApiCall(pfUrlMapping.login(data), (response) => {
            if (response.isAxiosError) {
                this.setState({
                    title: 'Packet Fence',
                    message: "Unable to login!",
                    show: true,
                    error: true,
                    showLoader: false
                }, () => $("#errorMessage").modal("show"));
            }
            else if (response) {
                this.setState({
                    auth_token: response.token
                }, () =>
                    this.hashPasswords()
                );
            } else {
                this.setState({
                    title: 'Packet Fence',
                    message: "Unable to login!",
                    show: true,
                    error: true,
                    showLoader: false
                }, () => $("#errorMessage").modal("show"));
            }
        })
    }
    hashPasswords = () => {
        let data = {
            hash_passwords: "plaintext"
        }
        PfApiCall(pfUrlMapping.hashPasswords(data, this.state.auth_token), (response) => {
            if (response.status === 200) {
                this.switchAuth();
            } else {
                this.setState({
                    showLoader: false
                })
            }
        });
    }
    switchAuth = () => {
        let data = {
            local_auth: "enabled",
            normalize_radius_machine_auth_username: "enabled"
        }
        PfApiCall(pfUrlMapping.switchAuth(data, this.state.auth_token), (response) => {
            if (response.status === 200) {
                this.createSwitchIp();
            } else {
                this.setState({
                    title: 'Radius Auth Configuration',
                    message: 'Radius Auth Configuration failed',
                    show: true,
                    error: true,
                    showLoader: false
                }, () => $("#errorMessage").modal("show"));
            }
        });
    }
    setSwitchPasswordValue = (e) => {
        this.setState({
            switchPass: e.target.value
        })
    }
    switchPassword = () => {
        let data = {
            radiusSecret: this.state.switchPass
        }
        PfApiCall(pfUrlMapping.switchPassword(data, this.state.auth_token), (response) => {
            if (response.status === 200) {
                PfApiCall(pfUrlMapping.restartRadius(this.state.auth_token), (response) => {
                    if (response.status === 200)
                        this.putAuthServerConfiguration();
                })
            } else {
                this.setState({
                    title: 'Switch Creation',
                    message: "Unable to update switch group password",
                    show: true,
                    error: true,
                    showLoader: false
                }, () => $("#errorMessage").modal("show"));
            }
        });
    }
    validateIP = (e) => {
        if (Common.validateIPaddress(e) === false) {
            const element = document.getElementById(e.target.name);
            if (element) {
                element.style.display = 'block';
                element.innerHTML = 'Invalid IP address';
                this.setState({
                    showError: true,
                })
            }
        }
    }
    createSwitchIp = () => {
        let data = {
            id: this.state.switchIp,
            isClone: false,
            isNew: true,
            group: "5GEdge-SG",
        }
        PfApiCall(pfUrlMapping.createSwitch(data, this.state.auth_token), (response) => {
            if (response.status === 201 || response.status === 409) {
                this.switchPassword();
            } else {
                this.setState({
                    title: 'Switch Creation',
                    message: "Unable to create switch!",
                    show: true,
                    error: true,
                    showLoader: false
                }, () => $("#errorMessage").modal("show"));
            }
        });
    }
    changeService = (e) => {
        this.setState({
            service: this.state.service === 'Remote' ? 'Local' : 'Remote'
        })
    }
    setLocalValue = (e) => {
        let data = this.state.localData;
        data[e.target.name] = e.target.value;
        this.setState({
            localData: data,
            pwdchanged: true
        })
    }
    setRemoteValue = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                showError: true,
            });
        }
        let data = this.state.remoteData;
        data[e.target.name] = e.target.value;
        this.setState({
            remoteData: data
        })
    }
    saveRemoteData = () => {
        this.setState({
            showLoader: true,
            enable: 'remote'
        })
        let data = {
            host: this.state.remoteData.server_ip,
            port: '1812',
            secret: this.state.remoteData.pre_shared_key,
            id: 'CloudRadius',
            description: 'Profile Created',
            type: "RADIUS",
            timeout: 1,
            options: "type = auth+acct\nstatus_check = none\nrequire_message_authenticator = yes\nresponse_window = 20\nzombie_period = 40\nmax_outstanding = 65536",
        };
        PfApiCall(pfUrlMapping.remoteService(data, this.state.auth_token), (response) => {
            if (response.status === 201) {
                // this.setState({
                //     title: 'Remote Service',
                //     message: response.message,
                //     created_id: response.id,
                //     show: true,
                //     error: false,
                //     realM_created: true,
                // }, () => {
                //     $("#errorMessage").modal("show")
                // });
                this.updateNULLrealM();
            } else {
                this.setState({
                    title: 'Remote Service',
                    message: response.response.data.message,
                    show: true,
                    error: true,
                }, () => $("#errorMessage").modal("show"));
            }
        });
    }
    login = () => {
        let decodedPass = atob(this.state.tokenPassword);
        let data = {
            password: decodedPass.substring(6),
            username: this.state.tokenUsername,
        }
        this.setState({
            showLoader: true
        })
        PfApiCall(pfUrlMapping.login(data), (response) => {
            if (response.isAxiosError) {
                this.setState({
                    title: 'Packet Fence',
                    message: "Unable to login!",
                    show: true,
                    error: true,
                    showLoader: false
                }, () => $("#errorMessage").modal("show"));
            }
            else if (response) {
                this.setState({
                    auth_token: response.token,
                    showLoader: false
                }, () => {
                    if (this.state.service === 'Local') {
                        PfApiCall(pfUrlMapping.getLocalUserDetails(this.state.auth_token), (response) => {
                            if (response.status === 200) {
                                this.setState({
                                    tenant_id: response.item.tenant_id,
                                })
                            }
                        })
                    }
                })
            } else {
                this.setState({
                    title: 'Packet Fence',
                    message: "Unable to login!",
                    show: true,
                    error: true,
                }, () => $("#errorMessage").modal("show"));
            }
        })
    }
    updateRemoteData = () => {
        this.setState({
            showLoader: true,
            updateRemote: true,
            enable: 'remote'
        })
        let data = {
            host: this.state.remoteData.server_ip,
            secret: this.state.remoteData.pre_shared_key,
            Port: this.state.remoteData.port,
            // id :'CloudRadius'
        };
        let id = 'CloudRadius'
        PfApiCall(pfUrlMapping.updateRemoteService(data, id, this.state.auth_token), (response) => {
            if (response.status === 200) {
                this.updateNULLrealM();
                // this.putAuthServerConfiguration();
            } else {
                this.setState({
                    title: 'Remote Service',
                    message: response.response.data.message,
                    show: true,
                    error: true,
                }, () => $("#errorMessage").modal("show"));
            }
        });
    }
    showPass = () => {
        const x = document.getElementById("password");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    }
    savePassword = () => {
        this.setState({
            showLoader: true,
        })
        let data = { password: this.state.localData.password };
        let id = '5gEdge';
        PfApiCall(pfUrlMapping.updatePassword(data, id, this.state.auth_token), (response) => {
            if (response.status === 200) {
                PfApiCall(pfUrlMapping.restartRadius(this.state.auth_token), (response) => {
                    if (response.status === 200) {
                        PfApiCall(pfUrlMapping.restartWinBindService(this.state.auth_token), (response) => {
                            if (response.status === 200) {
                                this.setState({
                                    usernameChange: false,
                                })
                                this.putAuthServerConfiguration()
                            }
                        })
                    }
                })
            }
        });
    }
    createUser = () => {
        this.setState({
            showLoader: true,
        })
        let actions = {
            type: 'set_access_level',
            value: ['ALL']
        }
        let data = {
            password: this.state.localData.password,
            actions: actions,
            pid: '5gEdge',
            email: '5gEdge@gmail.com',
        };
        // let id = '5gEdge';
        PfApiCall(pfUrlMapping.createUser(data, this.state.auth_token), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response.status === 201) {
                this.setState({
                    usernameChange: false,
                })
                this.createPassword()
            }
            else {
                this.savePassword();
            }
        });
    }
    createPassword = () => {
        this.setState({
            showLoader: true,
        })
        let actions = {
            type: 'set_access_level',
            value: ['ALL']
        }
        let data = {
            password: this.state.localData.password,
            actions: actions,
            pid: '5gEdge',
            email: '5gEdge@gmail.com',
        };
        PfApiCall(pfUrlMapping.createUserPassword(data, this.state.auth_token), (response) => {
            if (response.status === 201) {
                PfApiCall(pfUrlMapping.restartRadius(this.state.auth_token), (response) => {
                    if (response.status === 200) {
                        PfApiCall(pfUrlMapping.restartWinBindService(this.state.auth_token), (response) => {
                            if (response.status === 200) {
                                this.setState({
                                    usernameChange: false,
                                })
                                this.putAuthServerConfiguration()
                            }
                        })
                    }
                })
            } else {
                this.savePassword();
            }
        });
    }
    saveLocalData = () => {
        this.setState({
            localEnabled: true,
            enable: 'local',
            showLoader: true
        },
            () => {
                if (this.state.data?.local?.password === '') {
                    this.createUser();
                } else {
                    this.setState({
                        localEnabled: true,
                        enable: 'local'
                    }
                    )
                    if (this.state.pwdchanged) {
                        let data = {
                            login_remaining: null,
                            password: this.state.localData.password,
                            pid: "5gEdge",
                            tenant_id: this.state.tenant_id
                        }
                        PfApiCall(pfUrlMapping.setLocalPassword(data, this.state.auth_token), (response) => {
                            if (response.status === 200) {
                                this.removeNULLrealM();
                                this.setState({
                                    pwdchanged: false
                                })
                            }
                        })
                    } else
                        this.removeNULLrealM();
                    // this.savePassword()
                }
            }
        )
    }
    removeNULLrealM = () => {
        this.setState({
            showLoader: true,
        })
        let data = { radius_auth: [""] };
        PfApiCall(pfUrlMapping.removeNULLrealM(data, this.state.auth_token), (response) => {
            if (response.status === 200) {
                // restart radius
                PfApiCall(pfUrlMapping.restartRadius(this.state.auth_token), (response) => {
                    if (response.status === 200) {
                        PfApiCall(pfUrlMapping.restartWinBindService(this.state.auth_token), (response) => {
                            if (response.status === 200) {
                                this.setState({
                                    title: 'Update Settings',
                                    message: response.message,
                                    show: true,
                                    error: false,
                                }, () => {
                                    $("#errorMessage").modal("show")
                                });
                                this.putAuthServerConfiguration()
                            } else {
                                this.setState({
                                    showLoader: false,
                                });
                            }
                        })
                    } else {
                        this.setState({
                            showLoader: false,
                        });
                    }
                })
            } else {
                this.setState({
                    title: 'Update Settings',
                    message: response.response.data.message,
                    show: true,
                    error: true,
                }, () => $("#errorMessage").modal("show"));
            }
        });
    }
    putAuthServerConfiguration = () => {
        let { data } = this.state;
        data.settings.enable = this.state.service.toLocaleLowerCase();
        data.settings.switch_ip = this.state.switchIp;
        data.settings.secret_passphrase = this.state.switchPass;
        data.local = this.state.localData;
        data.local.switch_ip = this.state.switchIp;
        data.remote = this.state.remoteData;
        data.azure_ad = this.state.azureData;
        ApiCall(urlMapping.putAuthServerConfiguration(data), (response) => {
            this.setState({
                showLoader: false,
            });
            if (parseInt(response?.response?.status) === 200) {
                this.setState({
                    title: 'Auth Server Configuration',
                    message: (this.state.createSwitch
                        ? (response?.message + ". The device is going to restart; please use it after some time.")
                        : response?.message),
                    show: true,
                    error: false,
                    realM_created: false,
                    createSwitch: false
                }, () => {
                    this.getAuthServerConfiguration();
                    $("#errorMessage").modal("show")
                });
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        title: 'Auth Server Configuration',
                        message: response?.message,
                        show: true,
                        error: true,
                        realM_created: false,
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        });
    }
    checkRemoteError = () => {
        let result = false;
        const elements = document.getElementsByClassName('error-txt remote');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (this.state.remoteData.server_ip === '' || this.state.remoteData.port === '' || this.state.remoteData.pre_shared_key === '') {
            result = true;
        }
        return result;
    }
    openAzure = () => {
        this.login();
        this.setState({
            openSlide: true,
            azure: true,
            azureData: JSON.parse(JSON.stringify(this.state.data.azure_ad))
        })
    }
    closeAzure = () => {
        this.setState({
            openSlide: false,
            azure: false
        })
    }
    setAzureValue = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                showError: true,
            });
        }
        let data = this.state.azureData;
        data[e.target.name] = e.target.value;
        this.setState({
            azureData: data
        })
    }
    checkAzureError = () => {
        let result = false;
        const elements = document.getElementsByClassName('error-txt');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (this.state.azureData.client_id === '' || this.state.azureData.client_secret === '' ||
            this.state.azureData.tenant_id === '' || this.state.azureData.realm === '') {
            result = true;
        }
        return result;
    }
    validateRealm = (e) => {
        if (!Common.validateRealm(e)) {
            this.setState({
                showError: true
            })
        }
    }
    saveAzureData = () => {
        if (this.state.auth_token) {
            this.setState({
                showLoader: true,
            })
            let data = {
                "id": "AzureAD",
                "isClone": false,
                "isNew": true,
                "sourceType": "AzureAD",
                "administration_rules": [],
                "authentication_rules": [],
                "client_id": this.state.azureData?.client_id,
                "client_secret": this.state.azureData?.client_secret,
                "description": "Azure AD",
                "realms": [],
                "tenant_id": this.state.azureData?.tenant_id,
                "type": "AzureAD",
            };
            PfApiCall(pfUrlMapping.remoteService(data, this.state.auth_token), (response) => {
                if (response.status === 201) {
                    this.createRealm();
                } else if (response.message === 'Request failed with status code 409') {
                    PfApiCall(pfUrlMapping.updateRemoteService(data, data.id, this.state.auth_token), (response) => {
                        this.createRealm();
                    })
                }
            })
        } else {
            this.setState({
                title: 'Packet Fence',
                message: "Please login first!!",
                show: true,
                error: true,
            }, () => $("#errorMessage").modal("show"));
        }
    }
    createRealm = () => {
        const realm_data = {
            "id": this.state.azureData?.realm,
            "isClone": false,
            "isNew": true,
            "admin_strip_username": "disabled",
            "azuread_source_ttls_pap": "AzureAD",
            "portal_strip_username": "disabled",
            "radius_strip_username": "disabled",
            "eduroam_radius_auth_proxy_type": "keyed-balance",
            "radius_acct_proxy_type": "load-balance",
            "radius_auth_proxy_type": "keyed-balance",
            "eduroam_radius_acct_proxy_type": "load-balance",
        }
        PfApiCall(pfUrlMapping.createRealm(realm_data, this.state.auth_token), (response) => {
            if (response.status === 201) {
                PfApiCall(pfUrlMapping.restartRadius(this.state.auth_token), (response) => {
                    if (response.status === 200) {
                        PfApiCall(pfUrlMapping.restartWinBindService(this.state.auth_token), (response) => {
                            if (response.status === 200) {
                                this.putAuthServerConfiguration();
                            }
                        })
                    } else {
                        this.setState({
                            showLoader: false,
                        })
                    }
                })
            } else if (response.message === 'Request failed with status code 409') {
                PfApiCall(pfUrlMapping.updateRealm(realm_data, this.state.auth_token), (response) => {
                    if (response.status === 200) {
                        PfApiCall(pfUrlMapping.restartRadius(this.state.auth_token), (response) => {
                            if (response.status === 200) {
                                PfApiCall(pfUrlMapping.restartWinBindService(this.state.auth_token), (response) => {
                                    if (response.status === 200) {
                                        this.putAuthServerConfiguration();
                                    }
                                })
                            } else {
                                this.setState({
                                    showLoader: false,
                                })
                            }
                        })
                    } else {
                        this.setState({
                            showLoader: false,
                        })
                    }
                })
            }
        })
    }
    render() {
        return (
            <>
                {this.state.showLoader && <Loader />}
                <div className="packetfence tab-box">
                    <div className="white-bg">
                        <div className="ta-box set-pos mb-0">
                            <div className="action-btn d-flex align-items-center">
                                <button
                                    onClick={() =>
                                        this.getAuthServerConfiguration()
                                    }
                                >
                                    <img src="/images/svg-icons/refreshing.svg" alt="" />{" "}
                                    Refresh
                                </button>
                            </div>
                        </div>
                        <div className="tab-content mt-2">
                            {
                                this.state?.switch_ip === '' ?
                                    <>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Switch IP</th>
                                                    <th>Switch Password
                                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Shared secret used to communicate between the router and the packetfence">
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                        <span className="red-txt">*</span></th>
                                                    <th>Authenticate against local users database
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Use the local accounts in the password table to authenticate 802.1x users. Requires to have the passwords in clear text or NTLM.">
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <input
                                                            type='text'
                                                            className="form-control"
                                                            value={this.state.switchIp}
                                                            readOnly
                                                            name="switchIp" />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type='text'
                                                            className="form-control"
                                                            disabled={this.props?.userRole === "Viewer_Group" ||
                                                                this.props?.userRole === "Operator_Group"}
                                                            onChange={(e) =>
                                                                (this.props?.userRole === "Viewer_Group" ||
                                                                    this.props?.userRole === "Operator_Group")
                                                                    ? undefined
                                                                    : this.setSwitchPasswordValue(e)}
                                                            name="switchPass" />
                                                    </td>
                                                    <td><label className="switch mt-2">
                                                        <input
                                                            type="checkbox"
                                                            checked
                                                            name="advancedOptions" />
                                                        <span className={
                                                            (this.props?.userRole === "Viewer_Group" ||
                                                                this.props?.userRole === "Operator_Group")
                                                                ? "slider round disabled"
                                                                : "slider round"} />
                                                    </label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <button
                                            className="btn btn-primary"
                                            disabled={this.state.switchPass === '' ||
                                                (this.props?.userRole === "Viewer_Group" ||
                                                    this.props?.userRole === "Operator_Group")
                                            }
                                            onClick={() => this.createSwitch()}
                                        >Create Switch</button>
                                    </>
                                    :
                                    <>
                                        <div className="tab-pane active" id="local-source" role="tabpanel" aria-labelledby="local-source-tab">
                                            <div className="page-sub-title mt-2">
                                                <div className="d-flex align-items-center">
                                                    <strong>Authentication Service</strong>
                                                </div>
                                                {/* <div className="device-action">
                                                    <span className="icon-box">
                                                        <img src="/images/svg-icons/edit.svg" alt=""
                                                            disabled={this.state.data.localData === undefined && this.state.data.remoteData === undefined}
                                                            onClick={() => this.openSlide()} />
                                                    </span>
                                                </div> */}
                                            </div>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Profile Name</th>
                                                        <th>Authentication Type</th>
                                                        <th>Server IP</th>
                                                        <th>Port</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{this.state.data.settings.enable === 'local' ? 'Local Radius' : 'Cloud Radius'}</td>
                                                        <td>RADIUS</td>
                                                        <td>{this.state.data.settings.enable === 'local' ? this.state.localData?.switch_ip :
                                                            this.state.remoteData?.server_ip}</td>
                                                        <td>{this.state.data.settings.enable === 'local' ? this.state.localData?.port :
                                                            this.state.remoteData?.port}</td>
                                                        <td className="text-center">
                                                            <span className={
                                                                (this.state.data.settings.device_status === 1 &&
                                                                    (this.props?.userRole !== "Viewer_Group" && this.props?.userRole !== "Operator_Group"))
                                                                    ? "icon-box"
                                                                    : "icon-box disabled"}>
                                                                <img
                                                                    src="/images/svg-icons/edit-blue.svg" alt=""
                                                                    disabled={(this.state.data.localData === undefined && this.state.data.remoteData === undefined)
                                                                        || this.state.data.settings.device_status === 0}
                                                                    onClick={
                                                                        (this.state.data.settings.device_status === 1 &&
                                                                            (this.props?.userRole !== "Viewer_Group" && this.props?.userRole !== "Operator_Group"))
                                                                            ? () => this.openSlide()
                                                                            : undefined} />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Profile Name</th>
                                                        <th>Authentication Type</th>
                                                        <th>Client ID</th>
                                                        <th>Realm</th>
                                                        {/* <th>Tenant</th> */}
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>CloudAzureAD</td>
                                                        <td>Azure Active Directory</td>
                                                        <td>{this.state.data.azure_ad?.client_id}</td>
                                                        <td>{this.state.data.azure_ad?.realm}</td>
                                                        {/* <td>{this.state.data.azure_ad?.tenant_id}</td> */}
                                                        <td className="text-center">
                                                            <span className={
                                                                (this.state.data.settings.device_status === 1 &&
                                                                    (this.props?.userRole !== "Viewer_Group" && this.props?.userRole !== "Operator_Group"))
                                                                    ? "icon-box"
                                                                    : "icon-box disabled"}>
                                                                <img src="/images/svg-icons/edit-blue.svg" alt=""
                                                                    onClick={(this.state.data.settings.device_status === 1 &&
                                                                        (this.props?.userRole !== "Viewer_Group" && this.props?.userRole !== "Operator_Group"))
                                                                        ? () => this.openAzure()
                                                                        : undefined} />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                    {/* Local slide */}
                    <div
                        className={`slide-overlay ${this.state.openSlide && this.state.auth_token ? "open" : ""}`}
                    >
                        <div className="sidebar-slide w-500 open">
                            <div className="s-header">
                                <h1 className="ss-title">{`${this.state.service} Service`}
                                </h1>
                                <button>
                                    <img src="/images/svg-icons/s-cancel.svg" alt=""
                                        onClick={() => this.closeSlide()} />
                                </button>
                            </div>
                            <div className="s-body">
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <div>Local Service
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Packetfence server mode">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </div>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            onChange={(e) => this.changeService(e)}
                                            checked={this.state.service === 'Remote'}
                                            name="advancedOptions" />
                                        <span className="slider round"></span>
                                    </label>
                                    <div>Remote Service
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Packetfence relay mode">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                {(this.state.openSlide && this.state.service === 'Local') && (
                                    <>
                                        <div className="form-group">
                                            <label>Profile Name
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Name of the packetfence profile">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                            </label>
                                            <input type="text" value="Local Radius" readOnly className="form-control" />
                                        </div>

                                        <div className="form-group">
                                            <label>Authentication Type<span className="red-txt">*</span></label>
                                            <input type="text" value="Radius" readOnly className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label>Server IP
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Packetfence server IP">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                            </label>
                                            <input type="text"
                                                value={this.state.localData.switch_ip}
                                                readOnly className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label>Port
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Packetfence server port">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                            </label>
                                            <input type="text" value="1812" readOnly className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label>Username
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Username attributes for local calauthentication">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                                <span className="red-txt">*</span></label>
                                            <input type="text"
                                                value='5gEdge'
                                                readOnly
                                                name='username'
                                                onChange={(e) => this.setLocalValue(e)}
                                                disabled={this.state?.localData?.username}
                                                className="form-control" />

                                        </div>
                                        <div className="form-group">
                                            <label>Password
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Password attributes for local authentication">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                                <span className="red-txt">*</span></label>
                                            <input className="form-control" type="password" id="password" placeholder="" maxLength="120"
                                                defaultValue={this.state.localData?.password}
                                                name='password'
                                                onChange={(e) => this.setLocalValue(e)} />
                                            <div className="sh-icon">
                                                <img src={this.state.showPassword ? "/images/show.png" : "/images/hide.png"} alt="" onClick={() => this.showPass()} />
                                            </div>
                                        </div>
                                        <div className="s-footer">
                                            <button className="btn btn-light" onClick={() => this.closeSlide()}>Cancel</button>
                                            <button className="btn btn-primary"
                                                disabled={this.state.localData.password === ''}
                                                onClick={() => this.saveLocalData()}>Save</button>
                                        </div></>)}
                                {/* close local slide */}
                                {/* remote slide */}
                                {this.state.openSlide && this.state.service === 'Remote' && (
                                    <>
                                        <div className="form-group">
                                            <label>Profile Name
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Name of the packetfence profile">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                            </label>
                                            <input type="text" value="Cloud Radius" readOnly className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label>Authentication Type
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="RADIUS based authentication">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                                <span className="red-txt">*</span></label>
                                            <input type="text" value="Radius" readOnly className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label>Server IP
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Remote free RADIUS server IP">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                                <span className="red-txt">*</span></label>
                                            <input
                                                type="text"
                                                value={this.state.remoteData.server_ip}
                                                name='server_ip'
                                                onChange={(e) => this.setRemoteValue(e)}
                                                onBlur={(e) => this.validateIP(e)}
                                                className="form-control" />
                                            <div className="error-txt remote" id="server_ip" >Invalid value</div>
                                        </div>
                                        <div className="form-group">
                                            <label>Port
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Remote free RADIUS server port">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                                <span className="red-txt">*</span></label>
                                            <input
                                                type="text"
                                                value={this.state.remoteData?.port}
                                                name='port'
                                                onChange={(e) => this.setRemoteValue(e)}
                                                className="form-control" />
                                            <div className="error-txt remote" id="port" >Invalid value</div>
                                        </div>
                                        <div className="form-group">
                                            <label>Pre-Shared Key
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Remote free RADIUS preshared key">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                                <span className="red-txt">*</span></label>
                                            <input
                                                type="text"
                                                value={this.state.remoteData?.pre_shared_key}
                                                name='pre_shared_key'
                                                onChange={(e) => this.setRemoteValue(e)}
                                                className="form-control" />
                                            <div className="error-txt remote" id="pre_shared_key" >Invalid value</div>
                                        </div>
                                        <div className="s-footer">
                                            <button className="btn btn-light" onClick={() => this.closeSlide()}>Cancel</button>
                                            <button className="btn btn-primary" disabled={this.checkRemoteError()}
                                                onClick={() => { (this.state.data?.remote.server_ip !== '') ? this.updateRemoteData() : this.saveRemoteData() }}>
                                                Save</button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        {/* close remote slide */}
                    </div>
                    {/* Azure slide */}
                    <div
                        className={`slide-overlay ${this.state.openSlide && this.state.azure ? "open" : ""}`}
                    >
                        <div className="sidebar-slide w-500 open">
                            <div className="s-header">
                                <h1 className="ss-title">Azure AD
                                </h1>
                                <button>
                                    <img src="/images/svg-icons/s-cancel.svg" alt=""
                                        onClick={() => this.closeAzure()} />
                                </button>
                            </div>
                            <div className="s-body">
                                <div className="form-group">
                                    <label>Profile Name
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Name of the packetfence profile">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>
                                    <input type="text" value="CloudAzureAD" readOnly className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>Authentication Type</label>
                                    <input type="text" value="Azure Active Directory" readOnly className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>Client ID
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                            title="Client ID that was displayed while configuring the 'PacketFence' app inside Azure">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                        <span className="red-txt">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        value={this.state.azureData?.client_id} name="client_id"
                                        onBlur={(e) => Common.validateAlphaNumericSpecial(e)}
                                        onChange={(e) => this.setAzureValue(e)}
                                        className="form-control" />
                                    <div className="error-txt" id="client_id" >Invalid value</div>
                                </div>
                                <div className="form-group">
                                    <label>Client Secret
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                            title="Secret created inside the 'PacketFence' app in Azure AD">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                        <span className="red-txt">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        value={this.state.azureData?.client_secret}
                                        name="client_secret"
                                        onChange={(e) => this.setAzureValue(e)}
                                        onBlur={(e) => Common.validateAlphaNumericSpecial(e)}
                                        className="form-control" />
                                    <div className="error-txt" id="client_secret" >Invalid value</div>
                                </div>
                                <div className="form-group">
                                    <label>Tenant ID
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                            title="Tenant ID that was displayed while configuring the 'PacketFence' app inside Azure">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                        <span className="red-txt">*</span></label>
                                    <input
                                        type="text"
                                        value={this.state.azureData?.tenant_id}
                                        name='tenant_id'
                                        onChange={(e) => this.setAzureValue(e)}
                                        onBlur={(e) => Common.validateAlphaNumericSpecial(e)}
                                        className="form-control" />
                                    <div className="error-txt" id="tenant_id" >Invalid value</div>
                                </div>
                                <div className="form-group">
                                    <label>Realm
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                            title="Realm of  Azure AD users">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                        <span className="red-txt">*</span></label>
                                    <input
                                        className="form-control"
                                        value={this.state.azureData?.realm}
                                        name='realm'
                                        onBlur={(e) => this.validateRealm(e)}
                                        onChange={(e) => this.setAzureValue(e)} />
                                    <div className="error-txt" id="realm" >Invalid value</div>
                                </div>
                                <div className="s-footer">
                                    <button className="btn btn-light" onClick={() => this.closeAzure()}>Cancel</button>
                                    <button className="btn btn-primary"
                                        disabled={this.checkAzureError()}
                                        onClick={() => this.saveAzureData()}>Save</button>
                                </div>
                            </div>
                            {/* close local slide */}
                        </div>
                    </div>
                </div>
                {(this.state.show) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        reset={() => this.resetPopup()}
                    />
                }
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        userRole: String(state.auth.role)
    };
};
export default withRouter(connect(mapStateToProps)(Packetfence));
