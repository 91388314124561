/* eslint-disable array-callback-return */
import React, { Component } from "react";
import Select, { components } from 'react-select';
import { connect } from "react-redux";
import * as Action from "../../../../redux/actionTypes";
import urlMapping from "../../../../redux/API/api";
import ApiCall from "../../../../redux/API/apiCall";
import $ from "jquery";
import { debounce } from "lodash";
import { ErrorPopup } from "../../../../layOuts/errorPopup";
import { Loader } from "../../../../layOuts/Loader";
import * as Common from '../../../../utils/common';
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
const ButtonComponent = (props, onClickHandler, id) => {
  return (
    <>
      <components.MenuList {...props}>
        <div>{props.children}</div>
        <button data-toggle="modal" data-target={id} onClick={onClickHandler} name="addIP" className="btn btn-primary btn-block mt-3">+ Add DNS IP address</button>
      </components.MenuList>
    </>
  );
};
class DNSTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UpstreamServersData: [
        { title: 'None', value: 'none' },
        { title: '1.1.1.1, 1.0.0.1  (Cloudflare - Public DNS)', value: '1.1.1.1, 1.0.0.1' },
        { title: '1.1.1.2, 1.0.0.2  (Cloudflare - No Malware)', value: '1.1.1.2, 1.0.0.2' },
        { title: '1.1.1.3, 1.0.0.3  (Cloudflare - No Malware and Adult Content)', value: '1.1.1.3, 1.0.0.3' },
        { title: '208.67.222.123, 208.67.220.123  (OpenDNS - FamilyShield)', value: '208.67.222.123, 208.67.220.123' }
      ],
      selectedUpStream: "none",
      dns_data: this.props.template_schema_json?.dns_data ? this.props.template_schema_json?.dns_data : {
        UpstreamServers: ["none"],
        mode: "blacklist",
        BlDomains: [],
        WlDomains: [],
      },
      enterBlValue: '',
      enterWlValue: '',
      isBlackList: true,
      keyUpdateRes: "",
      toggledClearRows: false,
      update: false,
      selectedTemplateId: this.props.id ? this.props.id : "",
      template_type: this.props?.templateData?.template_type || "",
      template_name: this.props?.templateData?.template_name || "",
      template_desc: this.props?.templateData?.template_desc || "",
      device_model: this.props?.templateData?.device_model || "",
      collection_version: this.props?.templateData?.collection_version || "",
      fw_version: this.props?.templateData?.fw_version || "",
      chimay: this.props.chimay,
      cbr: this.props.cbr,
      mifi: this.props.mifi,
      selectedTab: 'DNSFiltering',
      multipleSelect: "",
      openAddForward: false,
      customIP_address: [],
      isAxiosError: false,
      isSameIP: false,
      template_schema_json: {
        dns_data: {
          UpstreamServers: ["none"],
          mode: "blacklist",
          BlDomains: [],
          WlDomains: [],
        },
        "dns_forwarding": []
      },
      ForwardingData: {
        "id": Math.random().toString(36).substring(2, 11).toUpperCase(),
        "enable": "1",
        "ipaddr": "",
        "DomainName": "",
        isdeleted: "0"
      },
      ForwardColumns: [
        {
          name: "Domain name",
          selector: "DomainName",
          cell: (row) => (
            <span title={row.DomainName} style={{ width: "auto" }} className="cursor-pointer"
            ><Link to={{}} className="txt-blue" onClick={() => this.openAddForward(row)} >{" "}{row.DomainName}
              </Link></span>
          ),
        },
        {
          name: "Enable",
          selector: "enable",
          cell: (row) =>
          (<label className="switch gray"><input type="checkbox"
            checked={row.enable === '1'} />
            <span className="slider round" style={{ cursor: "not-allowed" }}>
            </span>
          </label>
          ),
        },
        {
          name: "IP Address",
          selector: "ipaddr",
          cell: (row) =>
          (<span title={row.ipaddr} style={{ width: 'auto' }}
          > {row.ipaddr}
          </span>
          ),
        },
      ],
    };
  }
  componentDidMount() {
    if (this.props.template_schema_json?.dns_data) {
      this.setState({
        dns_data: JSON.parse(JSON.stringify(this.props.template_schema_json.dns_data)),
        isBlackList: this.props.template_schema_json?.dns_data?.mode === 'whitelist' ? false : true,
        selectedUpStream: this.props.template_schema_json?.dns_data?.UpstreamServers?.[0],
        template_schema_json: JSON.parse(JSON.stringify(this.props.template_schema_json))
      })
    }
    if (this.state.dns_data?.WlDomains?.length === 0) {
      let devUrl = process.env.REACT_APP_API_URL;
      let url = devUrl?.split('//')?.[1]?.split(':')?.[0]
      this.setState({ dns_data: { ...this.state.dns_data, WlDomains: [url] } })
    }
    if (this.state.UpstreamServersData.filter(item => item.value === this.props.template_schema_json?.dns_data?.UpstreamServers?.[0])?.length < 1) {
      let { UpstreamServersData } = this.state;
      UpstreamServersData.push({
        title: this.props.template_schema_json?.dns_data?.UpstreamServers?.[0],
        value: this.props.template_schema_json?.dns_data?.UpstreamServers?.[0]
      });
      this.setState({
        UpstreamServersData
      }, () => this.getOptions())
    } else
      this.getOptions();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.templateData).length !== 0) {
      if (nextProps.templateData !== undefined) {
        this.setState({
          selectedTemplateId: nextProps.id ? nextProps.id : "",
          template_type: nextProps?.templateData?.template_type || "",
          template_name: nextProps?.templateData?.template_name || "",
          template_desc: nextProps?.templateData?.template_desc || "",
          device_model: nextProps?.templateData?.device_model || "",
          collection_version: nextProps?.templateData?.collection_version || "",
          fw_version: nextProps?.templateData?.fw_version || ""
        });
      }
    }
    if (nextProps.edit && (nextProps.template_schema_json !== undefined)) {
      if (!isEqual(this.props.template_schema_json, nextProps.template_schema_json)) {
        this.setState({
          template_schema_json: JSON.parse(JSON.stringify(nextProps.template_schema_json)),
          dns_data: JSON.parse(JSON.stringify(nextProps.template_schema_json.dns_data)),
          selectedUpStream: nextProps.template_schema_json.dns_data?.UpstreamServers?.[0],
          isBlackList: nextProps.template_schema_json?.dns_data?.mode === 'whitelist' ? false : true
        });
      }
    }
    if (!isEqual(this.props?.chimay, nextProps?.chimay) ||
      !isEqual(this.props?.cbr, nextProps?.cbr) ||
      !isEqual(this.props?.mifi, nextProps?.mifi)) {
      this.setState({
        chimay: nextProps.chimay,
        cbr: nextProps.cbr,
        mifi: nextProps.mifi
      })
    }
  }
  closeAll = () => {
    this.props.dispatch({
      type: Action.CLOSE_APPLY_TEMPLATE,
    });
    this.props.closeAddTemplate();
  };
  handleKeyDown(e, mode) {
    if (e.key === 'Enter') {
      this.enterDomain(e, mode)
    }
  }
  setSelectedUpstream = (e) => {
    let data = this.state.dns_data;
    data.UpstreamServers = [e.value];
    this.setState({ dns_data: data });
  }
  enterDomain(e, mode, cancel) {
    let data = this.state.dns_data;
    if (!cancel) {
      let isDomain = false
      //EMGT-199-EM - Field validation issues in DNS Filtering
      //EMGT-318-EM - Not able to give subdomains with multiple dots in DNS filtering Blacklist and Whitelist
      let regex = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,3}$/;
      let str = (e.target.value)?.trim();
      if (str.toLowerCase()?.substring(0, 4) === 'www.') {
        str = str.toLowerCase()?.substring(4, str?.length)
      }
      if (regex.test(str)) {
        let BLDomains = data?.BlDomains?.map(domain => {
          if (domain.toLowerCase().substring(0, 4) === 'www.') {
            return domain?.toLowerCase()?.substring(4, domain?.length)
          }
          else return domain
        })
        let WLDomains = data?.WlDomains?.map(domain => {
          if (domain.toLowerCase().substring(0, 4) === 'www.') {
            return domain?.toLowerCase()?.substring(4, domain?.length)
          }
          else return domain
        })
        if (mode === 'blackList' && !BLDomains?.includes(str)) {
          isDomain = true
        }
        else if (mode === 'whiteList' && !WLDomains?.includes(str)) {
          isDomain = true
        }
      }
      if (!isDomain) {
        this.setState({ enterBlValue: '', enterWlValue: '' })
      }
      if (e.target.value && !cancel && isDomain) {
        if (mode === 'blackList') {
          data.BlDomains?.push((e.target.value)?.trim())
        } else {
          data.WlDomains?.push((e.target.value)?.trim())
        }
      }
    }
    else {
      if (mode === 'blackList') {
        data.BlDomains = data.BlDomains?.filter(i => i !== e.target.id)
      } else {
        data.WlDomains = data.WlDomains?.filter(i => i !== e.target.id)
      }
    }
    this.setState({
      dns_data: data,
      enterBlValue: '',
      enterWlValue: ''
    })
  }
  saveData = () => {
    let data = {};
    data.template_name = this.state.template_name;
    data.template_desc = this.state.template_desc;
    data.device_model = this.props?.selectedModelList ? this.props?.selectedModelList?.toString() : this.state.device_model.toString();
    data.template_type = this.state.template_type;
    data.collection_version = this.state.collection_version;
    data.fw_version = this.state.fw_version;
    data.template_order = 12;
    if (this.state.selectedTemplateId === "") {
      data.template_version = {
        chimay: this.state.chimay,
        cbr: this.state.cbr,
        mifi: this.state.mifi
      }
    }
    let template_schema_json = {
      dns_data: this.state.dns_data,
      dns_forwarding: this.state.template_schema_json?.dns_forwarding ? this.state.template_schema_json?.dns_forwarding : []
    }
    data.template_schema_json = template_schema_json;
    this.setState({
      showLoader: true,
    });
    if (this.state.selectedTemplateId !== "") {
      ApiCall(
        urlMapping.updateTemplate(this.state.selectedTemplateId, data),
        (response) => {
          this.setState({
            showLoader: false
          });
          if (response?.Success) {
            this.props.refreshList();
            this.props.openApplyTemplate(this.state.selectedTemplateId);
            this.props.closeAddTemplate();
          } else {
            if (parseInt(response?.response?.status) === 401) {
              this.setState({
                showRefreshModal: true
              });
            }
            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
              this.setState({
                title: 'Network Error',
                message: 'Something went wrong. Please try again later.',
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"))
            }
            else {
              this.setState(
                {
                  title: "Update Template",
                  message: response?.message,
                  show: true,
                  error: true,
                },
                () => $("#errorMessage").modal("show")
              );
            }
          }
        }
      );
    } else {
      ApiCall(urlMapping.createTemplate(data), (response) => {
        this.setState({
          showLoader: false
        });
        if (response?.success) {
          this.props.refreshList();
          this.props.openApplyTemplate(response?.data?.id);
          this.props.closeAddTemplate();
        } else {
          if (parseInt(response?.response?.status) === 401) {
            this.setState({
              showRefreshModal: true
            });
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState(
              {
                title: "Create Template",
                message: response?.message,
                show: true,
                error: true,
              },
              () => $("#errorMessage").modal("show")
            );
          }
        }
      });
    }
  };
  //EMGT-381-EM - "Template name already exists" pop-up is not shown while creating custom template.
  resetPopup = () => {
    this.setState(
      {
        show: false,
        error: false,
      },
      () => {
        $("#errorMessage").modal("hide");
        this.props.refreshList();
        this.props.closeAddTemplate();
      }
    );
  };
  checkError = () => {
    let result = false;
    if (this.props.applying)
      result = true;
    const elements = document.getElementsByClassName("error-txt");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].style.cssText === "display: block;")
        result = true;
    }
    if (
      this.state.template_name === "" ||
      this.state.template_type === ""
    )
      result = true;
    return result;
  };
  applyTemplate = () => {
    let template_schema_json = {
      dns_data: this.state.dns_data,
      dns_forwarding: this.state.template_schema_json?.dns_forwarding ? this.state.template_schema_json?.dns_forwarding : []
    }
    this.props.dispatch({
      type: Action.SET_APPLY_TEMPLATE,
    });
    this.props.applyTemplate(template_schema_json)
  }
  debounceApply = debounce((fn, event) => {
    fn(event)
  }, 500);
  getOptions = () => {
    let options = [];
    this.state.UpstreamServersData?.map((item) =>
      options.push({ value: item.value, label: item.title })
    )
    this.setState({ options });
  }
  changeIp = () => {
    this.setState({
      customIP: true,
      customIP_address: []
    })
  }
  addIP = (e) => {
    let { UpstreamServersData } = this.state;
    if (this.state.validIP) {
      UpstreamServersData.push({
        title: eval(this.state.customIP_address)?.join(', '),
        value: eval(this.state.customIP_address)?.join(', ')
      })
      this.setState({
        customIP: false,
        customIP_address: [],
        validIP: false
      }, () => {
        this.getOptions();
        $("#errorMessage").modal("hide")
      })
    }
  }
  cancelIP = () => {
    let element = document.getElementById('ip_address');
    if (element) {
      element.style.display = 'none';
      element.innerHTML = "";
      this.setState({
        showError: false
      })
    }
    this.setState({
      customIP: false
    })
  }
  validateCustomIP = (e) => {
    this.removeErrorIP(e);
    if (e.target.value && (["Enter", "Tab", " ", ","].includes(e.key) || e.type === "blur")) {
      e.preventDefault();
      if (this.state.customIP_address.length < 2) {
        if (Common.validateIPaddress(e) === false || e.target.value === '0.0.0.0' ||
          e.target.value === '255.255.255.255') {
          const element = document.getElementById(e.target.name);
          if (element) {
            element.style.display = 'block';
            element.innerHTML = 'Invalid IP address';
            this.setState({
              showError: true
            })
          }
        } else {
          let { UpstreamServersData } = this.state;
          let valid = true;
          UpstreamServersData.map((item) => {
            if (item.value && item.value !== 'none') {
              const IPs = item.value.split(',');
              if (IPs.filter(i => i.trim() === e.target.value).length > 0)
                valid = false;
            }
          })
          if (valid) {
            if (this.state.customIP_address.filter(i => i === e.target.value).length === 0) {
              this.setState({
                customIP_address: [...this.state.customIP_address, e.target.value],
                showError: false,
                validIP: true,
              })
            }
            $("textarea[name=ip_address]").val("");
          } else {
            const element = document.getElementById(e.target.name);
            if (element) {
              element.style.display = 'block';
              element.innerHTML = 'IP address already exists';
              this.setState({
                showError: true,
              })
            }
          }
        }
      } else {
        const element = document.getElementById(e.target.name);
        if (element) {
          element.style.display = 'block';
          element.innerHTML = 'Only two IP address can be added at a time';
          this.setState({
            showError: true
          })
        }
      }
    }
  }
  handleDeleteIP = item => {
    let { customIP_address } = this.state;
    customIP_address = customIP_address.filter(i => i !== item)
    this.setState({
      customIP_address
    });
  };
  removeErrorIP = (e) => {
    if (!Common.removeError(e)) {
      this.setState({
        showError: false,
        validIP: true
      })
    }
  }
  openAddForward = (data) => {
    if (data) {
      const rowData = JSON.parse(JSON.stringify(data));
      this.setState({
        ForwardingData: rowData,
        openAddForward: true,
        update: true
      })
    } else {
      const elements = document.getElementsByClassName('error-txt');
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.cssText = "display: none;"
      }
      this.setState({
        openAddForward: true,
        ForwardingData: {
          "id": Math.random().toString(36).substring(2, 11).toUpperCase(),
          "enable": "1",
          "ipaddr": "",
          "DomainName": "",
          isdeleted: "0"
        }
      })
    }
  }
  closeAddForwarding = () => {
    const elements = document.getElementsByClassName('error-txt');
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.cssText = "display: none;"
    }
    this.setState({
      openAddForward: false,
      ForwardingData: {
        "id": Math.random().toString(36).substring(2, 11).toUpperCase(),
        "enable": "1",
        "ipaddr": "",
        "DomainName": "",
        isdeleted: "0"
      }
    })
  }
  checkForwardingError = () => {
    let result = false;
    const elements = document.getElementsByClassName("error-txt forward");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].style.cssText === "display: block;")
        result = true;
    }
    if (this.state.ForwardingData.DomainName === "" || this.state.ForwardingData.ipaddr === "")
      result = true;
    return result;
  }
  setDNSForwardingData = (e) => {
    if (Common.removeError(e)) {
      this.setState({
        showError: true
      })
    }
    if (this.state.isSameIP) {
      const element = document.getElementById("ipaddr");
      if (element) {
        element.style.display = 'none';
        this.setState({
          showError: false,
          isSameIP: false
        })
      }
    }
    const value = (e.target.type === "checkbox") ?
      (e.target.checked === true ? "1" : "0") : e.target.value;
    const data = this.state.ForwardingData;
    data[e.target.name] = value;
    this.setState({ ForwardingData: data })
  }
  addDNSForwarding = () => {
    let found = false;
    let { template_schema_json } = this.state;
    template_schema_json?.dns_forwarding?.map((item, index) => {
      if (item.id === this.state.ForwardingData.id) {
        found = true;
        template_schema_json.dns_forwarding[index] = this.state.ForwardingData;
      }
    })
    if (!found)
      template_schema_json.dns_forwarding?.push(this.state.ForwardingData);
    this.setState({
      update: false,
      template_schema_json,
      openAddForward: false,
      keyUpdateRes: Math.random(),
    }, () => this.closeAddForwarding())
  }
  validateDomainName = (e) => {
    let regex = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,3}$/;
    let str = (e.target.value).trim();
    if (str.toLowerCase().substring(0, 4) === 'www.') {
      str = str?.toLowerCase()?.substring(4, str?.length)
    }
    if (!regex.test(str)) {
      const element = document.getElementById(e.target.name);
      if (element) {
        element.style.display = 'block';
        element.innerHTML = "Invalid Domain name."
        this.setState({
          showError: true
        })
      }
    }
    this.isSameIP(this.state.ForwardingData.ipaddr)
  }
  validateIP = (e) => {
    if (Common.validateIPaddress(e) === false || e.target.value === '0.0.0.0' ||
      e.target.value === '255.255.255.255') {
      const element = document.getElementById(e.target.name);
      if (element) {
        element.style.display = 'block';
        element.innerHTML = 'Invalid IP address';
        this.setState({
          showError: true,
        })
      }
    }
    this.isSameIP(e.target.value)
  }
  isSameIP = (value) => {
    let isSameIP = false
    this.state.template_schema_json?.dns_forwarding?.map(item => {
      if (item.id !== this.state.ForwardingData.id &&
        item.ipaddr === value &&
        item.DomainName === this.state.ForwardingData.DomainName &&
        item.isdeleted !== "1") {
        isSameIP = true
      }
    })
    if (isSameIP) {
      const element = document.getElementById("ipaddr");
      if (element) {
        element.style.display = 'block';
        element.innerHTML = "IP Address already exists!"
        this.setState({
          showError: true,
          isSameIP: true
        })
      }
    }
  }
  multipleSelect(rows) {
    if (!rows.selectedRows.length)
      this.setState({ multipleSelect: "" });
    else {
      this.setState({ multipleSelect: rows.selectedRows }
      )
    }
  }
  deleteForward = () => {
    let data = this.state.template_schema_json;
    if (this.props.edit) {
      this.state.multipleSelect.map((item) => {
        let found = false;
        this.props.template_schema_json?.dns_forwarding?.map((server) => {
          if (item.id === server.id) {
            this.state.template_schema_json.dns_forwarding.filter(value => value.id === item.id)[0].isdeleted = "1";
            found = true;
          }
          return server;
        })
        if (found === false) {
          data.dns_forwarding = this.state.template_schema_json.dns_forwarding.filter(finalValue => finalValue.id !== item.id);
        }
      })
    } else {
      this.state.multipleSelect.map((item) => {
        data.dns_forwarding = this.state.template_schema_json.dns_forwarding.filter(finalValue => finalValue.id !== item.id);
      })
    }
    this.setState({
      template_schema_json: data,
      toggledClearRows: !this.state.toggledClearRows,
      keyUpdateRes: Math.random()
    })
  }
  render() {
    return (
      <div className="tab-box">
        {this.state.showLoader && <Loader />}
        <div className={this.state.openAddForward ? "disabled-slide" : ""}></div>
        <ul className="tab-nav nav nav-pills bg-gray" id="firewallTab" role="tablist">
          <li className="nav-item" role="presentation">
            <a
              className="nav-link active"
              id="DNS-Filter-tab"
              data-toggle="tab"
              href="#DNSFiltering"
              role="tab"
              aria-controls="DNSFiltering"
              aria-selected="true"
            // onClick={() => this.setState({selectedTab:'DNSFiltering'})}
            >DNS Filtering</a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link"
              id="DNSForward-tab"
              data-toggle="tab"
              href="#DNSForward"
              role="tab"
              aria-controls="DNSForward"
              aria-selected="false"
            // onClick={() => this.setState({selectedTab:'DNSForwarding'})}
            >DNS Forwarding</a>
          </li>
        </ul>
        <div className="tab-content mt-2">
          <div className="tab-pane active" id="DNSFiltering" role="tabpanel" aria-labelledby="DNS-Filter-tab">
            <div>
              <div className="DNS-template">
                <div className="row">
                  <div className="form-group col-sm-6">
                    <label>Upstream DNS Servers
                      <div
                        className="tooltip-c"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="All DNS request will be sent to the selected DNS servers. DHCP provided DNS server will not be used, if any server is selected.">
                        <img src="/images/svg-icons/info.svg" alt="" />
                      </div>
                    </label>
                    <Select
                      isClearable
                      components={{ MenuList: (props) => ButtonComponent(props, this.changeIp, '#exampleModalIP') }}
                      isSearchable
                      className="custom-dropdown"
                      classNamePrefix="react-select-container"
                      name="customip"
                      options={this.state.options}
                      value={{
                        label: this.state.UpstreamServersData?.filter(i => i.value === this.state.dns_data?.UpstreamServers?.[0])?.[0]
                          ? this.state.UpstreamServersData?.filter(i => i.value === this.state.dns_data?.UpstreamServers?.[0])?.[0]?.title
                          : this.state.dns_data?.UpstreamServers?.[0],
                        value: this.state.dns_data?.UpstreamServers?.[0]
                      }}
                      onChange={this.setSelectedUpstream}
                    />
                  </div>
                </div>
                <div className="gray-bg rounded p-2">
                  <div className="form-group mb-2">
                    <label>
                      <strong>Mode</strong>
                      <div
                        className="tooltip-c"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Select the operation mode"
                      >
                        <img src="/images/svg-icons/info.svg" alt="" />
                      </div>
                    </label>
                  </div>
                  <div className="mb-2 border-bottom pb-1">
                    <div className="d-inline-flex align-items-center">
                      <span className={`mr-3 ${this.state.isBlackList ? 'text-bold' : ''}`}>Blacklist</span>
                      <label className="switch both-cheked">
                        <input
                          type="checkbox"
                          name="advancedOptions"
                          checked={!this.state.isBlackList}
                          onChange={e => this.setState({ isBlackList: !e.target.checked, dns_data: { ...this.state.dns_data, mode: e.target.checked ? 'whitelist' : 'blacklist' } })} />
                        <span className="slider round"></span>
                      </label>
                      <span className={`ml-3 ${!this.state.isBlackList ? 'text-bold' : ''}`}>Whitelist</span>
                    </div>
                  </div>
                  <p className="mb-1 mt-1">
                    Blacklist
                    <div
                      className="tooltip-c"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Added domains will be blocked from resolving the DNS"
                    >
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </p>
                  <div className={`chip-list-border ${!this.state.isBlackList ? 'disabled-chip' : ''}`}>
                    {this.state.dns_data.BlDomains?.map(i => <div className="chip-set" id={i}>
                      {i} <img
                        src="/images/svg-icons/cancel.svg"
                        id={i} alt=""
                        onClick={this.state.isBlackList ? e => this.enterDomain(e, 'blackList', true) : undefined}
                      />
                    </div>)}
                    <textarea
                      onKeyDown={(e) => this.handleKeyDown(e, 'blackList')}
                      type="text"
                      disabled={!this.state.isBlackList}
                      onChange={e => this.setState({ enterBlValue: e.target.value })}
                      value={this.state.enterBlValue}
                      onBlur={e => this.enterDomain(e, 'blackList')} />
                  </div>
                  <p className="mb-1 mt-3">
                    Whitelist
                    <div
                      className="tooltip-c"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Added domains will be allowed to resolve the DNS. All other domains will be blocked from resolving the DNS"
                    >
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </p>
                  <div className={`chip-list-border ${this.state.isBlackList ? 'disabled-chip' : ''}`}>
                    {this.state.dns_data.WlDomains?.map(i => <div className="chip-set" id={i}>
                      {i} <img
                        src="/images/svg-icons/cancel.svg"
                        id={i}
                        alt=""
                        onClick={!this.state.isBlackList ? e => this.enterDomain(e, 'whiteList', true) : undefined} />
                    </div>)}
                    <textarea
                      onKeyDown={(e) => this.handleKeyDown(e, 'whiteList')}
                      disabled={this.state.isBlackList}
                      type="text"
                      onChange={e => this.setState({ enterWlValue: e.target.value })}
                      value={this.state.enterWlValue}
                      onBlur={e => this.enterDomain(e, 'whiteList')} />
                  </div>
                </div>
                {/* Add custom DNS */}
                {this.state.customIP &&
                  <div className="modal fade" id="exampleModalIP" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">Add IP for DNS</h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => this.setState({ customIP: false })}>
                            <span aria-hidden="true"><img src="/images/svg-icons/cancel.svg" alt="" /></span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="form-group mb-1">
                            {/* //LONG-2154 UI validation need to be corrected for some cases */}
                            <label>IP address</label>
                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Maximum 2 IP adresses can be added at a time">
                              <img src="/images/svg-icons/info.svg" alt="" />
                            </div>
                            <div className="chip-list-border">
                              {this.state.customIP_address?.map(i => <div className="chip-set" id={i}>
                                {i} <img
                                  src="/images/svg-icons/cancel.svg"
                                  id={i}
                                  alt=""
                                  onClick={() => this.handleDeleteIP(i)} />
                              </div>)}
                              <textarea
                                name="ip_address"
                                onKeyDown={(e) => this.validateCustomIP(e)}
                                type="text"
                                // value={Array.isArray(this.state.customIP_address) ? this.state.customIP_address.join(',') : this.state.customIP_address}
                                onBlur={e => this.validateCustomIP(e)} />
                            </div>
                            {/* <div className="d-flex align-items-center">
                              <input type="text" className="form-control w-100" name="ip_address"
                                onBlur={(e) => this.validateCustomIP(e)}
                                onChange={(e) => this.removeErrorIP(e)}
                              />
                            </div> */}
                            <div className="error-txt ip" id="ip_address" >Invalid IP address</div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-light"
                            data-dismiss="modal"
                            onClick={() => this.cancelIP()}
                          >Cancel</button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            disabled={!this.state.validIP || this.state.showError}
                            onClick={this.addIP}
                          >Add</button>
                        </div>
                      </div>
                    </div>
                  </div>}
                {/*EMGT-381-EM - "Template name already exists" pop-up is not shown while creating custom template. */}
                {(this.state.show) &&
                  <ErrorPopup
                    title={this.state.title}
                    message={this.state.message}
                    error={this.state.error}
                    reset={() => this.resetPopup()}
                  />
                }
              </div>
            </div>
          </div>
          <div className="tab-pane" id="DNSForward" role="tabpanel" aria-labelledby="DNSForward-tab">
            <div className="page-sub-title border-0 pb-0">
              <div><strong>DNS Forwarding</strong>
                <img
                  src="/images/svg-icons/add.svg"
                  className={
                    (this.props.templateData?.default_type !== ''
                      && this.props.templateData?.default_type !== null) ? "add-btn ml-2 disabled" : "add-btn ml-2"}
                  alt=""
                  onClick={() => this.openAddForward()}
                />
              </div>
              {<div className="device-action">
                <span
                  className={(this.state.multipleSelect === '' || this.state.multipleSelect?.length > 1) ?
                    "icon-box disabled" : "icon-box"}>
                  <img
                    src="/images/svg-icons/edit.svg"
                    alt=""
                    onClick={(this.state.multipleSelect === '' ||
                      this.state.multipleSelect?.length > 1) ? undefined :
                      () => this.openAddForward(this.state.template_schema_json?.dns_forwarding?.filter(item =>
                        item?.id === this.state.multipleSelect?.[0]?.id)?.[0])}
                  />
                </span>
                <span className={this.state.multipleSelect === '' ?
                  "icon-box disabled" : "icon-box"}>
                  <img
                    src="/images/svg-icons/delete.svg"
                    alt=""
                    onClick={this.state.multipleSelect === '' ? undefined : () => this.deleteForward()}
                  />
                </span>
              </div>}
            </div>
            <div key={this.state.keyUpdateRes}
            >
              {this.state.template_schema_json?.dns_forwarding?.length > 0 &&
                <DataTable
                  columns={this.state.ForwardColumns}
                  data={this.state.template_schema_json?.dns_forwarding?.filter(item => item?.isdeleted !== "1")}
                  selectableRows={(!this.props.info && !this.props.view && !this.props.reapply) ? true : false}
                  fixedHeader
                  onSelectedRowsChange={(data) => this.multipleSelect(data)}
                  clearSelectedRows={this.state.toggledClearRows}
                />}
            </div>
          </div>
        </div>
        {
          <div className="s-footer">
            {/* 1665 reapply page issue */}
            <button
              className="btn btn-light"
              onClick={
                this.props.apply && this.props.edit && this.props.reapply
                  ? () => this.props.closeApplyTemplate()
                  : () => this.closeAll()
              }
            >
              {/* 1665 reapply page issue */}
              {this.props.apply && !this.props.edit && !this.props.reapply
                ? "Back"
                : "Cancel"}
            </button>
            <button
              className="btn btn-primary"
              disabled={this.checkError()}
              onClick={this.props.apply ? (event) => this.debounceApply(this.applyTemplate, event)
                : () => this.saveData()}
            >
              {this.props.apply
                ? "Apply"
                : this.state.selectedTemplateId !== ""
                  ? "Update"
                  : "Save"}
            </button>
          </div>
        }
        {/* slide Add new DNS forwarding */}
        <div className={this.state.openAddForward ? "sidebar-slide w-350 r-757 second-open" : "sidebar-slide w-350 r-757"}>
          <div className="s-header">
            <h1 className="ss-title">{this.state.update ? 'Update DNS Forwarding' : 'Add New DNS Forwarding'}</h1>
            <button>
              <img src="/images/svg-icons/s-cancel.svg" alt=""
                onClick={() => this.closeAddForwarding()} />
            </button>
          </div>
          <div className="s-body">
            <div className="form-group">
              <label>Domain Name
                <span className="red-txt">*</span>
                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Forward DNS queries for a specific domain">
                  <img src="/images/svg-icons/info.svg" alt="" /></div>
              </label>
              <input
                type="text"
                className="form-control"
                name="DomainName"
                onChange={(e) => this.setDNSForwardingData(e)}
                readOnly={this.props.info || this.props.view || this.props.reapply}
                value={this.state.ForwardingData?.DomainName}
                onBlur={(e) => this.validateDomainName(e)} />
              <div className="error-txt forward" id='DomainName'>Invalid Domain name</div>
            </div>
            <div className="form-group">
              <label>IP Address
                <span className="red-txt">*</span>
                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="DNS Server IP">
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </label>
              <input
                type="text"
                className="form-control"
                name='ipaddr'
                readOnly={this.props.info || this.props.view || this.props.reapply}
                onChange={(e) => this.setDNSForwardingData(e)}
                value={this.state.ForwardingData?.ipaddr}
                onBlur={this.validateIP} />
              <div className="error-txt forward" id="ipaddr">Invalid IP address</div>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
              <span className="mr-5">Enabled
                <span className="red-txt">*</span>
                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable">
                  <img src="/images/svg-icons/info.svg" alt="" /></div></span>
              <label className="switch">
                <input
                  type="checkbox"
                  name='enable'
                  onChange={this.setDNSForwardingData}
                  checked={this.state.ForwardingData?.enable === '1'}
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="s-footer">
              <button
                className="btn btn-light"
                onClick={() => this.closeAddForwarding()}
              >Cancel</button>
              {<button
                className="btn btn-primary"
                disabled={this.checkForwardingError()}
                onClick={() => this.addDNSForwarding()}
              >{this.state.update ? 'Update' : 'Add'}</button>}
            </div>
          </div>
        </div>
        {(this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    applying: state.displayAddTemplate.applying
  }
};
export default connect(mapStateToProps)(DNSTemplate);
